import styled from "styled-components";

export const StyledDashboardContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 0.8em;
`
);

export const StyledDashboardFilters = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: flex-end;
  gap: 1em;
  flex-wrap: wrap;
`
);

export const StyledCardContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`
);

export const StyledRankingUser = styled.div(
  ({ theme }) => `
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`
);

export const StyledDashboardContent = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 2rem;
`
);

export const StyledDashboardTeamActivity = styled.div(
  ({ theme }) => `
  width: 100%;
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 0.8em 1em;
`
);

export const StyledRankingHeader = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  p {
    margin: 0;
  }
`
);

export const StyledRankingContainer = styled.div``;

export const StyledItemsSelected = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
`;

export const StyledFiltersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 1em;
`;

export const StyledInformationDot = styled.p`
  background-color: #f4f4f4;
  font-size: 14px;
  padding: 0 1em;
  margin: 0px;
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 20px;
  font-weight: 500;
`;

export const StyledInformationDotsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1em;
`;
