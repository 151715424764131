import { TPKeyValue } from "@/helpers/TPKeyValue";
import { QueueModel } from "@/models/EventsManager/CreateQueueModels";
import { SystemParametersEnum, TPActiveOptions, TPLanguageFilterEnum } from "@/models/Global/TPGlobalEnums";
import { QueueFilter } from "@/models/Groups/GroupsModels";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { CalendarsService } from "@/services/CalendarsService";
import { QueueService } from "@/services/EventsManager/QueueService";
import LanguageService from "@/services/LanguageService";
import { SchedulesService } from "@/services/SchedulesService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { UserGroupService } from "@/services/UserGroupService";
import { UserService } from "@/services/UserService";
import { useEffect, useState } from "react";

export const useQueuesData = function () {

  const [queues, setQueuesData] = useState<QueueModel[]>([]);
  const [queuesLoaded, setQueuesLoaded] = useState<boolean>(false);

  const getQueues = async function () {
    await QueueService.getQueues(2, false, false, [200])
      .then((response) => {
        setQueuesData(response);
        setQueuesLoaded(true);
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    if (!queuesLoaded) getQueues();
  }, [queuesLoaded])

  return { queuesLoaded, setQueuesLoaded, queues }
}

const blankSelectItem = '--';

export const useQueuesAdminSelectOptions = function () {
  const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
  const [scheduleOptions, setScheduleOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [timeZoneOptions, setTimeZoneOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [calendarOptions, setCalendarOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [superiorOptions, setSuperiorOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [defaultLanguageOptions, setDefaultLanguageOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [groupBehaviorOptions, setGroupBehaviorOptions] = useState<TPKeyValue[]>([]);
  const [interactionOutcomes, setInteractionOutcomes] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [classificationOptions, setClassificationOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [customFields, setCustomFields] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [profileFilterOptions, setProfileFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [teamsFilterOptions, setTeamsFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [functionsFilterOptions, setFunctionsFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);
  const [superiorFilterOptions, setSuperiorFilterOptions] = useState<TPKeyValue[]>([{ key: '', value: blankSelectItem }]);

  const getOptions = async function () {
    setOptionsLoaded(true);

    const scheduleClient = new SchedulesService();
    scheduleClient.getSchedulesByFilter(
      TPActiveOptions.ALL.toString(),
      false, false, [200])
      .then(response => {
        if (response) setScheduleOptions([...scheduleOptions,
        ...response.filter(schedule => schedule.isActive).map((schedule) => {
          return {
            key: schedule.id,
            value: schedule.description,
          } as TPKeyValue;
        })
        ])
      })

    const timezonesClient = new TimeZonesService();
    timezonesClient.getTimeZonesCollection(
      false, false, [200])
      .then(response => {
        if (response) setTimeZoneOptions([...timeZoneOptions,
        ...response.map((timezone) => {
          return {
            key: timezone.id,
            value: timezone.displayName,
          } as TPKeyValue;
        })]
        )
      })

    const calendarClient = new CalendarsService();
    calendarClient.getCalendarsByFilter(
      TPActiveOptions.ALL.toString(),
      false, false, [200])
      .then(response => {
        if (response) setCalendarOptions([
          ...calendarOptions,
          ...response.filter(calendar => calendar.isActive).map((calendar) => {
            return {
              key: calendar.id,
              value: calendar.description,
            } as TPKeyValue;
          })]
        )
      })

    const userClient = new UserService();
    userClient.getUsersByFilter(
      TPActiveOptions.ALL.toString(),
      false, false, [200])
      .then(response => {
        if (response) setSuperiorOptions([
          ...superiorOptions,
          ...response.filter(user => user.isActive).map((user) => {
            return {
              key: user.userGuid,
              value: user.name,
            } as TPKeyValue;
          })]
        )
      })

    const languageClient = new LanguageService();
    languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false, false, [200])
      .then(response => {
        if (response) setDefaultLanguageOptions([
          ...defaultLanguageOptions,
          ...response.responseData.data.map((lang: any) => {
            return {
              key: lang.id,
              value: lang.name,
            } as TPKeyValue;
          })
        ])
      })

    // const parameterServiceInstance = new ParametersService();
    // parameterServiceInstance.getByParentIdAndFilterIsActive(
    //   SystemParametersEnum.EVENTLISTINTOUT,
    //   TPActiveOptions.ACTIVE.toString(),
    //   false, false, [200]
    // )
    //   .then((response) => {
    //     if (response) setInteractionOutcomes([
    //       ...interactionOutcomes,
    //       ...response.map(item => {
    //         return {
    //           key: item.id,
    //           value: item.localizedDescription
    //         }
    //       })
    //     ])
    //   })

    // parameterServiceInstance.getByParentIdAndFilterIsActive(
    //   SystemParametersEnum.EVENTLISTCLAOPT,
    //   TPActiveOptions.ACTIVE.toString(),
    //   false, false, [200]
    // )
    //   .then((response) => {
    //     if (response) setClassificationOptions([
    //       ...classificationOptions,
    //       ...response.map(item => {
    //         return {
    //           key: item.id,
    //           value: item.localizedDescription
    //         }
    //       })
    //     ])
    //   })

    QueueService.getActiveLists(false, false, [200, 404])
    .then((lists: any[]) => {
      setClassificationOptions([
        ...classificationOptions,
        ...lists.map(list => {
          return {
            key: list.id,
            value: list.description
          } as TPKeyValue
        })
      ])
      setInteractionOutcomes([
        ...interactionOutcomes,
        ...lists.map(list => {
          return {
            key: list.id,
            value: list.description
          } as TPKeyValue
        })
      ])
    })
    .catch((err) => console.error(err))

    const additionalDataFormInstance = new AdditionalDataFormService();
    additionalDataFormInstance.getByFiltersIsActive(
      TPActiveOptions.ACTIVE.toString(),
      SystemParametersEnum.EVENTFORMTYPE,
      false, false, [200]
    )
      .then((response) => {
        if (response) setCustomFields([
          ...customFields,
          ...response.map(item => {
            return {
              key: item.id,
              value: item.localizedDescription
            }
          })
        ])
      })

    const userGroupServiceInstance = new UserGroupService();
    userGroupServiceInstance.getFilters(false, false, [200])
      .then((response) => {
        if (response) {
          setProfileFilterOptions([
            ...profileFilterOptions,
            ...response.filter(item => item.itemType == QueueFilter.Profile).map(item => {
              return {
                key: item.itemId,
                value: item.itemDescription
              }
            })
          ])
          setTeamsFilterOptions([
            ...profileFilterOptions,
            ...response.filter(item => item.itemType == QueueFilter.Team).map(item => {
              return {
                key: item.itemId,
                value: item.itemDescription
              }
            })
          ])
          setFunctionsFilterOptions([
            ...profileFilterOptions,
            ...response.filter(item => item.itemType == QueueFilter.Function).map(item => {
              return {
                key: item.itemId,
                value: item.itemDescription
              }
            })
          ])
          setSuperiorFilterOptions([
            ...profileFilterOptions,
            ...response.filter(item => item.itemType == QueueFilter.Superior).map(item => {
              return {
                key: item.itemId,
                value: item.itemDescription
              }
            })
          ])
        }
      })
  }

  useEffect(() => {
    getOptions();
  }, [])

  return {
    optionsLoaded,
    scheduleOptions,
    timeZoneOptions,
    calendarOptions,
    superiorOptions,
    defaultLanguageOptions,
    groupBehaviorOptions,
    classificationOptions,
    interactionOutcomes,
    customFields,
    profileFilterOptions,
    superiorFilterOptions,
    teamsFilterOptions,
    functionsFilterOptions
  }
}
