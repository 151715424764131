import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { GroupsService } from "@/services/GroupsService";
import { FC, useEffect, useState } from "react";
import { useQueuesAdminSelectOptions } from "../../assets/controller";
import useQueueAdminLabels from "../../assets/labels";
import "../../assets/Styles.css";

interface QueueGeneralInfoProperties {
  queueData?: any;
  groupId: number;
  tabCallback: Function;
  active: boolean;
  updateCallback: Function;
}

export enum RecordTypes {
  front = "Frontoffice",
  back = "Backoffice"
}

const QueueAdministrationTab: FC<QueueGeneralInfoProperties> = function ({ groupId, tabCallback, updateCallback, active }) {
  const { labels, labelsLoaded } = useQueueAdminLabels();
  const [classificationOptionsInput, setClassificationOptions] = useState<string>("");
  const [customFieldsInput, setCustomFields] = useState<string>("");
  const [interactionOutcomesInput, setInteractionOutcomes] = useState<string>("");
  const [recordType, setRecordType] = useState<RecordTypes>(RecordTypes.back);
  const [warningMinutes, setWarningMinutes] = useState<number>(0);
  const [warningSeconds, setWarningSeconds] = useState<number>(0);
  const [contentLoaded, setContentLoaded] = useState<boolean>(true);
  const [canSave, setCanSave] = useState(true);

  const {
    optionsLoaded,
    classificationOptions,
    interactionOutcomes,
    customFields
  } = useQueuesAdminSelectOptions();

  const fetchData = function() {
    setContentLoaded(false);
    const groupServiceInstance = new GroupsService();
    groupServiceInstance.getQueue(
      String(groupId), false, false, [200]
    )
    .then(queue => {
      if (queue) {
        setClassificationOptions(String(queue.clasificationOptions));
        setInteractionOutcomes(String(queue.interactionOutcomes));
        setCustomFields(String(queue.customFields));
        setWarningMinutes(queue.activateWarningAfterMinutes || 0);
        setWarningSeconds(queue.activateWarningAfterSeconds || 0);
        setRecordType(queue.enventGroupType as RecordTypes);
      }
      setContentLoaded(true);
    })
    .catch(error => console.error(error));
  }

  const handleSave = function () {
    if (!canSave) return;
    setCanSave(false);
    setContentLoaded(false);
    const groupServiceInstance = new GroupsService();
    groupServiceInstance.updateQueueAdministration({
      groupId: groupId,
      interactionOutcomes: interactionOutcomesInput,
      clasificationOptions: classificationOptionsInput.length > 0 ? classificationOptionsInput : null,
      customFields: customFieldsInput.length > 0 ? customFieldsInput : null,
      activateWarningAfterMinutes: warningMinutes,
      activateWarningAfterSeconds: warningSeconds,
      enventGroupType: recordType
    }, true, true, [200])
      .then(() => {
        setContentLoaded(true);
        updateCallback({
          result: 'ReloadGrid'
        })
      })
      .catch((err) => console.error(err))
      .finally(() => setCanSave(true))
  }

  const handleTimeInput = function (value: string, type: 'min' | 'sec') {
    let fixedValue: number = Number(value);
    if (isNaN(fixedValue) || fixedValue < 0 || value.length < 0) { fixedValue = 0; }
    if (fixedValue > 59) { fixedValue = 59; }

    if (type == 'min') setWarningMinutes(fixedValue);
    if (type == 'sec') setWarningSeconds(fixedValue);
  }

  useEffect( () => {
    fetchData();
  }, [])

  return (
    <>
      {active && <TPLoadingOverlay active={!(optionsLoaded && labelsLoaded && contentLoaded)}>
        <div id='queue-general-info-container' className="queue-admin-tab">
          <div><b>{labels.Description}</b></div>
          <div className="form-grid3" style={{ gap: '16px' }}>
            <div id='queue-admin-interaction-outcomes-input' className="queues-form-select">
              <TPSelect
                labelText={labels.InteractionOutcomes}
                isMandatory
                dataSource={interactionOutcomes}
                onChange={(event: any) => setInteractionOutcomes(event.target.value)}
                value={interactionOutcomesInput}
              />
            </div>
            <div id='queue-admin-classification-options-input'>
              <TPSelect
                labelText={labels.ClassificationOptions}
                dataSource={classificationOptions}
                onChange={(event: any) => setClassificationOptions(event.target.value)}
                value={classificationOptionsInput}
              />
            </div>
            <div id='queue-admin-custom-fields-input'>
              <TPSelect
                labelText={labels.CustomFields}
                dataSource={customFields}
                onChange={(event: any) => setCustomFields(event.target.value)}
                value={customFieldsInput}
              />
            </div>
          </div>
          <div className="form-grid3">
            <div id='queue-admin-warning-time-input'>
              <div><b>{labels.ActivateWarningAfter}</b></div>
              <div className="evenly-distributed" style={{ justifyContent: 'flex-start' }}>
                <b>({labels.Min} : {labels.Sec})</b>
                <TPTextBox
                  onChange={(event: any) => handleTimeInput(event.target.value, 'min')}
                  value={warningMinutes}
                  containerStyle={{ width: '64px' }}
                />
                <label>:</label>
                <TPTextBox
                  onChange={(event: any) => handleTimeInput(event.target.value, 'sec')}
                  value={warningSeconds}
                  containerStyle={{ width: '64px' }}
                />
              </div>
            </div>
            <div id='queue-admin-warning-time-input'>
              <div><b>{labels.RecordType}</b></div>
              <div className="evenly-distributed" style={{ justifyContent: 'flex-start', gap: '32px' }}>
                <TPRadio
                  checked={recordType == RecordTypes.front}
                  onClick={() => setRecordType(RecordTypes.front)}
                  label={labels.FrontOffice}
                />
                <TPRadio
                  checked={recordType == RecordTypes.back}
                  onClick={() => setRecordType(RecordTypes.back)}
                  label={labels.BackOffice}
                />
              </div>
            </div>
          </div>
          <div className="option-button-pair">
            <TPButton
              onClick={() => {
                if (tabCallback) tabCallback({
                  command: 'delete',
                  recordId: String(groupId)
                })
              }}
              isDesignSystem
              style={{
                backgroundColor:'white',
                color:'purple',
                paddingTop:'11px',
                paddingBottom:'11px',
                paddingLeft:'16px',
                paddingRight:'16px'
              }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => handleSave()}
              type={TPButtonTypes.primary}
              disabled={interactionOutcomesInput.length == 0 || !canSave}
              isDesignSystem
              style={{
                paddingTop:'11px',
                paddingBottom:'11px',
                paddingLeft:'16px',
                paddingRight:'16px'
              }}
            >
              {labels.Save}
            </TPButton>
          </div>
        </div>
      </TPLoadingOverlay>}
    </>
  )
}

export default QueueAdministrationTab;