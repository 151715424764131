import TPButton from '@/components/bootstrap/components/buttons/TPButton';
import TPIcon from '@/components/bootstrap/extend/TPIcons/TPIcon';
import TPLoadingOverlay from '@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay';
import TPCheckBox from '@/components/bootstrap/forms/checkbox/TPCheckBox';
import TPSelect from '@/components/bootstrap/forms/select/TPSelect';
import TPTextArea from '@/components/bootstrap/forms/textArea/TPTextArea';
import TPTextBox from '@/components/bootstrap/forms/textbox/TPTextBox';
import TPReorderableList from '@/components/TPDragList/TPDragList';
import { TPPageTitle } from '@/components/TPPage/tpPageStyles';
import TPSwitch from '@/components/TPSwitch/TPSwitch';
import TPGlobal from '@/helpers/TPGlobal';
import {
	VerticalTabsAdminContainerSlice,
	VerticalTabsAdminContainerStateModel
} from '@/layouts/VerticalTabs/VerticalTabsAdminContainer/_redux/VerticalTabsAdminContainerSlice';
import { SequenceGeneratorSequencesNameEnum, TPIconTypes } from '@/models/Global/TPGlobalEnums';
import { ListItemModel, ListModel, ListTypes, LocalizedValue } from '@/models/ListAdministration/ListAdministrationModels';
import { StoreModel } from '@/redux/store';
import { ListsServices } from '@/services/EventsManager/ListsService';
import { SequenceService } from '@/services/SequenceService';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useListAdminLabels from './assets/Labeling';
import './assets/Styles.css';
import ListAdminCancelModal from './components/CancelModal';
import ListAdminDraggableItem from './components/DraggableListItem';
import { ListItemUpdateModal } from './components/ListItemUpdate';
import { ListLocalizedNamesModal } from './components/ListLocalizedNamesModal';
import { showToast, TPToastTypes } from '@/components/bootstrap/components/toasts/TPToast';

interface NewListProperties {
	verticalTabCallback: Function;
	updateCallback: Function;
	mode: 'new' | 'update' | 'clone';
	recordId: any;
}

const ListManagement: FC<NewListProperties> = function ({ verticalTabCallback, mode, recordId, updateCallback }) {
	const { labels } = useListAdminLabels();
	const dispatch = useDispatch();
	const { exitRequest } = useSelector(
		(s: StoreModel) => s[VerticalTabsAdminContainerSlice.name]
	) as VerticalTabsAdminContainerStateModel;
	const [contentLoaded, setContentLoaded] = useState<boolean>(true);
	const [persistentName, setPersistentName] = useState<string>('');
	const [sourceListId,] = useState<string>(recordId);
	const [listId, setListId] = useState<string>(recordId);
	const [listName, setListName] = useState<string>('');
	const [listItemsInput, setListItemsInput] = useState<string>('');
	const [isActive, setIsActive] = useState<boolean>(true);
	const [alphabeticalOrderSelected, setAlphabeticalOrderSelected] = useState<boolean>(true);
	const [listItems, setListItems] = useState<ListItemModel[]>([]);
	const [localizedListNames, setLocalizedListNames] = useState<LocalizedValue[]>(
		TPGlobal.TPClientAvailableLanguages.map(lang => {
			return {
				languageId: lang.id,
				localizedValue: '',
				languageName: lang.name
			};
		})
	);
	const [listCreator, setListCreator] = useState("");
	const [changesMade, setChangesMade] = useState<boolean>(false);
	const [canChange, setCanChange] = useState(false);
	const [localizedNamesListVisible, setLocalizedNamesListVisible] = useState<boolean>(false);
	const [itemToUpdate, setItemToUpdate] = useState<ListItemModel | null>(null);
	const [initialState, setInitialState] = useState<ListModel>({} as ListModel);
	const [duplicateItems, setDuplicateItems] = useState(false);

	const fetchId = async function (sequenceName: SequenceGeneratorSequencesNameEnum) {
		const sequenceServiceInstance = new SequenceService();
		const response = await sequenceServiceInstance.generalAutomaticId(false, false, [200], sequenceName);
		return response.responseData.data[0].sequenceCode;
	};

	const handleItemsReorder = function (source: number, destination: number): void {
		const newItems = [...listItems];
		const aux = newItems[source];
		newItems[source] = newItems[destination];
		newItems[destination] = aux;
		setListItems(newItems);
	};

	const handleItemUpdate = function (itemId: string, newItemValues: ListItemModel) {
		setListItems([
			...listItems.map(item => {
				return item.id === itemId
					? {
							...item,
							isActive: newItemValues.isActive,
							description: newItemValues.description,
							descriptionLocalizedValues: newItemValues.descriptionLocalizedValues
						}
					: item;
			})
		]);
	};

	const handleItemDelete = function (itemId: string) {
		setListItems([...listItems.filter(item => item.id !== itemId)]);
	};

	const getNewItems = async function() {
		const newItems: ListItemModel[] = [];

		const itemsPromises = listItemsInput.split('\n').filter(item => item.trim().length > 0).map(async (item, index) => {
			const id = await fetchId(SequenceGeneratorSequencesNameEnum.SQLIIT);
			return {
				id: id,
				description: item.trim(),
				isActive: true,
				order: Math.max(...listItems.map(item => item.order)) + index,
				descriptionLocalizedValues: TPGlobal.TPClientAvailableLanguages.map(lang => {
					return {
						languageId: lang.id,
						localizedValue: lang.id == TPGlobal.language ? item : '',
						languageName: lang.name
					};
				})
			}
		})
		const finalItems = await Promise.all(itemsPromises);
		newItems.push(...finalItems);

		return newItems;
	}

	const handleListSave = async function () {
		setContentLoaded(false);
		let newItems: ListItemModel[] = [];
		if (listItemsInput.length > 0) newItems = await getNewItems();
		if (mode == 'new') {
					ListsServices.createList(
						{
							alphabeticalOrder: alphabeticalOrderSelected,
							description: listName,
							descriptionLocalizedValues: localizedListNames,
							id: listId,
							isActive: isActive,
							items: newItems.map((item, index) => {
								return {
									...item,
									order: index
								};
							}),
							type: ListTypes.S_EVENTLISTTYPE,
							createdBy: TPGlobal.currentUserName
						},
						true,
						true,
						[200]
					)
						.then((response) => {
							verticalTabCallback({
								command: 'delete',
								recordId: '--',
								languageId: TPGlobal.language,
								recordDescription: listId
							});
							verticalTabCallback({
								command: 'update',
								recordId: listId,
								languageId: TPGlobal.language,
								recordDescription: listId
							});
							updateCallback({
								result: 'ReloadGrid',
							});
							setContentLoaded(true);
						})
						.catch(() => setContentLoaded(true));
		} else if (mode == 'update') {
			const joinedItems = [
				...listItems,
				...newItems.filter(item => !(listItems.find(listIt => listIt.description === item.description)))
			]
			ListsServices.updateList(
				{
					alphabeticalOrder: alphabeticalOrderSelected,
					description: listName,
					descriptionLocalizedValues: localizedListNames,
					id: listId,
					isActive: isActive,
					type: ListTypes.S_EVENTLISTTYPE,
					items: joinedItems.map((item, index) => {
						return {
							...item,
							order: index
						}
					}),
					createdBy: listCreator
				},
				true,
				true,
				[200]
			).then(() => {
				setListItemsInput("");
				getListData(listId);
				updateCallback({
					result: 'ReloadGrid',
				});
				setContentLoaded(true);
			});
		} else if (mode == 'clone') {
			ListsServices.cloneList(
				{
					description: listName,
					descriptionLocalizedValues: localizedListNames,
					id: listId,
					sourceId: sourceListId,
					isActive: isActive,
				},
				true,
				true,
				[200]
			)
				.then(() => {
					verticalTabCallback({
						command: 'delete',
						recordId: '--',
						languageId: TPGlobal.language,
						recordDescription: listId
					});
					verticalTabCallback({
						command: 'update',
						recordId: listId,
						languageId: TPGlobal.language,
						recordDescription: listId
					});
					updateCallback({
						result: 'ReloadGrid',
					});
					setContentLoaded(true);
				})
				.catch(() => {
					getListData(listId);
					setContentLoaded(true);
				});
		}
	};

	const getListData = function (listId: string) {
		ListsServices.getListById(listId, false, true, [200]).then(response => {
			if (response) {
				mode == 'update' && setListId(response.id);
				setLocalizedListNames([
					...response.descriptionLocalizedValues
						.filter(value => TPGlobal.TPClientAvailableLanguages.find(lang => lang.id === value.languageId))
						.map(value => {
							return {
								...value,
								languageName:
									TPGlobal.TPClientAvailableLanguages.find(lang => lang.id === value.languageId)?.name ||
									value.languageId
							};
						}),
					...TPGlobal.TPClientAvailableLanguages.filter(
						lang => !response.descriptionLocalizedValues.find(value => value.languageId === lang.id)
					).map(lang => {
						return {
							languageId: lang.id,
							localizedValue: '',
							languageName: lang.name
						} as LocalizedValue;
					})
				]);
				setAlphabeticalOrderSelected(response.alphabeticalOrder);
				setIsActive(response.isActive);
				let retrievedListItems = response.items.map(item => {
					return {
						...item,
						descriptionLocalizedValues: TPGlobal.TPClientAvailableLanguages.map(lang => {
							return {
								languageId: lang.id,
								localizedValue:
									item.descriptionLocalizedValues.find(val => val.languageId === lang.id)?.localizedValue || '',
								languageName: lang.name
							} as LocalizedValue;
						})
					} as ListItemModel;
				});
				if (!response.alphabeticalOrder) {
					retrievedListItems = retrievedListItems.sort((itemA, itemB) => itemA.order > itemB.order ? 1 : (itemA.order < itemB.order ? -1 : 0));
				}
				setListItems(retrievedListItems);
				setListCreator(response.createdBy);
				setListName(mode == 'clone' ? '' : response.description);
				setPersistentName(response.description);
				setContentLoaded(true);
				setChangesMade(false);
				setInitialState({
					id: response.id,
					alphabeticalOrder: response.alphabeticalOrder,
					description: response.description,
					descriptionLocalizedValues: response.descriptionLocalizedValues,
					isActive: response.isActive,
					items: retrievedListItems,
					type: response.type,
					createdBy: TPGlobal.currentUserName
				});
				setCanChange(true);
			}
		});
	};

	const handleAlphabeticalReorder = function() {
		const reorderedList = [
			...listItems.sort((itemA, itemB) => {
				if (itemA.description < itemB.description) return -1;
				if (itemA.description > itemB.description) return 1;
				return 0;
			})
		].map((it, idx) => {
			return {
				...it,
				order: idx
			}
		});
		setListItems(reorderedList);
	};

	useEffect(() => {
		setContentLoaded(false);
		if (mode == 'new') {
			fetchId(SequenceGeneratorSequencesNameEnum.SQLIST).then(response => {
				setListId(response);
				setContentLoaded(true);
			});
		} else if (mode == 'update') {
			getListData(recordId);
		} else if (mode == 'clone') {
			getListData(recordId);
			fetchId(SequenceGeneratorSequencesNameEnum.SQLIST).then(response => {
				setListId(response);
				setContentLoaded(true);
			});
		}
	}, []);

	useEffect(() => {
		if (localizedListNames.length == 0) {
			setLocalizedListNames([
				{
					languageId: TPGlobal.language,
					localizedValue: listName,
					languageName: TPGlobal.TPClientAvailableLanguages.find(lang => lang.id === TPGlobal.language)?.name
				}
			]);
		} else {
			setLocalizedListNames(
				localizedListNames.map(name => {
					return name.languageId === TPGlobal.language
						? {
								...name,
								localizedValue: listName
							}
						: name;
				})
			);
		}
	}, [listName]);

	useEffect(() => {
		if (alphabeticalOrderSelected && listItems.length > 0) handleAlphabeticalReorder();
	}, [alphabeticalOrderSelected, listItems.length]);

	useEffect(() => {
		const intervalId = setTimeout(() => {
			if (contentLoaded && canChange) {
				let listsAreDifferent = false;
				listsAreDifferent = listItems.length != initialState.items.length;
				if (!alphabeticalOrderSelected) {
					for (let i = 0; i < listItems.length && !listsAreDifferent; i++) {
						const currentItem = listItems[i];
						const savedItem = initialState.items.find(it => it.id == currentItem.id);
						if (savedItem) {
							listsAreDifferent = savedItem.order !== i;
						}
					}
				}

				let changeInItemName = false;
				for (let i = 0; i < listItems.length && !changeInItemName; i++) {
					const currentItem = listItems[i];
					const savedItem = initialState.items.find(it => it.id == currentItem.id);
					if (savedItem) {
						changeInItemName = Boolean(currentItem.descriptionLocalizedValues
							.some(desc => desc.localizedValue !== savedItem.descriptionLocalizedValues
								.find(loc => loc.languageId == desc.languageId)?.localizedValue)
						)
					}
				}

				let changeInListNames = false;
				for (let i = 0; i < localizedListNames.length && !changeInListNames; i++) {
					const currentName = localizedListNames[i];
					const savedName = initialState.descriptionLocalizedValues.find(desc => desc.languageId == currentName.languageId);
					if (!savedName && currentName.localizedValue.trim().length > 0) changeInListNames = true;
				}

				let changeInActiveItems = false;
				for (let i = 0; i < listItems.length && !changeInActiveItems; i++) {
					const item = listItems[i];
					const savedItem = initialState.items.find(init => init.id == item.id);
					if (savedItem) {
						changeInActiveItems = savedItem.isActive != item.isActive;
					}
				}

				setChangesMade(
					initialState.description != listName ||
					initialState.descriptionLocalizedValues
						.some(name =>
							name.localizedValue !== localizedListNames
								.find(loc => loc.languageId == name.languageId)?.localizedValue) ||
					initialState.alphabeticalOrder != alphabeticalOrderSelected ||
					initialState.isActive != isActive ||
					listItemsInput.trim().length > 0 ||
					listsAreDifferent ||
					changeInItemName ||
					changeInListNames ||
					changeInActiveItems
				);
			}
		}, 100)
		return () => clearInterval(intervalId);
	})

	if (exitRequest && !changesMade) {
		verticalTabCallback({
			command: 'delete',
			recordId: mode === 'new' ? '--' : recordId,
			languageId: TPGlobal.language,
			recordDescription: listId,
			alert: true
		});
		dispatch(VerticalTabsAdminContainerSlice.actions.setExitRequest(false));
	}

	useEffect(() => {
		if (listItemsInput.trim().length > 0) {
			const input = listItemsInput.split('\n').map(item => item.trim());
			const foundDuplicates = listItems.filter(item => input.includes(item.description.trim()))
			setDuplicateItems(foundDuplicates.length > 0);
		}
	}, [listItemsInput, listItems])

	useEffect(() => {
		if (duplicateItems) showToast(labels.DuplicateItems, TPToastTypes.error);
	}, [duplicateItems])

	return (
		<>
			{exitRequest && changesMade && (
				<ListAdminCancelModal
					callbackAnswer={accept => {
						if (accept) {
							verticalTabCallback({
								command: 'delete',
								recordId: mode === 'new' ? '--' : recordId,
								languageId: TPGlobal.language,
								recordDescription: listId,
								alert: true
							});
						}
						dispatch(VerticalTabsAdminContainerSlice.actions.setExitRequest(false));
					}}
				/>
			)}
			{localizedNamesListVisible && (
				<ListLocalizedNamesModal
					listLocalizedValues={localizedListNames}
					callBackAnswer={(accept: boolean, newNames: LocalizedValue[]) => {
						accept && setLocalizedListNames(newNames);
						setLocalizedNamesListVisible(false);
					}}
				/>
			)}
			{itemToUpdate && (
				<ListItemUpdateModal
					callBackAnswer={(accept: boolean, newItem: ListItemModel) => {
						accept && handleItemUpdate(newItem.id, newItem);
						setItemToUpdate(null);
					}}
					visible={Boolean(itemToUpdate)}
					item={itemToUpdate}
				/>
			)}
			<TPLoadingOverlay active={!contentLoaded}>
				<div id="list-admin-new-item" className="list-admin-main" style={{ width: '75%' }}>
					<div className="list-admin-header">
						{mode == 'new' && <TPPageTitle>{labels.AddNewList}</TPPageTitle>}
						{mode == 'update' && (
							<TPPageTitle>
								{labels.EditList}: {persistentName}
							</TPPageTitle>
						)}
						{mode == 'clone' && (
							<TPPageTitle>
								{labels.CloneList}: {persistentName}
							</TPPageTitle>
						)}
					</div>
					{mode == 'clone' &&
					<div>
						<TPSelect
							disabled
							dataSource={[
								{key:'', value:persistentName}
							]}
							onChange={() => {}}
							value={''}
							labelText={labels.ElementToClone}
						/>
					</div>}
					<div className="form-row">
						<div id="list-id-input">
							<TPTextBox
								onChange={() => {}}
								value={listId}
								labelText={labels.Id}
								containerStyle={{ height: '32px' }}
								disabled
							/>
						</div>
						<div style={{ display: 'flex', alignItems: 'flex-end', gap: '8px' }}>
							<div id="list-name-input">
								<TPTextBox
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => setListName(event.target.value)}
									value={listName}
									labelText={labels.ListName}
									containerStyle={{ height: '32px' }}
									isMandatory
								/>
							</div>
							<div id="list-localizaed-names-option" className="language-option">
								<button className="language-button" onClick={() => setLocalizedNamesListVisible(true)}>
									<TPIcon iconType={TPIconTypes.language} />
								</button>
								<label>+{TPGlobal.TPClientAvailableLanguages.length - 1}</label>
							</div>
						</div>
						<TPSwitch
							checked={isActive}
							onChange={event => setIsActive(event.target.checked)}
							label={labels.Active}
						/>
					</div>
					<TPCheckBox
						id="new-list-alphabetical-order-checkbox"
						checked={alphabeticalOrderSelected}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setAlphabeticalOrderSelected(event.target.checked)
						}
						labelText={labels.AlphabeticalOrder}
					/>
					<TPTextArea
						labelText={
							<h6>
								<b>{labels.ListItems}</b>
							</h6>
						}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setListItemsInput(String(event.target.value))}
						rows={10}
						value={listItemsInput}
						textAreaStyle={{ maxHeight: '256px', overflow: 'auto' }}
						placeholder={labels.ItemInsertInstructions}
						className='list-admin-text-area'
					/>
					<div className="list-admin-footer">
						<div className="option-button-pair">
							<div>
								<TPButton
									onClick={() => {
										if (changesMade) {
											dispatch(VerticalTabsAdminContainerSlice.actions.setExitRequest(changesMade))
										} else {
											verticalTabCallback({
												command: 'delete',
												recordId: recordId,
												languageId: TPGlobal.language,
												recordDescription: listId,
												alert: false
											});
										}
									}}
									isDesignSystem
									style={{
										paddingTop:'11px', paddingBottom:'11px',
										paddingLeft:'16px', paddingRight:'16px',
										color:'purple',
										backgroundColor:'white'
									}}
								>
									{labels.Cancel}
								</TPButton>
							</div>
							<TPButton
								onClick={() => handleListSave()}
								disabled={
									(mode == "update" && !changesMade) ||
									(mode == 'new' && listItemsInput.trim().length == 0) ||
									listName.trim().length == 0 ||
									duplicateItems
								}
								isDesignSystem
								style={{
									paddingTop:'11px', paddingBottom:'11px',
									paddingLeft:'16px', paddingRight:'16px',
								}}
							>
								{mode == 'new' && labels.CreateList}
								{mode == 'update' && labels.SaveChanges}
								{mode == 'clone' && labels.CloneList}
							</TPButton>
						</div>
					</div>
					{listItems.length > 0 &&
					<TPReorderableList
						enabled={!alphabeticalOrderSelected && mode != 'clone'}
						data={listItems.map((item, index) => {
							return {
								id: String(index) + item.id,
								content: (
									<ListAdminDraggableItem
										style={{ backgroundColor: index % 2 == 0 ? '#e0e0e0' : 'white' }}
										key={index}
										item={item}
										onUpdateRequest={() => setItemToUpdate(item)}
										onDelete={() => handleItemDelete(item.id)}
										onCheck={() => handleItemUpdate(item.id, { ...item, isActive: !item.isActive })}
										enabled={!alphabeticalOrderSelected && mode != 'clone'}
									/>
								)
							};
						})}
						onDragEnd={handleItemsReorder}
						style={{ padding: 0, height: '256px', overflow: 'auto' }}
						headers={[
							{ label: labels.Actions, width: '10%', centered: true },
							{ label: labels.Active, width: '10%', centered: true },
							{ label: labels.Id, width: '15%', centered: false },
							{ label: labels.ItemName, width: '65%', centered: false }
						]}
					/>}
				</div>
			</TPLoadingOverlay>
		</>
	);
};

export default ListManagement;
