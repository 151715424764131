import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import SearchSelect from "@/modules/core/design-system/selects/SearchSelect";
import { TPI18N } from "@/services/I18nService";
import React, { ReactElement, useRef, useState } from "react";
import {
  CockpitDashboardLabels,
  CockpitFilters,
  CockpitView,
  InfoCardType,
  InfoCardTypes,
  SelectedFilterItems,
} from "../supervisor-cockpit.model";
import CockpitInfoCard from "../utils/CockpitInfoCard";
import CockpitRemovableItem from "../utils/CockpitRemovableItem";
import CockpitSubDate from "../utils/CockpitSubDate";
import CockpitTabs from "../utils/CockpitTabs";
import CockpitTeamActivityTable from "../utils/CockpitTeamActivityTable";
import {
  StyledCardContainer,
  StyledDashboardContainer,
  StyledDashboardContent,
  StyledDashboardFilters,
  StyledDashboardTeamActivity,
  StyledFiltersHeader,
  StyledInformationDot,
  StyledInformationDotsContainer,
  StyledItemsSelected,
} from "./cockpit-dashboard-styles";
import EmployeeRanking from "./EmployeeRanking";

interface CockpitDashboardCallBacks {
  callBackCommands: Function;
}

const CockpitDashboard = React.forwardRef(
  ({ callBackCommands }: CockpitDashboardCallBacks, ref) => {
    const component: string = "CockpitDashboard";

    const initialComponentLabels: CockpitDashboardLabels = {
      title: "Supervisor Cockpit | Dashboard",
      apply: "Apply",
      goToDetail: "Go to detail",
      project: "Project",
      totalProjects: "Total projects",
      queue: "Queue",
      totalQueues: "Total queues",
      group: "Select group",
      totalGroups: "Total groups",
      filterPlaceholder: "Choose",
      employee: "Employee ranking",
      lastDays: "Last 30 days",
    };
    const [componentLabels, setComponentLabels] =
      useState<CockpitDashboardLabels>(initialComponentLabels);

    const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
    const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(true);
    const [projectsSelected, setProjectsSelected] = useState<TPKeyValue[]>([]);
    const [queuesSelected, setQueuesSelected] = useState<TPKeyValue[]>([]);
    const [groupsSelected, setGroupsSelected] = useState<TPKeyValue[]>([]);
    const [filterViewAvailable, setFilterViewAvailable] =
      useState<boolean>(false);
    const [view, setView] = useState<CockpitView>("team");

    const removeOptionReferences = {
      projects: useRef<(valueToRemove: string) => void | null>(null),
      queues: useRef<(valueToRemove: string) => void | null>(null),
      groups: useRef<(valueToRemove: string) => void | null>(null),
    };

    const projects: TPKeyValue[] = [
      {
        key: "PJ1",
        value: "Project 1",
      },
      {
        key: "PJ2",
        value: "Project 2",
      },
      {
        key: "PJ3",
        value: "Project 3",
      },
      {
        key: "PJ4",
        value: "Project 4",
      },
    ];

    const queues: TPKeyValue[] = [
      {
        key: "Q1",
        value: "Queue 1",
      },
      {
        key: "Q2",
        value: "Queue 2",
      },
    ];

    const groups: TPKeyValue[] = [
      {
        key: "GP1",
        value: "Group 1",
      },
      {
        key: "GP2",
        value: "Group 2",
      },
    ];

    const loadUtilsResources = async () => {
      setComponentLabels({
        title: await TPI18N.GetText(component, "Title"),
        apply: await TPI18N.GetText(component, "Apply"),
        goToDetail: await TPI18N.GetText(component, "GoToDetail"),
        project: await TPI18N.GetText(component, "Project"),
        totalProjects: await TPI18N.GetText(component, "TotalProjects"),
        queue: await TPI18N.GetText(component, "Queue"),
        totalQueues: await TPI18N.GetText(component, "TotalQueues"),
        group: await TPI18N.GetText(component, "Group"),
        totalGroups: await TPI18N.GetText(component, "TotalGroups"),
        filterPlaceholder: await TPI18N.GetText(component, "FilterPlaceholder"),
        employee: await TPI18N.GetText(component, "EmployeeRanking"),
        lastDays: await TPI18N.GetText(component, "LastDays"),
      });
    };

    const removeOption = (value: string, type: CockpitFilters) => {
      removeOptionReferences[type].current?.(value);
    };

    const isFilterDisabled = () => {
      if (view === "event") {
        return projectsSelected.length === 0;
      } else if (view === "group") {
        return groupsSelected.length === 0;
      }
    };

    const getSelectedItemsBy = (view: CockpitView): SelectedFilterItems[] => {
      switch (view) {
        case "event":
          let itemsSelected: SelectedFilterItems[] = [
            ...projectsSelected.map((item) => {
              return {
                ...item,
                type: "projects",
              } as SelectedFilterItems;
            }),
            ...queuesSelected.map((item) => {
              return {
                ...item,
                type: "queues",
              } as SelectedFilterItems;
            }),
          ];

          return itemsSelected;
        case "group":
          return groupsSelected.map((item) => {
            return {
              ...item,
              type: "groups",
            } as SelectedFilterItems;
          });
        default:
          return [];
      }
    };

    const isFilterViewAvailable = (): boolean => {
      switch (view) {
        case "event":
          return projectsSelected.length !== 0 || queuesSelected.length !== 0;
        case "group":
          return groupsSelected.length !== 0;
        default:
          return false;
      }
    };

    const RenderSelectedItems = React.memo((): ReactElement => {
      const selectedItems: SelectedFilterItems[] = getSelectedItemsBy(view);
      return (
        <>
          {selectedItems.length > 0 && (
            <StyledItemsSelected>
              {selectedItems.map((item, index) => {
                return (
                  <CockpitRemovableItem
                    key={index}
                    item={item}
                    onRemoveItem={() => removeOption(item.key, item.type)}
                  />
                );
              })}
            </StyledItemsSelected>
          )}
        </>
      );
    });

    const RenderButtons = React.memo((): ReactElement => {
      return (
        <>
          {view !== "team" && (
            <TPButton
              id="apply-filters"
              onClick={() => setFilterViewAvailable(true)}
              style={{ padding: "0 15px" }}
              disabled={isFilterDisabled()}
              isDesignSystem
            >
              {componentLabels.apply}
            </TPButton>
          )}

          {view === "event" && (
            <TPButton
              id="go-to-detail"
              onClick={() => console.log("Go to detail")}
              customType={ButtonCustomType.tertiary}
              isDesignSystem
            >
              {componentLabels.goToDetail}
            </TPButton>
          )}
        </>
      );
    });

    const RenderInfoCards = React.memo((): ReactElement => {
      let allCards = Object.keys(InfoCardType).map((item) => {
        return {
          type: item as InfoCardTypes,
          value: Math.floor(Math.random() * 100),
          hide: false,
        };
      });

      if (view !== "event") {
        allCards.forEach((item) => {
          if (item.type === "worked") {
            item.hide = true;
          }
        });
      }

      return (
        <StyledCardContainer>
          {allCards
            .filter((item) => !item.hide)
            .map((item, index) => {
              return (
                <CockpitInfoCard
                  key={index}
                  type={item.type}
                  value={item.value}
                />
              );
            })}
        </StyledCardContainer>
      );
    });

    const RenderInformationDots = React.memo((): ReactElement => {
      return (
        <StyledInformationDotsContainer>
          {view === "event" && (
            <>
              <StyledInformationDot>
                {`${componentLabels.totalProjects}: ${projectsSelected.length}`}
              </StyledInformationDot>
              <StyledInformationDot>
                {`${componentLabels.totalQueues}: ${queuesSelected.length}`}
              </StyledInformationDot>
            </>
          )}

          {view === "group" && (
            <StyledInformationDot>
              {`${componentLabels.totalGroups}: ${groupsSelected.length}`}
            </StyledInformationDot>
          )}
        </StyledInformationDotsContainer>
      );
    });

    return (
      <TPLoadingOverlay active={isLoadingScreen}>
        {isResourcesLoaded && (
          <div className="row">
            <div className="col">
              <CIMTitleSection isCIM width="100%">
                <TPPageTitle style={{ margin: 0 }}>
                  {componentLabels.title}
                  <CockpitSubDate />
                </TPPageTitle>
                <CockpitTabs activeView={view} handleViewChange={setView} />
              </CIMTitleSection>
              <StyledDashboardContent>
                <StyledDashboardContainer>
                  <StyledFiltersHeader>
                    <StyledDashboardFilters>
                      {view === "event" && (
                        <>
                          <SearchSelect
                            id="cockpit-project"
                            width="200px"
                            options={projects}
                            optionSelected={projectsSelected}
                            label={componentLabels.project}
                            placeholder={componentLabels.filterPlaceholder}
                            handleMultiChange={setProjectsSelected}
                            removeSelectedOptionRef={
                              removeOptionReferences.projects
                            }
                            orientation="vertical"
                            isMandatory
                            isMulti
                          />
                          <SearchSelect
                            id="cockpit-queue"
                            width="200px"
                            options={queues}
                            optionSelected={queuesSelected}
                            label={componentLabels.queue}
                            placeholder={componentLabels.filterPlaceholder}
                            handleMultiChange={setQueuesSelected}
                            removeSelectedOptionRef={
                              removeOptionReferences.queues
                            }
                            orientation="vertical"
                            isMulti
                          />
                        </>
                      )}

                      {view === "group" && (
                        <SearchSelect
                          id="cockpit-group"
                          width="200px"
                          options={groups}
                          optionSelected={groupsSelected}
                          label={componentLabels.group}
                          placeholder={componentLabels.filterPlaceholder}
                          handleMultiChange={setGroupsSelected}
                          removeSelectedOptionRef={
                            removeOptionReferences.groups
                          }
                          orientation="vertical"
                          isMandatory
                          isMulti
                        />
                      )}
                      <RenderButtons />
                    </StyledDashboardFilters>
                    <RenderInformationDots />
                  </StyledFiltersHeader>
                  <RenderSelectedItems />
                </StyledDashboardContainer>
                {filterViewAvailable && isFilterViewAvailable() ? (
                  <DynamicTable
                    data={[...projectsSelected, ...queuesSelected]}
                  />
                ) : (
                  <>
                    <RenderInfoCards />
                    {view === "event" && (
                      <EmployeeRanking componentLabels={componentLabels} />
                    )}
                    <StyledDashboardTeamActivity>
                      <CockpitTeamActivityTable view={view} withChildren />
                    </StyledDashboardTeamActivity>
                  </>
                )}
              </StyledDashboardContent>
            </div>
          </div>
        )}
      </TPLoadingOverlay>
    );
  }
);

export default CockpitDashboard;
