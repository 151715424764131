import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ThemeColor } from "@/modules/core/design-system/design-system.model";

export interface CockpitDashboardLabels {
  title: string;
  apply: string;
  goToDetail: string;
  project: string;
  totalProjects: string;
  queue: string;
  totalQueues: string;
  group: string;
  totalGroups: string;
  filterPlaceholder: string;
  employee: string;
  lastDays: string;
}

export interface CockpitTabLabels {
  team: string;
  group: string;
  event: string;
}

export interface CockpitTabButton {
  view: CockpitView;
  label: string;
  isActive: boolean;
}

export type CockpitView = "team" | "group" | "event";

export type InfoCardTypes =
  | "offline"
  | "online"
  | "working"
  | "ready"
  | "worked";

export enum InfoCardType {
  offline = "logOut",
  working = "working",
  online = "online",
  ready = "ready",
  worked = "worked",
}

export interface InfoCardColor extends ThemeColor {}

export interface InfoCardLabels {
  offline: string;
  online: string;
  working: string;
  ready: string;
  worked: string;
}

export interface SelectedFilterItems extends TPKeyValue {
  type: CockpitFilters;
}

export type CockpitFilters = "projects" | "queues" | "groups";
