import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
    DataTableContainer,
    TableContainer,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
    TPPageSection,
    TPPageSectionTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
    ContactInputDTO,
    ContactInputDTOValidator,
} from "@/models/Contacts/ContactInputDTO";
import { ContactViewModel } from "@/models/Contacts/ContactViewModel";
import {
    ConfigParametersEnum,
    TPActiveOptions,
    TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import { ContactsService } from "@/services/ContactsService";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { useEffect, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import { AnyIfEmpty } from "react-redux";
import { tableStyles } from "../FormDesigner/StyleFromDesigner";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

interface screenStateProps {
    clientId: number;
    subsidiaryId: number | null;
    name: string;
    contactType: string;
    phone: string;
    email: string;
    address: string;
    comments: string;
    useThisConctactAsPersonWhoReportsThisCase: boolean;

    //validations
    nameErrorMessage: string;
    contactTypeErrorMessage: string;
    phoneErrorMessage: string;
    emailErrorMessage: string;
    addressErrorMessage: string;
    commentsErrorMessage: string;
    [key: string]: any;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};

type commandType = {
    type: commandsEnum;
    payload: any;
};

const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
};




enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}

const ContactComponent = ({ subsidiaryId, clientId }: any) => {
    const resourceSet: string = "ContactComponent";
    const componentFileName: string = "ContactComponent.tsx";
    const screenStateInitialState: screenStateProps = {
        clientId: clientId,
        subsidiaryId: subsidiaryId,
        name: "",
        contactType: "",
        phone: "",
        email: "",
        address: "",
        comments: "",
        useThisConctactAsPersonWhoReportsThisCase: true,
        nameErrorMessage: "",
        contactTypeErrorMessage: "",
        phoneErrorMessage: "",
        emailErrorMessage: "",
        addressErrorMessage: "",
        commentsErrorMessage: "",
    };
    const [contactTypeList, setContactTypeList] = useState<Array<TPKeyValue>>([]);
    const [isLoadingScreen, setIsLoadingScreen] = useState(false);
    const [contactList, setContactList] = useState<any>();
    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    const [gridColumns, setGridColumns] = useState<Array<any>>([]);
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [countHttp, setCountHttp] = useState(0);

    //columns labels
    const [contactIdLabel, setContactIdColumnLabel] = useState("");
    const [clientIdLabel, setClientIdColumnLabel] = useState("");
    const [subsidaryIdLabel, setSubsidaryIdColumnLabel] = useState("");
    const [namesColumnLabel, setNamesColumnLabel] = useState("");
    const [relationShipIdColumnLabel, setRelationShipIdColumnLabel] =
        useState("");
    const [phoneColumnLabel, setPhoneColumnLabel] = useState("");
    const [emailColumnLabel, setEmailColumnLabel] = useState("");
    const [addressColumnLabel, setAddressColumnLabel] = useState("");
    const [commentsColumnLabel, setCommentsColumnLabel] = useState("");

    const handleRowsPerPageChanged = (e: any) => {
        const recordSize = fixedHeaderScrollHeight / 10;
        const newRecordSize = recordSize * e;
        setFixedHeaderScrollHeight(newRecordSize);
    };

    const loadResources = async () => {
        setContactIdColumnLabel(
            await TPI18N.GetText(resourceSet, "ContactIdLabel"),
        );
        setClientIdColumnLabel(await TPI18N.GetText(resourceSet, "ClientIdLabel"));
        setSubsidaryIdColumnLabel(
            await TPI18N.GetText(resourceSet, "SubsidaryIdLabel"),
        );
        setNamesColumnLabel(await TPI18N.GetText(resourceSet, "NamesColumnLabel"));
        setRelationShipIdColumnLabel(
            await TPI18N.GetText(resourceSet, "RelationShipIdColumnLabel"),
        );
        setPhoneColumnLabel(await TPI18N.GetText(resourceSet, "PhoneColumnLabel"));
        setEmailColumnLabel(await TPI18N.GetText(resourceSet, "EmailColumnLabel"));
        setAddressColumnLabel(
            await TPI18N.GetText(resourceSet, "AddressColumnLabel"),
        );
        setCommentsColumnLabel(
            await TPI18N.GetText(resourceSet, "CommentsColumnLabel"),
        );
        setThereAreNoRecordsToShow(
            await TPI18N.GetText(resourceSet, "DataTableNoCurrentData"),
        );

        //Load contact Type
        let parameterService = new ParametersService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest =
                await parameterService.getByParentIdAndFilterIsActive(
                    ConfigParametersEnum.Custom_Contacts_Relationships,
                    TPActiveOptions.ACTIVE.toString(),
                    false,
                    true,
                    expectedCodes,
                );

            let newContactTypeList: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return { key: item.id, value: item.localizedDescription };
                },
            );
            newContactTypeList.unshift({ key: "", value: "--" });
            setContactTypeList(newContactTypeList);

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} loadResources getByParentIdAndFilterIsActive ex`,
            );

        }
    };

    // Grid columns
    const setupGridColumns = (prevState: AdminStateType) => {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        try {
            //Contact ID
            newColumns.push({
                name: contactIdLabel,
                cell: (row: { [x: string]: any }) => {
                    return (
                        <span
                            href="#"
                            onClick={(e: any) => { }}
                        // style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }} // Optional styling
                        >
                            {row["contactId"]}
                        </span>
                    );
                },
                selector: (row: { [x: string]: any }) => row["contactId"],
                sortable: true,
            });
            //Client ID
            // newColumns.push({
            //   name: "",
            //   width: "50px",
            //   center: true,
            //   cell: (row: { [x: string]: any }) => {
            //     if (!row["tpshowbutton"]) {
            //       return "";
            //     } else {
            //       function handleOnArrowButtonClick(e: any, row: { [x: string]: any; }) {
            //         throw new Error("Function not implemented.");
            //       }

            //       return (
            //         <TPIcon
            //           iconType={TPIconTypes.arrowcircledown}
            //           className="tprotate-270-and-scale2 mt-2"
            //           onClick={(e: any) => handleOnArrowButtonClick(e, row)}
            //         />
            //       );
            //     }
            //   },
            //   selector: (row: { [x: string]: any }) => row["tpshowbutton"],
            //   sortable: false,
            // });

            //customerTypeDescription
            newColumns.push({
                name: clientIdLabel,
                cell: (row: { [x: string]: any }) => {
                    if (row["thumbnail"]) {
                        return (
                            <>
                                <span>{row["clientId"]}</span>
                                &nbsp; &nbsp;
                                {/* <img src={row["thumbnail"]} /> */}
                            </>
                        );
                    }
                    return row["clientId"];
                },
                selector: (row: { [x: string]: any }) => row["clientId"],
                sortable: false,
            });
            //Subsidiary ID
            newColumns.push({
                name: subsidaryIdLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["subsidiaryId"];
                },
                selector: (row: { [x: string]: any }) => row["subsidiaryId"],
                sortable: true,
            });
            //Name
            newColumns.push({
                name: namesColumnLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["name"];
                },
                selector: (row: { [x: string]: any }) => row["name"],
                sortable: true,
            });
            //Relationship ID
            newColumns.push({
                name: relationShipIdColumnLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["relationshipId"];
                },
                selector: (row: { [x: string]: any }) => row["relationshipId"],
                sortable: false,
            });
            //Phone
            newColumns.push({
                name: phoneColumnLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["phone"];
                },
                selector: (row: { [x: string]: any }) => row["phone"],
                sortable: true,
            });
            //Email
            newColumns.push({
                name: emailColumnLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["email"];
                },
                selector: (row: { [x: string]: any }) => row["email"],
                sortable: true,
            });
            //Address
            newColumns.push({
                name: addressColumnLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["address"];
                },
                selector: (row: { [x: string]: any }) => row["address"],
                sortable: true,
            });
            //Comments
            newColumns.push({
                name: commentsColumnLabel,
                cell: (row: { [x: string]: any }) => {
                    return row["comments"];
                },
                selector: (row: { [x: string]: any }) => row["comments"],
                sortable: true,
            });
            setGridColumns(newColumns);
            newState.gridColumns = [...newColumns];
            return newState;

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} setupGridColumns ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} setupGridColumns ex`);
            return prevState;
        }
    };

  const getContactByClientIdAndSubsidiaryId = async (
    clientId: number,
    subsidiaryId: number,
  ) => {
      setIsLoadingScreen(true);
    let serviceClient = new ContactsService();
    let expectedCodes: Array<number> = [200,404];

        try {
            setIsLoadingScreen(true);

            let responseRequest =
                await serviceClient.getContactByClientIdAndSubsidiaryId(
                    clientId,
                    subsidiaryId,
                    false,
                    false,
                    expectedCodes,
                );
          
            let recordInfo: Array<ContactViewModel>;
            recordInfo = [...responseRequest];
            let command11: commandType = {
                type: commandsEnum.reload_grid,
                payload: responseRequest,
            };

            dispatchCommand(command11);
            //setupGridColumns();
            setContactList(responseRequest);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(
                `Error ${componentFileName} getCustomerBySubsidiaryOrganizationId ex`,
            );
        } finally {
            if (
                contactIdLabel &&
                clientIdLabel &&
                subsidaryIdLabel &&
                namesColumnLabel &&
                relationShipIdColumnLabel &&
                phoneColumnLabel &&
                emailColumnLabel &&
                addressColumnLabel &&
                commentsColumnLabel
            ) {
                setIsLoadingScreen(false);
            }
        }
    };


    const [adminState, dispatchCommand] = useReducer(
        doCommand,
        initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
        switch (command.type) {
            case commandsEnum.set_filterIsLoaded:
                let newStateFilter: AdminStateType;
                newStateFilter = { ...prevState };
                newStateFilter.filterIsLoaded = true;
                return newStateFilter;
            case commandsEnum.setup_grid_columns:
                let newStateColumns: AdminStateType = setupGridColumns(prevState);
                newStateColumns.columnsAreLoaded = true;
                return newStateColumns;
            case commandsEnum.reload_grid:
                let newStateGrid: AdminStateType;
                newStateGrid = { ...prevState };
                newStateGrid.gridData = command.payload;
                return newStateGrid;
            case commandsEnum.change_selectedFilter:
                let newStateChangeFilter: AdminStateType;
                newStateChangeFilter = { ...prevState };
                newStateChangeFilter.selectedFilter = command.payload;
                return newStateChangeFilter;
            case commandsEnum.change_search_pattern:
                let newStatePattern: AdminStateType;
                newStatePattern = { ...prevState };
                newStatePattern.searchPattern = command.payload;
                return newStatePattern;
            default:
                return prevState;
        }
    }


    //Filtered data based on selected pattern on search box
    const filteredData = () => {
        //todo more columns
        let searcheableColumns: Array<string> = [
            "id",
            "documentType",
            "document",
            "name",
            "lastName",
            "customerTypeDescription",
        ];


        let i: number;
        let search: string;
        search = adminState.searchPattern.trim();
        return adminState.gridData.filter(function (item: any, index: number) {
            if (search == "" || search.length <= 2) {
                return item;
            }
            for (i = 0; i <= searcheableColumns.length - 1; i++) {
                let itemany: any;
                itemany = item;
                if (
                    itemany[searcheableColumns[i]] &&
                    itemany[searcheableColumns[i]]
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                ) {
                    return item;
                }
            }
        });



    };


    useEffect(() => {
        if (adminState.filterIsLoaded) {
            let command1: commandType = {
                type: commandsEnum.setup_grid_columns,
                payload: null,
            };
            dispatchCommand(command1);
            getContactByClientIdAndSubsidiaryId(clientId, subsidiaryId)
            setIsLoadingScreen(false);
        }
    }, [adminState.filterIsLoaded]);




    useEffect(() => {
        loadResources().then(function () {
            //set filter is loaded
            let command1: commandType = {
                type: commandsEnum.set_filterIsLoaded,
                payload: null,
            };
            dispatchCommand(command1);

        })
            .catch(function (error) {
                TPLog.Log(
                    `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
                    TPLogType.ERROR,
                    error,
                );
                console.error(
                    `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
                );
            });


    }, []);


    return (
        <>
            {/* Selected table data */}
            <div className="container-fluid" >
                <TPLoadingOverlay active={isLoadingScreen}>
                    <div
                        className="row"
                        style={{ padding: "10px 20px", marginTop: "12px" }}
                    >
                        <div className="col">
                            <TableContainer>
                                <DataTableContainer>
                                    <DataTable
                                        fixedHeader={true}
                                        fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                                        onChangeRowsPerPage={handleRowsPerPageChanged}
                                        responsive={true}
                                        striped={true}
                                        highlightOnHover={true}
                                        pagination
                                        paginationPerPage={10}
                                        // onSearchChange={onSearchPatternChange2}
                                        paginationComponentOptions={
                                            TPGlobal.paginationComponentOptions
                                        }
                                        columns={gridColumns}
                                        data={filteredData()}
                                        noDataComponent={thereAreNoRecordsToShow}
                                        sortFunction={TPGlobal.datatableCustomSort}
                                        customStyles={tableStyles}
                                        persistTableHead={true}
                                    />
                                </DataTableContainer>
                            </TableContainer>
                        </div>
                    </div>
                </TPLoadingOverlay>
            </div>
        </>
    );
};

export default ContactComponent;
