import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageAcceptCancelButtonsContainer, TPPageSection } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { SequenceGeneratorSequencesNameEnum, SystemParametersEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { StructureAdminInputDTO } from "@/models/StructureAdmin/StructureAdminInputDTO";
import { TPI18N } from "@/services/I18nService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { StructureService } from "@/services/StructureService";
import { FC, useEffect, useState } from "react";

type InsertUpdateProps = {
    mode: string
    recordInput: string
    recordDescription: string
    callBackComands: Function
};

enum whenCustomerExistsEnum {
    ignoreData = "ignoreData",
    addNewData = "addNewData",
}

type InsertUpdateStateType = {
    id: string,
    recordLanguageList: Array<TPKeyValue>;
}

const StructureAdminClone: FC<InsertUpdateProps> = ({
    mode,
    recordInput,
    recordDescription,
    callBackComands
}) => {
    const componentFileName: string = "StructureAdminUpdateInsert.tsx";

    const resourceSet: string = "StructureAdminClone";

    const [isLoading, setIsLoading] = useState(false);

    const [realMode, setRealMode] = useState(mode);

    const [elementToCloneLabel, setElementToCloneLabel] = useState("");
    const [structureIdLabel, setStructureIdLabel] = useState("");
    const [structureNameLabel, setStructureNameLabel] = useState("");

    const [structureNameValue, setStuctureNameValue] = useState("");
    const [typeValue, setTypeValue] = useState("--");
    const [referenceId, setReferenceId] = useState("");

    const [titleLabel, setTitleLabel] = useState("");
    const [toastInfo, setToastInfo] = useState("");
    const [structureTypeLabel, setStructureTypeLabel] = useState("");
    const [ignoreDataLabel, setIgnoreDataLabel] = useState("");
    const [addNewDataLabel, setAddNewDataLabel] = useState("");
    const [whenCustomerExistsLabel, setWhenCustomerExistsLabel] = useState("");
    const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");

    const [structureNameErrorMessage, setStructureNameErrorMessage] = useState("");

    const [showAsActive, setShowAsActive] = useState(true);

    const loadResourcesAndLoadStructureInfo = async () => {
        console.log(mode);

        setElementToCloneLabel(await TPI18N.GetText(resourceSet, "ElementToCloneLabel"));
        setStructureIdLabel(await TPI18N.GetText(resourceSet, "StructureIdLabel"));
        setStructureNameLabel(await TPI18N.GetText(resourceSet, "StructureNameLabel"));
        setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
        setToastInfo(await TPI18N.GetText(resourceSet, "ToastInfo"));
        setStructureTypeLabel(await TPI18N.GetText(resourceSet, "StructureTypeLabel"));
        setIgnoreDataLabel(await TPI18N.GetText(resourceSet, "IgnoreDataLabel"));
        setAddNewDataLabel(await TPI18N.GetText(resourceSet, "AddNewDataLabel"));
        setWhenCustomerExistsLabel(await TPI18N.GetText(resourceSet, "WhenCustomerExistsLabel"));
        setShowAsActiveLabel(await TPI18N.GetText(resourceSet, "ShowAsActiveLabel"));
        setSaveButtonLabel(await TPI18N.GetText(resourceSet, "SaveButtonLabel"));
        setCancelButtonLabel(await TPI18N.GetText(resourceSet, "CancelButtonLabel"));
        setLanguageListLabel(await TPI18N.GetText(resourceSet, "LanguageListLabel"));

        if (mode === "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (let i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }


            setInsertUpdateState(newInsertUpdateState);

        } else if (mode === "CloneEmail") {
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (let i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }


            setInsertUpdateState(newInsertUpdateState);
        }

        getStructureTypeList();
    }

    const [structureTypes, setStructureTypes] = useState<Array<any>>([])

    const getStructureTypeList = async () => {
        let parametersService = new ParametersService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await parametersService.getByParentIdAndFilterIsActive(
                SystemParametersEnum.STRUCTURETYPEPARENT,
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes
            );
            if (responseRequest) {
                let structureTypeList = responseRequest.map(function (item) {
                    return { key: item.id, value: item.localizedDescription };
                })
                structureTypeList.unshift({ key: "", value: "--" });
                setStructureTypes(structureTypeList);
            } else {
                return null;
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getStructureTypeList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getStructureTypeList ex`);
        }
    }

    const generateAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQEVLS
            )

            setIsLoading(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} generateAutomaticId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} generateAutomaticId ex`);
        }
    }

    const insertStructure = async (inputDTO: any) => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);
            
            let responseRequest = await serviceClient.cloneStructure(inputDTO, true, true, expectedCodes);

            setIsLoading(false);

            console.log("responseRequest", responseRequest);

            callBackComands({
                result: "clone_email_success",
                recordId: inputDTO.id,
            });
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} insertEmailTemplate ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} insertEmailTemplate ex`);
            setIsLoading(false);
        }
    }

    const handleOnOkButtonClick = async () => {
        let i: number;
        let n: number;
        let atLeastOneError = false;
        let RecordInputDTO: StructureAdminInputDTO = {
            id: realMode === 'CloneEmail' ? referenceId : insertUpdateState.id,
            idBase: recordInput,
            description: insertUpdateState.recordLanguageList[0].value,
            typistIdUser: TPGlobal.currentUserGuid,
            isActive: showAsActive,
            descriptionLocalizedValues: []
        }
        n = insertUpdateState.recordLanguageList.length;
        for (i = 0; i < n; i++) {
            let item: TPKeyValue;
            item = insertUpdateState.recordLanguageList[i];
            RecordInputDTO.descriptionLocalizedValues.push({
                order: i + 1,
                languageId: item.key,
                localizedValue: item.value
            });
        }

        if (insertUpdateState.recordLanguageList[0].value === "") {
            setStructureNameErrorMessage(await TPI18N.GetText(resourceSet, "StructureNameErrorMessage"));
            atLeastOneError = true;
        }

        if (!atLeastOneError) {
            if (realMode === "Insert" || realMode === "CloneEmail") {
                await insertStructure(RecordInputDTO);
            }
        }

    }

    let customerExistPropsInitialState = {
        type: "ignoreData"
    }

    const [customerExistPropsState, setCustomerExistPropsState] = useState(customerExistPropsInitialState);

    let insertUpdateInitialState: InsertUpdateStateType = {
        id: "",
        recordLanguageList: []
    }

    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState
    );

    const handleStructureNameChange = (event: any) => {
        setStuctureNameValue(event.target.value);
    }

    const handleStructureTypeChange = (event: any) => {
        setTypeValue(event.target.value);
    }

    const handleCustomerExistTypeChange = (event: any) => {
        let newCustomerExistPropsState = { ...customerExistPropsState };
        newCustomerExistPropsState.type = event.target.value;
        setCustomerExistPropsState(newCustomerExistPropsState);
    }

    const handleSetAsActive = (event: any) => {
        setShowAsActive(!showAsActive);
    }

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        setInsertUpdateState(newInsertUpdateState);
        setStructureNameErrorMessage("");
    };

    useEffect(() => {
        loadResourcesAndLoadStructureInfo();
        generateAutomaticId();
    }, []);

    const handleCancelButtonClick = () => {
        callBackComands({ result: "cancel_general", recordId: "" });
    };

    return (
        <TPLoadingOverlay active={isLoading}>
            <div className="row">
                <div className="col-8" style={{ width: "56%"}}>
                    <div className="toast-info">
                        <div className="toast-info-inner">
                            <TPIcon iconType={TPIconTypes.alert} style={{ color: "#3047b0" }} />
                            <p className="toast-info-text">{toastInfo}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-10">
                    <TPPageSection>
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={true}
                                        disabled
                                        labelText={elementToCloneLabel}
                                        value={`${recordDescription} (${recordInput})`}
                                        onChange={(e: any) => setReferenceId(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={true}
                                        disabled
                                        labelText={structureIdLabel}
                                        value={referenceId}
                                        onChange={(e: any) => setReferenceId(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {insertUpdateState.recordLanguageList.length > 0 &&
                                TPGlobal.TPClientAvailableLanguages.map(
                                    (item, index) =>
                                        index === 0 && (
                                            <div className="col-8" key={"languageItem" + item.id}>
                                                <div className="form-group">
                                                    <TPTextBox
                                                        id="IdTextBox"
                                                        isMandatory={index === 0}
                                                        labelText={`${structureNameLabel} (${item.name})`}
                                                        value={insertUpdateState.recordLanguageList[index].value}
                                                        onChange={(e: any) =>
                                                            handleLanguageChange(index, e.target.value)
                                                        }
                                                        maxLength={100}
                                                        errorMessage={structureNameErrorMessage}
                                                    />
                                                </div>
                                            </div>
                                        )
                                )}
                            <div className="col-4">
                                <div className="pt-4">
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.icon}
                                        icon={TPIconTypes.language}
                                        text={`+${insertUpdateState.recordLanguageList.length - 1
                                            }`}
                                        tooltip={languageListLabel}
                                        className={"pt-3"}
                                        onClick={handleOpenModalLanguageList}
                                    />
                                </div>
                            </div>
                            <TPModalLanguageList
                                isOpen={isOpenModalLanguageList}
                                title={languageListLabel}
                                acceptLabel={saveButtonLabel}
                                cancelLabel={cancelButtonLabel}
                                saveChanges={handleSaveChangesModalLanguageList}
                                closeModal={handleCloseModalLanguageList}
                            >
                                <div
                                    className="row overflow-auto"
                                    style={{ height: "200px" }}
                                >
                                    {TPGlobal.TPClientAvailableLanguages.map(
                                        (item, index) =>
                                            index > 0 && (
                                                <div
                                                    className="col-12"
                                                    key={`languageItem-${item.id}`}
                                                >
                                                    <div className="form-group">
                                                        <TPTextBox
                                                            id="IdTextBox"
                                                            isMandatory={index === 0}
                                                            labelText={`${structureNameLabel} (${item.name})`}
                                                            value={insertUpdateState.recordLanguageList[index]?.value}
                                                            onChange={(e: any) => handleLanguageChange(index, e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    )
                                    }
                                </div>
                            </TPModalLanguageList>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        labelText={showAsActiveLabel}
                                        checked={showAsActive}
                                        onChange={handleSetAsActive}
                                    />
                                </div>
                            </div>
                        </div>
                    </TPPageSection>
                </div>
            </div>
            <div className="col-7">
                    <TPPageAcceptCancelButtonsContainer>
                        <TPButton
                            id="IdButton"
                            type={TPButtonTypes.primary}
                            onClick={() => handleOnOkButtonClick()}
                        >
                            {saveButtonLabel}
                        </TPButton>
                        <TPButton
                            id="IdButton"
                            type={TPButtonTypes.link}
                            onClick={() => handleCancelButtonClick()}
                            className={"ms-2"}
                        >
                            {cancelButtonLabel}
                        </TPButton>
                    </TPPageAcceptCancelButtonsContainer>
                </div>
        </TPLoadingOverlay>
    );
}

export default StructureAdminClone;