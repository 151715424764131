import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import React, { FC, ReactElement } from "react";
import { CockpitDashboardLabels } from "../supervisor-cockpit.model";
import CockpitRankingUser from "../utils/CockpitRankingUser";
import {
  StyledRankingContainer,
  StyledRankingHeader,
  StyledRankingUser,
} from "./cockpit-dashboard-styles";

type EmployeeRankingProps = {
  componentLabels: CockpitDashboardLabels;
};

const EmployeeRanking: FC<EmployeeRankingProps> = React.memo(
  ({ componentLabels }): ReactElement => {
    const component: string = "EmployeeRanking";

    const employees: any[] = [
      {
        name: "Mike Canales",
        time: "00:20:10",
        cases: 30,
      },
      {
        name: "Lorri Warf",
        time: "00:25:12",
        cases: 28,
      },
      {
        name: "Corina McCoy",
        time: "00:30:40",
        cases: 25,
      },
    ];

    return (
      <StyledRankingContainer>
        <StyledRankingHeader>
          <p>
            <strong>{componentLabels.employee}</strong> (
            {componentLabels.lastDays})
          </p>
          <TPButton
            id="go-to-wallboards"
            onClick={() => console.log("Go to wallboards")}
            withIcon={TPIconTypes.doubleArrowRight}
            customType={ButtonCustomType.tertiary}
            isDesignSystem
          >
            Go to wallboards
          </TPButton>
        </StyledRankingHeader>
        <StyledRankingUser>
          {employees.map((item, index) => {
            return (
              <CockpitRankingUser
                key={index}
                name={item.name}
                time={item.time}
                cases={item.cases}
                position={index + 1}
              />
            );
          })}
        </StyledRankingUser>
      </StyledRankingContainer>
    );
  }
);

export default EmployeeRanking;
