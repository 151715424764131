import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import { exportToCSV } from "@/helpers/ExportToCSV";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { OutboundMailAccountViewModel } from "@/models/OutboundMailAccount/OutboundMailAccountModel";
import { TPI18N } from "@/services/I18nService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<OutboundMailAccountViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface OutboundMailAccountAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const OutboundMailAccountAdmin = forwardRef(
  ({ callBackCommands }: OutboundMailAccountAdminInterface, ref) => {
    const componentFileName: string = "OutboundMailAccountAdmin.tsx";

    //Mail account called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const resourceSet: string = "OutboundMailAccountAdminAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [ipServerColumnLabel, setIpServerColumnLabel] = useState("");
    const [sslEnabledColumnLabel, setSslEnabledColumnLabel] = useState("");
    const [portColumnLabel, setPortColumnLabel] = useState("");
    const [fromAddressColumnLabel, setFromAddressColumnLabel] = useState("");
    const [fromNameColumnLabel, setFromNameColumnLabel] = useState("");
    const [emailBccColumnLabel, setEmailBccColumnLabel] = useState("");
    const [replyToAddressColumnLabel, setReplyToAddressColumnLabel] =
      useState("");
    const [userAuthenticationColumnLabel, setUserAuthenticationColumnLabel] =
      useState("");
    const [
      passwordAuthenticationColumnLabel,
      setPasswordAuthenticationColumnLabel,
    ] = useState("");
    const [timeNotRepeatColumnLabel, setTimeNotRepeatColumnLabel] =
      useState("");
    const [maxTriesColumnLabel, setMaxTriesColumnLabel] = useState("");
    const [timeBetweenTriesColumnLabel, setTimeBetweenTriesColumnLabel] =
      useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    //#endregion

    //Load Resources and fill Active Filter
    const loadResourcesAndRolesFilter = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm")
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle")
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton")
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton")
      );
      //screen
      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel")
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel")
      );
      setCloneLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel"));
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton")
      );
      setNewLabel(await TPI18N.GetText(resourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton")
      );
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData"
        )
      );
      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setDescriptionColumnLabel(
        await TPI18N.GetText(resourceSet, "Description")
      );
      setIsActiveColumnLabel(await TPI18N.GetText(resourceSet, "IsActive"));
      setIpServerColumnLabel(await TPI18N.GetText(resourceSet, "IpServer"));
      setSslEnabledColumnLabel(await TPI18N.GetText(resourceSet, "SslEnabled"));
      setPortColumnLabel(await TPI18N.GetText(resourceSet, "Port"));
      setFromAddressColumnLabel(
        await TPI18N.GetText(resourceSet, "FromAddress")
      );
      setFromNameColumnLabel(await TPI18N.GetText(resourceSet, "FromName"));
      setEmailBccColumnLabel(await TPI18N.GetText(resourceSet, "EmailBCC"));
      setReplyToAddressColumnLabel(
        await TPI18N.GetText(resourceSet, "ReplyToAddress")
      );
      setUserAuthenticationColumnLabel(
        await TPI18N.GetText(resourceSet, "UserAuthentication")
      );
      setPasswordAuthenticationColumnLabel(
        await TPI18N.GetText(resourceSet, "PasswordAuthentication")
      );
      setTimeNotRepeatColumnLabel(
        await TPI18N.GetText(resourceSet, "TimeNoRepeat")
      );
      setMaxTriesColumnLabel(await TPI18N.GetText(resourceSet, "MaxTries"));
      setTimeBetweenTriesColumnLabel(
        await TPI18N.GetText(resourceSet, "TimeBetweenTries")
      );

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        newColumns.push({
          name: "",
          width: "50px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <div className="dropdown">
                  <TPButton
                    id="IdButton"
                    dataBsToggle={true}
                    type={TPButtonTypes.empty}
                    onClick={() => {
                      TPGlobal.foo();
                    }}
                    className={"menu-button"}
                  >
                    <TPIcon iconType={TPIconTypes.moreVert} />
                  </TPButton>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        onClick={(id: any) =>
                          handleDeleteClick(row["idAccount"])
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {deleteLabel}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={(id: any) =>
                          handleUpdateClick(row["idAccount"])
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        {updateLabel}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => onClickCloneHandler(row)}
                        className="dropdown-item"
                        href="#"
                      >
                        {cloneLabel}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            } else {
              return null;
            }
          },
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <TPButton
                  id="IdButton"
                  type={TPButtonTypes.primary}
                  onClick={(id: string) => handleUpdateClick(row["idAccount"])}
                  className="update-button"
                >
                  <TPIcon iconType={TPIconTypes.chevronRight} />
                </TPButton>
              );
            } else {
              return null;
            }
          },
          selector: (row: { [x: string]: any }) => row["idAccount"],
          sortable: true,
        });
        //id
        newColumns.push({
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["idAccount"];
          },
          selector: (row: { [x: string]: any }) => row["idAccount"],
          sortable: true,
        });
        //description
        newColumns.push({
          name: descriptionColumnLabel,
          width: "250px",
          cell: (row: { [x: string]: any }) => {
            return row["description"];
          },
          selector: (row: { [x: string]: any }) => row["description"],
          sortable: true,
        });
        //IP Server
        newColumns.push({
          name: ipServerColumnLabel,
          width: "200px",
          cell: (row: { [x: string]: any }) => {
            return row["ipServer"];
          },
          selector: (row: { [x: string]: any }) => row["ipServer"],
          sortable: true,
        });
        //SSLIsEnabled
        newColumns.push({
          name: sslEnabledColumnLabel,
          selector: (row: { [x: string]: any }) => row["isEnableSSL"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isEnableSSL"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });
        //port
        newColumns.push({
          name: portColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["port"];
          },
          selector: (row: { [x: string]: any }) => row["port"],
          sortable: true,
        });
        //From address
        newColumns.push({
          name: fromAddressColumnLabel,
          width: "250px",
          cell: (row: { [x: string]: any }) => {
            return row["fromAddress"];
          },
          selector: (row: { [x: string]: any }) => row["fromAddress"],
          sortable: true,
        });
        //From name
        newColumns.push({
          name: fromNameColumnLabel,
          width: "250px",
          cell: (row: { [x: string]: any }) => {
            return row["fromName"];
          },
          selector: (row: { [x: string]: any }) => row["fromName"],
          sortable: true,
        });
        //EmailBCC
        newColumns.push({
          name: emailBccColumnLabel,
          width: "400px",
          cell: (row: { [x: string]: any }) => {
            return `${row["emailAddressBCC"]}`;
          },
          selector: (row: { [x: string]: any }) => `${row["emailAddressBCC"]}`,
          sortable: true,
        });
        //replytoemail
        newColumns.push({
          name: replyToAddressColumnLabel,
          width: "250px",
          cell: (row: { [x: string]: any }) => {
            return `${row["emailAddressReplyTo"]}`;
          },
          selector: (row: { [x: string]: any }) =>
            `${row["emailAddressReplyTo"]}`,
          sortable: true,
        });
        //User auth
        newColumns.push({
          name: userAuthenticationColumnLabel,
          width: "200px",
          cell: (row: { [x: string]: any }) => {
            return row["user"];
          },
          selector: (row: { [x: string]: any }) => row["user"],
          sortable: true,
        });
        // //passwordAuth
        // newColumns.push({
        // 	name: passwordAuthenticationColumnLabel,
        // 	width: '200px',
        // 	center: true,
        // 	cell: (row: { [x: string]: any }) => {
        // 		return row['password'];
        // 	},
        // 	selector: (row: { [x: string]: any }) => row['password'],
        // 	sortable: true
        // });
        //timeNotrepeat
        newColumns.push({
          name: timeNotRepeatColumnLabel,
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            return row["timeNotRepeatMail"];
          },
          selector: (row: { [x: string]: any }) => row["timeNotRepeatMail"],
          sortable: true,
        });
        //maxTries
        newColumns.push({
          name: maxTriesColumnLabel,
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            return row["maxTries"];
          },
          selector: (row: { [x: string]: any }) => row["maxTries"],
          sortable: true,
        });
        //timeBetweenTries
        newColumns.push({
          name: timeBetweenTriesColumnLabel,
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            return row["timeBetweenRetries"];
          },
          selector: (row: { [x: string]: any }) => row["timeBetweenRetries"],
          sortable: true,
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get mail accounts by Filter
    const reloadDataGrid = async () => {
      let serviceClient = new OutboundMailAccountService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        let responseRequest = await serviceClient.getAllMailAccounts(
          false,
          true,
          expectedCodes
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid()
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New mail account
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Update mail account
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    const onClickCloneHandler = (element: any) => {
      callBackCommands({ 
        command: "clone",
        recordId: element.idAccount,
        recordDescription: element.idAccount,
        form: element
      });
    };

    //Modal Question to delete mail account
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete mail account after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new OutboundMailAccountService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteMailAccountById(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "idAccount",
        "name",
        "description",
        "ipServer",
        "port",
        "fromAddress",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    // const exportToCSV = (
    //   apiData = filteredData(),
    //   fileName = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    // ) => {
    //   const ws = XLSX.utils.json_to_sheet(apiData);
    //   /* custom headers */
    //   XLSX.utils.sheet_add_aoa(ws, [[]], { origin: 'A1' });
    //   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    //   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //   const data = new Blob([excelBuffer], {
    //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    //   });
    //   FileSaver.saveAs(data, fileName + '.xlsx');
    // };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndRolesFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndRolesFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndRolesFilter ex`
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={(confirmDelete: boolean, callBackData: any) => {
            handleCallBackModal(confirmDelete, callBackData);
          }}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr />
              <TPPageFirstRow>
                <TPPageActions>
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleNewClick()}
                    text={newLabel}
                    icon={TPIconTypes.newEntity}
                  />
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() => handleRefreshClick()}
                    text={refreshLabel}
                    icon={TPIconTypes.refresh}
                  />
                  <TPButton
                    id="IdButton"
                    type={TPButtonTypes.icon}
                    onClick={() =>
                      exportToCSV(filteredData(), "outbound-mail-admin-data", [
                        "password",
                      ])
                    } //exportToCSV(filteredData(), 'outbound-mail-admin-data')}
                    text={exportLabel}
                    icon={TPIconTypes.fileDownload}
                  />
                </TPPageActions>
                <TPFilterAndSearch>
                  <TPPageSearchContainer>
                    <TPTextBox
                      id="IdTextBox"
                      icon={TPIconTypes.search}
                      withIcon={true}
                      value={adminState.searchPattern}
                      placeholder={searchLabel}
                      onChange={(e: any) =>
                        handleSearchPatternChange(e.target.value)
                      }
                      isHorizontal={true}
                    />
                  </TPPageSearchContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  }
);

export default OutboundMailAccountAdmin;
