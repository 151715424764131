import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { CaseService } from "@/services/CaseService";
import React, { forwardRef, useEffect, useState } from "react";
import { ContainerTabStyle, TabActiveStyle } from "../FormDesigner/StyleFromDesigner";
import { useAppMyReminders } from "./ContextMyReminders";
import MyRemindersCalendar from "./MyRemindersCalendar";
import MyRemindersList from "./MyRemindersList";
import { LocationLanguagesReminders, LocationNameReminders } from "./MyRemindersLocation";
import { CountStyle, NumberCountRemindersStyle } from "./StyeMyReminders";



interface Model {
  callBackCommands: Function;
}

enum pageEnum {
  Calendar,
  List
}

enum pageContainerEnum {
  Calendar
}


const MyReminders = forwardRef(
  ({ callBackCommands }: Model, ref) => {
    const casecontext: any = React.useContext(TPCaseViewerContext);
    const [location, setLocation] = useState(new LocationNameReminders);
    const [page, setPage] = useState(pageEnum.Calendar);
    const [pageContainer, setPageContainer] = useState(pageContainerEnum.Calendar);

    const handleSearchButtonClick = async (id: string) => {
      let serviceClient = new CaseService();
      let expectedCodes: Array<number> = [200];

      try {
        setLoading(true);

        let isValidCaseNumber: boolean = await serviceClient.checkCaseById(
          Number.parseInt(id),
          false,
          false,
          expectedCodes
        );

        if (isValidCaseNumber) {
          casecontext.handleAddNewCaseViewerCallBack(id);
        }
        setLoading(false);
      } catch (error) {
        TPLog.Log(
          `Error ${resourceSet} handleSearchButtonClick ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${resourceSet} handleSearchButtonClick ex`);
        setLoading(false);
      }
    };


    const loadResourcesAndLoadInfo = async () => {
      let temp = new LocationLanguagesReminders();
      await temp.handleLocationNameReminders(resourceSet);
      setLocation(temp.getLocationNameReminders);
    }

    const value = useAppMyReminders();
    const {
      resourceSet, calendarReminders, setLoading
    } = value;

    useEffect(() => {
      loadResourcesAndLoadInfo();
    }, []);

    return (

      <>

        <ContainerTabStyle style={{ height: "44px" }}>
          <TabActiveStyle
            onClick={() => {
              setPage(pageEnum.List);
            }}
            activeBorder={page == pageEnum.List} >


            <TPButton
              style={{
                color: page == pageEnum.List ? "" : "gray"
              }}
              iconStyle={{ fontSize: "24px" }}
              type={TPButtonTypes.icon}
              text={""}
              onClick={() => {
              }}
              icon={TPIconTypes.playlistAddCheck}
            >

            </TPButton>

          </TabActiveStyle>
          <TabActiveStyle
            onClick={() => {
              setPage(pageEnum.Calendar);
            }}
            activeBorder={page == pageEnum.Calendar} >
            <NumberCountRemindersStyle>
              <TPButton
                style={{
                  color: page == pageEnum.Calendar ? "" : "gray"
                }}
                iconStyle={{ fontSize: "18px" }}
                type={TPButtonTypes.icon}
                text={""}
                onClick={() => { }}
                icon={TPIconTypes.calendar}
              >

              </TPButton>
              {calendarReminders && calendarReminders.length > 0 &&
                <CountStyle>{calendarReminders.length}</CountStyle>
              }
            </NumberCountRemindersStyle>
          </TabActiveStyle>
        </ContainerTabStyle>


        <>
          {Array.isArray(calendarReminders) && calendarReminders.filter(s => s.idCase != "").length > 0 ?
            < MyRemindersCalendar active={(page == pageEnum.Calendar)} key="calendar" callBackCommands={callBackCommands} ></MyRemindersCalendar>
            :
            < MyRemindersCalendar active={(page == pageEnum.Calendar)} key="calendar1" callBackCommands={callBackCommands} ></MyRemindersCalendar>
          }
          <MyRemindersList active={(page == pageEnum.List)} key="list" callBackCommands={handleSearchButtonClick}  ></MyRemindersList>
        </>

      </>

    );
  }
);

export default MyReminders;
