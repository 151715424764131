import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { GroupsInputDTO } from "@/models/Groups/GroupsInputDTO";
import { AssignableGroupUser, GroupsViewModel, GroupUsersReassignModel, QueueAdminModel } from "@/models/Groups/GroupsModels";
import { ProjectType } from "@/models/Project/Projects";
import GroupCloneFormModel from "@/pages/Groups/GroupCloneForm/models/GroupCloneFormModel";

export class GroupsService {
  serviceFileName: string = "GroupsService.ts";
  public async getGroupsByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<GroupsViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroupsByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGroupsByFilter`);
      throw new Error(`Error ${this.serviceFileName} getGroupsByFilter`);
    }
  }

  public async getGroupsByUserId(
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user-group/user/";
    url = url + userId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroupsByUserId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGroupsByUserId`);
      throw new Error(`Error ${this.serviceFileName} getGroupsByUserId`);
    }
  }

  public async reassignGroups(
    inputDTO: any,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/user-group/reassign-user";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} reassignGroups ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} reassignGroups`);
      throw new Error(`Error ${this.serviceFileName} reassignGroups`);
    }
  }

  public async getGroupById(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<GroupsViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups/";
    url = url + groupId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    console.warn(groupId);
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getGroupById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getGroupById`);
      throw new Error(`Error ${this.serviceFileName} getGroupById`);
    }
  }

  public async deleteGroupById(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups/" + groupId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteGroupById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteGroupById`);
      throw new Error(`Error ${this.serviceFileName} deleteGroupById`);
    }
  }

  public async insertGroup(
    inputDTO: GroupsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} insertGroup`);
      throw new Error(`Error ${this.serviceFileName} insertGroup`);
    }
  }

  public async updateGroup(
    inputDTO: GroupsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateGroup`);
      throw new Error(`Error ${this.serviceFileName} updateGroup`);
    }
  }

  public async updateQueueAdministration(
    requestBody: QueueAdminModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/groups/eventtakenextgroup";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateGroup`);
      throw new Error(`Error ${this.serviceFileName} updateGroup`);
    }
  }

  public async clone(
    group: GroupCloneFormModel,
    showPositiveMessage: boolean = false,
    showNegativeMessage: boolean = false,
    expectedCodes: Array<number> = [200],
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/groups/clone`;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        url,
        group,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateGroup ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateGroup`);
      throw new Error(`Error ${this.serviceFileName} updateGroup`);
    }
  }

  public async assignUsersToGroup(
    requestBody: GroupUsersReassignModel,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/user-group/assign-user-massive';
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      await httpClientInstance.postData(
        url,
        requestBody,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    }
    catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} assignUsersToGroup ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} assignUsersToGroup`);
      throw new Error(`Error ${this.serviceFileName} assignUsersToGroup`);
    }
  }

  public async unassignUserToGroup(
    groupId: string,
    userId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<void> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + `/user-group/Unassign/group/${groupId}/user/${userId}`;
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      await httpClientInstance.putData(
        url,
        null,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )
    }
    catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} assignUsersToGroup ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} assignUsersToGroup`);
      throw new Error(`Error ${this.serviceFileName} assignUsersToGroup`);
    }
  }

  public async getAssignedUsers(
    groupId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<AssignableGroupUser>> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/user-group/group/' + groupId;
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )

      return response.responseData.data;
    }
    catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} assignUsersToGroup ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getAssignedUsers`);
      throw new Error(`Error ${this.serviceFileName} getAssignedUsers`);
    }
  }

  public async findByType(type: ProjectType): Promise<Array<any>> {
    const {getData} = new TPHTTPService();
    try {
      const response = await getData(
          `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/groups/event-take-next-type/${type}`,
          [200, 404],
          false,
          false,
          [...TPGlobal.standardHeaders]
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
      console.error(`Error ${this.serviceFileName} find`);
      throw new Error(`Error ${this.serviceFileName} find`);
    }
  }

  public async getQueue(
    queueId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<QueueAdminModel> {
    try {
      const httpClientInstance = new TPHTTPService();
      const url = TPGlobal.baseAPIGateway + 'api/tenant/' + TPGlobal.tenantHex + '/groups/eventtakenextgroup/' + queueId;
      const headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

      const response: HTTPResponse = await httpClientInstance.getData(
        url,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      )

      return response.responseData.data[0];
    }
    catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} assignUsersToGroup ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getAssignedUsers`);
      throw new Error(`Error ${this.serviceFileName} getAssignedUsers`);
    }
  }

}
