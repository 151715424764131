import React, { useEffect, useState } from "react";
import { useAppConctextImageAdmin } from "./ImagesAdminContextImagesAdmin";
import {
    ButtonStyle,
    ContainerSliderStyle,
    ContainerViewDetail,
    ImageIcoStyle,
    ImageSliderStyle,
    ItemSliderStyle,
    TitleImageSlider,
} from "./StyleImageAdmin";
import styled from "styled-components";
import { OperationMode } from "./useStatusImagesAdmin";
import { RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";

type ContainerFolderProps = {
    files: RecursiveRoutes[];
    ChangeImageSelection?: Function;
};


const ImagesAdminSlider: React.FC<ContainerFolderProps> = ({ files, ChangeImageSelection }: ContainerFolderProps) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const value = useAppConctextImageAdmin();
    const { handleSelected, selectedItem, selectedFolderParent, modeSelect } = value;

    useEffect(() => {
        for (let i = 0; i < files.length; i++) {
            if (files[i].id == selectedItem.id) {
                if (selectedItem.imageUrl != "") {
                    setCurrentIndex(i);
                    break;
                }
            }
        }
    }, []);

    const handleNext = () => {
        if (currentIndex < files.length - 1) {
            setCurrentIndex(currentIndex + 1);
            if (files[currentIndex].imageUrl != "") {
                handleSelected(files[currentIndex], OperationMode.SelectFile);
            }
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            if (files[currentIndex].imageUrl != "") {
                handleSelected(files[currentIndex], OperationMode.SelectFile);
            }
        }
    };

    const handleCancel = () => {
        setCurrentIndex(0);
        handleSelected(selectedFolderParent, OperationMode.ActiveFolder);
    };


    const handleModeSelected = (file?: RecursiveRoutes) => {
        if (file) {

            if (ChangeImageSelection) ChangeImageSelection(file);
        }
        else {

            if (ChangeImageSelection) ChangeImageSelection("...");
        }
        handleCancel();

    };

    if (selectedItem && selectedItem.imageUrl) {
        return (
            <ContainerSliderStyle onClick={handleCancel}>
                <ItemSliderStyle onClick={(e) => e.stopPropagation()}>
                    <TitleImageSlider>{files[currentIndex].fileName}</TitleImageSlider>
                    {files.length > 0 ? (
                        <ImageSliderStyle
                            src={files[currentIndex].imageUrl}
                            alt={
                                files[currentIndex].fileName != undefined
                                    ? files[currentIndex].fileName
                                    : ""
                            }
                        />
                    ) : null}

                    <div>

                        {!modeSelect ?
                            <>
                                <ButtonStyle onClick={handlePrev} disabled={currentIndex === 0}>
                                    <i className="bi bi-chevron-compact-left"></i>
                                </ButtonStyle>
                                <ButtonStyle
                                    onClick={handleNext}
                                    disabled={currentIndex === files.length - 1}
                                >
                                    <i className="bi bi-chevron-compact-right"></i>
                                </ButtonStyle>
                            </>
                            :

                            <>
                                <ButtonStyle onClick={() => handleModeSelected(files[currentIndex])}>
                                    <i style={{ color:"black" }} className="bi bi-plus-circle-fill"></i>
                                </ButtonStyle>
                            </>

                        }
                    </div>

                </ItemSliderStyle>
            </ContainerSliderStyle>
        );
    } else {
        return null;
    }
};

export default ImagesAdminSlider;
