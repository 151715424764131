import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useState } from "react";
import { ContainerTabStyle, TabActiveStyle } from "../FormDesigner/StyleFromDesigner";
import StructureAdminClone from "./StructureAdminClone";
import StructureAdminUpdateInsert from "./StructureAdminUpdateInsert";
import StructureAdminUpdateInsertDetails from "./StructureAdminUpdateInsertDetails";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    description: string;
    callBackResult: Function;
};

enum pageEnum {
    general,
    details
}

const StructureAdminUpdateTab: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    description,
    callBackResult,
}) => {


    const componentFileName: string = "StructureAdminUpdateTab.tsx";
    const resourceSet: string = "StructureAdminUpdateTab";

    const [page, setPage] = useState(pageEnum.general);

    const [detailsLabel, setDetailsLabel] = useState('');
    const [generalLabel, setGeneralLabel] = useState('');

    const [titleLabel, setTitleLabel] = useState("");

    const [detailsZIndex, setDetailsZIndex] = useState(0);

    const loadResourcesAndLoadInfo = async () => {
        setDetailsLabel(await TPI18N.GetText(resourceSet, "DetailsTitle"));
        setGeneralLabel(await TPI18N.GetText(resourceSet, "GeneralInformationTitle"));

        //ResourcesAndLoadInfo
        if (mode === 'CloneEmail') {
            setTitleLabel(await TPI18N.GetText(resourceSet, "CloneTitle"));
        } else if (mode === 'Update') {
            setTitleLabel(await TPI18N.GetText(resourceSet, "UpdateTitle"));
        } else if (mode === 'Insert') {
            setTitleLabel(await TPI18N.GetText(resourceSet, "InsertTitle"));
        }
    }

    useEffect(() => {
        loadResourcesAndLoadInfo();
    }, []);

    const indexCallback = (index: number) => {
        setDetailsZIndex(index);
    }

    return (
        <>
            <div style={{ position: "relative", zIndex: 1, top: "0px", left: "20px", width: "40%" }}>
                <div className="row">
                    <div className="col-10">
                        <TPPageTitle>{titleLabel}</TPPageTitle>
                    </div>
                </div>
                <div>
                    <ContainerTabStyle>
                        <TabActiveStyle activeBorder={page == pageEnum.general} style={{ display: "inline-flex", whiteSpace: "nowrap", width: "fit-content" }}>
                            <TPButton
                                style={{
                                    color: page == pageEnum.general ? "" : "gray"
                                }}
                                type={TPButtonTypes.empty}
                                text={"1"}
                                onClick={() => {
                                    setPage(pageEnum.general);
                                }}
                                icon={TPIconTypes.default}
                            >
                                {generalLabel}
                            </TPButton>
                        </TabActiveStyle>
                        <TabActiveStyle activeBorder={page == pageEnum.details} style={{ display: "inline-flex", whiteSpace: "nowrap", width: "fit-content" }}>
                            <TPButton
                                style={{
                                    color: page == pageEnum.details ? "" : "gray"
                                }}
                                disabled={mode === 'Update' ? false : true}
                                type={TPButtonTypes.empty}
                                text={"1"}
                                onClick={() => {
                                    setPage(pageEnum.details);
                                }}
                                icon={TPIconTypes.default}
                            >
                                {detailsLabel}
                            </TPButton>
                        </TabActiveStyle>
                    </ContainerTabStyle>
                </div>
            </div>
            <br />
            {page == pageEnum.general && (
                <>
                    {mode === 'CloneEmail' ? (
                        <div style={{ position: "absolute", top: "180px", left: "20px", zIndex: 0, width: "95%" }}>
                            <StructureAdminClone
                                mode={mode}
                                recordInput={recordId}
                                recordDescription={description}
                                callBackComands={callBackResult}
                            />
                        </div>

                    ) : (
                        <div style={{ position: "absolute", top: "180px", left: "20px", zIndex: 2, width: "95%" }}>
                            <StructureAdminUpdateInsert
                                mode={mode}
                                recordId={recordId}
                                callBackResult={callBackResult}
                            />
                        </div>
                    )}
                </>
            )}
            {page == pageEnum.details && (
                <>
                    {mode === 'Update' && (
                        <div style={{ position: "absolute", top: "50px", left: "20px", zIndex: detailsZIndex, width: "95%" }}>
                            <StructureAdminUpdateInsertDetails
                                mode={mode}
                                recordInput={recordId}
                                recordDescription=""
                                callBackComands={indexCallback}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default StructureAdminUpdateTab;