import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPI18N } from "@/services/I18nService";
import TPGlobal from "@/helpers/TPGlobal";
import CustomStateInsertUpdateForm from "./CustomStateInsertUpdateForm";
import { Box, SxProps } from "@mui/material";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  SequenceGeneratorSequencesNameEnum,
  TPButtonTypes,
} from "@/models/Global/TPGlobalEnums";
import {
  CustomStateCommandType,
  CustomStateNewEvents,
  CustomStateNewModel,
  CustomStateNewProps,
  CustomStateNewEnum as e,
} from "@/models/CustomState/CustomState";
import { CustomStateService } from "@/services/CustomStateService";
import { SequenceService } from "@/services/SequenceService";

/**
 * STYLES
 */
const styles = {
  title: {
    textTransform: "none",
  } as CSSProperties,
  actions: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    placeContent: "flex-end",
    alignItems: "flex-end",
    gap: "10px",
  } as SxProps,
  cancelButton: {
    backgroundColor: "white",
    color: "#a00095",
    border: "1px solid #a00095",
  } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * component of custom state insert or update
 */
const CustomStateInsertUpdate = forwardRef(
  (
    { workflowDefinitionId, element, pageDispatch }: CustomStateNewProps,
    ref
  ) => {
    /**
     * ATTRIBUTES
     */
    /**
     * custom state
     */
    const [customState, setCustomState] = useState<CustomStateNewModel>();
    /**
     * messages
     */
    const [m, setMessages] = useState<any>({});
    /**
     * loading
     */
    const [loading, setLoading] = useState(true);
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
        }) as CustomStateNewEvents
    );
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
      loadResources().then(() => setLoading(false));

      if (element?.customStateFlowId) {
        loadDataSource();
      } else {
        loadId();
      }
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {}, []);
    /**
     * EVENT LISTENERS END
     */

    async function loadResources() {
      const messages = { ...m };
      // title label
      messages[e.TitleLabel] = "New Customer State"; //await TPI18N.GetText(e.CustomStateNewComponent, e.TitleLabel);
      messages[e.UpdateTitleLabel] = "Update Customer State"; //await TPI18N.GetText( e.CustomStateNewComponent,e.UpdateTitleLabel);
      // form inputs label
      messages[e.FormInputIdLabel] = "Custom State ID"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormInputIdLabel);
      messages[e.FormSelectCustomStateTypeIdLabel] = "Custom State Type"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectCustomStateTypeIdLabel);
      messages[e.FormSelectCustomStateLabel] = "Custom State"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectCustomStateLabel);
      messages[e.FormSelectBaseField1Label] = "Base Field 1"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectBaseField1Label);
      messages[e.FormSelectReferenceValue1Label] = "Reference Value 1"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectReferenceValue1Label);
      messages[e.FormSelectBaseField2Label] = "And Base Field 2"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectBaseField2Label);
      messages[e.FormSelectReferenceValue2Label] = "Reference Value 2"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectReferenceValue2Label);
      messages[e.FormSelectComparisonOperatorLabel] = "Comparison Operator 2"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormSelectComparisonOperatorLabel);
      messages[e.FormInputWeightLabel] = "Weight"; //await TPI18N.GetText(e.CustomStateNewComponent, e.FormInputWeightLabel);
      // form actions label
      messages[e.FormActionCancelLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.FormActionCancelLabel
      );
      messages[e.FormActionSaveLabel] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.FormActionSaveLabel
      );
      messages[e.TaskStatus] = await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        e.TaskStatus
      );

      setMessages(messages);
    }
    /**
     * load custom state
     */
    async function loadId() {
      const { generalAutomaticId } = new SequenceService();
      generalAutomaticId(
        false,
        true,
        [200],
        SequenceGeneratorSequencesNameEnum.SQCUTY
      ).then((response) => {
        setCustomState({
          id: response?.responseData?.data[0]?.sequenceCode,
        } as CustomStateNewModel);
      });
    }
    /**
     * load custom state
     */
    function loadDataSource() {
      const { findOne } = new CustomStateService();
      findOne(element!.customStateFlowId)
        .then((response) => {
          console.log("response", response);
          setCustomState(response as CustomStateNewModel);
        })
        .catch((error) => console.error(error));
    }

    function onChangeHandler(element: CustomStateNewModel) {
      setCustomState({ ...customState, ...element });
    }

    function onClickCancelButtonHandler() {
      pageDispatch({ type: CustomStateCommandType.refreshList });
      pageDispatch({ type: CustomStateCommandType.redirectToList });
    }

    function onClickSaveButtonHandler() {
      const request = {
        ...customState,
        workFlowTypeId: workflowDefinitionId,
      } as CustomStateNewModel;

      const { save, update } = new CustomStateService();
      setLoading(true);
      (element ? update : save)(request)
        .then((response) => {
          setLoading(false);
          pageDispatch({ type: CustomStateCommandType.refreshList });
          pageDispatch({ type: CustomStateCommandType.redirectToList });
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }

    return (
      <TPLoadingOverlay active={loading}>
        <div className="row">
          <div className="col-8">
            <TPPageTitle style={styles.title}>
              {m?.[element ? e.UpdateTitleLabel : e.TitleLabel]}
            </TPPageTitle>

            {customState && (
              <CustomStateInsertUpdateForm
                m={m}
                mode={element ? "update" : "insert"}
                element={
                  element
                    ? customState
                    : ({
                        id: customState.id,
                        workFlowTypeId: workflowDefinitionId!,
                      } as CustomStateNewModel)
                }
                onChange={onChangeHandler}
              />
            )}

            <Box sx={styles.actions}>
              <TPButton
                id="CustomStateButtonCancel"
                key="CustomStateButtonCancel"
                style={styles.cancelButton}
                type={TPButtonTypes.primary}
                onClick={onClickCancelButtonHandler}
              >
                {m?.[e.FormActionCancelLabel]}
              </TPButton>

              <TPButton
                id="CustomStateButtonSave"
                key="CustomStateButtonSave"
                type={TPButtonTypes.primary}
                onClick={onClickSaveButtonHandler}
              >
                {m?.[e.FormActionSaveLabel]}
              </TPButton>
            </Box>
          </div>
        </div>
      </TPLoadingOverlay>
    );
  }
);

export default CustomStateInsertUpdate;
