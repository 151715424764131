import TPButton from '@/components/bootstrap/components/buttons/TPButton';
import TPIcon from '@/components/bootstrap/extend/TPIcons/TPIcon';
import TPLoadingOverlay from '@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay';
import { TPChip } from '@/components/TPChip/TPChip';
import { TPPageTitle } from '@/components/TPPage/tpPageStyles';
import TPGlobal from '@/helpers/TPGlobal';
import { TPButtonTypes, TPIconTypes } from '@/models/Global/TPGlobalEnums';
import { ListModel, ListTableModel } from '@/models/ListAdministration/ListAdministrationModels';
import DynamicTable from '@/modules/core/components/dynamic-table/DynamicTable';
import { ListsServices } from '@/services/EventsManager/ListsService';
import { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import useListAdminLabels from './assets/Labeling';
import './assets/Styles.css';

interface ListAdminHomeProperties {
	verticalTabsCallback: Function;
}

const ListAdminHome = forwardRef(({ verticalTabsCallback }: ListAdminHomeProperties, ref) => {
	const { labels } = useListAdminLabels();
	const [lists, setLists] = useState<ListModel[]>([]);
	const [dataLoaded, setDataLoaded] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		refreshGridFromParent() {
			fetchData();
		}
	}))

	const fetchData = function () {
		setDataLoaded(false);
		ListsServices.getAllLists(false, false, [200]).then(listsResponse => {
			setDataLoaded(true);
			setLists(listsResponse);
		});
	};

	useEffect(() => {
		if (!dataLoaded) fetchData();
	}, []);

	const handleTableIconClick = function (icon: TPIconTypes) {
		if (icon == TPIconTypes.refresh || icon == TPIconTypes.loop) {
			fetchData();
		}
	};

	return (
		<TPLoadingOverlay active={!dataLoaded}>
			<div id="list-admin-home" className="list-admin-main">
				<div className="list-admin-header">
					<TPPageTitle>{labels.ListAdministration}</TPPageTitle>
					<TPButton
						onClick={() =>
							verticalTabsCallback({
								command: 'new'
							})
						}
						type={TPButtonTypes.primary}
						style={{ display: 'flex', alignItems: 'center', gap: '8px', backgroundColor: '#780096' }}
					>
						<TPIcon iconType={TPIconTypes.plusAlt} />
						{labels.NewList}
					</TPButton>
				</div>
				<DynamicTable
					data={lists.map(list => {
						return {
							id: list.id,
							description: list.description,
							createdBy: list.createdBy,
							alphabeticalOrder: list.alphabeticalOrder,
							isActive: list.isActive,
						} as ListTableModel;
					})}
					minorOptions={[
						{
							key: labels.Edit,
							onOptionChange: e =>
								verticalTabsCallback({
									command: 'update',
									recordId: e.id,
									languageId: TPGlobal.language,
									recordDescription: e.description
								}),
							type: 'edit',
							icon: TPIconTypes.edit
						},
						{
							key: labels.Delete,
							onOptionChange: e => {
								setDataLoaded(false);
								ListsServices.deleteList(e.id, true, true, [200])
									.then(() => fetchData())
									.catch(err => {
										console.error(err);
										setDataLoaded(true);
									});
							},
							type: 'delete',
							icon: TPIconTypes.delete
						},
						{
							key: labels.Clone,
							onOptionChange: e =>
								verticalTabsCallback({
									command: 'clone',
									recordId: e.id,
									languageId: TPGlobal.language,
									recordDescription: e.description
								}),
							type: 'clone',
							icon: TPIconTypes.clone
						}
					]}
					withPreferences
					columnNames={{
						id: labels.Id?.toUpperCase(),
						alphabeticalOrder: labels.AlphabeticalOrder?.toUpperCase(),
						description: labels.Name?.toUpperCase(),
						isActive: labels.Active?.toUpperCase(),
						createdBy: labels.CreatedBy?.toUpperCase(),
					}}
					columnStyles={{
						id: ({ item, value }) => (
							<b
								style={{ cursor: 'pointer', color: 'purple' }}
								onClick={() =>
									verticalTabsCallback({
										command: 'update',
										recordId: item.id,
										languageId: TPGlobal.language,
										recordDescription: item.description
									})
								}
							>
								<u>{value}</u>
							</b>
						),
						alphabeticalOrder: ({ item, value }) => (
							<TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? labels.Yes : labels.No} />
						),
						isActive: ({ item, value }) => (
							<TPChip backgroundColor={value ? '#B1F2D7' : '#FFD7DD'} label={value ? labels.Yes : labels.No} />
						)
					}}
					onIconClicked={handleTableIconClick}
				/>
			</div>
		</TPLoadingOverlay>
	);
});

export default ListAdminHome;
