import TPGlobal from "@/helpers/TPGlobal";
import {TPLog, TPLogType} from "@/helpers/TPLog";
import {TPHTTPService} from "@/helpers/TPHttpService";
import {
    ProjectCloneModel,
    ProjectDetailModel,
    ProjectModel,
    ProjectType,
} from "@/models/Project/Projects";

export class EventProjectService {
    private serviceFileName: string = "EventProjectService.ts";

    public async save(eventProject: any): Promise<ProjectModel> {
        const {postData} = new TPHTTPService();
        try {
            const response = await postData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject`,
                eventProject,
                [200, 404],
                true,
                true,
                [...TPGlobal.standardHeaders]
            );
            return {id: response.responseData.keyList?.[0]?.value} as ProjectModel;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} save ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} save`);
            throw new Error(`Error ${this.serviceFileName} save`);
        }
    }

    public async update(eventProject: ProjectModel): Promise<ProjectModel> {
        const {putData} = new TPHTTPService();
        try {
            const response = await putData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject`,
                eventProject,
                [200, 404],
                true,
                true,
                [...TPGlobal.standardHeaders]
            );
            return {id: response.responseData.keyList?.[0]?.value} as ProjectModel;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} update`);
            throw new Error(`Error ${this.serviceFileName} update`);
        }
    }

    public async reassign(eventProject: ProjectModel): Promise<ProjectModel> {
        const {putData} = new TPHTTPService();
        try {
            const response = await putData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject`,
                eventProject,
                [200, 404],
                true,
                true,
                [...TPGlobal.standardHeaders]
            );
            return {id: response.responseData.keyList?.[0]?.value} as ProjectModel;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} update`);
            throw new Error(`Error ${this.serviceFileName} update`);
        }
    }

    public async clone(eventProject: ProjectCloneModel): Promise<ProjectModel> {
        const {postData} = new TPHTTPService();
        try {
            const response = await postData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject/clone`,
                eventProject,
                [200, 404],
                true,
                true,
                [...TPGlobal.standardHeaders]
            );
            return {id: response.responseData.keyList?.[0]?.value} as ProjectModel;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} update`);
            throw new Error(`Error ${this.serviceFileName} update`);
        }
    }

    public async deleteOne(id: string): Promise<void> {
        const {deleteData} = new TPHTTPService();
        try {
            await deleteData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject/${id}`,
                [200, 404],
                true,
                true,
                [...TPGlobal.standardHeaders]
            );
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} delete ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} delete`);
            throw new Error(`Error ${this.serviceFileName} delete`);
        }
    }

    public async findOne(id: string): Promise<ProjectModel> {
        const {getData} = new TPHTTPService();
        try {
            const response = await getData(
                `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject/${id}`,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response?.responseData?.data?.[0];
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} findOne ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} findOne`);
            throw new Error(`Error ${this.serviceFileName} findOne`);
        }
    }

    public async find(params?: { [attribute: string]: string }): Promise<Array<ProjectModel>> {
        const {getData} = new TPHTTPService();
        let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject`;
        if (params) {
            const p = Object.entries(params)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            url = `${url}?${p}`;
        }
        try {
            const response = await getData(
                url,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} find`);
            throw new Error(`Error ${this.serviceFileName} find`);
        }
    }

    public async findDetail(id: string): Promise<Array<ProjectDetailModel>> {
        const {getData} = new TPHTTPService();
        let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject/details/${id}`;
        try {
            const response = await getData(
                url,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response.responseData.data?.[0]?.queueDetails;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} find`);
            throw new Error(`Error ${this.serviceFileName} find`);
        }
    }

    public async findGroups(id: string): Promise<Array<{id: number, name: string}>> {
        const {getData} = new TPHTTPService();
        let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventProject/${id}/group`;
        try {
            const response = await getData(
                url,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} find`);
            throw new Error(`Error ${this.serviceFileName} find`);
        }
    }

    public async isToReplace(id: string): Promise<any> {
        const {getData} = new TPHTTPService();
        let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventLoad/lastEventLoad/eventProjectId/${id}`;
        try {
            const response = await getData(
                url,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} find`);
            throw new Error(`Error ${this.serviceFileName} find`);
        }
    }

    public async hasFile(id: string): Promise<any> {
        const {getData} = new TPHTTPService();
        let url = `${TPGlobal.baseAPIGateway}api/tenant/${TPGlobal.tenantHex}/eventload/lasteventtemporaryload/eventProjectId/${id}`;
        try {
            const response = await getData(
                url,
                [200, 404],
                false,
                false,
                [...TPGlobal.standardHeaders]
            );
            return response.responseData.data;
        } catch (error) {
            TPLog.Log(`Error ${this.serviceFileName} find ex`, TPLogType.ERROR, error);
            console.error(`Error ${this.serviceFileName} find`);
            throw new Error(`Error ${this.serviceFileName} find`);
        }
    }
}
