
import TPCaseViewerContext from '@/contexts/TPCaseViewerContext';
import TPGlobal from '@/helpers/TPGlobal';
import { TPLog, TPLogType } from '@/helpers/TPLog';
import { CaseService } from '@/services/CaseService';
import { RemindersService } from "@/services/MyRemindersService";
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import React, { forwardRef, useEffect, useState } from 'react';
import { useAppMyReminders } from "./ContextMyReminders";
import { LocationLanguagesReminders, LocationNameReminders } from "./MyRemindersLocation";
import { CalendarHeaderStyle, ContainerCalendarStyle } from './StyeMyReminders';
interface InputModel {
    callBackCommands: Function;
    active: boolean;
}

const MyRemindersCalendar = forwardRef(
    ({ callBackCommands, active }: InputModel, ref) => {
        const [location, setLocation] = useState(new LocationNameReminders());
        const [calendar, setCalendar] = useState(0);
        const casecontext: any = React.useContext(TPCaseViewerContext);

        const handleSearchButtonClick = async (id: string) => {
            let serviceClient = new CaseService();
            let expectedCodes: Array<number> = [200];

            try {
                setLoading(true);

                let isValidCaseNumber: boolean = await serviceClient.checkCaseById(
                    Number.parseInt(id),
                    false,
                    true,
                    expectedCodes
                );

                if (isValidCaseNumber) {
                    casecontext.handleAddNewCaseViewerCallBack(id);
                }
                setLoading(false);
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} handleSearchButtonClick ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} handleSearchButtonClick ex`);
                setLoading(false);
            }
        };

        const handleConvertToCalendar = () => {

            if (Array.isArray(calendarReminders) && calendarReminders.filter(s => s.idCase != "").length > 0) {

                let temp = calendarReminders.filter(s => s.idCase != "");

                const list = temp.map((item, key) => (
                    {
                        id: item.id,
                        title: handleGetTime(item.id) + " - " + item.idCase,
                        date: item.date.replaceAll("/", "-").split(",")[0]
                    }
                ));
                return list;
            } else {

            }
        };


        const handleGetTime = (id: string) => {
            if (calendarReminders && calendarReminders.length > 0) {
                const data = calendarReminders.find(s => s.id == id);

                if (data) {
                    if (Array.isArray(data?.date.split(",")) && data?.date.split(",").length > 0) {

                        let timeString = data?.date.split(",")[1];

                        if (timeString) {
                            const [hours, minutes] = timeString.split(":");
                            timeString = `${hours}:${minutes}`;
                        }

                        return timeString;
                    }
                }
            }
            return "";
        };


        const handleGetDescription = (id: string) => {

            if (calendarReminders && calendarReminders.length > 0) {
                const data = calendarReminders.find(s => s.id == id);
                if (data)
                    return data.description;

            }
            return "";
        };


        const handleGetDateEnd = () => {

            if (Array.isArray(calendarReminders) && calendarReminders.length > 0) {

                const validReminders = calendarReminders.filter(
                    (s) => s && s.idCase && s.idCase.trim() !== ""
                );

                if (validReminders.length > 0) {
                    const sortedReminders = validReminders.sort((a, b) => {
                        const dateA = a?.date ? new Date(a.date.split(",")[0]).getTime() : NaN;
                        const dateB = b?.date ? new Date(b.date.split(",")[0]).getTime() : NaN;
                        return dateA - dateB;
                    });
                                      
                    const latestReminder = sortedReminders[sortedReminders.length - 1];
                    if (latestReminder && latestReminder.date && latestReminder.date.split(",").length >= 0) {

                        console.log("Fecha", latestReminder.date.split(",")[0]);
                        if (latestReminder.date.trim() != "") {
                            return latestReminder.date.split(",")[0];
                        } else {
                            //console.warn("La fecha m�s reciente es inv�lida o no tiene un formato correcto.");
                        }
                    } else {
                        //console.warn("No se pudo encontrar una fecha v�lida en los recordatorios.");
                    }
                } else {
                    //console.warn("No se encontraron recordatorios v�lidos con idCase.");
                }
            } else {
                // console.warn("No se proporcionaron recordatorios o los datos son incorrectos.");
            }

            //console.warn("No se pudo obtener una fecha v�lida. Mostrando la fecha de hoy.");
            const today = new Date().toISOString().split('T')[0];
            return today;
        };



        const handleTitleEvent = (id: string, title: string) => {
            let data = id.split(',')[0];
            handleSearchButtonClick(data);
        };

        const handleDateEvent = (date: string) => { };

        const loadResources = async () => {
            let temp = new LocationLanguagesReminders();
            await temp.handleLocationNameReminders(resourceSet);
            setLocation(temp.getLocationNameReminders);
        };
        function loadData() {
            const { getByFilter: getFunctionsByFilter } = new RemindersService();
            let expectedCodes: Array<number> = [200];

        }

        const value = useAppMyReminders();
        const { resourceSet, calendarReminders, setLoading, setCalendarReminders } = value;

        useEffect(() => {
            loadResources();
            loadData();
        }, [calendarReminders]);

        useEffect(() => {          
            setCalendar(calendar + 1);
        }, [calendarReminders]);


        return (
            <>
                {active &&
                    <ContainerCalendarStyle
                    >
                        <CalendarHeaderStyle>
                            <FullCalendar
                                initialDate={handleGetDateEnd()}
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                                initialView="dayGridMonth"
                                headerToolbar={{
                                    left: 'prevYear,prev,next,nextYear today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                                buttonText={{
                                    prevYear: '<<', 
                                    prev: '<',      
                                    next: '>',     
                                    nextYear: '>>',
                                    today: 'Hoy',            
                                }}
                                editable={true}
                                selectable={true}
                                events={handleConvertToCalendar()}
                                eventContent={(eventInfo: any) => (
                                    <div className="tooltip-container">
                                        {eventInfo.event.title}
                                        <div className="my-tooltip" >
                                            {handleGetDescription(eventInfo.event.id)}
                                        </div>
                                    </div>
                                )}
                                eventClick={(info: any) => handleTitleEvent(info.event.id, info.event.title)}
                                dateClick={(info: any) => handleDateEvent('Fecha: ' + info.dateStr)}
                                stickyHeaderDates={false}
                                locale={TPGlobal.language}
                                locales={allLocales}
                            />
                        </CalendarHeaderStyle>
                    </ContainerCalendarStyle>
                }
            </>
        );
    }
);

export default MyRemindersCalendar;
