import TPImagesAdmin from "@/components/TPImagesAdmin/TPImagesAdmin";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { FC, useState } from "react";

export type TPModalImageSelectorState = {
  isShown: boolean;
  callBackData: any;
};

type TPModalImageSelectorProps = {
  title: string;
  acceptLabel: string;
  cancelLabel: string;
  isShown: boolean;
  callBackData: any;
  callBackAnswer: any;
  topic: string;
  selectedFileDescriptionLabel: string;
};
type selectedFileType = {
  fileName: string;
  fileGuid: string;
  hierarchyId: string;
};

const TPModalImageSelector: FC<TPModalImageSelectorProps> = ({
  isShown,
  title,
  acceptLabel,
  cancelLabel,
  callBackData,
  callBackAnswer,
  topic,
  selectedFileDescriptionLabel,
}) => {
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const initialStateSelectedFile: selectedFileType = {
    fileName: "",
    fileGuid: "",
    hierarchyId: "",
  };
  const [selectedFile, setSelectedFile] = useState<selectedFileType>(
    initialStateSelectedFile
  );

  let classModal: string;
  let styleModal: any = {};
  let backdropClass: string;

  if (isShown) {
    classModal = "modal show";
    styleModal["display"] = "block";
    backdropClass = "modal-backdrop show";
  } else {
    classModal = "modal";
    styleModal["display"] = "none";
    backdropClass = "";
  }

  // const handleCallBackResponse = (data: HierarchyFoldersViewModel) => {
  //   setSelectedFile(data);
  // };

  const handleCallBackResponse = (data: selectedFileType) => {
    setSelectedFile(data);
  };

  const handleAcceptOnClick = () => {
    callBackData.selectedFile = selectedFile;
    callBackAnswer(true, callBackData);
  };

  return (
    <>
      <div
        className={classModal}
        tabIndex={-1}
        data-bs-backdrop="static"
        style={styleModal}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header justify-content-between">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => callBackAnswer(false, callBackData)}
              ></button>
            </div>
            <div className="modal-body" style={{ height: "500px" }}>
              <TPLoadingOverlay active={isLoadingScreen} top={"200px"} isModal>
                <TPImagesAdmin
                  onSelect={handleCallBackResponse}
                  topic={topic}
                />
              </TPLoadingOverlay>
            </div>
            <div className="modal-footer">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {selectedFile.fileName !== "" && (
                    <h6 style={{ color: "#5C636A" }}>
                      <span style={{ fontWeight: "600" }}>
                        {selectedFileDescriptionLabel}
                      </span>
                      &nbsp;&nbsp;
                      {selectedFile.fileName.length > 20
                        ? selectedFile.fileName.substring(0, 20) + "..."
                        : selectedFile.fileName}
                      &nbsp;&nbsp;(
                      {selectedFile.hierarchyId})
                    </h6>
                  )}
                  <TPButton
                    type={TPButtonTypes.primary}
                    onClick={() => handleAcceptOnClick()}
                  >
                    {acceptLabel}
                  </TPButton>

                  <TPButton
                    type={TPButtonTypes.link}
                    onClick={() => callBackAnswer(false, callBackData)}
                  >
                    {cancelLabel}
                  </TPButton>
                </div>
              </div>
              {/* <TPButton
                type={TPButtonTypes.primary}
                onClick={() => handleAcceptOnClick()}
              >
                {acceptLabel}
              </TPButton>
              <TPButton
                type={TPButtonTypes.link}
                onClick={() => callBackAnswer(false, callBackData)}
              >
                {cancelLabel}
              </TPButton> */}
            </div>
          </div>
        </div>
      </div>
      <div className={backdropClass} />
    </>
  );
};

export default TPModalImageSelector;

//TODO:
//resaltar archivo seleccionado solo si es archivo, no para carpetas
//botones de ok, cancel, botón de ok disponible unicamente si hay un archiovo seleccionado
