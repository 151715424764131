import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { DataTableContainer, IsActiveIcon, TableContainer, tableStyles } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPFilterAndSearch, TPPageActions, TPPageFilterContainer, TPPageFirstRow, TPPageSearchContainer, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import { CustomerTypeViewModel } from "@/models/CustomerType/CustomerTypeModels";
import { TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from "react";
import DataTable from "react-data-table-component";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { TPKeyValue } from "@/helpers/TPKeyValue";

import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import { FormDeignerModel } from "@/models/FormDesigner/FormDesigner";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { useDispatch } from "react-redux";
import { VerticalTabsAdminContainerSlice } from "@/layouts/VerticalTabs/VerticalTabsAdminContainer/_redux/VerticalTabsAdminContainerSlice";
import { useSelector } from "react-redux";
import { StoreModel, TypeOf } from "@/redux/store";
import { useAppDistributionListAdmin } from "./ContextDistributionListAdmin";
import { ChildContainerStyle, ContainerCheck, ContainerInputSelectStyle, RightRowContainerStyle, RowConfigStyle, SelectStyle } from "../FormDesigner/StyleFromDesigner";
import { LocationLanguages, LocationNameGlobal, LocationNameInsertUpdate, LocationNameTable } from "./DistributionListAdminLocalization";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { DistributionListAdminService } from "@/services/DistributionListAdminService";
import { DistributionModel, TableDistributionModel } from "@/models/DistributionListAdmin/DistributionListAdmin";

interface InputModel {
  callBackCommands: Function;
}

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<TableDistributionModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

//State grid and current filter
const initialStateBLL: AdminStateType = {
  filterIsLoaded: false,
  columnsAreLoaded: false,
  selectedFilter: TPActiveOptions.ALL.toString(),
  gridColumns: [],
  gridData: [],
  searchPattern: "",
};

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

//State modal
let modalQuestionInitialState: TPModalQuestionState = {
  isShown: false,
  callBackData: {},
};

//State filter dropdown
let initialStateFilter: Array<TPKeyValue> = [];

const DistributionListAdminTable = forwardRef(
  ({ callBackCommands }: InputModel, ref) => {
    const dispatch = useDispatch();

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
    const [nameDelete, setNameDelete] = useState("");
    const [filterSttus, setFilterSttus] = useState(TPActiveOptions.ALL.toString());


    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState
    );


    //Screen resources
    const [location, setLocation] = useState(new LocationNameTable);
    const [locationGlobal, setLocationGlobal] = useState(new LocationNameGlobal);

    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);


    const loadResourcesAndCustomerTypeFilter = async () => {


      let temp = new LocationLanguages();
      await temp.locationDistributionTable(resourceSet);
      setLocation(temp.getLocationNameTable);


      await temp.locationGlobal(TPGlobal.globalResourceSet);
      setLocationGlobal(temp.getLocationNameGlobal);


      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    }

    const setupGridColumns = (prevState: AdminStateType) => {

      let list = new Array<string>();
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];

        newColumns.push({
          name: "",
          active: true,
          width: "80px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => {
            if (!row["isSystemRecord"]) {
              return (
                <div className="dropdown">
                  <TPButton
                    id="IdButton"
                    dataBsToggle={true}
                    type={TPButtonTypes.empty}
                    onClick={() => {
                      TPGlobal.foo();
                    }}
                    className={"menu-button"}
                  >
                    <TPIcon iconType={TPIconTypes.moreVert} />
                  </TPButton>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        onClick={(id: any) => {
                          setNameDelete(row["description"]);
                          handleDeleteClick(row["id"]);
                        }}
                        className="dropdown-item"
                        href="#"
                      >
                        {locationGlobal.deleteLabel}
                      </a>
                    </li>

                    <li>
                      <a
                        onClick={(id: any) => handleUpdateClick(row["id"])}
                        className="dropdown-item"
                        href="#"
                      >
                        {locationGlobal.updateLabel}
                      </a>
                    </li>
                  </ul>
                </div>
              );
            } else {
              return null;
            }
          },
        });


        newColumns.push({
          name: location.nameIdColumn,
          width: "150px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            return (
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={(id: string) => handleUpdateClick(row["id"])}
                className="update-button"
              >
                <TPIcon iconType={TPIconTypes.chevronRight} />
              </TPButton>
            );
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //id

        newColumns.push({
          width: "auto",
          center: true,
          name: location.nameColumn,
          cell: (row: { [x: string]: any }) => {
            return row["description"];
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });


        newColumns.push({
          name: location.nameIsActiveColumn,
          selector: (row: { [x: string]: any }) => row["isActive"],
          width: "100px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isActive"];
            return (
              <TPCheckBox
                id="isActive"
                disabled={true}
                labelText={""}
                checked={currentValue}
                onChange={(e: any) => () => { }}
              ></TPCheckBox>
            );
          },
        });



        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${resourceSet} setupGridColumns ex`,
          TPLogType.ERROR,
          error
        );
        console.error(`Error ${resourceSet} setupGridColumns ex`);
        return prevState;
      }
    };

    //Funtion  Table
    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }



    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    const onClicExportHandler = (element: any) => {
      dispatch(VerticalTabsAdminContainerSlice.actions.export(element));
    };

    //Modal Question to delete CustomerType
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete function after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new DistributionListAdminService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        setLoading(true);
        try {
          // setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteDistributionListAdminById(
            callBackData.recordId,
            true,
            true,
            expectedCodes
          );
          //  setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            loadData();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${resourceSet} handleCallBackModal ex`,
            TPLogType.ERROR,
            error
          );
          console.error(`Error ${resourceSet} handleCallBackModal ex`);
          // setIsLoadingScreen(false);
        }
      }
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {        

      let searcheableColumns: Array<string> = [
        "id",
        "description",
        "name",
        "isActive",
      ];

      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      if (Array.isArray(adminState.gridData)) {
        return adminState.gridData.filter(function (item) {
          if (search == "" || search.length <= 2) {
            return item;
          }
          for (i = 0; i <= searcheableColumns.length - 1; i++) {
            let itemany: any;
            itemany = item;
            if (
              itemany[searcheableColumns[i]] &&
              itemany[searcheableColumns[i]]
                .toString()
                .toLowerCase()
                .includes(search.toLowerCase())
            ) {
              return item;
            }
          }
        });
      }

      return Array<TableDistributionModel>();
    };

    const callBackVerticalTabTemp = () => {
      let command: any = { command: "new", recordId: 0 };
      callBackCommands(command);
    }

    //Command
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };


    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);

    };

    const exportToCSV = (apiData = filteredData(), fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8") => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    const handleLoad = () => {
      let command1: commandType = {
        type: commandsEnum.setup_grid_columns,
        payload: null,
      };
      dispatchCommand(command1);
    }

    //Mail account called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
      },
    }));

    function loadData(status: string = "2") {
      setLoading(true);
      const { getByFiltersIsActive } = new DistributionListAdminService();
      let expectedCodes: Array<number> = [200,404];
        getByFiltersIsActive(status, false, true, expectedCodes)
        .then((data: any) => {
          dispatchCommand({
            type: commandsEnum.reload_grid,
            payload: data,
          } as commandType);
          setLoading(false);
        })
    }


    //Refresh
    const handleRefreshClick = () => {
      loadData();
    };

    const value = useAppDistributionListAdmin();
    const {
      resourceSet,
      setLoading,
      changed
    } = value;

    useEffect(() => {
      loadResourcesAndCustomerTypeFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`,
            TPLogType.ERROR,
            error
          );
          console.error(
            `Error ${resourceSet} loadResourcesAndCustomerTypeFilter ex`
          );
        });

      loadData()

    }, []);

    useEffect(() => {
      if (adminState.filterIsLoaded) {
        handleLoad();
      }
    }, [adminState.filterIsLoaded]);

    useEffect(() => {
      loadData();

    }, [changed]);


    return (
      <>

            <div>
                <div className="row"   >
                    <div className="col-6" style={{ width: "98%" }}>
                        <TPPageTitle>{location.title}</TPPageTitle>
                        <hr />
                    </div>
                </div>
          <TPModalQuestion
            id="IdModalQuestion"
            title={location.titleModalDelete}
            yesLabel={locationGlobal.okAlertButton}
            noLabel={locationGlobal.cancelAlertButton}
            question={locationGlobal.deleteLabelQuestion + " " + nameDelete}
            callBackData={modalQuestionState.callBackData}
            isShown={modalQuestionState.isShown}
            callBackAnswer={handleCallBackModal}
          ></TPModalQuestion>

          <TPPageFirstRow>
            <RowConfigStyle>
              <TPPageFilterContainer>
                <TPButton
                  id="IdButtonRefresh"
                  style={{ marginRight: "10px" }}
                  type={TPButtonTypes.icon}
                  onClick={() => handleRefreshClick()}
                  text={locationGlobal.refreshLabel}
                  icon={TPIconTypes.refresh}
                />
                <TPButton
                  iconStyle={{}}
                  id="IdButtonNew"
                  type={TPButtonTypes.icon}
                  onClick={() => handleNewClick()}
                  text={location.newDistributionListLabel}
                  icon={TPIconTypes.newEntity}
                />
              </TPPageFilterContainer>
              <RightRowContainerStyle style={{ gap: "10px" }} >
                <TPPageFilterContainer>
                  <TPSelect
                    id="IdSelect"
                    onChange={(e: any) => {
                      let temp = { ...adminState };
                      temp.selectedFilter = e.target.value;
                      setFilterSttus(e.target.value);
                      loadData(e.target.value)
                    }}
                    dataSource={filterKeyValue}
                    value={filterSttus}
                    labelText={location.filterIsActiveLabel}
                    isHorizontal={true}
                  ></TPSelect>
                </TPPageFilterContainer>

                <TPTextBox
                  id="IdTextBox"
                  icon={TPIconTypes.search}
                  withIcon={true}
                                value={adminState.searchPattern}
                                placeholder={locationGlobal.search}
                  onClick={() => { }}
                  onChange={(e: any) =>
                    handleSearchPatternChange(e.target.value)
                  }
                  isHorizontal={true}
                />

              </RightRowContainerStyle>
            </RowConfigStyle>
          </TPPageFirstRow>


          <div className="row">
            <div className="col">
              <TableContainer>
                <DataTableContainer>
                  <DataTable
                    persistTableHead={true}
                    fixedHeader={true}
                    fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                    onChangeRowsPerPage={handleRowsPerPageChanged}
                    responsive={true}
                    striped={true}
                    highlightOnHover={true}
                    pagination
                    paginationPerPage={10}
                    paginationComponentOptions={
                      TPGlobal.paginationComponentOptions
                    }
                    columns={adminState.gridColumns}
                    data={filteredData()}
                    noDataComponent={location.thereAreNoRecordsToShow}
                    sortFunction={TPGlobal.datatableCustomSort}
                    customStyles={tableStyles}
                  />
                </DataTableContainer>
              </TableContainer>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default DistributionListAdminTable;
