import { TPI18N } from "@/services/I18nService";
import { FC, useEffect, useReducer, useState } from "react";
import StructureAdminUpdateTab from "./StructureAdminUpdateTab";

type StructureAdminContainerInsertUpdateProps = {
    mode: string;
    recordId: string;
    recordName: string;
    callBackResult: Function;
    tabId: string;
};

interface asideMenuItemsModel {
    itemId: string;
    itemLabel: string;
    itemType: asideMenuItemTypes;
    isActive: boolean;
    isEnabled: boolean;
    isVisible: boolean;
    onClickHandler: Function;
}

enum asideMenuItemTypes {
    "general" = 0,
    "details" = 1,
    "clone" = 2,
    "previews" = 3,
}

enum commandsEnum {
    "create_general" = 0,
    "create_details_and_change_mode" = 1,
    "set_active_general" = 2,
    "set_active_details" = 3,
    "set_active_clone" = 4
}


type commandType = {
    type: commandsEnum;
    payload: any;
};

type VerticalManagement = {
    general: asideMenuItemsModel;
    details: asideMenuItemsModel;
};

const StructureAdminContainerInsertUpdate: FC<StructureAdminContainerInsertUpdateProps> = ({ mode, recordId, recordName, callBackResult, tabId }) => {

    const componentFileName: string = "StructureAdminContainerInsertUpdate.tsx";
    const resourceSet: string = "StructureAdminContainerInsertUpdate";

    const [detailsLabel, setDetailsLabel] = useState('');
    const [generalLabel, setGeneralLabel] = useState('');

    const [exitLabel, setExitLabel] = useState('');
    const [realRecordId, setRealRecordId] = useState(recordId);
    const [realRecordDescription, setRealRecordDescription] = useState("");

    const [isReady, setIsReady] = useState(false);

    const loadResources = async () => {
        setDetailsLabel(await TPI18N.GetText(resourceSet, "DetailsTitle"));
        setGeneralLabel(await TPI18N.GetText(resourceSet, "GeneralInformationTitle"));
        setExitLabel(await TPI18N.GetText(resourceSet, "ExitLabel"));
        setIsReady(true);
    }

    const handleCallBackCommands = (theCommand: any) => {
        console.log("🚀 ~ handleCallBackCommands ~ theCommand:", theCommand)
        if (theCommand.result === "save_general_insert_and_change_mode") {
            //create holidays content
            let command: commandType = {
                type: commandsEnum.create_details_and_change_mode,
                payload: { recordId: theCommand.recordId },
            };
            dispatchCommand(command);
            callBackResult({
                result: "ChangeToUpdateMode",
                recordId: theCommand.recordId,
                tabId: tabId,
            });
            return;
        }

        if (theCommand.result === "save_general_insert") {
            callBackResult({ result: "OK", recordId: realRecordId });
            return;
        }
        if (theCommand.result === "save_general_update") {
            callBackResult({ result: "OK", recordId: realRecordId });
            return;
        }
        if (theCommand.result === "cancel_general") {
            callBackResult({ result: "cancel_general", recordId: realRecordId });
            return;
        }

        if (theCommand.result === "clone_email_success") {
            callBackResult({ result: "cancel_general", recordId: realRecordId });
            callBackResult({ result: "clone_email_success", recordId: theCommand.recordId });
            return;
        }
    };

    const setActiveContent = (
        prevState: Array<asideMenuItemsModel>,
        itemType: asideMenuItemTypes
    ) => {
        let newStateHome: asideMenuItemsModel[] = [...prevState];

        newStateHome.forEach(item => {
            item.isActive = item.itemType === itemType;
        });

        return newStateHome;
    };

    const onGeneralAsideItemClick = () => {
        let currentActive: asideMenuItemTypes;
        if (asideMenuState.filter((x: asideMenuItemsModel) => x.isActive === true).length >= 1) {
            currentActive = asideMenuState.filter((x: asideMenuItemsModel) => x.isActive === true)[0].itemType;
            if (currentActive === asideMenuItemTypes.general) {
                //already on general
                return;
            }
        }
        let command: commandType = {
            type: commandsEnum.set_active_general,
            payload: null
        };
        dispatchCommand(command);
        return;
    };

    const onDetailsAsideItemClick = () => {
        let command: commandType = {
            type: commandsEnum.set_active_details,
            payload: null
        };
        dispatchCommand(command);
    };

    const getVerticalTabBy = (type: 'details' | 'general', status: boolean): asideMenuItemsModel => {
        let verticals: VerticalManagement = {
            details: {
                itemId: 'details-section',
                itemLabel: detailsLabel,
                itemType: asideMenuItemTypes.details,
                isActive: status,
                isVisible: true,
                isEnabled: true,
                onClickHandler: onDetailsAsideItemClick
            },
            general: {
                itemId: 'general-section',
                itemLabel: generalLabel,
                itemType: mode === 'CloneEmail' ? asideMenuItemTypes.clone : asideMenuItemTypes.general,
                isActive: !status,
                isVisible: true,
                isEnabled: true,
                onClickHandler: onGeneralAsideItemClick
            },
        }

        return verticals[type];
    }

    const realCreateGeneralContentCommand = (prevState: Array<asideMenuItemsModel>, isCloned: boolean = false) => {
        let newStateHome: Array<asideMenuItemsModel> = [...prevState];

        newStateHome.push(getVerticalTabBy('general', isCloned));

        if (mode === 'Update') {
            newStateHome.push(getVerticalTabBy('details', isCloned));
        }

        return newStateHome;
    }

    const realCreateDetailsContentCommand = (prevState: Array<asideMenuItemsModel>) => {
        let newStateHome: Array<asideMenuItemsModel> = [...prevState];

        newStateHome.forEach((item: asideMenuItemsModel) => { item.isActive = false; });

        newStateHome.push(getVerticalTabBy('details', true));

        return newStateHome;
    }

    function doCommand(
        prevState: Array<asideMenuItemsModel>,
        command: commandType
    ) {
        switch (command.type) {
            case commandsEnum.create_general:
                return realCreateGeneralContentCommand(prevState);
            case commandsEnum.create_details_and_change_mode:
                const newRecordId: string = command.payload.recordId;
                const newRecordDescription: string = command.payload.recordDescription;
                setRealRecordId(newRecordId);
                setRealRecordDescription(newRecordDescription);
                return realCreateDetailsContentCommand(prevState);
            case commandsEnum.set_active_general:
                return setActiveContent(prevState, asideMenuItemTypes.general);
            case commandsEnum.set_active_details:
                return setActiveContent(prevState, asideMenuItemTypes.details);
            case commandsEnum.set_active_clone:
                return setActiveContent(prevState, asideMenuItemTypes.clone);
        }
    }

    let initialState: Array<asideMenuItemsModel> = [];
    const [asideMenuState, dispatchCommand] = useReducer(doCommand, initialState);

    const handleOnExitClick = () => {
        callBackResult({ result: 'CANCEL', recordId: realRecordId });
    };

    useEffect(() => {
        //load resources
        if (!isReady) {
            loadResources();
        } else {
            let command: commandType = {
                type: commandsEnum.create_general,
                payload: null,
            };
            dispatchCommand(command);
        }
    }, [isReady]);

    return (
        <>
            <div className="row" style={{ width: "120%" }}>
                <div className="col-10">
                    <ul>
                        {asideMenuState.map(function (item, index) {
                            let jsxFragment: any;
                            let visibilityStyle: any;
                            if (item.isActive) {
                                visibilityStyle = { display: "block" };
                            } else {
                                visibilityStyle = { display: "none" };
                            }
                            switch (item.itemType) {
                                case asideMenuItemTypes.general:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <StructureAdminUpdateTab
                                                    mode={mode}
                                                    recordId={realRecordId}
                                                    description=""
                                                    callBackResult={handleCallBackCommands}
                                                />
                                            </div>
                                        </li>
                                    );
                                    break;
                                case asideMenuItemTypes.details:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <StructureAdminUpdateTab
                                                    mode={mode}
                                                    recordId={recordId}
                                                    description=""
                                                    callBackResult={handleCallBackCommands}
                                                />
                                            </div>
                                        </li>
                                    );
                                    break;
                                case asideMenuItemTypes.clone:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <StructureAdminUpdateTab
                                                    mode={mode}
                                                    recordId={recordId}
                                                    description={recordName}
                                                    callBackResult={handleCallBackCommands}
                                                />
                                            </div>
                                        </li>
                                    );
                                    break;
                                default:
                                    jsxFragment = (
                                        <li key={"asidecontent" + index.toString()}>
                                            <div style={visibilityStyle}>
                                                <label>error this should not be here</label>
                                            </div>
                                        </li>
                                    );
                                    break;
                            }
                            return jsxFragment;
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default StructureAdminContainerInsertUpdate;














