import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { TaskTypeInputDTO } from "@/models/TaskType/TaskTypeInputDTO";
import { TPActiveOptions } from "@/models/Global/TPGlobalEnums";

export class TaskTypeService {
  serviceFileName: string = "TaskTypeService.ts";
  public async getTaskTypeByFilter(
    filterActive: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<TaskTypeViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/tasktype?FilterIsActive=";
    url = url + filterActive;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTaskTypeByFilter ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName}  getTaskTypeByFilter`);
      throw new Error(`Error ${this.serviceFileName}  getTaskTypeByFilter`);
    }
  }

  public async getTaskTypeById(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<TaskTypeViewModel> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/tasktype/";
    url = url + id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTaskTypeById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} getTaskTypeById`);
      throw new Error(`Error ${this.serviceFileName} getTaskTypeById`);
    }
  }

  public async deleteTaskTypeById(
    Id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/tasktype/" + Id;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteTaskTypeById ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} deleteTaskTypeById`);
      throw new Error(`Error ${this.serviceFileName} deleteTaskTypeById`);
    }
  }

  public async insertTaskType(
    inputDTO: TaskTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/tasktype";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    const fixedInputDTO: TaskTypeInputDTO = {
      ...inputDTO,
      jSONParameters: TPGlobal.stringToUTF8(inputDTO.jSONParameters).join(',')
    }

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        fixedInputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertTaskType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName}insertTaskType`);
      throw new Error(`Error ${this.serviceFileName} insertTaskType`);
    }
  }

  public async updateTaskType(
    inputDTO: TaskTypeInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/tasktype";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    const fixedInputDTO: TaskTypeInputDTO = {
      ...inputDTO,
      jSONParameters: TPGlobal.stringToUTF8(inputDTO.jSONParameters).join(',')
    }

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        fixedInputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateTaskType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName} updateTaskType`);
      throw new Error(`Error ${this.serviceFileName} updateTaskType`);
    }
  }
  public async getTaskTypeByWorkflowId(
    workflowId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<TaskTypeViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = `/tasktype/workflowid/${workflowId}`;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getTaskTypeByWorkflowId ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName}  getTaskTypeByWorkflowId`);
      throw new Error(`Error ${this.serviceFileName}  getTaskTypeByWorkflowId`);
    }
  }

  public async getSearchTaskType(
    words: string,
    filterActive: TPActiveOptions,
    filterIsAddable: number | null,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>,
  ): Promise<Array<TaskTypeViewModel>> {
    let httpclient = new TPHTTPService();
    let url: string;
    if (filterIsAddable) {
      url = `/tasktype/get-search?words=${words}&FilterIsActive=${filterActive}&filterIsAddable=${filterIsAddable}`;
    } else {
      url = `/tasktype/get-search?words=${words}&FilterIsActive=${filterActive}`;
    }

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers,
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getSearchTaskType ex`,
        TPLogType.ERROR,
        error,
      );
      console.error(`Error ${this.serviceFileName}  getSearchTaskType`);
      throw new Error(`Error ${this.serviceFileName}  getSearchTaskType`);
    }
  }
}
