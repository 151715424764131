import { Placement } from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { Tooltip, Typography } from "@mui/material";
import React, { FC, ReactElement, useState } from "react";
import { camelCaseToId } from "../../utils/text-regex";
import {
  SizeItem,
  StyledUserProfile,
  UserProfileSize,
} from "../design-system-styles";
import { ProfileThemeColor } from "../design-system.model";

type UserProfileProps = {
  completeName: string;
  imageUrl?: string;
  size?: UserProfileSize;
  customSize?: SizeItem;
  enableTooltip?: boolean;
  tooltipPlacement?: Placement;
  defaultTheme?: ThemeCase;
};

export type ThemeCase = 0 | 1 | 2 | 3 | 4;

const UserProfile: FC<UserProfileProps> = React.memo(
  ({
    completeName,
    imageUrl,
    size = "sm",
    customSize,
    enableTooltip,
    tooltipPlacement,
    defaultTheme,
  }): ReactElement => {
    const [imageWithError, setImageWithError] = useState<boolean>(false);

    const randomCase: ThemeCase = Math.floor(Math.random() * 5) as ThemeCase;
    const profileId: string = `${camelCaseToId(completeName)}-profile`;

    const profileThemes: ProfileThemeColor = {
      0: {
        color: "#E63946",
        variant: "#F8DCDC",
      },
      1: {
        color: "#F77F00",
        variant: "#FFE6CC",
      },
      2: {
        color: "#457B9D",
        variant: "#DCEFFF",
      },
      3: {
        color: "#2A9D8F",
        variant: "#D2F4EC",
      },
      4: {
        color: "#8D0801",
        variant: "#F4CCCC",
      },
    };

    const getInitials = (name: string): string => {
      if (!name) return "";

      const nameParts = name.trim().split(/\s+/);

      if (nameParts.length === 1) {
        return nameParts[0].charAt(0).toUpperCase();
      }

      const firstInitial = nameParts[0].charAt(0).toUpperCase();
      const lastInitial = nameParts[nameParts.length - 1]
        .charAt(0)
        .toUpperCase();

      return `${firstInitial}${lastInitial}`;
    };

    const styledTooltipText = enableTooltip && (
      <Typography sx={{ fontFamily: "Noto Sans", fontSize: "10px" }}>
        {completeName}
      </Typography>
    );

    const handleError = () => {
      setImageWithError(true);
    };

    return (
      <Tooltip title={styledTooltipText} placement={tooltipPlacement}>
        <StyledUserProfile
          id={profileId}
          color={profileThemes[defaultTheme ? defaultTheme : randomCase].color}
          variant={
            profileThemes[defaultTheme ? defaultTheme : randomCase].variant
          }
          size={size}
          customSize={customSize}
        >
          {imageUrl && !imageWithError ? (
            <img src={imageUrl} alt={profileId} onError={handleError} />
          ) : (
            <>{getInitials(completeName)}</>
          )}
        </StyledUserProfile>
      </Tooltip>
    );
  }
);

export default UserProfile;
