import { DataTableContainer, TableContainer, tableStyles } from "@/components/bootstrap/content/tables/tpTableStyles";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPFilterAndSearch, TPPageActions, TPPageFirstRow, TPPageSearchContainer } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, { TPModalQuestionState } from "@/layouts/ModalQuestion/TPModalQuestion";
import { taskStatusEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { forwardRef, useEffect, useImperativeHandle, useReducer, useState } from "react";
import DataTable from "react-data-table-component";

import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { RemindersService } from "@/services/MyRemindersService";
import FileSaver from "file-saver";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import { useAppMyReminders } from "./ContextMyReminders";
import { LocationLanguagesReminders, LocationNameReminders } from "./MyRemindersLocation";

interface InputModel {
    callBackCommands: Function;
    active: boolean;
}

type AdminStateType = {
    selectedFilter: string;
    gridColumns: Array<any>;
    gridData: Array<any>;
    filterIsLoaded: boolean;
    columnsAreLoaded: boolean;
    searchPattern: string;
};
type CalendarEvent = {
    name: string;
    date: string;
    id: string;
    idCase: string;
    description: string;
};

//State grid and current filter
const initialStateBLL: AdminStateType = {
    filterIsLoaded: false,
    columnsAreLoaded: false,
    selectedFilter: TPActiveOptions.ALL.toString(),
    gridColumns: [],
    gridData: [],
    searchPattern: "",
};

enum commandsEnum {
    "set_filterIsLoaded" = 0,
    "setup_grid_columns" = 1,
    "reload_grid" = 2,
    "change_selectedFilter" = 3,
    "change_search_pattern" = 4,
}

type commandType = {
    type: commandsEnum;
    payload: any;
};

//State modal
let modalQuestionInitialState: TPModalQuestionState = {
    isShown: false,
    callBackData: {},
};

//State filter dropdown
let initialStateFilter: Array<TPKeyValue> = [];

const MyRemindersList = forwardRef(
    ({ callBackCommands, active }: InputModel, ref) => {
        const dispatch = useDispatch();

        const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);
        const [nameDelete, setNameDelete] = useState("");



        const [modalQuestionState, setModalQuestionState] = useState(
            modalQuestionInitialState
        );


        //Screen resources
        const [location, setLocation] = useState(new LocationNameReminders);

        const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

        const loadResources = async () => {

            let temp = new LocationLanguagesReminders();
            await temp.handleLocationNameReminders(resourceSet);
            setLocation(temp.getLocationNameReminders);

            //Filter
            let newFilterKeyValue: Array<TPKeyValue> = [];
            newFilterKeyValue.push({
                key: TPActiveOptions.ALL.toString(),
                value: await TPI18N.GetText(resourceSet, "All"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.ACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Active"),
            });
            newFilterKeyValue.push({
                key: TPActiveOptions.INACTIVE.toString(),
                value: await TPI18N.GetText(resourceSet, "Inactive"),
            });
            setFilterKeyValue(newFilterKeyValue);
        }

        const setupGridColumns = (prevState: AdminStateType) => {

            let list = new Array<string>();
            try {
                let newState: AdminStateType;
                newState = { ...prevState };
                let newColumns: Array<any> = [];

                newColumns.push({
                    width: "89px",
                    name: location.stateColumn,
                    cell: (row: { [x: string]: any }) => {
                        switch (row["statusId"]) {
                            case taskStatusEnum.Solved:
                                return (
                                    <TPIcon
                                        iconType={TPIconTypes.checkCircle}
                                        style={{ color: "#69c169", fontSize: "20px" }}
                                        inline={true}
                                    />
                                );
                                break;
                            case taskStatusEnum.Pending:
                                return (
                                    <TPIcon
                                        iconType={TPIconTypes.watchLater}
                                        style={{ color: "rgb(11 122 187)", fontSize: "20px" }}
                                        inline={true}
                                    />
                                );
                                break;
                            case taskStatusEnum.Expired:
                                return (
                                    <TPIcon
                                        iconType={TPIconTypes.warning}
                                        style={{ color: "red", fontSize: "20px" }}
                                        inline={true}
                                    />
                                );
                                break;
                            case taskStatusEnum.Lag:
                                return (
                                    <TPIcon
                                        iconType={TPIconTypes.hourglassTop}
                                        style={{ color: "#e57348", fontSize: "20px" }}
                                        inline={true}
                                    />
                                );
                                break;
                        }
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });


                newColumns.push({
                    width: "140px",
                    name: location.customerStateColumn,
                    cell: (row: { [x: string]: any }) => {
                        return row["customStateId"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "140px",
                    name: location.caseNumberColumn,
                    cell: (row: { [x: string]: any }) => {
                        return <a
                            onClick={(id: any) => callBackCommands(row["caseId"])}
                            className="dropdown-item"
                            href="#"
                            style={{ textDecoration: "underline" }}
                        >
                            {row["caseId"]}
                        </a>
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "160px",
                    name: location.customerTypeColumn,
                    cell: (row: { [x: string]: any }) => {
                        return row["customerTypeLocalizedDescription"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "auto",
                    name: location.customerColumn,
                    cell: (row: { [x: string]: any }) => {
                        return row["clientName"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "200px",
                    name: location.commentsColumn,
                    cell: (row: { [x: string]: any }) => {
                        return row["comments"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "auto",
                    name: location.reminderDateColumn,
                    cell: (row: { [x: string]: any }) => {
                        return row["localDate"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });

                newColumns.push({
                    width: "96px",
                    name: location.reminderColumn,
                    cell: (row: { [x: string]: any }) => {
                        return row["description"];
                    },
                    selector: (row: { [x: string]: any }) => row["id"],
                    sortable: true,
                });


                newState.gridColumns = [...newColumns];
                return newState;
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} setupGridColumns ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} setupGridColumns ex`);
                return prevState;
            }
        };

        //Funtion  Table
        const handleRowsPerPageChanged = (e: any) => {
            const recordSize = fixedHeaderScrollHeight / 10;
            const newRecordSize = recordSize * e;
            setFixedHeaderScrollHeight(newRecordSize);
        };

        const [adminState, dispatchCommand] = useReducer(
            doCommand,
            initialStateBLL
        );

        function doCommand(prevState: AdminStateType, command: commandType) {
            switch (command.type) {
                case commandsEnum.set_filterIsLoaded:
                    let newStateFilter: AdminStateType;
                    newStateFilter = { ...prevState };
                    newStateFilter.filterIsLoaded = true;
                    return newStateFilter;
                case commandsEnum.setup_grid_columns:
                    let newStateColumns: AdminStateType = setupGridColumns(prevState);
                    newStateColumns.columnsAreLoaded = true;
                    return newStateColumns;
                case commandsEnum.reload_grid:
                    let newStateGrid: AdminStateType;
                    newStateGrid = { ...prevState };
                    newStateGrid.gridData = command.payload;
                    return newStateGrid;
                case commandsEnum.change_selectedFilter:
                    let newStateChangeFilter: AdminStateType;
                    newStateChangeFilter = { ...prevState };
                    newStateChangeFilter.selectedFilter = command.payload;
                    return newStateChangeFilter;
                case commandsEnum.change_search_pattern:
                    let newStatePattern: AdminStateType;
                    newStatePattern = { ...prevState };
                    newStatePattern.searchPattern = command.payload;
                    return newStatePattern;
                default:
                    return prevState;
            }
        }

        const handleUpdateClick = (id: string) => {
            let command: any = { command: "update", recordId: id };
            callBackCommands(command);
        };


        //Modal Question to delete CustomerType
        const handleDeleteClick = (id: string) => {
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = true;
            newModalQuestionState.callBackData = { recordId: id };
            setModalQuestionState(newModalQuestionState);
        };

        //Delete function after question confirmation
        const handleCallBackModal = async (
            confirmDelete: boolean,
            callBackData: any
        ) => {
            let expectedCodes: Array<number> = [200];
            let serviceClient = new RemindersService();
            let newModalQuestionState: TPModalQuestionState;
            newModalQuestionState = { ...modalQuestionState };
            newModalQuestionState.isShown = false;
            newModalQuestionState.callBackData = {};
            setModalQuestionState(newModalQuestionState);
            if (confirmDelete) {
                try {
                    // setIsLoadingScreen(true);
                    let responseRequest = await serviceClient.deleteRemindersById(
                        callBackData.recordId,
                        true,
                        true,
                        expectedCodes
                    );
                    //  setIsLoadingScreen(false);
                    if (responseRequest.responseData.responseCode !== 500) {
                        loadData();
                        callBackCommands({
                            command: "delete",
                            recordId: callBackData.recordId,
                        });
                    }
                } catch (error) {
                    TPLog.Log(
                        `Error ${resourceSet} handleCallBackModal ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(`Error ${resourceSet} handleCallBackModal ex`);
                    // setIsLoadingScreen(false);
                }
            }
        };

        //Filtered data based on selected pattern on search box
        const filteredData = () => {

            let searcheableColumns: Array<string> = [
                "id",
                "description",
                "name",
                "isActive",
            ];
            let i: number;
            let search: string;
            search = adminState.searchPattern.trim();
            if (Array.isArray(adminState.gridData)) {

                return adminState.gridData.filter(function (item) {
                    if (search == "" || search.length <= 2) {
                        return item;
                    }
                    for (i = 0; i <= searcheableColumns.length - 1; i++) {
                        let itemany: any;
                        itemany = item;
                        if (
                            itemany[searcheableColumns[i]] &&
                            itemany[searcheableColumns[i]]
                                .toString()
                                .toLowerCase()
                                .includes(search.toLowerCase())
                        ) {
                            return item;
                        }
                    }
                });
            }

            return new Array<any>;
        };

        //Command
        const handleNewClick = () => {
            let command: any = { command: "new" };
            callBackCommands(command);
        };

        //Filter Active Change
        const handleFilterChange = (e: any) => {
            let command1: commandType = {
                type: commandsEnum.change_selectedFilter,
                payload: e.target.value,
            };
            dispatchCommand(command1);
        };


        //Handler to filter data inside data grid
        const handleSearchPatternChange = (newValue: string) => {
            let command1: commandType = {
                type: commandsEnum.change_search_pattern,
                payload: newValue,
            };
            dispatchCommand(command1);

        };

        const exportToCSV = (apiData = filteredData(), fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8") => {
            const ws = XLSX.utils.json_to_sheet(apiData);
            /* custom headers */
            XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
            FileSaver.saveAs(data, fileName + ".xlsx");
        };


        const handleLoad = (payload?: any) => {
            let command1: commandType = {
                type: commandsEnum.setup_grid_columns,
                payload: payload,
            };
            dispatchCommand(command1);
        }

        useImperativeHandle(ref, () => ({
            refreshGridFromParent() {
            },
        }));



        const formatFecha = (date: string, dateTimeLocal: string): string => {

            const dateTime = new Date(date);
            //const dateLocal = new Date(dateTimeLocal);
            const [datePart, timePart] = dateTimeLocal.split(' ');
            const [hour, minutes, seconds] = timePart.split(':');         
            const año = dateTime.getFullYear();
            const mes = String(dateTime.getMonth() + 1).padStart(2, '0');
            const dia = String(dateTime.getDate()).padStart(2, '0');
           
            console.log(`${año}-${mes}-${dia}, ${hour}:${minutes}`);
            return `${año}-${mes}-${dia}, ${hour}:${minutes}`;
        };

        let count = 0;
        function loadData() {

            let listTemp = { ...statusModel };

            if (Array.isArray(listTemp) && listTemp.length > 0) {
                setLoading(true);
                let temp = Array();
                listTemp.forEach((e: any) => {
                    if (Array.isArray(temp)) {

                        temp.push({
                            name: e.comments,
                            date: formatFecha(e.date, e.localDate),
                            id: e.caseId + "," + count,
                            idCase: e.caseId + "" + formatFecha,
                            description: e.comments
                        });
                    } else {
                        temp = [{
                            name: e.comments,
                            date: formatFecha(e.date, e.localDate),
                            id: e.caseId + "," + count,
                            idCase: e.caseId + "",
                            description: e.comments
                        }];

                    }
                    count++;
                });

                setCalendarReminders(temp);

                dispatchCommand({
                    type: commandsEnum.reload_grid,
                    payload: { ...statusModel },
                } as commandType);

                setLoading(false);

            }
            else {


                setLoading(true);
                const { getByFilter: getFunctionsByFilter } = new RemindersService();
                let expectedCodes: Array<number> = [200];
                getFunctionsByFilter(TPGlobal.currentUserGuid, false, false, expectedCodes)
                    .then((data: Array<any>) => {
                        let dataList = { ...statusModel };
                        dataList = data;
                        setStatusModel(dataList);
                        let temp = { ...calendarReminders };
                        let count = 0;
                        data.forEach((e: any) => {
                            if (Array.isArray(temp)) {
                                temp.push({
                                    name: e.comments,
                                    date: formatFecha(e.date, e.localDate),
                                    id: e.caseId + "," + count,
                                    idCase: e.caseId + "",
                                    description: e.comments

                                });
                            } else {
                                temp = [{
                                    name: e.comments,
                                    date: formatFecha(e.date, e.localDate),
                                    id: e.caseId + "",
                                    idCase: e.caseId + "",
                                    description: e.comments

                                }];

                            }
                            count++;
                        });
                        callBackCommands(temp.length);
                        setCalendarReminders(temp);

                        dispatchCommand({
                            type: commandsEnum.reload_grid,
                            payload: data,
                        } as commandType);
                        setLoading(false);
                    })

            }
        }


        const reloadGridCommand = () => {
            reloadDataGrid(adminState.selectedFilter)
                .then(function (result) {
                    let command1: commandType = {
                        type: commandsEnum.reload_grid,
                        payload: result,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${resourceSet} reloadGridCommand ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(`Error ${resourceSet} reloadGridCommand ex`);
                });
        };

        const reloadDataGrid = async (selectedFilter: string) => {
            let serviceClient = new RemindersService();
            let expectedCodes: Array<number> = [200, 404];
            setLoading(true);
            try {
                let responseRequest = await serviceClient.getByFilter(
                    selectedFilter,
                    false, //show positive message
                    true, //show negative message
                    expectedCodes
                );

                setLoading(false);

                return [...responseRequest];
            } catch (error) {
                TPLog.Log(
                    `Error ${resourceSet} reloadDataGrid ex`,
                    TPLogType.ERROR,
                    error
                );
                console.error(`Error ${resourceSet} reloadDataGrid ex`);

                return [];
            }
        };


        //Refresh
        const handleRefreshClick = () => {
            reloadGridCommand();
        };

        const value = useAppMyReminders();
        const {
            resourceSet,
            calendarReminders,
            setCalendarReminders,
            setLoading,
            statusModel,
            setStatusModel,

        } = value;

        useEffect(() => {
            loadResources()
                .then(function () {
                    let command1: commandType = {
                        type: commandsEnum.set_filterIsLoaded,
                        payload: null,
                    };
                    dispatchCommand(command1);
                })
                .catch(function (error) {
                    TPLog.Log(
                        `Error ${resourceSet} loadResources ex`,
                        TPLogType.ERROR,
                        error
                    );
                    console.error(
                        `Error ${resourceSet} loadResources ex`
                    );
                });

            loadData()

        }, []);

        useEffect(() => {
            if (adminState.filterIsLoaded) {
                handleLoad();
            }
        }, [adminState.filterIsLoaded]);


        return (

            <>
                {active ?
                    <>
                        <TPModalQuestion
                            id="IdModalQuestion"
                            title={location.titleModalDelete}
                            yesLabel={location.okAlertButton}
                            noLabel={location.cancelAlertButton}
                            question={location.deleteLabel + " " + nameDelete}
                            callBackData={modalQuestionState.callBackData}
                            isShown={modalQuestionState.isShown}
                            callBackAnswer={handleCallBackModal}
                        ></TPModalQuestion>

                        <TPPageFirstRow>
                            <TPPageActions>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.icon}
                                    onClick={() => handleRefreshClick()}
                                    text={location.refreshLabel}
                                    icon={TPIconTypes.refresh}
                                />

                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.icon}
                                    onClick={() => exportToCSV(filteredData(), "my-reminders-admin-data")}
                                    text={location.exportLabel}
                                    icon={TPIconTypes.fileDownload}
                                />

                            </TPPageActions>
                            <TPFilterAndSearch>
                                <TPPageSearchContainer>
                                    <TPTextBox
                                        id="IdTextBox"
                                        icon={TPIconTypes.search}
                                        withIcon={true}
                                        value={adminState.searchPattern}
                                        placeholder={location.search}
                                        onChange={(e: any) =>
                                            handleSearchPatternChange(e.target.value)
                                        }
                                        isHorizontal={true}
                                    />
                                </TPPageSearchContainer>
                            </TPFilterAndSearch>
                        </TPPageFirstRow>


                        <div >
                            <div >
                                <TableContainer  >
                                    <DataTableContainer style={{ minWidth: "80vw" }}>
                                        <DataTable                                           
                                            persistTableHead={true}
                                            fixedHeader={true}
                                            fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                                            onChangeRowsPerPage={handleRowsPerPageChanged}
                                            responsive={true}
                                            striped={true}
                                            highlightOnHover={true}
                                            pagination
                                            paginationPerPage={10}
                                            paginationComponentOptions={
                                                TPGlobal.paginationComponentOptions
                                            }
                                            columns={adminState.gridColumns}
                                            data={filteredData()}
                                            noDataComponent={location.thereAreNoRecordsToShow}
                                            sortFunction={TPGlobal.datatableCustomSort}
                                            customStyles={tableStyles}
                                        />
                                    </DataTableContainer>
                                </TableContainer>
                            </div>
                        </div>
                    </>

                    : null}

            </>
        );
    }
);

export default MyRemindersList;
