import TPRequired from "@/components/bootstrap/forms/TPRequired/TPRequired";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { ReactElement } from "react";
import Select, { MultiValue } from "react-select";
import {
  getCustomSelectStyles,
  OptionType,
} from "../../utils/search-select-styles";
import {
  StyledMultiItem,
  StyledMultiItemsContainer,
  StyledSearchSelect,
} from "../design-system-styles";
type SearchSelectProps = {
  options: TPKeyValue[];
  optionSelected?: TPKeyValue | TPKeyValue[] | null;
  label?: string;
  orientation?: "vertical" | "horizontal";
  id?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isMandatory?: boolean;
  placeholder?: string;
  noDataMessage?: string;
  handleChange?: (item: TPKeyValue) => void;
  handleMultiChange?: (items: TPKeyValue[]) => void;
  width?: string;
  removeSelectedOptionRef?: React.MutableRefObject<
    ((valueToRemove: string) => void) | null
  >;
};

const MultiValueContainer = (props: any) => {
  const { selectProps, data } = props;
  const values = selectProps.value || [];

  if (values.length > 1 && data === values[0]) {
    return (
      <StyledMultiItemsContainer>
        <StyledMultiItem>{values[0].label}</StyledMultiItem>
        <p className="extra-options"> (+{values.length - 1} others)</p>
      </StyledMultiItemsContainer>
    );
  }

  if (values.length === 1 || data === values[0]) {
    return <StyledMultiItem>{values[0].label}</StyledMultiItem>;
  }

  return null;
};

function SearchSelect({
  options,
  optionSelected,
  label,
  orientation = "horizontal",
  id,
  isClearable,
  isSearchable,
  isDisabled,
  isLoading,
  isMulti,
  isMandatory,
  placeholder,
  noDataMessage,
  handleChange,
  handleMultiChange,
  width = "inherit",
  removeSelectedOptionRef,
}: SearchSelectProps): ReactElement {
  const elementId: string = `${id && `${id}-${isMulti && "multi-"}`}tp-select`;

  const onSelectChange = (event: MultiValue<OptionType> | OptionType) => {
    if (!event) {
      handleChange?.({ key: "", value: "" });
      return;
    }

    if (isMulti) {
      onMultiChange(event as MultiValue<OptionType>);
      return;
    }

    onChange(event as OptionType);
  };

  const onMultiChange = (event: MultiValue<OptionType>) => {
    const values: TPKeyValue[] = event.map((item) => {
      return { key: item.value, value: item.label };
    });
    handleMultiChange?.(values);
  };

  const onChange = ({ value, label }: OptionType) => {
    handleChange?.({
      key: value,
      value: label,
    });
  };

  const getDefaultOption = () => {
    if (!optionSelected) return null;

    if (isMulti && Array.isArray(optionSelected)) {
      return optionSelected.map((option) => ({
        value: option.key,
        label: option.value,
      }));
    }

    return {
      value: (optionSelected as TPKeyValue).key,
      label: (optionSelected as TPKeyValue).value,
    };
  };

  const removeSelectedOption = (valueToRemove: string) => {
    if (isMulti && Array.isArray(optionSelected)) {
      const updatedOptions = optionSelected.filter(
        (option) => option.key !== valueToRemove
      );
      handleMultiChange?.(updatedOptions);
    }
  };

  const getOptions = (): OptionType[] => {
    return options.map((option) => ({
      value: option.key,
      label: option.value,
    }));
  };

  if (removeSelectedOptionRef) {
    removeSelectedOptionRef.current = removeSelectedOption;
  }

  return (
    <StyledSearchSelect orientation={orientation}>
      {label && (
        <label htmlFor={elementId}>
          {label}
          {isMandatory && <TPRequired />}
          {orientation === "horizontal" && ":"}
        </label>
      )}
      <Select
        id={elementId}
        styles={getCustomSelectStyles(width)}
        options={getOptions()}
        value={getDefaultOption()}
        onChange={onSelectChange}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        placeholder={placeholder}
        noOptionsMessage={() => noDataMessage}
        components={{ MultiValueContainer }}
        {...(isMulti && { closeMenuOnSelect: false, isMulti })}
      />
    </StyledSearchSelect>
  );
}

export default SearchSelect;
