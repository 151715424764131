import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPGlobal from "@/helpers/TPGlobal";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import { Table } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { ChangeEvent, useEffect, useState } from "react";
import SearchInput from "../../design-system/inputs/SearchInput";
import { camelCaseToPhrase } from "../../utils/text-regex";
import {
  StyledDisabledCheck,
  StyledDynamicCards,
  StyledDynamicTable,
  StyledHeaderOptions,
  StyledTable,
  StyledTableCell,
  StyledTableChildren,
  StyledTableHeader,
  StyledTableRow,
} from "./dynamic-table-styles";
import { TableProps } from "./dynamic-table.model";
import SortTableItem from "./SortTableItem";
import SwitchTableView, { TableViews } from "./SwitchTableView";
import TableActionItem from "./TableActionItem";
import TableIcons from "./TableIcons";
import TableNotFound from "./TableNotFound";
import TablePager from "./TablePager";
import TablePreferences from "./TablePreferences";

type ColumnComponentProps<T> = {
  value: any;
  item: T;
};

type AlignmentPosition = "left" | "center" | "right" | "justify" | "inherit";

export type ChildPosition = "right" | "left" | "bottom";

export type AnyObject = { [key: string]: any };

export type CustomColumnNames<T> = {
  [K in keyof T]?: string;
};

export type HeaderAlignment<T> = {
  [K in keyof T]?: AlignmentPosition;
};

export type CardComponentProps<T> = {
  item: T;
};

export type CustomActionProps<T> = {
  item: T;
};

export type ColumnStyles<T> = Partial<
  Record<keyof T, React.ComponentType<ColumnComponentProps<T>>>
>;

export type ExportCustomValues<T> = Partial<
  Record<keyof T, (props: ColumnComponentProps<T>) => string>
>;

export type Short = "ascending" | "descending";

function DynamicTable<T extends object>({
  data,
  id,
  style,
  columnNames,
  columnStyles,
  exportCustomValues,
  minorOptions,
  headerAlignment,
  selectedValues,
  CustomCard,
  CustomAction,
  hiddenColumns,
  icons,
  additionalFilters,
  additionalCheckboxes,
  children,
  selectable,
  switchable,
  withPreferences,
  uppercaseActions,
  hideControls,
  hidePager,
  hideExport,
  hideAllTableOnNoData,
  persistSelectionAfterFilterChange,
  isHeaderCheckboxDisabled,
  noDataMessage,
  pagerStyle,
  childrenPosition = "bottom",
  searchPosition = "left",
  maxWithTableContainer,
  customHeight,
  disableMinorOption = () => false,
  disableSelectionCheckbox = () => false,
  onIconClicked,
  onSelectionChange,
  onSwitchChange,
}: TableProps<T>) {
  const [sortConfig, setSortConfig] = useState<{
    key: keyof T;
    direction: Short;
  } | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(!hidePager ? 10 : -1);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<(keyof T)[]>(
    Object.keys(data.length > 0 ? data[0] : []) as (keyof T)[]
  );
  const [lastColumn, setLastColumn] = useState<keyof T | null>(null);
  const [tableView, setTableView] = useState<TableViews>(TableViews.TABLE);

  const [minorOptionsLabel, setMinorOptionsLabel] = useState<string>("");
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>("");
  const [preferencesPlaceholder, setPreferencesPlaceholder] =
    useState<string>("");
  const [preferencesButtonLabel, setPreferencesButtonLabel] =
    useState<string>("");
  const [ofLabel, setOfLabel] = useState<string>("");
  const [allLabel, setAllLabel] = useState<string>("");
  const [pageLabel, setPageLabel] = useState<string>("");
  const [nextPageLabel, setNextPageLabel] = useState<string>("");
  const [exportTableLabel, setExportTableLabel] = useState<string>("");
  const [refreshTableLabel, setRefreshTableLabel] = useState<string>("");
  const [noResultsLabel, setNoResultsLabel] = useState<string>("");
  const [newOneLabel, setNewOneLabel] = useState<string>("");

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setRowsPerPage(parseInt(value));
    setCurrentPage(1);
  };

  const filteredData = React.useMemo(() => {
    if (!searchTerm) return data;

    return data.filter((item) =>
      Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    );
  }, [data, searchTerm]);

  const sortedData = React.useMemo(() => {
    if (sortConfig !== null) {
      return [...filteredData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return filteredData;
  }, [filteredData, sortConfig]);

  const requestSort = (key: keyof T) => {
    let direction: Short = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortBy = (key: string): Short | null => {
    return sortConfig?.key === key ? sortConfig.direction : null;
  };

  const totalPages = Math.ceil(sortedData.length / rowsPerPage);

  const paginatedData = React.useMemo(() => {
    if (rowsPerPage === -1) return sortedData;
    const start = (currentPage - 1) * rowsPerPage;
    return sortedData.slice(start, start + rowsPerPage);
  }, [sortedData, currentPage, rowsPerPage]);

  const handleCheckboxChange = (item: T) => {
    const isSelected = selectedItems.some(
      (selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(item)
    );

    const newSelectedItems = isSelected
      ? selectedItems.filter(
          (selectedItem) =>
            JSON.stringify(selectedItem) !== JSON.stringify(item)
        )
      : [...selectedItems, item];

    setSelectedItems(newSelectedItems);

    if (onSelectionChange) {
      onSelectionChange(newSelectedItems);
    }
  };

  const filteredChecksBy = (items: T[]) => {
    return items.filter((item) => !disableSelectionCheckbox(item));
  };

  const isAllChecksSelected = (): boolean => {
    return (
      filteredChecksBy(selectedItems).length ===
      filteredChecksBy(filteredData).length
    );
  };

  const handleSelectAllChange = () => {
    const items = filteredChecksBy(selectedItems);
    const data = filteredChecksBy(filteredData);

    const allSelected = items.length === data.length;

    const newSelectedItems = allSelected ? [] : data;
    setSelectedItems(newSelectedItems);

    if (onSelectionChange) {
      onSelectionChange(newSelectedItems);
    }
  };

  const handleColumnVisibilityChange = (column: keyof T) => {
    setVisibleColumns((prev) => {
      if (prev.includes(column)) {
        let columnsFiltered = prev.filter((col) => col !== column);
        columnsFiltered.length === 1 && setLastColumn(columnsFiltered[0]);
        return columnsFiltered;
      } else {
        // Insert the column back to its original position
        const allColumns = Object.keys(data[0]) as (keyof T)[];
        const updatedColumns = [...prev];
        const originalIndex = allColumns.indexOf(column);
        updatedColumns.splice(originalIndex, 0, column);
        updatedColumns.length === 1 && setLastColumn(updatedColumns[0]);
        return updatedColumns;
      }
    });
  };

  const resetColumns = () => {
    setVisibleColumns(Object.keys(data[0]) as (keyof T)[]);
  };

  const handleViewChange = (view: TableViews) => {
    if (onSwitchChange) onSwitchChange(view);
    setTableView(view);
  };

  const isCustomColumnsEnabled = (key: keyof T): boolean => {
    return (columnNames && columnNames[key] !== undefined) || false;
  };

  const filtersValidation = (): boolean => {
    return additionalFilters !== undefined && additionalFilters.length > 0;
  };

  const checkboxesValidation = (): boolean => {
    return (
      additionalCheckboxes !== undefined && additionalCheckboxes.length > 0
    );
  };

  const getRowAlignmentBy = (key: keyof T): AlignmentPosition => {
    return headerAlignment?.[key] ?? "inherit";
  };

  const handleFilterChange = (
    event: ChangeEvent<HTMLSelectElement>,
    filterOnChange: (e: ChangeEvent<HTMLSelectElement>) => void
  ) => {
    handleResetSelection();
    filterOnChange(event);
  };

  const handleCheckChange = (
    event: ChangeEvent<HTMLInputElement>,
    checkboxOnChange: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    handleResetSelection();
    checkboxOnChange(event);
  };

  const handleIconClickEvent = (iconType: TPIconTypes) => {
    onIconClicked && onIconClicked(iconType);
    iconType === TPIconTypes.loop && handleResetSelection();
  };

  const handleResetSelection = () => {
    !persistSelectionAfterFilterChange && setSelectedItems([]);

    if (onSelectionChange && !persistSelectionAfterFilterChange)
      onSelectionChange([]);
  };

  const isChecked = (item: T): boolean => {
    return selectedItems.some(
      (selectedItem) => JSON.stringify(selectedItem) === JSON.stringify(item)
    );
  };

  const ColumnsManagement = (): JSX.Element => {
    return (
      <>
        {minorOptions && minorOptions.length > 0 && (
          <StyledTableCell
            style={{
              textTransform: uppercaseActions ? "uppercase" : "initial",
            }}
            align="center"
            width={"6em"}
          >
            {minorOptionsLabel}
          </StyledTableCell>
        )}
        {selectable && (
          <StyledTableCell align="center">
            {!isHeaderCheckboxDisabled ? (
              <TPCheckBox
                id={`${id}-head-check`}
                checked={
                  isAllChecksSelected() &&
                  filteredChecksBy(filteredData).length > 0
                }
                onChange={handleSelectAllChange}
              />
            ) : (
              <StyledDisabledCheck />
            )}
          </StyledTableCell>
        )}
        {visibleColumns
          .filter((key) => !hiddenColumns?.includes(key as keyof T))
          .map((key) => (
            <StyledTableCell
              key={String(key)}
              align={getRowAlignmentBy(key as keyof T)}
            >
              <SortTableItem
                isCustomColumns={isCustomColumnsEnabled(key)}
                itemName={
                  isCustomColumnsEnabled(key)
                    ? (columnNames![key as keyof T] ?? "")
                    : String(key)
                }
                direction={getSortBy(String(key))}
                handleClick={() => requestSort(key as keyof T)}
              />
            </StyledTableCell>
          ))}

        {CustomAction && (
          <StyledTableCell
            style={{
              textTransform: uppercaseActions ? "uppercase" : "initial",
            }}
          >
            {minorOptionsLabel}
          </StyledTableCell>
        )}
      </>
    );
  };

  const CardManagement = (): JSX.Element => {
    return (
      <>
        {paginatedData.map((item, index) => (
          <React.Fragment key={index}>
            {CustomCard ? (
              React.createElement(CustomCard, { item })
            ) : (
              <div className="default-card">
                {visibleColumns
                  .filter((key) => !hiddenColumns?.includes(key as keyof T))
                  .map((key) => (
                    <div className="default-card-item" key={String(key)}>
                      <strong>{camelCaseToPhrase(String(key))}:</strong>
                      {columnStyles?.[key] ? (
                        React.createElement(columnStyles[key]!, {
                          value: item[key],
                          item,
                        })
                      ) : (
                        <p>{String(item[key])}</p>
                      )}
                    </div>
                  ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  const FiltersManagement = (): JSX.Element => {
    return (
      <>
        {filtersValidation() && (
          <>
            {additionalFilters!.map((filter) => (
              <TPSelect
                key={filter.key}
                id={`${id && `${id}-`}${filter.key}-select`}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  handleFilterChange(e, filter.onChange)
                }
                dataSource={filter.data}
                value={filter.selectedValue}
                labelText={filter.label}
                placeholder={filter.placeholder}
                minWidth={filter.width}
                maxWidth={filter.width}
                isHorizontal={false}
                isDynamic={true}
              />
            ))}
          </>
        )}
      </>
    );
  };

  const CheckboxesManagement = (): JSX.Element => {
    return (
      <>
        {checkboxesValidation() && (
          <>
            {additionalCheckboxes!.map((checkbox) => (
              <TPCheckBox
                key={checkbox.key}
                id={`${id && `${id}-`}${checkbox.key}-select`}
                labelText={checkbox.label}
                checkboxStyle={checkbox?.styles}
                checked={checkbox.checked}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  checkbox.checked = !checkbox.checked;
                  handleCheckChange(e, checkbox.onChange);
                }}
              />
            ))}
          </>
        )}
      </>
    );
  };

  const loadUtilsResources = async () => {
    setMinorOptionsLabel(
      await TPI18N.GetText("FormDesignerComponent", "ActionColumnLabel")
    );
    setPreferencesPlaceholder(
      await TPI18N.GetText("FormDesignerComponent", "PreferencesLabel")
    );
    setPreferencesButtonLabel(
      await TPI18N.GetText("FormDesignerComponent", "ResetToDefault")
    );
    setSearchPlaceholder(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "Search")
    );
    setOfLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableOfCounter")
    );
    setAllLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveAll")
    );
    setPageLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTablePageCounter")
    );
    setNextPageLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "DataTableNextPageCounter"
      )
    );
    setExportTableLabel(await TPI18N.GetText("DynamicTable", "Export"));
    setRefreshTableLabel(await TPI18N.GetText("DynamicTable", "Refresh"));
    setNoResultsLabel(await TPI18N.GetText("DynamicTable", "NoResultsFound"));
    setNewOneLabel(await TPI18N.GetText("DynamicTable", "NewOne"));
  };

  useEffect(() => {
    if (data.length > 0) {
      resetColumns();
    }
  }, [data]);

  useEffect(() => {
    loadUtilsResources().then();
  }, []);

  // add selectedValues to selectedItems checkboxes
  useEffect(() => {
    if (selectedValues && selectedValues.length > 0) {
      const items = selectedValues.filter((inputItem) =>
        data.some(
          (baseItem) => JSON.stringify(baseItem) === JSON.stringify(inputItem)
        )
      );

      setSelectedItems(items);
      onSelectionChange && onSelectionChange(items);
    }
  }, [selectedValues]);

  return (
    <>
      {data.length === 0 && hideAllTableOnNoData ? (
        <TableNotFound
          newOneLabel={newOneLabel}
          noResultsLabel={noResultsLabel}
          text={noDataMessage}
        />
      ) : (
        <StyledDynamicTable maxWidth={maxWithTableContainer}>
          {!hideControls && (
            <StyledTableHeader>
              <StyledHeaderOptions>
                {switchable && (
                  <SwitchTableView
                    id={id}
                    active={tableView}
                    handleViewChanged={(i) => handleViewChange(i)}
                  />
                )}
                {childrenPosition === "left" && <>{children}</>}
                {searchPosition === "right" && (
                  <>
                    <FiltersManagement />
                    <CheckboxesManagement />
                  </>
                )}
                {searchPosition === "left" && (
                  <SearchInput
                    id={id}
                    onChange={handleSearch}
                    placeholder={searchPlaceholder}
                  />
                )}
              </StyledHeaderOptions>

              <StyledHeaderOptions>
                {searchPosition === "left" && <CheckboxesManagement />}

                {filteredData.length !== 0 && (
                  <TableIcons
                    id={id}
                    icons={icons}
                    exportData={filteredData}
                    visibleColumns={visibleColumns}
                    hiddenColumns={hiddenColumns}
                    columnNames={columnNames}
                    exportCustomValues={exportCustomValues}
                    hideExport={hideExport}
                    exportLabel={exportTableLabel}
                    refreshLabel={refreshTableLabel}
                    onIconClick={handleIconClickEvent}
                  />
                )}

                {searchPosition === "left" && <FiltersManagement />}

                {filteredData.length !== 0 && (
                  <>
                    {withPreferences && tableView === TableViews.TABLE && (
                      <TablePreferences
                        id={id}
                        tableData={data}
                        visibleColumns={visibleColumns}
                        hiddenColumns={hiddenColumns}
                        columnNames={columnNames}
                        placeholder={preferencesPlaceholder}
                        buttonValue={preferencesButtonLabel}
                        lastColumn={lastColumn}
                        resetColumns={resetColumns}
                        handleColumnVisibilityChange={
                          handleColumnVisibilityChange
                        }
                      />
                    )}
                  </>
                )}

                {searchPosition === "right" && (
                  <SearchInput
                    id={id}
                    onChange={handleSearch}
                    placeholder={searchPlaceholder}
                  />
                )}

                {childrenPosition === "right" && <>{children}</>}
              </StyledHeaderOptions>
            </StyledTableHeader>
          )}
          {childrenPosition === "bottom" && (
            <StyledTableChildren>{children}</StyledTableChildren>
          )}
          {filteredData.length === 0 ? (
            <TableNotFound
              newOneLabel={newOneLabel}
              noResultsLabel={noResultsLabel}
              isSearchData={true}
            />
          ) : (
            <>
              {tableView === TableViews.TABLE ? (
                <StyledTable height={customHeight} style={style}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    id={`${id ? id : "dynamic"}-table`}
                  >
                    <TableHead>
                      <TableRow>
                        <ColumnsManagement />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedData.map((item, index) => (
                        <StyledTableRow key={index}>
                          {minorOptions && minorOptions.length > 0 && (
                            <TableActionItem
                              key={`${index}-option`}
                              index={index}
                              item={item}
                              minorOptions={minorOptions}
                              disableMinorOption={disableMinorOption(item)}
                            />
                          )}
                          {selectable && (
                            <StyledTableCell
                              key={`${index}-checks`}
                              align="center"
                            >
                              {!disableSelectionCheckbox(item) ? (
                                <TPCheckBox
                                  id={`${id}-${index}-check`}
                                  checked={isChecked(item)}
                                  onChange={() => handleCheckboxChange(item)}
                                />
                              ) : (
                                <StyledDisabledCheck />
                              )}
                            </StyledTableCell>
                          )}
                          {visibleColumns
                            .filter(
                              (key) => !hiddenColumns?.includes(key as keyof T)
                            )
                            .map((key) => (
                              <StyledTableCell
                                key={String(key)}
                                sx={{ fontFamily: "Noto Sans" }}
                                align={getRowAlignmentBy(key as keyof T)}
                              >
                                {columnStyles?.[key]
                                  ? React.createElement(columnStyles[key]!, {
                                      value: item[key],
                                      item,
                                    })
                                  : String(item[key])}
                              </StyledTableCell>
                            ))}
                          {CustomAction && (
                            <StyledTableCell>
                              {React.createElement(CustomAction, { item })}
                            </StyledTableCell>
                          )}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTable>
              ) : (
                <StyledDynamicCards>
                  <CardManagement />
                </StyledDynamicCards>
              )}
              {!hidePager && (
                <TablePager
                  id={id}
                  page={currentPage}
                  totalPages={totalPages}
                  rowsPerPage={rowsPerPage}
                  resultsPerPage={paginatedData.length}
                  allResults={filteredData.length}
                  selectedResults={selectedItems.length}
                  ofLabel={ofLabel}
                  allLabel={allLabel}
                  pageLabel={pageLabel}
                  nextPageLabel={nextPageLabel}
                  pagerStyle={pagerStyle}
                  onPagerChange={setCurrentPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              )}
            </>
          )}
        </StyledDynamicTable>
      )}
    </>
  );
}

export default DynamicTable;
