import TPGeneralContext from "@/contexts/TPGeneralContext";
import React from "react";
import styled from "styled-components";

export const TPPageTitle = styled.h1<{ isSubtitle?: boolean }>`
  font-size: 24px;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 900;
  text-transform: ${({ isSubtitle }) =>
    !isSubtitle ? "capitalize" : "uppercase"};
`;

export const TPPageSubTitle = styled.h6`
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  margin-top: -22px;
  margin-bottom: 30px;
`;

export const TPPageSectionTitle = styled.h2`
  // margin-left: 40px;
  font-weight: 600;
  font-size: 24px;
  margin-top: 10px;
`;

export const TPPageSection = styled.section`
  // margin-left: 80px;
`;

export const TPPageAcceptCancelButtonsContainer = styled.div(({ theme }) => {
  const contextData: any = React.useContext(TPGeneralContext);
  return `text-align: right`;
});

export const TPPageFilterContainer = styled.div`
  display: flex;

  .tp-select {
    padding-left: 10px;
    height: 38px;
  }

  .form-control {
    padding: 5px;
    width: 192px;
  }
`;

export const TPPageActions = styled.div`
  display: flex;
  gap: 15px;
`;

export const TPPageActionsSpaceBetween = styled(TPPageActions)`
  width: 100%;
  justify-content: space-between;
`;

export const TPPageSearchContainer = styled.div``;

export const CIMTitleSection = styled.div<{ isCIM?: boolean; width?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0 ${({ isCIM }) => (isCIM ? "20px" : "30px")}; 0;
	max-width: ${({ width }) => (width ? width : "98%")};
	flex-wrap: wrap;
	gap: 1em;
	margin-bottom: ${({ isCIM }) => (isCIM ? "0" : "1em")};
`;

export const TPPageFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TPFilterAndSearch = styled.div`
  display: flex;

  gap: 10px;
`;

export const ExpandableColumn = styled.div`
  padding: 1rem;
  span {
    padding: 0.5rem;
  }
`;
