import { AdditionalFilter, TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    ProjectDetailEnum as e,
    ProjectDetailModel,
    ProjectType,
    TableProjectDetailModel
} from "@/models/Project/Projects";
import DynamicTable, { ColumnStyles, CustomColumnNames } from "@/modules/core/components/dynamic-table/DynamicTable";
import ProjectReassign from "@/pages/Projects/ProjectReassign/ProjectReassign";
import { Chip } from "@mui/material";
import FileSaver from "file-saver";
import { CSSProperties, forwardRef, useEffect, useImperativeHandle } from "react";
import * as XLSX from "xlsx";
import ProjectDetailCard from "./ProjectDetailCard";

/**
 * STYLES
 */
const styles = {
    globalReportSection: {
        margin: "20px 0",
        padding: "10px",
        backgroundColor: "#ebf2ff",
        borderRadius: "5px",
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        gap: "10px",
    } as CSSProperties,
    globalReportLabel: {
        margin: "0",
        fontSize: "13px",
        fontWeight: "bold",
    } as CSSProperties,
    reportLabel: {
        margin: "0",
        fontSize: "13px",
        fontWeight: "600",
    } as CSSProperties,
    report: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
        gap: "10px",
        placeContent: "center space-around",
        alignItems: "center",
        flex: "1 1 0%",
    } as CSSProperties,
    column: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'column',
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: 'flex',
        flexDirection: 'row',
    } as CSSProperties,
    chip: {
        backgroundColor: "#72f6dd5e" // #ffd3d9
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * child component events
 */
export interface ProjectDetailTableEvents {
    load: () => void;
}

/**
 * properties that the child component needs
 */
export interface ProjectDetailTableProps {
    /**
     * Messages that are rendered in the view depending on the language
     */
    m: { [attribute: string]: any };
    /**
     * overall report values
     */
    globalReport: ProjectDetailModel;
    /**
     * queues available
     */
    queues: Array<TPKeyValue>;
    /**
     * detail of the project queues
     */
    dataSource: Array<TableProjectDetailModel>;
    /**
     * Function responsible for consulting the records that detail the component
     */
    loadDataSource: (sort?: string) => void;

    structureDetails?: any;

    templateName?: string;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * project detail table component
 */
const ProjectDetailTable = forwardRef((
    {
        m,
        globalReport,
        queues,
        dataSource,
        loadDataSource,
        structureDetails,
        templateName
    }: ProjectDetailTableProps,
    ref
) => {
    /**
     * ATTRIBUTES
     */
    /**
     * sorting options
     */
    const sorts = [
        {value: m?.[e.SortByLabel], key: ""},
        {value: m?.[e.SortByMoreEventsLabel], key: "1"},
        {value: m?.[e.SortByMoreCustomerExpertsReadyLabel], key: "3"},
        {value: m?.[e.SortByMorePendingLabel], key: "5"},
        {value: m?.[e.SortByMoreWorkedLabel], key: "7"},
        {value: m?.[e.SortByLessEventsLabel], key: "2"},
        {value: m?.[e.SortByLessCustomerExpertsReadyLabel], key: "4"},
        {value: m?.[e.SortByLessPendingLabel], key: "6"},
        {value: m?.[e.SortByLessWorkedLabel], key: "8"},
        {value: "A - Z", key: "9"},
        {value: "Z - A", key: "10"},
    ];
    /**
     * Contains the label to be painted for each column
     */
    const columns: CustomColumnNames<TableProjectDetailModel> = {
        queueName: `${m?.[e.TableColumnQueueNameLabel] ?? ""}`,
        totalCe: `${m?.[e.TableColumnTotalCELabel] ?? ""}`,
        ceReady: `${m?.[e.TableColumnCEReadyLabel] ?? ""}`,
        online: `${m?.[e.TableColumnOnlineLabel] ?? ""}`,
        working: `${m?.[e.TableColumnWorkingLabel] ?? ""}`,
        offline: `${m?.[e.TableColumnOfflineLabel] ?? ""}`,
        events: `${m?.[e.TableColumnEventsLabel] ?? ""}`,
        worked: `${m?.[e.TableColumnWorkedLabel] ?? ""}`,
        pending: `${m?.[e.TableColumnPendingLabel] ?? ""}`,
        type: `${m?.[e.TableColumnTypeLabel] ?? ""}`,
        reassign: "",
    };
    /**
     * custom styles for each cell per column
     */
    const columnStyles: ColumnStyles<TableProjectDetailModel> = {
        queueName:  ({value, item}) => (<div>{item?.queueName ?? ""}</div>),
        ceReady: ({value, item}) => (
            <Chip label={item.ceReady}
                  style={styles.chip}
            />
        ),
        type: ({value, item}) => (
            <Chip label={capitalize(item.type)}
                  style={{backgroundColor: item.type === ProjectType.BACKOFFICE ? "#72f6dd5e" : "#e2d3f8"}}
            />
        ),
        reassign: ({value, item}) => (
            <ProjectReassign
                m={m}
                queues={queues}
                detail={item}
                loadDataSource={loadDataSource}
            />
        ),
    };
    /**
     * Custom filters to sort by
     */
    const filters: AdditionalFilter[] = [
      {
        key: "status",
        data: sorts,
        label: "",
        selectedValue: "",
        onChange: onChangeSelectSortHandler,
      },
    ];
    /**
     * ATTRIBUTES END
     */

    /**
     * CALLED FATHER COMPONENT
     */
    useImperativeHandle(ref, () => ({
        load() {
        },
    } as ProjectDetailTableEvents));
    /**
     * CALLED FATHER COMPONENT END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {
    }, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * 
     * @param value 
     */
    function onChangeSelectSortHandler(event: any) {
        loadDataSource(event.target.value)
    }
    
    /**
     * Function responsible for capturing the refresh event and executing the data refresh function
     */
    function onClickIconHandler(event: TPIconTypes) {
        if (event === TPIconTypes.loop) loadDataSource();
        if (event === TPIconTypes.downloadTemplate && templateName) exportToCSV(structureDetails, templateName);
    }
    /**
     * function in charge of converting a text to capitalize
     * @param text text to convert
     * @returns converted text
     */
    function capitalize(text: string) {
        return text
            .split(" ")
            .map(t => `${t.charAt(0).toUpperCase()}${t.slice(1).toLowerCase()}`)
            .join(" ")
    }
    /**
     * FUNCTIONS END
     */

    const exportToCSV = (apiData: any, fileName: string) => {
        const ws = XLSX.utils.json_to_sheet(apiData);
        /* custom headers */
        XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        FileSaver.saveAs(data, fileName + ".xlsx");
    };
    
    /**
     * COMPONENT TO RENDER
     */
    return (
        <DynamicTable
            data={dataSource}
            hiddenColumns={["groupId", "queueId"]}
            columnNames={columns}
            columnStyles={columnStyles}
            additionalFilters={filters}
            switchable={true}
            CustomCard={({item}) => (
                <ProjectDetailCard
                    m={m}
                    queues={queues}
                    element={item}
                    loadDataSource={loadDataSource}
                />
            )}
            onIconClicked={event => onClickIconHandler(event)}
            icons={ structureDetails && templateName && [{
                        type: TPIconTypes.downloadTemplate,
                        status: true,
                    }]}
        >
            <div style={styles.globalReportSection}>
                <p style={styles.globalReportLabel}>
                    {m?.[e.GlobalReportLabel]}
                </p>

                <div style={styles.report}>
                    <p style={styles.reportLabel}>
                        {m?.[e.GlobalReportTotalQueuesLabel]} {globalReport?.totalCe}
                    </p>

                    <p style={styles.reportLabel}>
                        {m?.[e.GlobalReportEventsLabel]} {globalReport?.events}
                    </p>

                    <p style={styles.reportLabel}>
                        {m?.[e.GlobalReportWorkedLabel]} {globalReport?.worked}
                    </p>

                    <p style={styles.reportLabel}>
                        {m?.[e.GlobalReportPendingLabel]} {globalReport?.pending}
                    </p>

                    <p style={styles.reportLabel}>
                        {m?.[e.GlobalReportCustomerExpertsAvailableLabel]} {globalReport?.ceReady}
                    </p>
                </div>
            </div>
        </DynamicTable>
    );
})
export default ProjectDetailTable;
