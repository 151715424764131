import allThemes from "@/assets/styles/theme";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextArea from "@/components/bootstrap/forms/textArea/TPTextArea";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { ClientAdminViewModel } from "@/models/Client/ClientAdminViewModel";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { ClientService } from "@/services/ClientService";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMainCustomerData,
  setMergeCustomerData,
} from "../../redux/MergeCustomersSlice";
import { MergeCustomersState } from "../../redux/MergeCustomersStore";
import useLabels from "../../styles/labels";
import "../../styles/Styles.css";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

interface CustomerPersonDataTabProperties {
  customer: ClientAdminViewModel;
}

const CustomerPersonDataTab: FC<CustomerPersonDataTabProperties> = function ({
  customer,
}) {
  const dispatch = useDispatch();
  const { labels } = useLabels();
  const [editingData, setEditingData] = useState<boolean>(false);
  const [newNameInput, setNewNameInput] = useState<string>(customer.name);
  const [newDocumentTypeInput, setNewDocumentTypeInput] = useState<string>(
    customer.documentType,
  );
  const [newDocumentInput, setNewDocumentInput] = useState<string>(
    customer.document,
  );
  const [commentsInput, setCommentsInput] = useState<string>(customer.additionalInfo || "");
  const { mainCustomerData, mergeCustomerData } = useSelector(
    (state: MergeCustomersState) => state.mainData,
  );
  const [loading, setLoading] = useState(false);

  const handleNewDocumentInput = function (value: string) {
    if (!isNaN(Number(value))) setNewDocumentInput(value);
  };

  const handleUserEdit = function () {
    setLoading(true);
    const clientServiceInstance = new ClientService();
    clientServiceInstance
      .updateClient(
        {
          subsidiaryOrganizationId: customer.subsidiaryOrganizationId,
          documentTypeId: newDocumentTypeInput,
          document: newDocumentInput,
          name: newNameInput,
          lastName: customer.lastName,
          additionalInfo: commentsInput,
          isActive: customer.isActive,
          typistIdGuid: TPGlobal.currentUserGuid,
          internalCode: customer.internalCode,
          subsidiaryName: customer.subsidiaryName,
          preferredPhone: customer.preferredPhone,
          preferredAddress: customer.preferredAddress,
          preferredAddressInfo: customer.preferredAddressInfo,
          preferredEmail: customer.preferredEmail,
          geography: customer.geography,
          organizationId: customer.organizationId,
          relationId: customer.relationId,
          regionId: customer.regionId,
          channelId: customer.channelId,
          customerTypeId: customer.customerTypeId,
        },
        true,
        true,
        [200],
      )
      .then(() => {
        const newCustomerData: ClientAdminViewModel = {
          ...customer,
          name: newNameInput,
          documentType: newDocumentTypeInput,
          document: newDocumentInput,
          additionalInfo: commentsInput,
        };
        if (customer.id == mainCustomerData.id) {
          dispatch(setMainCustomerData(newCustomerData));
        } else if (customer.id == mergeCustomerData.id) {
          dispatch(setMergeCustomerData(newCustomerData));
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        setEditingData(false);
        setLoading(false);
      });
  };
  return (
    <>
      <TPLoadingOverlay active={loading}>
      {editingData ? (
        <div>
          <div className="customer-edit-info-tab">
            <div className="merge-customers-text-box">
              <b>{labels["name"]}</b>
              <label style={{ color: "red" }}>*</label>
              <TPTextBox
                value={newNameInput}
                onChange={(event: any) => setNewNameInput(event.target.value)}
              />
            </div>
            <div className="merge-customers-text-box">
              <b>{labels["documentType"]}</b>
              <label style={{ color: "red" }}>*</label>
              <TPSelect
                onChange={(event: any) =>
                  setNewDocumentTypeInput(event.target.value)
                }
                dataSource={[
                  { key: customer.documentType, value: customer.documentType },
                ]}
                value={newDocumentTypeInput}
              />
            </div>
            <div className="merge-customers-text-box">
              <b
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {labels["document"]}
              </b>
              <TPTextBox
                disabled
                value={newDocumentInput}
                onChange={(event: any) =>
                  handleNewDocumentInput(event.target.value)
                }
              />
            </div>
          </div>
          <div className="customer-edit-info-tab">
            <div className="merge-customers-text-area">
              <b>
                {labels.Comments}
                <label style={{ color: "red" }}>*</label>
              </b>
              <div style={{ width: "100%" }}>
                <TPTextArea
                  onChange={(event: any) =>
                    setCommentsInput(event.target.value)
                  }
                  value={commentsInput}
                  rows={5}
                  isHorizontal
                  columns={100}
                />
              </div>
            </div>
          </div>
          <div className="merge-edit-buttons">
            <TPButton
              onClick={() => setEditingData(false)}
              type={TPButtonTypes.link}
                isDesignSystem
                style={{
                  background: 'none',
                  color: 'purple',
                  paddingLeft: '16px',
                  paddingRight: '16px'
                }}
            >
              {labels.Cancel}
            </TPButton>
            <TPButton
              onClick={() => {
                  handleUserEdit();
              }}
              type={TPButtonTypes.primary}
              disabled={
                newNameInput.trim().length == 0 ||
                newDocumentInput.trim().length == 0 ||
                newDocumentTypeInput.trim().length == 0 ||
                commentsInput.trim().length == 0 ||
                loading
              }
                isDesignSystem
                style={{
                  paddingLeft: '16px',
                  paddingRight: '16px'
                }}
            >
              {labels.Save}
            </TPButton>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "start" }}>
          <div className="customer-info-tab">
            <label>
              <b>{labels["id"]}: </b>
              {customer.id}
            </label>
            <label>
              <b>{labels["internalCode"]}: </b>
              {customer.internalCode}
            </label>
            <label>
              <b>{labels["documentType"]}: </b>
              {customer.documentType}
            </label>
            <label>
              <b>{labels["document"]}: </b>
              {customer.document}
            </label>
            <label>
              <b>{labels["name"]}: </b>
              {customer.name}
            </label>
            <label>
              <b>{labels["lastName"]}: </b>
              {customer.lastName}
            </label>
            <label>
              <b>{labels["customerTypeDescription"]}: </b>
              {customer.customerTypeDescription}
            </label>
            <label>
              <b>{labels["organizationId"]}: </b>
              {customer.organizationId}
            </label>
            <label>
              <b>{labels["organizationName"]}: </b>
              {customer.organizationName}
            </label>
            <label>
              <b>{labels["additionalInfo"]}: </b>
              {customer.additionalInfo}
            </label>
          </div>
          <button
            className="edit-button"
            type="button"
            onClick={() => setEditingData(true)}
            style={{ color: allThemes.base.primary }}
          >
            <TPIcon iconType={TPIconTypes.edit} />
          </button>
        </div>
      )}
      </TPLoadingOverlay>
    </>
  );
};

export default CustomerPersonDataTab;
