import { ReactNode, createContext, useContext, useEffect } from "react";
import { useStatusImagesAdmin } from "./useStatusImagesAdmin";
import { EventHandler } from "@azure/msal-browser/dist/internals";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { Data, RecursiveRoutes } from "@/models/ImagesAdmin/ImagesAdminModel";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

type AppProviderImagesAdminProps = {
    children: ReactNode;
};

class model {
    status: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
    listFilesModel: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
    listFolderModel: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
    listMultiFileSelected: Array<RecursiveRoutes> = new Array<RecursiveRoutes>();
    selectedItem: RecursiveRoutes = new RecursiveRoutes();
    selectedFolderParent: RecursiveRoutes = new RecursiveRoutes();
    optionList: Array<TPKeyValue> = new Array<TPKeyValue>();
    optionStatusList: Array<TPKeyValue> = new Array<TPKeyValue>();
    sortAscendingOrDescending: boolean = false;
    viewListAndGrid: boolean = false;
    orderBy: string = "Name";
    filterStatus: string = "All";
    operationMode: string = "";
    isLoading: boolean = false;
    modeSelect: boolean = false;
    parentFolderId: number = 0;
    setParentFolderId: (data: number) => void = (data: number) => void {};
    setModeSelect: (data: boolean) => void = (data: boolean) => void {};
    handleMultiSelectedFile: (data: RecursiveRoutes) => void = (
        data: RecursiveRoutes,
    ) => void {};
    handleMultiSelectedFileClear: () => void = () => void {};
    handleAllSelectedFile: () => void = () => void {};
    handleInitializeFilesModeSelect: (data: number) => void = (data: number) => void {};
    handleSelected: (data: RecursiveRoutes, mode: string) => void = (
        data: RecursiveRoutes,
        mode: string,
    ) => void {};
    handleNewFolder: (data: RecursiveRoutes) => void = (data: RecursiveRoutes) =>
        void {};
    handleUpdateFolder: (data: RecursiveRoutes) => void = (
        data: RecursiveRoutes,
    ) => void {};
    handleInitializeFolders: (id: number, modeSelectFolder: boolean) => void = (id: number = -1, modeSelectFolder: boolean = false) => void {};
    handleInitializeFiles: () => void = () => void {};
    handleUpdateFile: (data: RecursiveRoutes) => void = (data: RecursiveRoutes) =>
        void {};
    handleDisableFolder: (data: RecursiveRoutes) => void = (
        data: RecursiveRoutes,
    ) => void {};
    handleDisableFile: (data: RecursiveRoutes) => void = (
        data: RecursiveRoutes,
    ) => void {};
    handleOrderData: (mode: string) => void = (mode: string) => void {};
    handleSortFolderActive: (mode: string) => void = (mode: string) => void {};
    handleOrderBy: (mode: string) => void = (mode: string) => void {};
    handleFilter: (mode: string) => void = (mode: string) => void {};
    handleSortAscendingOrDescending: (mode: boolean) => void = (mode: boolean) =>
        void {};
    handleSortView: (mode: boolean) => void = (mode: boolean) => void {};
    handleUploadImage: (file: any) => void = (file: any) => void {};
    handleLoading: (mode: boolean) => void = (mode: boolean) => void {};
    listTempFolder: Array<Data> =  new Array<Data> ;
}

type modelContext = {
    status: Array<RecursiveRoutes>;
    listFilesModel: Array<RecursiveRoutes>;
    listFolderModel: Array<RecursiveRoutes>;
    listMultiFileSelected: Array<RecursiveRoutes>;
    selectedItem: RecursiveRoutes;
    selectedFolderParent: RecursiveRoutes;
    optionList: Array<TPKeyValue>;
    optionStatusList: Array<TPKeyValue>;
    sortAscendingOrDescending: boolean;
    viewListAndGrid: boolean;
    orderBy: string;
    filterStatus: string;
    operationMode: string;
    isLoading: boolean;
    modeSelect: boolean;
    parentFolderId: number;
    handleInitializeFilesModeSelect: (data: number) => void;
    setParentFolderId: (data: number) => void;
    setModeSelect: (data: boolean) => void;
    handleMultiSelectedFile: (data: RecursiveRoutes) => void;
    handleMultiSelectedFileClear: () => void;
    handleAllSelectedFile: () => void;
    handleSelected: (data: RecursiveRoutes, mode: string) => void;
    handleNewFolder: (data: RecursiveRoutes) => void;
    handleUpdateFolder: (data: RecursiveRoutes) => void;
    handleInitializeFolders: (id: number , modeSelectFolder: boolean ) => void;
    handleInitializeFiles: () => void;
    handleUpdateFile: (data: RecursiveRoutes) => void;
    handleDisableFolder: (data: RecursiveRoutes) => void;
    handleDisableFile: (data: RecursiveRoutes) => void;
    handleOrderData: (mode: string) => void;
    handleSortFolderActive: (mode: string) => void;
    handleOrderBy: (mode: string) => void;
    handleFilter: (mode: string) => void;
    handleSortAscendingOrDescending: (mode: boolean) => void;
    handleSortView: (mode: boolean) => void;
    handleUploadImage: (file: any) => void;
    handleLoading: (mode: boolean) => void;
    listTempFolder: Array<Data>;
};

export const ImagesAdminConctext = createContext<modelContext>(new model());

export const AppProviderImagesAdmin: React.FC<AppProviderImagesAdminProps> = ({
    children,
}) => {
    const {
        status,
        listFilesModel,
        listFolderModel,
        selectedItem,
        selectedFolderParent,
        optionList,
        optionStatusList,
        sortAscendingOrDescending,
        viewListAndGrid,
        orderBy,
        filterStatus,
        listMultiFileSelected,
        operationMode,
        isLoading,
        modeSelect,
        setModeSelect,
        parentFolderId,
        setParentFolderId,
        handleMultiSelectedFile,
        handleMultiSelectedFileClear,
        handleAllSelectedFile,
        handleSelected,
        handleNewFolder,
        handleInitializeFolders,
        handleInitializeFiles,
        handleInitializeFilesModeSelect,
        handleUpdateFolder,
        handleUpdateFile,
        handleDisableFolder,
        handleDisableFile,
        handleOrderData,
        handleSortAscendingOrDescending,
        handleSortFolderActive,
        handleSortView,
        handleOrderBy,
        handleFilter,
        handleUploadImage,
        handleLoading,
        listTempFolder
    } = useStatusImagesAdmin();

    useEffect(() => {
       
    }, []);

    useEffect(() => { }, [isLoading]);

    return (
        <TPLoadingOverlay active={isLoading}>
            <ImagesAdminConctext.Provider
                value={{
                    status,
                    listFilesModel,
                    listFolderModel,
                    selectedItem,
                    selectedFolderParent,
                    optionList,
                    optionStatusList,
                    sortAscendingOrDescending,
                    viewListAndGrid,
                    orderBy,
                    filterStatus,
                    listMultiFileSelected,
                    operationMode,
                    isLoading,
                    modeSelect,
                    setModeSelect,
                    parentFolderId,
                    setParentFolderId,
                    handleMultiSelectedFile,
                    handleMultiSelectedFileClear,
                    handleAllSelectedFile,
                    handleSelected,
                    handleNewFolder,
                    handleInitializeFolders,
                    handleInitializeFiles,
                    handleInitializeFilesModeSelect,
                    handleUpdateFolder,
                    handleUpdateFile,
                    handleDisableFolder,
                    handleDisableFile,
                    handleOrderData,
                    handleSortAscendingOrDescending,
                    handleSortFolderActive,
                    handleSortView,
                    handleOrderBy,
                    handleFilter,
                    handleUploadImage,
                    handleLoading,
                    listTempFolder
                }}
            >
                {children}
            </ImagesAdminConctext.Provider>
        </TPLoadingOverlay>
    );
};

export const useAppConctextImageAdmin = () => {
    return useContext(ImagesAdminConctext);
};
