import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { TPI18N } from "@/services/I18nService";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { AppProviderFormDesigner } from "./ContextFormDesignerAdmin";
import FormDesignerTable from "./FormDesignerTable";
import { RightRowContainerStyle } from "./StyleFromDesigner";

interface FormDesignerTableModel {
  callBackCommands: Function;
}

const FormDesigner = forwardRef(
  ({ callBackCommands }: FormDesignerTableModel, ref) => {
    const tableRef = useRef<any>();

    //ResourcesAndLoadInfo
    const resourceSet: string = "FormDesignerComponent";

    const [titleLabel, setTitleLabel] = useState("");

    const [addNewCustomerFormLabel, setAddNewCustomerFormLabel] = useState("");

    const loadResourcesAndLoadInfo = async () => {
      //ResourcesAndLoadInfo
      setTitleLabel(
        await TPI18N.GetText(resourceSet, "TitleFormDesignerLabel"),
      );

      setAddNewCustomerFormLabel(
        await TPI18N.GetText(resourceSet, "AddNewCustomerFormLabel"),
      );
    };

    const callBackVerticalTabTemp = () => {
      let command: any = { command: "new", recordId: 0 };
      callBackCommands(command);
    };

    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        tableRef.current!.refreshGridFromParent();
      },
    }));

    useEffect(() => {
      loadResourcesAndLoadInfo();
    }, []);

    return (
      <AppProviderFormDesigner>
        <div>
          <div className="row">
            <div>
            <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-formdesigner-type"
                  isDesignSystem={true}
                  onClick={callBackVerticalTabTemp}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                   {addNewCustomerFormLabel}
                </TPButton>
            </CIMTitleSection>               
            </div>
          </div>
          <FormDesignerTable
            ref={tableRef}
            callBackCommands={callBackCommands}
          ></FormDesignerTable>
        </div>
      </AppProviderFormDesigner>
    );
  },
);

export default FormDesigner;
