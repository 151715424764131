import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { FormesignerInputDTO } from "@/models/FormDesigner/FormDesigner";
import { TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { AdditionalDataService } from "@/services/AdditionalDataService";
import { TPI18N } from "@/services/I18nService";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useAppConctextFormDesigner } from "./ContextFormDesignerAdmin";
import FormDesignerBuilder from "./FormDesignerBuilder";
import FormDesignerPreferences from "./FormDesignerPreferences";
import FormDesignerPreview from "./FormDesignerPreview";
import {
  ContainerTabStyle,
  RightRowContainerStyle,
  TabActiveStyle,
} from "./StyleFromDesigner";

interface FormDesignerTableModel {
  callBackCommands: Function;
  recordId?: string;
  mode: string;
  tabId: string;
}

enum pageEnum {
  preference,
  design,
  preview,
}

const FormDesignerAndBuilder = forwardRef(
    ({ callBackCommands, recordId, mode, tabId }: FormDesignerTableModel, ref) => {
    const resourceSet: string = "FormDesignerComponent";

    //Page
    const [page, setPage] = useState(pageEnum.preference);

    const [titleLabelEdit, setTitleLabelEdit] = useState("");
    const [titleLabel, setTitleLabel] = useState("");
    const [titlePreviewLabel, setTitlePreviewLabel] = useState("");
    const [preferencesLabel, setPreferencesLabel] = useState("");
    const [designLabel, setDesignLabel] = useState("");

    const loadResourcesAndLoadInfo = async () => {
      //ResourcesAndLoadInfo
      setTitleLabelEdit(
        await TPI18N.GetText(resourceSet, "TitleFormDesignerLabelEdit"),
      );
      setTitleLabel(
        await TPI18N.GetText(resourceSet, "TitleNewCustomerFormLabel"),
      );
      setPreferencesLabel(
        await TPI18N.GetText(resourceSet, "PreferencesLabel"),
      );
      setDesignLabel(await TPI18N.GetText(resourceSet, "DesignLabel"));
      setTitlePreviewLabel(
        await TPI18N.GetText(resourceSet, "NewFormDesingnePreviewLabel"),
      );
    };

    const activePage = (page: pageEnum) => {
      if (rowsFormDesingner.id == undefined) return "";
      if (rowsFormDesingner.id == "") return "";
      if (rowsFormDesingner.id.trim() == "") return "";
      if (
        rowsFormDesingner.primaryLanguage == undefined ||
        rowsFormDesingner.primaryLanguage.key.trim() == ""
      )
        return "";

      if (rowsFormDesingner.formType == undefined) return "";
      if (rowsFormDesingner.formType.key == "") return "";

      if (!isUpdate) return "";

      setPage(page);
    };

    const handleGetAllAdditional = async (list: Array<string>) => {
      let serviceClient = new AdditionalDataService();
      let expectedCodes: Array<number> = [200, 404];

      if (additionalData == undefined || additionalData.length <= 0) {
        try {
          let responseRequest = await serviceClient.getAllByTypeId(
            list,
            false,
            true,
            expectedCodes,
          );

          setAdditionalData(responseRequest);

          return [...responseRequest];
        } catch (error) {
          TPLog.Log(
            `Error ${resourceSet} handleGetAllAdditional ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${resourceSet} handleGetAllAdditional ex`);

          return [];
        }
      }
    };

    const getAditionDataById = async (formId: string) => {
      let insert = new FormesignerInputDTO();
      setStatusLoading(true);
      let serviceClient = new AdditionalDataFormService();
      let expectedCodes: Array<number> = [200];

      try {
        let responseRequest = await serviceClient.getFormDesingnerById(
          formId,
          false,
          true,
          expectedCodes,
        );

        let data = { ...rowsFormDesingner };

        let utf8 = responseRequest.jsonDesigner;

        //if (utf8 != undefined)
        //    data = JSON.parse(utf8.split(",").map((charCode: any) => String.fromCharCode(charCode)).join(""));
        data = JSON.parse(utf8);

        data.id = responseRequest.id;

        if (responseRequest.jsonDesigner.replaceAll("{}", "") == "") {
          data.img = { key: responseRequest.logo, value: responseRequest.logo };
        }

        if (responseRequest.descriptions) {
          data.descriptions = responseRequest.descriptions
          data.recordLocalizedModel = TPGlobal.TPClientAvailableLanguages
            .map(({id: languageId}, order) => ({
              languageId,
              localizedValue: responseRequest.descriptions
                .find((d: any) => d.languageId === languageId)?.localizedValue ?? "",
              order,
            }))
        }

        if (responseRequest.formTypeId) {
          data.formType = {
            key: responseRequest.formTypeId,
            value: responseRequest.formTypeLocalizedDescription,
          };
        }

        data.showAsActive = responseRequest.isActive
        data.isHidePersonalData = responseRequest.applyPersonalData

        let allControlIds: string[] = data.rows.flatMap((row) =>
          row.controls.map((control) => control.idControl),
        );

        if (allControlIds) {
          handleGetAllAdditional(allControlIds);
        }

        handleEditForm(data);
        setStatusLoading(false);
        return responseRequest;
      } catch (error) {
        TPLog.Log(
          `Error ${resourceSet} getAditionDataById ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${resourceSet} getAditionDataById ex`);
        setStatusLoading(false);
        return [];
      }
    };

    const previowPage = () => {
      setPage(pageEnum.preview);
    };

    const previowBackPage = () => {
      setPage(pageEnum.design);
    };

    const replaceTitle = (edit: string) => {
      let copy = "";
      if (mode == "Insert") copy = titleLabel;
      if (mode == "Update") copy = titleLabelEdit;

      let title: string = edit;

      if (edit.trim().includes(" ")) {
        title = "";
        let tempCopy = copy.split(" ")[0];
        let tempEdit = edit.split(" ")[0];
        title = edit.replace(tempEdit, tempCopy);
      }

      return title;
    };

    const value = useAppConctextFormDesigner();
    const {
      rowsFormDesingner,
      isUpdate,
      additionalData,
      setAdditionalData,
      handleEditForm,
      setIsUpdate,
      setStatusLoading,
    } = value;

    //Mail account called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {},
    }));

    useEffect(() => {
      if (recordId) {
        setIsUpdate(true);
        getAditionDataById(recordId);
      }
      loadResourcesAndLoadInfo();
    }, []);

    return (
      <>
        <div className="row">
          <div>
            <RightRowContainerStyle>
              {pageEnum.preview == page ? (
                <>
                  {mode == "Insert" ? (
                    <TPPageTitle> {titlePreviewLabel}</TPPageTitle>
                  ) : (
                    <TPPageTitle>
                      {" "}
                      {replaceTitle(titlePreviewLabel)}
                    </TPPageTitle>
                  )}
                </>
              ) : (
                <>
                  {mode == "Insert" ? (
                    <TPPageTitle> {titleLabel}</TPPageTitle>
                  ) : (
                    <TPPageTitle> {titleLabelEdit}</TPPageTitle>
                  )}
                </>
              )}
            </RightRowContainerStyle>
          </div>
        </div>
        {page != pageEnum.preview && (
          <ContainerTabStyle>
            <TabActiveStyle activeBorder={page == pageEnum.preference}>
              <TPButton
                style={{
                  color: page == pageEnum.preference ? "" : "gray",
                }}
                type={TPButtonTypes.empty}
                text={"1"}
                onClick={() => {
                  setPage(pageEnum.preference);
                }}
                icon={TPIconTypes.default}
              >
                {preferencesLabel}
              </TPButton>
            </TabActiveStyle>
            <TabActiveStyle activeBorder={page == pageEnum.design}>
              <TPButton
                style={{
                  color: page == pageEnum.design ? "" : "gray",
                }}
                type={TPButtonTypes.empty}
                text={"1"}
                onClick={() => {
                  activePage(pageEnum.design);
                }}
                icon={TPIconTypes.default}
              >
                {designLabel}
              </TPButton>
            </TabActiveStyle>
          </ContainerTabStyle>
        )}

        {page == pageEnum.preview && (
          <FormDesignerPreview
            callBackCommandsBack={previowBackPage}
          ></FormDesignerPreview>
        )}

        {page == pageEnum.preference && (
          <FormDesignerPreferences
                    mode={mode}
                    recordId={recordId + ""}
                    callBackCommands={callBackCommands}
                    tabId={tabId}
          ></FormDesignerPreferences>
        )}

        {page == pageEnum.design && (
          <FormDesignerBuilder
            callBackCommandsPreview={previowPage}
            callBackCommands={callBackCommands}
          ></FormDesignerBuilder>
        )}
      </>
    );
  },
);

export default FormDesignerAndBuilder;
