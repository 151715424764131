import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";

import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPChip } from "@/components/TPChip/TPChip";
import {
  CIMTitleSection,
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { QuickClassifierViewModel } from "@/models/QuickClassifier/QuickClassifierViewModel";
import { RelationsWithRestrictionsViewModel } from "@/models/Relations/RelationsModels";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import { TPI18N } from "@/services/I18nService";
import { OrganizationsRelationsService } from "@/services/OrganizationsRelationsService";
import { QuickClassifierService } from "@/services/QuickClassifierService";
import { RelationsService } from "@/services/RelationsService";
import * as FileSaver from "file-saver";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

type AdminStateType = {
  selectedFilter: string;
  organizationsRelationsAreLoaded: boolean;
  currentBaseLevel: string;
  gridColumns: Array<any>;
  gridData: Array<QuickClassifierViewModel>;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface QuickClassifiersAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_OrganizationsRelationsAreLoaded" = 0,
  "setup_grid_columns" = 1,
  "change_CurrentBaseLevel" = 2,
  "reload_grid" = 3,
  "change_search_pattern" = 4,
  "change_selectedFilter" = 5,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const QuickClassifiersAdmin = React.forwardRef(
  ({ callBackCommands }: QuickClassifiersAdminInterface, ref) => {
    const componentFileName: string = "QuickClassifiersAdmin.tsx";

    //Function called form parent VerticalTabsAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));

	//#region Init
	//screen loading
	const [isLoadingScreen, setIsLoadingScreen] = useState(true);
	//Screen resources
	const resourceSet: string = 'QuickClassifiersAdminComponent';
	const [titleLabel, setTitleLabel] = useState('');
	const [filterIsActiveLabel, setFilterLabel] = useState('');
	const [organizationsRelationsLabel, setOrganizationsRelationsLabel] = useState('');
	const [refreshLabel, setRefreshLabel] = useState('');
	const [newLabel, setNewLabel] = useState('');
	const [exportLabel, setExportLabel] = useState('');
	const [searchLabel, setSearchLabel] = useState('');
	const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState('');
	const [deleteLabel, setDeleteLabel] = useState('');
	const [updateLabel, setUpdateLabel] = useState('');
	const [cloneLabel, setCloneLabel] = useState('');
  const [yesLabel, setYesLabel] = useState('');
  const [noLabel, setNoLabel] = useState('');

    //organizations/relations filter
    const [organizationsRelationsKeyValue, setOrganizationsRelationsKeyValue] =
      useState<Array<TPKeyValue>>([]);

    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [nameColumnLabel, setNameColumnLabel] = useState("");
    const [
      classificatorBranchColumnLabel1,
      setClassificatorBranchColumnLabel1,
    ] = useState("");
    const [
      classificatorBranchColumnLabel2,
      setClassificatorBranchColumnLabel2,
    ] = useState("");
    const [
      classificatorBranchColumnLabel3,
      setClassificatorBranchColumnLabel3,
    ] = useState("");
    const [
      classificatorBranchColumnLabel4,
      setClassificatorBranchColumnLabel4,
    ] = useState("");
    const [
      classificatorBranchColumnLabel5,
      setClassificatorBranchColumnLabel5,
    ] = useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isSystemRecordColumnLabel, setIsSystemRecordColumnLabel] =
      useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };
    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    //#endregion

    //Load Resources and QuickClassifiers
    const loadResourcesAndQuickClassifiers = async () => {
      //modal
      setDeleteQuestion(
        await TPI18N.GetText(resourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

		//screen
		setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DeleteLabel'));
		setUpdateLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'UpdateLabel'));
		setCloneLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'CloneLabel'));
		setRefreshLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ReloadButton'));
		setNewLabel(await TPI18N.GetText(resourceSet, 'NewButton'));
		setExportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ExportButton'));
		setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'Search'));
		setThereAreNoRecordsToShow(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DataTableNoCurrentData'));

      setTitleLabel(await TPI18N.GetText(resourceSet, "TitleLabel"));
      setOrganizationsRelationsLabel(
        await TPI18N.GetText(resourceSet, "BaseLevel"),
      );
      setFilterLabel(await TPI18N.GetText(resourceSet, "FilterIsActiveLabel"));

      //grid columns
      setIdColumnLabel(await TPI18N.GetText(resourceSet, "Id"));
      setNameColumnLabel(await TPI18N.GetText(resourceSet, "Description"));
      setClassificatorBranchColumnLabel1(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel1"),
      );
      setClassificatorBranchColumnLabel2(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel2"),
      );
      setClassificatorBranchColumnLabel3(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel3"),
      );
      setClassificatorBranchColumnLabel4(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel4"),
      );
      setClassificatorBranchColumnLabel5(
        await TPI18N.GetText(resourceSet, "ClassificatorBranchLabel5"),
      );
      setIsActiveColumnLabel(await TPI18N.GetText(resourceSet, "IsActive"));
      setIsSystemRecordColumnLabel(
        await TPI18N.GetText(resourceSet, "IsSystemRecord"),
      );

      setYesLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveYes"));
      setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "IsActiveNo"));

      //Organizations/Relations
      await getOrganizationsRelationsList();
      let command1: commandType = {
        type: commandsEnum.set_OrganizationsRelationsAreLoaded,
        payload: null,
      };
      dispatchCommand(command1);

      //Filter
      let newFilterKeyValue: Array<TPKeyValue> = [];
      newFilterKeyValue.push({
        key: TPActiveOptions.ALL.toString(),
        value: await TPI18N.GetText(resourceSet, "All"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.ACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Active"),
      });
      newFilterKeyValue.push({
        key: TPActiveOptions.INACTIVE.toString(),
        value: await TPI18N.GetText(resourceSet, "Inactive"),
      });
      setFilterKeyValue(newFilterKeyValue);
    };

    const getRelationsList = async (
      newOrganization: string,
    ): Promise<Array<TPKeyValue>> => {
      let serviceClient = new RelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        let responseRequest =
          await serviceClient.getRelationsByOrganizationAndFilter(
            newOrganization,
            TPActiveOptions.ALL.toString(),
            false,
            false,
            expectedCodes,
          );

        let newRelationsListState: Array<TPKeyValue> = [];
        let relationsListData: Array<RelationsWithRestrictionsViewModel>;
        relationsListData = responseRequest;
        for (i = 0; i <= relationsListData.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: relationsListData[i].baseLevelId,
            value: relationsListData[i].localizedName
              ? relationsListData[i].localizedName
              : relationsListData[i].name,
          };
          newRelationsListState.push(keyvalue);
        }
        return newRelationsListState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getRelationsList ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} getRelationsList ex`);
        return [];
      }
    };

    const getOrganizationsRelationsList = async () => {
      let serviceOrganizationRelationClient =
        new OrganizationsRelationsService();
      let expectedCodes: Array<number> = [200];
      let i: number;
      try {
        setIsLoadingScreen(true);
        let responseRequestOrgRela =
          await serviceOrganizationRelationClient.getOrganizationsRelationsByFilter(
            TPActiveOptions.ALL.toString(),
            false,
            true,
            expectedCodes,
          );

        let newOrganizationsRelationsListState: Array<TPKeyValue> = [];
        for (i = 0; i <= responseRequestOrgRela.length - 1; i++) {
          let keyvalue: TPKeyValue = {
            key: responseRequestOrgRela[i].id,
            value: responseRequestOrgRela[i].localizedDescription,
          };
          newOrganizationsRelationsListState.push(keyvalue);
        }

        let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
        newOrganizationsRelationsListState.unshift(intitialKeyValue);
        setOrganizationsRelationsKeyValue(newOrganizationsRelationsListState);
        setIsLoadingScreen(false);
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(
          `Error ${componentFileName} getOrganizationsRelationsList ex`,
        );
        setIsLoadingScreen(false);
      }
    };

	const setupGridColumns = (prevState: AdminStateType) => {
		try {
			let newState: AdminStateType;
			newState = { ...prevState };
			let newColumns: Array<any> = [];
			//delete
			newColumns.push({
				name: '',
				width: '50px',
				style: { padding: 0 },
				center: true,
				cell: (row: { [x: string]: any }) => {
					if (!row['isSystemRecord']) {
						return (
							<div className="dropdown">
								<TPButton
									id="IdButton"
									dataBsToggle={true}
									type={TPButtonTypes.empty}
									onClick={() => {
										TPGlobal.foo();
									}}
									className={'menu-button'}
								>
									<TPIcon iconType={TPIconTypes.moreVert} />
								</TPButton>
								<ul className="dropdown-menu" >
									<li>
										<a onClick={(id: any) => handleDeleteClick(row['id'])} className="dropdown-item" href="#">
											{deleteLabel}
										</a>
									</li>
									<li>
										<a onClick={(id: any) => handleUpdateClick(row['id'])} className="dropdown-item" href="#">
											{updateLabel}
										</a>
									</li>
									<li>
										<a onClick={(id: any) => handleCloneClick(row['id'])} className="dropdown-item" href="#">
											{cloneLabel}
										</a>
									</li>
								</ul>
							</div>
						);
					} else {
						return false;
					}
				}
			});
			//update
			newColumns.push({
				width: '50px',
				style: { padding: 0 },
				cell: (row: { [x: string]: any }) => {
					if (!row['isSystemRecord']) {
						return (
							<TPButton
								id="IdButton"
								type={TPButtonTypes.primary}
								onClick={(id: string) => handleUpdateClick(row['id'])}
								className="update-button"
							>
								<TPIcon iconType={TPIconTypes.chevronRight} />
							</TPButton>
						);
					} else {
						return null;
					}
				},
				selector: (row: { [x: string]: any }) => row['Id'],
				sortable: true
			});
			//id
			newColumns.push({
				name: idColumnLabel,
				cell: (row: { [x: string]: any }) => {
					return row['id'];
				},
				selector: (row: { [x: string]: any }) => row['id'],
				sortable: true
			});
			//name primary language
			let languageColumnLabel: string;
			languageColumnLabel = nameColumnLabel;
			languageColumnLabel = (languageColumnLabel + ' (' + TPGlobal.TPClientAvailableLanguages[0].name) as string;
			languageColumnLabel = languageColumnLabel + ')';
			newColumns.push({
				width: '250px',
				name: languageColumnLabel,
				selector: (row: { [x: string]: any }) => row['description'],
				sortable: true
			});
			//name current language if it is different
			if (TPGlobal.TPClientAvailableLanguages[0].id !== TPGlobal.language) {
				languageColumnLabel = nameColumnLabel;
				languageColumnLabel = (languageColumnLabel +
					' (' +
					TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)) as string;
				languageColumnLabel = languageColumnLabel + ')';
				newColumns.push({
					width: '250px',
					name: languageColumnLabel,
					selector: (row: { [x: string]: any }) => row['localizedDescription'],
					sortable: true
				});
			}
			//Base Level Description
			newColumns.push({
				width: '300px',
				name: organizationsRelationsLabel,
				cell: (row: { [x: string]: any }) => {
					return row['localizedBaseLevel'];
				},
				selector: (row: { [x: string]: any }) => row['localizedBaseLevel'],
				sortable: true
			});

        //classificator branch1
        newColumns.push({
          width: "150px",
          name: classificatorBranchColumnLabel1,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier1"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier1"],
          sortable: true,
        });
        //classificator branch2
        newColumns.push({
          width: "150px",
          name: classificatorBranchColumnLabel2,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier2"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier2"],
          sortable: true,
        });
        //classificator branch3
        newColumns.push({
          width: "150px",
          name: classificatorBranchColumnLabel3,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier3"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier3"],
          sortable: true,
        });
        //classificator branch4
        newColumns.push({
          width: "150px",
          name: classificatorBranchColumnLabel4,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier4"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier4"],
          sortable: true,
        });
        //classificator branch5
        newColumns.push({
          width: "150px",
          name: classificatorBranchColumnLabel5,
          cell: (row: { [x: string]: any }) => {
            return row["localizedClassifier5"];
          },
          selector: (row: { [x: string]: any }) => row["localizedClassifier5"],
          sortable: true,
        });
        //isActive
        newColumns.push({
          name: isActiveColumnLabel,
          selector: (row: { [x: string]: any }) => row["isActive"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isActive"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //IsSystemRecord
        newColumns.push({
          name: isSystemRecordColumnLabel,
          selector: (row: { [x: string]: any }) => row["isSystemRecord"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isSystemRecord"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    const handleOrganizationRelationChange = async (e: any) => {
      let newBaseLevel: string;
      newBaseLevel = e.target.value;
      let command1: commandType = {
        type: commandsEnum.change_CurrentBaseLevel,
        payload: { newBaseLevel: newBaseLevel },
      };
      dispatchCommand(command1);
    };

    //New quick classifier
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      reloadGridCommand();
    };

    //Get quick classifiers by Filter organizationRelation
    const reloadDataGrid = async (
      baseLevelId: string,
      selectedFilter: string,
    ) => {
      let serviceClient = new QuickClassifierService();
      let expectedCodes: Array<number> = [200, 404];
      try {
        setIsLoadingScreen(true);
        if (baseLevelId !== "") {
          let responseRequest =
            await serviceClient.getQuickClassifiersByBaseLevel(
              baseLevelId,
              false,
              true,
              expectedCodes,
            );
          if (selectedFilter != TPActiveOptions.ALL.toString()) {
            responseRequest = responseRequest.filter((quickClassifier) =>
              selectedFilter == TPActiveOptions.ACTIVE.toString()
                ? quickClassifier.isActive == true
                : quickClassifier.isActive == false,
            );
          }
          setIsLoadingScreen(false);
          return [...responseRequest];
        } else {
          let responseRequest = await serviceClient.getQuickClassifierByFilter(
            selectedFilter,
            false,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          return [...responseRequest];
        }
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.currentBaseLevel, adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //Update quick classifier
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

	const handleCloneClick = (id: string) => {
		let command: any = { command: 'clone', recordId: id };
		callBackCommands(command);
	};

	//Modal Question to delete quick classifier
	const handleDeleteClick = (id: string) => {
		let newModalQuestionState: TPModalQuestionState;
		newModalQuestionState = { ...modalQuestionState };
		newModalQuestionState.isShown = true;
		newModalQuestionState.callBackData = { recordId: id };
		setModalQuestionState(newModalQuestionState);
	};

    //Delete QuickClassifier after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new QuickClassifierService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.deleteQuickClassifierById(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "description",
        "localizedDescription",
        "localizedBaseLevel",
        "localizedClassifier1",
        "localizedClassifier2",
        "localizedClassifier3",
        "localizedClassifier4",
        "localizedClassifier5",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item, index) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    const exportToCSV = (
      apiData = filteredData(),
      fileName = "quick-classifiers-admin-data",
    ) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(ws, [[]], { origin: "A1" });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      organizationsRelationsAreLoaded: false,
      currentBaseLevel: "",
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };

    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );
    function doCommand(prevState: AdminStateType, command: commandType) {
      let newAdminState: AdminStateType;
      switch (command.type) {
        case commandsEnum.set_OrganizationsRelationsAreLoaded:
          newAdminState = { ...prevState };
          newAdminState.organizationsRelationsAreLoaded = true;
          return newAdminState;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.change_CurrentBaseLevel:
          newAdminState = { ...prevState };
          newAdminState.currentBaseLevel = command.payload.newBaseLevel;
          return newAdminState;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndQuickClassifiers();
    }, []);

    //Run when organizations/relations are loaded to get columns
    useEffect(() => {
      if (adminState.organizationsRelationsAreLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.organizationsRelationsAreLoaded]);

    //Run to populate grid columns when columns are loaded or
    //user change organization/relation
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [
      adminState.columnsAreLoaded,
      adminState.currentBaseLevel,
      adminState.selectedFilter,
    ]);

    const getHideenColumns = () : (keyof QuickClassifierViewModel)[] => {
      const hiddenColumns: (keyof QuickClassifierViewModel)[] = [
        'baseLevelId','caseComments','classifier1Id','classifier2Id',
        'classifier3Id','classifier4Id','classifier5Id','emailTemplateHeaderId',
        'localizedHierarchyClassifier1','localizedHierarchyClassifier2',
        'localizedHierarchyClassifier3','localizedHierarchyClassifier4',
        'localizedHierarchyClassifier5','type'
      ]
      if (TPGlobal.TPClientAvailableLanguages[0].id === TPGlobal.language) hiddenColumns.push('localizedDescription');
      return hiddenColumns
    }

    return (
      <>
        <TPModalQuestion
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          <div className="row">
            <div className="col">
              {/* <TPPageTitle>{titleLabel}</TPPageTitle>
              <hr /> */}
              <CIMTitleSection>
                <TPPageTitle style={{ margin: "0" }}>{titleLabel}</TPPageTitle>
                <TPButton
                  id="new-quickclassifier-type"
                  isDesignSystem={true}
                  onClick={handleNewClick}
                  withIcon={TPIconTypes.add}
                  orientationIcon="left"
                  style={{ padding: "1px 18px" }}
                >
                  {newLabel}
                </TPButton>
              </CIMTitleSection>
              <TPPageFirstRow>
                <TPFilterAndSearch style={{ width: "60%" }}>
                  <TPPageFilterContainer>
                    <TPSelect
                      id="quick-classifier-admin-client-service-select"
                      onChange={handleOrganizationRelationChange}
                      dataSource={organizationsRelationsKeyValue}
                      value={adminState.currentBaseLevel}
                      labelText={organizationsRelationsLabel}
                      isHorizontal
                    />
                    <TPSelect
                      id="quick-classifier-admin-filter"
                      onChange={handleFilterChange}
                      dataSource={filterKeyValue}
                      value={adminState.selectedFilter}
                      labelText={filterIsActiveLabel}
                      isHorizontal
                    />
                  </TPPageFilterContainer>
                </TPFilterAndSearch>
              </TPPageFirstRow>
              <DynamicTable
                data={filteredData().map(entry => {
                  return {
                    id: entry.id || '',
                    description: entry.description || '',
                    localizedDescription: entry.localizedDescription || '',
                    localizedBaseLevel: entry.localizedBaseLevel || '',
                    localizedClassifier1: entry.localizedClassifier1 || '',
                    localizedClassifier2: entry.localizedClassifier2 || '',
                    localizedClassifier3: entry.localizedClassifier3 || '',
                    localizedClassifier4: entry.localizedClassifier4 || '',
                    localizedClassifier5: entry.localizedClassifier5 || '',
                    isActive: entry.isActive,
                    isSystemRecord: entry.isSystemRecord
                  } as QuickClassifierViewModel
                })}
                uppercaseActions
                hiddenColumns={getHideenColumns()}
                columnNames={{
                  id: idColumnLabel.toUpperCase(),
                  description: (nameColumnLabel + ` (${TPGlobal.TPClientAvailableLanguages[0].name})`).toUpperCase(),
                  localizedDescription: (nameColumnLabel + ` (${TPGlobal.getLanguageDescriptionByCode(TPGlobal.language)})`).toUpperCase(),
                  localizedBaseLevel: organizationsRelationsLabel.toUpperCase(),
                  localizedClassifier1: classificatorBranchColumnLabel1.toUpperCase(),
                  localizedClassifier2: classificatorBranchColumnLabel2.toUpperCase(),
                  localizedClassifier3: classificatorBranchColumnLabel3.toUpperCase(),
                  localizedClassifier4: classificatorBranchColumnLabel4.toUpperCase(),
                  localizedClassifier5: classificatorBranchColumnLabel5.toUpperCase(),
                  isActive: isActiveColumnLabel.toUpperCase(),
                  isSystemRecord: isSystemRecordColumnLabel.toUpperCase()
                }}
                minorOptions={[
                  {
                    key: deleteLabel,
                    onOptionChange: (e) => handleDeleteClick(e.id),
                    type: 'delete',
                    icon: TPIconTypes.delete
                  },
                  {
                    key: cloneLabel,
                    onOptionChange: (e) => handleCloneClick(e.id),
                    type: 'clone',
                    icon: TPIconTypes.clone
                  },
                ]}
                columnStyles={{
                  id: (entry, value) => (
                    <button
                      type="button"
                      onClick={() => handleUpdateClick(entry.item.id)}
                      style={{background:'none', border:'none', color:"purple"}}
                    >
                      <b><u >{entry.item.id}</u></b>
                    </button>
                  ),
                  isActive: (entry, value) => (
                    <TPChip
                      backgroundColor={entry.item.isActive ? '#B1F2D7' : '#FFD7DD'}
                      label={entry.item.isActive ? yesLabel : noLabel}
                    />
                  ),
                  isSystemRecord: (entry, value) => (
                    <TPChip
                      backgroundColor={entry.item.isSystemRecord ? '#B1F2D7' : '#FFD7DD'}
                      label={entry.item.isSystemRecord ? yesLabel : noLabel}
                    />
                  ),
                }}
                hideExport
                icons={[
                  // {
                  //   status: true,
                  //   type: TPIconTypes.newEntity,
                  //   tooltip: newLabel
                  // },
                  {
                    status: true,
                    type: TPIconTypes.excel,
                    tooltip: exportLabel
                  }
                ]}
                onIconClicked={(icon) => {
                  if (icon == TPIconTypes.excel || icon == TPIconTypes.download) exportToCSV();
                  // if (icon == TPIconTypes.newEntity) handleNewClick();
                  if (icon == TPIconTypes.loop || icon == TPIconTypes.refresh) handleRefreshClick();
                }}
                searchPosition="right"
              />
            </div>
          </div>
        </TPLoadingOverlay>
      </>
    );
  },
);

export default QuickClassifiersAdmin;
