import { v4 as uuidv4 } from "uuid";
import { Sa } from "@/models/Global/TPGlobalModels";
import * as yup from "yup";
import { CSSProperties } from "react";

/**
 * properties that the child component needs
 */
export interface CustomStateProps {
  /**
   * workflow definition identifier
   */
  workflowDefinitionId: string;
}

/**
 * child component events
 */
export interface CustomStateEvents {
  /**
   * allows you to reload the table detail data
   */
  load: () => void;
}

/**
 * custom state
 */
export enum CustomStateFocus {
  /**
   * view list mode
   */
  LIST = "LIST",
  /**
   * view new mode
   */
  NEW = "NEW",
  /**
   * view edit mode
   */
  UPDATE = "UPDATE",
  /**
   * view clone mode
   */
  CLONE = "CLONE",
}

/**
 * custom state
 */
export interface CustomStatePage {
  /**
   * identifier
   */
  id: string;
  /**
   * type
   */
  type: CustomStateFocus;
  /**
   * focus
   */
  focus: boolean;
  /**
   * element
   */
  element?: any;
}

/**
 * custom state
 */
export interface CustomStateState {
  /**
   * pages
   */
  pages: Array<CustomStatePage>;
}

/**
 * custom state initial state value
 */
export const customStateInitialStateValue: CustomStateState = {
  pages: [
    {
      id: uuidv4(),
      type: CustomStateFocus.LIST,
      focus: true,
    },
  ],
};

export enum CustomStateCommandType {
  /**
   * redirect to list
   */
  redirectToList = "redirectToList",
  /**
   * redirect to new
   */
  redirectToNew = "redirectToNew",
  /**
   * redirect to update
   */
  redirectToUpdate = "redirectToUpdate",
  /**
   * redirect to clone
   */
  redirectToClone = "redirectToClone",
  /**
   * refresh list
   */
  refreshList = "refreshList",
}

/**
 * custom state command type
 */
export type CustomStateCommandAction = {
  type: CustomStateCommandType;
  payload?: any;
};

/**
 * properties that the child component needs
 */
export interface CustomStateAdminProps {
  /**
   * key of the component
   */
  key?: string;
  /**
   * identifier of the component
   */
  id?: string;
  /**
   * styles
   */
  style?: CSSProperties;
  /**
   * workflow definition identifier
   */
  workflowDefinitionId: string;
  /**
   * dispatch function
   */
  pageDispatch: (action: CustomStateCommandAction) => void;
}

/**
 * child component events
 */
export interface CustomStateAdminEvents {
  /**
   * allows you to reload the table detail data
   */
  load: () => void;
}

export interface CustomStateAdminTableProps {
  state: CustomStateListState;
  pageDispatch: (action: CustomStateCommandAction) => void;
  loadData: () => void;
}

export interface CustomStateAdminTableEvents {
  load: () => void;
}

/**
 * custom state list enum
 */
export enum CustomStateAdminEnum {
  /**
   * key word
   */
  CustomStateAdmin = "CustomStateAdmin",

  /**
   * file name component
   */
  CustomStateAdminFilename = "CustomStateAdminFilename.tsx",

  /**
   * component name
   */
  CustomStateAdminComponent = "CustomStateAdminComponent",

  /**
   * COMPONENT LABELS
   */

  /**
   * title label
   */
  TitleLabel = "TitleLabel",

  /**
   * new button label
   */
  NewButtonLabel = "NewButtonLabel",

  /**
   * search input label
   */
  SearchInputLabel = "Search",

  /**
   * export button label
   */
  ExportButtonLabel = "Export",

  /**
   * refresh button label
   */
  RefreshButtonLabel = "Refresh",

  /**
   * actions table column label
   */
  ActionsTableColumnLabel = "ActionsTableColumnLabel",

  /**
   * delete action label
   */
  DeleteActionLabel = "DeleteLabel",

  /**
   * update action label
   */
  UpdateActionLabel = "UpdateLabel",

  /**
   * clone action label
   */
  CloneActionLabel = "CloneLabel",

  /**
   * id table column label
   */
  IdTableColumnLabel = "IdTableColumnLabel",

  /**
   * id table column label
   */
  OrderTableColumnLabel = "OrderTableColumnLabel",

  /**
   * name table column label
   */
  BaseField1TableColumnLabel = "BaseField1TableColumnLabel",

  /**
   * structure type table column label
   */
  ReferenceValue1TableColumnLabel = "ReferenceValue1TableColumnLabel",

  /**
   * queues table column label
   */
  BaseField2TableColumnLabel = "BaseField2TableColumnLabel",

  /**
   * active table column label
   */
  CustomStateTableColumnLabel = "CustomStateTableColumnLabel",

  /**
   * active table column label
   */
  WeightTableColumnLabel = "WeightTableColumnLabel",

  /**
   * MODAL COMPONENT LABELS
   */

  /**
   * title modal label
   */
  titleModalLabel = "ConfirmTitle",

  /**
   * yes modal button label
   */
  YesModalButtonLabel = "OkButton",

  /**
   * no modal button label
   */
  NoModalButtonLabel = "CancelButton",

  /**
   * question modal label
   */
  QuestionModalLabel = "QuestionModalLabel",
}

/**
 * custom state list state event
 */
export enum CustomStateListStateEvent {
  getMessage = "CustomStateList/getMessage/loading",
  getMessageSuccess = "CustomStateList/getMessage/success",
  getMessageError = "CustomStateList/getMessage/error",
  find = "CustomStateList/find/loading",
  findSuccess = "CustomStateList/find/success",
  findError = "CustomStateList/find/error",
}

export enum CustomStateComparator {
  EQUAL = "=",
  DIFFERENT = "<>",
}

/**
 * custom state model
 */
export interface CustomStateModel {
  id: string;
  workFlowTypeId: string;
  customStateType?: string | null;
  order?: string | null;
  nextState: string | null;
  customStateDescription: string | null;
  baseField1: string | null;
  baseField1Description?: string | null;
  referenceValue1: string | null;
  referenceValue1Description: string | null;
  comparison1?: string | null;
  baseField2?: string | null;
  baseField2Description?: string | null;
  referenceValue2?: string | null;
  referenceValue2Description?: string | null;
  comparison2?: CustomStateComparator | null;
  comparison2Description?: string | null;
  weight: number | null;
}

/**
 * custom state model
 */
export interface CustomStateListModel {
  customStateFlowId: string,
  workFlowTypeId: string,
  workFlowDetailId: number,
  order: string,
  taskTypeId: string,
  taskDescription: string,
  caseStatus: string,
  taskStatus: string,
  customStateTypeId: string,
  customStateTypeDescription: string,
  weight: number,
}

/**
 * custom state new and update model
 */
export interface CustomStateNewModel {
  id: string | null;
  workFlowTypeId: string | null;
  nextState: string | null;
  baseField1: string | null;
  referenceValue1: string | null;
  baseField2?: string | null;
  referenceValue2?: string | null;
  comparison2?: CustomStateComparator | null;
  weight: number | null;

  // These attributes do not belong to the original model
  customStateType?: string | null;
  baseField1Description?: string | null;
  referenceValue1Description?: string | null;
  comparison1?: string | null;
  baseField2Description?: string | null;
  referenceValue2Description?: string | null;
  comparison2Description?: string | null;
}

/**
 * custom state clone model
 */
export interface CustomStateCloneModel {
  sourceId: string | null;
  id: string | null;
  weight: number | null;
}

export type CustomStateListState = {
  messages: { [attribute: string]: Sa<string> };
  datasource: Sa<Array<CustomStateListModel>>;
};

export const customStateListInitialState: CustomStateListState = {
  messages: {},
  datasource: new Sa<Array<CustomStateListModel>>({ value: [] }),
};

/**
 * custom state new enum
 */
export enum CustomStateServiceEnum {
  CustomStateFilePath = "src/service/CustomState/CustomStateService.tsx",
}

/**
 * custom state new
 */

/**
 * custom state new props
 */
export interface CustomStateNewProps {
  workflowDefinitionId?: string;
  element?: CustomStateListModel;
  pageDispatch: (action: CustomStateCommandAction) => void;
}

/**
 * child component events
 */
export interface CustomStateNewEvents {
  /**
   * allows you to reload the table detail data
   */
  load: () => void;
}

/**
 * custom state new enum
 */
export enum CustomStateNewEnum {
  /**
   * key word
   */
  CustomStateNew = "CustomStateNew",

  /**
   * file name component
   */
  CustomStateNewFilename = "CustomStateNew.tsx",

  /**
   * component name
   */
  CustomStateNewComponent = "CustomStateNewComponent",

  /**
   * COMPONENT LABELS
   */

  /**
   * title label
   */
  TitleLabel = "TitleLabel",

  /**
   * update title label
   */
  UpdateTitleLabel = "UpdateTitleLabel",

  /**
   * form
   */

  /**
   * form input id label
   */
  FormInputIdLabel = "FormInputIdLabel",

  /**
   * form input name label
   */
  FormInputNameLabel = "FormInputNameLabel",

  /**
   * form input structure type label
   */
  FormSelectCustomStateTypeIdLabel = "FormSelectCustomStateTypeIdLabel",

  /**
   * form input supervisor label
   */
  FormSelectCustomStateLabel = "FormSelectCustomStateLabel",

  /**
   * form input supervisor label
   */
  FormSelectBaseField1Label = "FormSelectBaseField1Label",

  /**
   * form input supervisor label
   */
  FormSelectReferenceValue1Label = "FormSelectReferenceValue1Label",

  /**
   * form input supervisor label
   */
  FormSelectBaseField2Label = "FormSelectBaseField2Label",

  /**
   * form input supervisor label
   */
  FormSelectReferenceValue2Label = "FormSelectReferenceValue2Label",

  /**
   * form input supervisor label
   */
  FormSelectComparisonOperatorLabel = "FormSelectComparisonOperatorLabel",

  /**
   * form input supervisor label
   */
  FormInputWeightLabel = "FormInputWeightLabel",

  /**
   * required field label
   */
  RequiredFieldLabel = "RequiredFieldLabel",

  /**
   * maximum 20 characters allowed label
   */
  Maximum20CharactersAllowedLabel = "Maximum20CharactersAllowedLabel",

  /**
   * form actions label
   */

  /**
   * form action cancel label
   */
  FormActionCancelLabel = "CancelButton",

  /**
   * form action accept label
   */
  FormActionSaveLabel = "SaveButton",

  /**
   * form action accept label
   */
  TaskStatus = "BaseFieldType_TASKSTATUS",
}

/**
 * custom state new control
 */
export const CustomStateNewControl = {
  initialValues: {
    id: null,
    workFlowTypeId: null,
    customStateTypeId: null,
    nextState: null,
    baseField1: null,
    referenceValue1: null,
    baseField2: null,
    comparison2: null,
    referenceValue2: null,
    weight: null,
  } as CustomStateNewModel,
  validationSchema: yup.object({
    id: yup.string().required(CustomStateNewEnum.RequiredFieldLabel),
    workflowDefinitionId: yup
      .string()
      .required(CustomStateNewEnum.RequiredFieldLabel),
    customStateTypeId: yup
      .string()
      .required(CustomStateNewEnum.RequiredFieldLabel),
    customStateId: yup.string().required(CustomStateNewEnum.RequiredFieldLabel),
    baseField1Id: yup.string().required(CustomStateNewEnum.RequiredFieldLabel),
    referenceValue1Id: yup
      .string()
      .required(CustomStateNewEnum.RequiredFieldLabel),
    weight: yup.number().required(CustomStateNewEnum.RequiredFieldLabel),
  }),
};

/**
 * custom state insert or update form props
 */
export interface CustomStateInsertUpdateFormProps {
  /**
   * messages
   */
  m: { [attribute: string]: string };
  /**
   * mode
   */
  mode: "insert" | "update";
  /**
   * 
   */
  element: CustomStateNewModel;
  /**
   * on change event
   */
  onChange: (element: CustomStateNewModel) => void;
}

/**
 * child component events
 */
export interface CustomStateNewTableEvents {
  /**
   * allows you to reload the table detail data
   */
  load: () => void;
}

/**
 * custom state clone enum
 */
export enum CustomStateCloneEnum {
  /**
   * key word
   */
  CustomStateClone = "CustomStateClone",

  /**
   * file name component
   */
  CustomStateCloneFilename = "CustomStateClone.tsx",

  /**
   * component name
   */
  CustomStateCloneComponent = "CustomStateCloneComponent",

  /**
   * COMPONENT LABELS
   */

  /**
   * title label
   */
  TitleLabel = "TitleLabel",

  /**
   * description label
   */
  DescriptionLabel = "DescriptionLabel",

  /**
   * form
   */

  /**
   * form input source id label
   */
  FormInputSourceIdLabel = "FormInputSourceIdLabel",

  /**
   * form input id label
   */
  FormInputIdLabel = "FormInputIdLabel",

  /**
   * form input name label
   */
  FormInputNameLabel = "FormInputNameLabel",

  /**
   * form input active label
   */
  FormInputActiveLabel = "FormInputActiveLabel",

  /**
   * required field label
   */
  RequiredFieldLabel = "RequiredFieldLabel",

  /**
   * maximum 20 characters allowed label
   */
  Maximum20CharactersAllowedLabel = "Maximum20CharactersAllowedLabel",

  /**
   * form actions label
   */

  /**
   * form action cancel label
   */
  FormActionCancelLabel = "CancelButton",

  /**
   * form action accept label
   */
  FormActionNewProjectLabel = "FormActionNewProjectLabel",

  /**
   * MODAL COMPONENT LABELS
   */

  /**
   * language IconButton tooltip
   */
  LanguageButtonTooltipLabel = "LanguageButtonTooltipLabel",

  /**
   * modal title label
   */
  LanguageModalTitleLabel = "LanguageModalTitleLabel",

  /**
   * save label when clicking the save button
   */
  LanguageModalOkButtonLabel = "OkButton",

  /**
   * cancel label when clicking the cancel button
   */
  LanguageModalCancelButtonLabel = "CancelButton",
}

/**
 * custom state clone control
 */
export const CustomStateCloneControl = {
  initialValues: {
    sourceId: null,
    id: null,
    weight: null,
  } as CustomStateCloneModel,
  validationSchema: yup.object({
    sourceId: yup.string().required(CustomStateNewEnum.RequiredFieldLabel),
    id: yup.string().required(CustomStateNewEnum.RequiredFieldLabel),
    weight: yup.number().required(CustomStateNewEnum.RequiredFieldLabel),
  }),
};
