import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import { TPPageAcceptCancelButtonsContainer, TPPageSection } from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { SequenceGeneratorSequencesNameEnum, SystemParametersEnum, TPActiveOptions, TPButtonTypes, TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { StructureAdminInputDTO } from "@/models/StructureAdmin/StructureAdminInputDTO";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { ParametersService } from "@/services/ParametersService";
import { SequenceService } from "@/services/SequenceService";
import { StructureService } from "@/services/StructureService";
import { FC, useEffect, useState } from "react";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    callBackResult: Function;
};

enum whenCustomerExistsEnum {
    ignoreData = "IGNOREDATA",
    addNewData = "ADDNEWDATAANDUPDATEEXISTS",
    onlyaddNewData = "ONLYADDNEWDATA",
}

type InsertUpdateStateType = {
    id: string,
    recordLanguageList: Array<TPKeyValue>;
}

const StructureAdminUpdateInsert: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult
}) => {
    const componentFileName: string = "StructureAdminUpdateInsert.tsx";

    const resourceSet: string = "StructureAdminClone";

    const [isLoading, setIsLoading] = useState(false);

    const [realMode, setRealMode] = useState(mode);
    const [realRecordId, setRealRecordId] = useState(recordId);

    const [structureIdLabel, setStructureIdLabel] = useState("");
    const [structureNameLabel, setStructureNameLabel] = useState("");

    const [structureNameValue, setStuctureNameValue] = useState("");
    const [typeValue, setTypeValue] = useState("--");
    const [referenceId, setReferenceId] = useState("");

    const [titleLabel, setTitleLabel] = useState("");
    const [toastInfo, setToastInfo] = useState("");
    const [structureTypeLabel, setStructureTypeLabel] = useState("");
    const [ignoreDataLabel, setIgnoreDataLabel] = useState("");
    const [addNewDataLabel, setAddNewDataLabel] = useState("");
    const [whenCustomerExistsLabel, setWhenCustomerExistsLabel] = useState("");
    const [showAsActiveLabel, setShowAsActiveLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");

    const [structureNameErrorMessage, setStructureNameErrorMessage] = useState("");
    const [typeErrorMessage, setTypeErrorMessage] = useState("");

    const [showAsActive, setShowAsActive] = useState(true);

    const loadResourcesAndLoadStructureInfo = async () => {

        setStructureIdLabel(await TPI18N.GetText(resourceSet, "StructureIdLabel"));
        setStructureNameLabel(await TPI18N.GetText(resourceSet, "StructureNameLabel"));
        setTitleLabel(await TPI18N.GetText(resourceSet, "InsertTitleLabel"));
        setToastInfo(await TPI18N.GetText(resourceSet, "ToastInfo"));
        setStructureTypeLabel(await TPI18N.GetText(resourceSet, "StructureTypeLabel"));
        setIgnoreDataLabel(await TPI18N.GetText(resourceSet, "IgnoreDataLabel"));
        setAddNewDataLabel(await TPI18N.GetText(resourceSet, "AddNewDataLabel"));
        setWhenCustomerExistsLabel(await TPI18N.GetText(resourceSet, "WhenCustomerExistsLabel"));
        setShowAsActiveLabel(await TPI18N.GetText(resourceSet, "ShowAsActiveLabel"));
        setSaveButtonLabel(await TPI18N.GetText(resourceSet, "SaveButtonLabel"));
        setCancelButtonLabel(await TPI18N.GetText(resourceSet, "CancelButtonLabel"));
        setLanguageListLabel(await TPI18N.GetText(resourceSet, "LanguageListLabel"));

        if (realMode === "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (let i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }

            setInsertUpdateState(newInsertUpdateState);
        } else if (realMode === "Update") {

            setTitleLabel(await TPI18N.GetText(resourceSet, "UpdateTitleLabel"));
            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            for (let i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }



            let info = await getStructureInfo();

            newInsertUpdateState.id = info.id
            newInsertUpdateState.recordLanguageList[0].value = info.localizedDescription;
            setInsertUpdateState(newInsertUpdateState);

            setTypeValue(info.typeIdParam);
            setShowAsActive(info?.isActive);

            let newCustomerExistPropsState = { ...customerExistPropsState };
            newCustomerExistPropsState.type = info?.actionWhenClientExists;

            let languageDescriptions = await getLanguageDescriptions(info.id);

            if (languageDescriptions) {
                for (let i = 1; i <= languageDescriptions.length - 1; i++) {
                    newInsertUpdateState.recordLanguageList[i].value = languageDescriptions[i].recordDescription;
                }
            }

            setCustomerExistPropsState(newCustomerExistPropsState);

            setIsLoading(false);

        }

        getStructureTypeList();
    }

    const [structureTypes, setStructureTypes] = useState<Array<any>>([])

    const getStructureTypeList = async () => {
        let parametersService = new ParametersService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await parametersService.getByParentIdAndFilterIsActive(
                SystemParametersEnum.STRUCTURETYPEPARENT,
                TPActiveOptions.ACTIVE.toString(),
                false,
                true,
                expectedCodes
            );
            if (responseRequest) {
                let structureTypeList = responseRequest.map(function (item) {
                    return { key: item.id, value: item.localizedDescription };
                })
                structureTypeList.unshift({ key: "", value: "--" });
                setStructureTypes(structureTypeList);
            } else {
                return null;
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getStructureTypeList ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getStructureTypeList ex`);
        }
    }

    let customerExistsList = [
        {
            key: whenCustomerExistsEnum.ignoreData,
            value: ignoreDataLabel
        },
        {
            key: whenCustomerExistsEnum.addNewData,
            value: addNewDataLabel
        }
    ]

    const getLanguageDescriptions = async (id: string) => {
        let serviceMultilanguageFieldValues = new MultilanguageFieldValueService();
        let expectedCodes: Array<number> = [200];

        try {
            let responseRequest = await serviceMultilanguageFieldValues.getTPClientUIByTableAndRecordIdAndFieldName(
                "EVentLoadStructure",
                "Description_EVLS",
                id,
                false,
                true,
                expectedCodes
            )

            return responseRequest;

        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getLanguageDescriptions ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getLanguageDescriptions ex`);
        }
    }

    const getStructureInfo = async () => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);
            let responseRequest = await serviceClient.getStructureById(
                realRecordId,
                false,
                true,
                expectedCodes
            )

            return responseRequest[0];
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getStructureInfo ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} getStructureInfo ex`);
        }
    }

    const generateAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQEVLS
            )

            setIsLoading(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} generateAutomaticId ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} generateAutomaticId ex`);
        }
    }

    const insertStructure = async (inputDTO: any) => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.insertStructure(inputDTO, true, true, expectedCodes);

            console.log(responseRequest.keyList[0].value);

            callBackResult({
                result: "clone_email_success",
                recordId: inputDTO.id,
            });

        } catch (error) {
            TPLog.Log(`Error ${componentFileName} insertEmailTemplate ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} insertEmailTemplate ex`);
            setIsLoading(false);
        }
    }

    const updateStructure = async (inputDTO: any) => {
        let serviceClient = new StructureService();
        let expectedCodes: Array<number> = [200];

        try {
            setIsLoading(true);

            let responseRequest = await serviceClient.updateStructure(inputDTO, true, true, expectedCodes);

            setIsLoading(false);

            callBackResult({
                result: 'save_general_update',
                recordId: inputDTO.id
            });
            return responseRequest;
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} update ex`);
            setIsLoading(false);
        }
    }

    const handleOnOkButtonClick = async () => {
        let i: number;
        let n: number;
        let atLeastOneError = false;

        let RecordInputDTO: StructureAdminInputDTO = {
            id: realMode === 'Insert' ? referenceId : insertUpdateState.id,
            description: insertUpdateState.recordLanguageList[0].value,
            typeIdParam: typeValue,
            actionWhenClientExists: customerExistPropsState.type,
            typistIdUser: TPGlobal.currentUserGuid,
            isActive: showAsActive,
            descriptionLocalizedValues: []
        }
        n = insertUpdateState.recordLanguageList.length;
        for (i = 0; i < n; i++) {
            let item: TPKeyValue;
            item = insertUpdateState.recordLanguageList[i];
            RecordInputDTO.descriptionLocalizedValues.push({
                order: i + 1,
                languageId: item.key,
                localizedValue: item.value
            });
        }

        if (insertUpdateState.recordLanguageList[0].value === "") {
            atLeastOneError = true;
            setStructureNameErrorMessage(await TPI18N.GetText(resourceSet, "StructureNameErrorMessage"));
        }

        if (typeValue === "--") {
            atLeastOneError = true;
            setTypeErrorMessage(await TPI18N.GetText(resourceSet, "StructureTypeErrorMessage"));
        }


        if (!atLeastOneError) {
            if (realMode === "Insert" || realMode === "CloneEmail") {
                await insertStructure(RecordInputDTO);
            } else {
                await updateStructure(RecordInputDTO);
            }
        }

    }

    let customerExistPropsInitialState = {
        type: "IGNOREDATA",
    }

    const [customerExistPropsState, setCustomerExistPropsState] = useState(customerExistPropsInitialState);

    let insertUpdateInitialState: InsertUpdateStateType = {
        id: "",
        recordLanguageList: []
    }

    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState
    );

    const handleStructureNameChange = (event: any) => {
        setStuctureNameValue(event.target.value);
    }

    const handleStructureTypeChange = (event: any) => {
        setTypeValue(event.target.value);
    }

    const handleCustomerExistTypeChange = (event: any) => {
        let newCustomerExistPropsState = { ...customerExistPropsState };
        newCustomerExistPropsState.type = event.target.value;
        setCustomerExistPropsState(newCustomerExistPropsState);
    }

    const handleSetAsActive = (event: any) => {
        setShowAsActive(!showAsActive);
    }

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        setInsertUpdateState(newInsertUpdateState);
        setStructureNameErrorMessage("");
    };

    useEffect(() => {
        loadResourcesAndLoadStructureInfo();
        generateAutomaticId();
    }, []);

    const handleCancelButtonClick = () => {
        callBackResult({ result: 'cancel_general', recordId: realRecordId });
    };


    return (
        <TPLoadingOverlay active={isLoading}>
            <div className="row" style={{ marginBottom: "10px" }}>
                <div className="col-10">
                    <TPPageSection>
                        <div className="row" style={{ marginBottom: "10px" }}>
                            <div className="col-8">
                                <div className="form-group">
                                    <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={true}
                                        disabled
                                        labelText={structureIdLabel}
                                        value={realMode === "Insert" ? referenceId : realRecordId}
                                        onChange={(e: any) => setReferenceId(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "10px" }}>
                            {insertUpdateState.recordLanguageList.length > 0 &&
                                TPGlobal.TPClientAvailableLanguages.map(
                                    (item, index) =>
                                        index === 0 && (
                                            <>
                                                <div className="col-8" key={"languageItem" + item.id}>
                                                    <div className="form-group">
                                                        <TPTextBox
                                                            id="IdTextBox"
                                                            isMandatory={index === 0}
                                                            labelText={`${structureNameLabel} (${item.name})`}
                                                            value={insertUpdateState.recordLanguageList[index].value}
                                                            onChange={(e: any) =>
                                                                handleLanguageChange(index, e.target.value)
                                                            }
                                                            maxLength={100}
                                                            errorMessage={structureNameErrorMessage}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                )}
                            <div className="col-4">
                                <div className="pt-4">
                                    <TPButton
                                        id="IdButton"
                                        type={TPButtonTypes.icon}
                                        icon={TPIconTypes.language}
                                        text={`+${insertUpdateState.recordLanguageList.length - 1
                                            }`}
                                        tooltip={languageListLabel}
                                        className={"pt-3"}
                                        onClick={handleOpenModalLanguageList}
                                    />
                                </div>
                            </div>
                            <div style={{ zIndex: 2 }}>
                                <TPModalLanguageList
                                    isOpen={isOpenModalLanguageList}
                                    title={languageListLabel}
                                    acceptLabel={saveButtonLabel}
                                    cancelLabel={cancelButtonLabel}
                                    saveChanges={handleSaveChangesModalLanguageList}
                                    closeModal={handleCloseModalLanguageList}
                                >
                                    <div
                                        className="row overflow-auto"
                                        style={{ height: "200px" }}
                                    >
                                        {TPGlobal.TPClientAvailableLanguages.map(
                                            (item, index) =>
                                                index > 0 && (
                                                    <div
                                                        className="col-12"
                                                        key={`languageItem-${item.id}`}
                                                    >
                                                        <div className="form-group">
                                                            <TPTextBox
                                                                id="IdTextBox"
                                                                isMandatory={index === 0}
                                                                labelText={`${structureNameLabel} (${item.name})`}
                                                                value={insertUpdateState.recordLanguageList[index]?.value}
                                                                onChange={(e: any) => handleLanguageChange(index, e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                        )
                                        }
                                    </div>
                                </TPModalLanguageList>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPSelect
                                        isMandatory={true}
                                        dataSource={structureTypes.map((item) => ({ key: item.key, value: item.value } as TPKeyValue))}
                                        value={typeValue}
                                        labelText={structureTypeLabel}
                                        onChange={handleStructureTypeChange}
                                        errorMessage={typeErrorMessage}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <TPRadioGroup
                                        id="IdRadioGroup"
                                        value={customerExistPropsState.type}
                                        onChange={(e: any) => handleCustomerExistTypeChange(e)}
                                        source={customerExistsList}
                                        labelText={whenCustomerExistsLabel}
                                    />
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col-8">
                                <div className="form-group">
                                    <TPCheckBox
                                        id="IdCheckBox"
                                        labelText={showAsActiveLabel}
                                        checked={showAsActive}
                                        onChange={handleSetAsActive}
                                    />
                                </div>
                            </div>
                        </div>
                    </TPPageSection>
                </div>
            </div>
            <div className="col-7">
                    <TPPageAcceptCancelButtonsContainer>
                        <TPButton
                            id="IdButton"
                            type={TPButtonTypes.primary}
                            onClick={() => handleOnOkButtonClick()}
                        >
                            {saveButtonLabel}
                        </TPButton>
                        <TPButton
                            id="IdButton"
                            type={TPButtonTypes.link}
                            onClick={() => handleCancelButtonClick()}
                            className={"ms-2"}
                        >
                            {cancelButtonLabel}
                        </TPButton>
                    </TPPageAcceptCancelButtonsContainer>
                </div>
        </TPLoadingOverlay>
    );
}

export default StructureAdminUpdateInsert;

