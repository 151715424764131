import UserProfile from "@/modules/core/design-system/user-data/UserProfile";
import { TPI18N } from "@/services/I18nService";
import { FC, ReactElement, useState } from "react";
import { StyledRankingUserCard } from "../supervisor-cockpit-styles";

type CockpitRankingUserProps = {
  name: string;
  time: string;
  cases: number;
  position: number;
};

const CockpitRankingUser: FC<CockpitRankingUserProps> = ({
  name,
  time,
  cases,
  position,
}): ReactElement => {
  const component: string = "CockpitRankingUser";

  const [averageLabel, setAverageLabel] = useState<string>(
    "Average handle time"
  );
  const [casesLabel, setCasesLabel] = useState<string>("Managed cases");

  const loadUtilsResources = async () => {
    setAverageLabel(await TPI18N.GetText(component, "Average"));
    setCasesLabel(await TPI18N.GetText(component, "Cases"));
  };

  return (
    <StyledRankingUserCard>
      <UserProfile
        completeName={name}
        customSize={{ font: "20px", size: "54px" }}
      ></UserProfile>
      <div className="user-rank">
        <p className="name">
          <strong>{`${position}. ${name}`}</strong>
        </p>
        <p className="time">
          {averageLabel} <strong>{time}</strong>
        </p>
        <p className="cases">
          {casesLabel} <strong>{cases}</strong>
        </p>
      </div>
    </StyledRankingUserCard>
  );
};

export default CockpitRankingUser;
