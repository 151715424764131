import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
    ProjectDetailEnum as e,
    ProjectType,
    TableProjectDetailModel
} from "@/models/Project/Projects";
import ProjectReassign from "@/pages/Projects/ProjectReassign/ProjectReassign";
import { Chip } from "@mui/material";
import { CSSProperties, useEffect } from "react";

/**
 * STYLES
 */
const styles = {
    column: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
    } as CSSProperties,
    row: {
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
    } as CSSProperties,
    card: {
        border: "1px solid #bfbfbf",
        borderRadius: "10px",
        padding: "30px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        gap: "15px",
    } as CSSProperties,
    center: {
        placeContent: "center",
        alignItems: "center",
    } as CSSProperties,
    start: {
        placeContent: "flex-start",
        alignItems: "center",
    } as CSSProperties,
    spaceAround: {
        placeContent: "center space-around",
        alignItems: "center",
    } as CSSProperties,
    spaceBetween: {
        placeContent: "center space-between",
        alignItems: "center",
    } as CSSProperties,
    title: {
        fontWeight: "bold",
        margin: "0",
    } as CSSProperties,
    p: {
        margin: "0",
        fontSize: "13px",
    } as CSSProperties,
    pv: {
        margin: "0",
        fontWeight: "bold",
    } as CSSProperties,
    chip: {
        backgroundColor: "#72f6dd5e" // #ffd3d9
    } as CSSProperties,
    icon: {
        fontSize: "25px",
        marginLeft: "10px",
    } as CSSProperties,
};
/**
 * STYLES END
 */

/**
 * SPECIFIC COMPONENT MODELS
 */
/**
 * properties that the child component needs
 */
export interface ProjectDetailCardProps {
    /**
     * Messages that are rendered in the view depending on the language
     */
    m: { [attribute: string]: any };
    /**
     * queues available
     */
    queues: Array<TPKeyValue>;
    /**
     * detail of the project queue
     */
    element: TableProjectDetailModel;
    /**
     * Function responsible for consulting the records that detail the component
     */
    loadDataSource: (sort?: string) => void;
}
/**
 * SPECIFIC COMPONENT MODELS END
 */

/**
 * project detail card component
 */
export default function ProjectDetailCard({
    m,
    queues,
    element,
    loadDataSource,
}: ProjectDetailCardProps) {
    /**
     * ATTRIBUTES
     */
    /**
     * ATTRIBUTES END
     */

    /**
     * EVENT LISTENERS
     */
    /**
     * event when component starts
     */
    useEffect(() => {
    }, []);
    /**
     * event on component close
     */
    useEffect(() => () => {
    }, []);
    /**
     * EVENT LISTENERS END
     */

    /**
     * FUNCTIONS
     */
    /**
     * function in charge of converting a text to capitalize
     * @param text text to convert
     * @returns converted text
     */
    function capitalize(text: string) {
        return text
            .split(" ")
            .map(t => `${t.charAt(0).toUpperCase()}${t.slice(1).toLowerCase()}`)
            .join(" ")
    }
    /**
     * FUNCTIONS END
     */

    /**
     * COMPONENT TO RENDER
     */
    return (
        <div style={styles.card}>
            <div style={{...styles.row, ...styles.spaceBetween, padding: "0 5px"}}>
                <h4 style={styles.title}>{element.queueName}</h4>

                <ProjectReassign
                    m={m}
                    queues={queues}
                    detail={element}
                    loadDataSource={loadDataSource}
                />
            </div>

            <div style={{...styles.row, gap: "20px"}}>
                <div style={styles.center}>
                    <TPIcon
                        iconType={TPIconTypes.personCircle}
                        style={styles.icon}
                    />
                </div>

                <div style={{...styles.row, ...styles.spaceBetween, flex: "1 1 0%", padding: "0 10px"}}>
                    <div style={styles.column}>
                        <p style={styles.p}>{m?.[e.CardTotalCELabel]}:</p>

                        <p style={styles.pv}>{element.totalCe}</p>
                    </div>

                    <div style={styles.column}>
                        <p style={styles.p}>{capitalize(m?.[e.TableColumnOnlineLabel])}:</p>

                        <p style={styles.pv}>{element.online}</p>
                    </div>

                    <div style={styles.column}>
                        <p style={styles.p}>{capitalize(m?.[e.TableColumnWorkingLabel])}:</p>

                        <p style={styles.pv}>{element.working}</p>
                    </div>

                    <div style={styles.column}>
                        <p style={styles.p}>{capitalize(m?.[e.TableColumnOfflineLabel])}:</p>

                        <p style={styles.pv}>{element.offline}</p>
                    </div>
                </div>
            </div>

            <div style={{...styles.row, backgroundColor: "#f4f4f4", gap: "20px"}}>
                <div style={styles.center}>
                    <TPIcon
                        iconType={TPIconTypes.ticket}
                        style={styles.icon}
                    />
                </div>

                <div style={{
                    ...styles.row,
                    ...styles[element.type === ProjectType.BACKOFFICE ? 'spaceBetween' : 'start'],
                    flex: "1 1 0%",
                    padding: "5px 10px"
                }}>
                    {element.type === ProjectType.BACKOFFICE && (
                        <div style={styles.column}>
                            <p style={styles.p}>{capitalize(m?.[e.TableColumnEventsLabel])}:</p>

                            <p style={styles.pv}>{element.events}</p>
                        </div>
                    )}

                    <div style={styles.column}>
                        <p style={styles.p}>{capitalize(m?.[e.TableColumnWorkedLabel])}:</p>

                        <p style={styles.pv}>{element.worked}</p>
                    </div>

                    {element.type === ProjectType.BACKOFFICE && (
                        <div style={styles.column}>
                            <p style={styles.p}>{capitalize(m?.[e.TableColumnPendingLabel])}:</p>

                            <p style={styles.pv}>{element.pending}</p>
                        </div>
                    )}
                </div>
            </div>

            <div style={{...styles.row, ...styles.spaceBetween}}>
                <div style={{...styles.row, ...styles.center, gap: "10px"}}>
                    <p style={styles.p}>{m?.[e.CardCEReadyLabel]}: </p>

                    <Chip label={element.ceReady}
                          style={styles.chip}
                    />
                </div>

                <Chip label={capitalize(element.type)}
                      style={{backgroundColor: element.type === ProjectType.BACKOFFICE ? "#72f6dd5e" : "#e2d3f8"}}
                />
            </div>
        </div>
    );
}
