import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPMultiSelect from "@/components/bootstrap/forms/multiSelect/TPMultiSelect";
import TPRadioGroup from "@/components/bootstrap/forms/radio/TPRadioGroup";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPSwitch from "@/components/bootstrap/forms/switch/TPSwitch";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPNumeric from "@/components/bootstrap/forms/TPNumeric/TPNumeric";
import TPBranchSelection2 from "@/components/TPBranchSelection/TPBranchSelection2";
import TPConfigKeyValueItem from "@/components/TPConfigKeyValues/TPConfigKeyValueItem";
import {
  AddButton,
  CategoryContentBody,
  CategoryContentContainer,
  CategoryContentTitle,
  CategoryItemContainer,
  CollapsableCategoryContentBody,
  CollapsableMenuContainer,
  CollapsableMenuContentBody,
  CollapsableMenuContentDescription,
  CollapsableMenuContentTitle,
  ContentContainer,
  DeleteButton,
  FooterContainer,
  InputContainer,
  LabelWithAsterisk,
  LanguageCountBadge,
  MenuContainer,
  MenuContentBody,
  MenuContentContainer,
  MenuContentDescription,
  MenuContentTitle,
  MenuItemStyled,
  MenuStyled,
  RowContainer,
  SubMenuItemStyled,
  SubMenuStyled,
  TPConfigKeyValueContainerStyled,
  TPConfigValueHeader,
  TPConfigValueHeaderContainer,
  TPConfigValueHeaderTitle,
  TPStyledBody,
  TPStyledBodyCell,
  TPStyledBodyRow,
  TPStyledDiv,
  TPStyledHead,
  TPStyledHeadCell,
  TPStyledHeaderRow,
  TPStyledTable,
  WideSelect
} from "@/components/TPConfigKeyValues/TPConfigKeyValueStyles";
import FileUploader from "@/components/TPDragAndDropUploadFile/FileUploader";
import TPLanguage from "@/components/TPLanguage/TPLanguage";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import TPGeneralContext from "@/contexts/TPGeneralContext";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { BranchViewModel } from "@/models/Branch/BranchModels";
import {
  ConfigurationValuesInputDTO,
  ConfigurationValuesMassiveInputDTO
} from "@/models/Configuration/ConfigurationValuesInputDTOModel";
import {
  SystemParametersEnum,
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
  TPLanguageFilterEnum
} from "@/models/Global/TPGlobalEnums";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import { AdditionalDataFormService } from "@/services/AdditionalDataFormService";
import { BranchService } from "@/services/BranchService";
import ConfigurationService from "@/services/ConfigurationService";
import { TPI18N } from "@/services/I18nService";
import LanguageService from "@/services/LanguageService";
import { OutboundMailAccountService } from "@/services/OutboundMailAccountService";
import { ParametersService } from "@/services/ParametersService";
import { TimeZonesService } from "@/services/TimeZonesService";
import { TreeService } from "@/services/TreeService";
import { useContext, useEffect, useState } from "react";
import { Marker } from "react-mark.js";
import { v4 as uuid } from "uuid";

export enum MenusEnum {
  CustomerClassifierMapping,
  ExternalCustomerSearch,
  EnableAnonimous,
  CustomerSearchParameters,
  CustomerContactsAndProducts,
  Site,
  URLSiteTPClient,
  InternalIPMasks,
  CORSDomains,
  PasswordExpiration,
  ProxyInternetWebServer,
  SelectDefaultTheme,
  MenuItemsSortOrder,
  ReCaptchaModule,
  EnableExtendedLogInformation,
  LogLevel,
  ActiveTaskBGColor,
  CaseResponseTaskOrder,
  FirstDayOfWeek,
  TaskEscalationTerm,
  DateFormat,
  DateTimeFormat,
  OutboundMail,
  UserClassifierMapping,
  TopRowCountForCases,
  ParameterToShortenComments,
  CaseNumberConfirmationOnSave,
  CloneCaseWithTodayDate,
  EnableJobAutoCloseTask,
  ChatModule,
  MailReaderModule,
  EventsManager,
  TimeTracking,
  KnowledgeBaseManagement,
  CognitiveAnalysisAndGrammarChecker,
  SalesOpportunities,
  SocialMedia,
  KnowledgeBase,
  KnowledgeManagement,
  KnowledgePoweredAI,
  RecentAgents,
  InactivateAndArchiveCases,
  DeleteEmailsAndSocialMediaMessages,
  ExportCases,
  PrivateCasesAndTasks,
  StoryfAITranslation,
  TPGenAIIntegration,
  ReplicatedTablesForCustomReports,
  BulkLoad,
  CaseExchange,
  CustomerAlerts,
  DataRights,
  OutboundOmniChannel,
  InternalSpellChecker,
  CCMSXMLRPC,
  DialPhone,
  PingExternalURLs,
  defaultLanguage,
  logo,
  SLARecalculationOnCaseReclassification,
  // CaseResponseTaskOrder
  UserAdditionalData,
}

export enum CategoriesEnum {
  General,
  CustomerInformation,
  SiteAndSecurity,
  CaseHandling,
  ModulesActivation,
  Language,
  Logo,
}

export interface IMenu {
  id: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
}

export interface ICategory {
  id: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
  accordionCollapsed: boolean;
}

export type TreeManagementProps = {
  //Team
  userTreeBranchTeamMapping: string;
  userTreeBranchTeamMappingcurrentBranch: string;
  userTreeBranchTeamMappingcurrentDescriptionBranch: string;
  userTreeBranchTeamMappingdefaultBranchHierarchyDescription: string;
  userTreeBranchTeamMappingSubsetType: string;
  //Department
  userTreeBranchAreaMapping: string;
  userTreeBranchAreaMappingcurrentBranch: string;
  userTreeBranchAreaMappingcurrentDescriptionBranch: string;
  userTreeBranchAreaMappingdefaultBranchHierarchyDescription: string;
  userTreeBranchAreaMappingSubsetType: string;
  [key: string]: string;
};

interface TPConfigValueCategoryItem {
  categoryId: string;
  categoryDescription: string | JSX.Element;
  categoryTitle: string | JSX.Element;
  menus: any[];
}

interface TPConfigKeyValueItemNumericProps {
  keyString: string;
}

type ErrorStateType = {
  defaultLanguageErrorMessage: string;
  escalatedTaskTermErrorMessage: string;
  escalatedTaskTermUnitErrorMessage: string;
  dateFormatErrorMessage: string;
  inboundEmailCaseNumberPatternErrorMessage: string;
  maximumLengthMailBodyErrorMessage: string;
  mailReaderMaxLengthAddressErrorMessage: string;
  cognitiveAnalysisURLErrorMessage: string;
  cognitiveAnalysisAzureKeyErrorMessage: string;
  taskOrderErrorMessage: string;
  getFirstDayOfWeekErrorMessage: string;
  modeSendingCaseEmailErrorMessage: string;
  userAddaFormErrorMessage: string;
  dateTimeFormatErrorMessage: string;
  defaultOutboundMailAccountIdErrorMessage: string;
  userTreeBranchTeamMappingErrorMessage: string;
  userTreeBranchTeamMappingSubsetTypeErrorMessage: string;
  userTreeBranchAreaMappingSubsetTypeErrorMessage: string;
  userTreeBranchAreaMappingErrorMessage: string;
  userBranchIdAreaMappingErrorMessage: string;
  userBranchIdTeamMappingErrorMessage: string;
  topViewCustomerHistoryErrorMessage: string;
  topExportErrorMessage: string;
  topViewErrorMessage: string;
  characterNumberCutStringCommentsErrorMessage: string;
  storyFaiServiceUrlErrorMessage: string;
  tPGenAIServiceUrlErrorMessage: string;
  tPGenAIMinimumWordCountErrorMessage: string;
  daysToKeepRecentAgentErrorMessage: string;
  storyFaiApiKeyErrorMessage: string;
  tPGenAIApiKeyErrorMessage: string;
  [key: string]: string;
};

type modalAvailableLanguagesScreenType = {
  newAvailableLanguages: Array<TPKeyValue>;
  reportAvailableLanguages: string;
  newAvailableLanguagesErrorMessage: string;
  reportAvailableLanguagesErrorMessage: string;
  [x: string]: any;
};

type modalAvailableLanguagesScreenType2 = {
  newAvailableLanguages: Array<TPKeyValue>;
  reportAvailableLanguages: string;
  newAvailableLanguagesErrorMessage: string;
  reportAvailableLanguagesErrorMessage: string;
  [x: string]: any;
};

interface CustomFieldRow {
  statusName: string;
  statusType: string;
  isActive: "1" | "0";
}

const TPFieldContainer = (props: any) => (
  <div className="row justify-content-md-center">
    <div className="col-sm-3">
      <div className="form-group">{props.children}</div>
    </div>
  </div>
);

const TPCollapsableFieldContainer = (props: any) => (
  <div className="row">
    <div className="col-sm-6">
      <div className="form-group mt-2">{props.children}</div>
    </div>
  </div>
);

const ConfigurationValuesNew = () => {
  const contextData: any = useContext(TPGeneralContext);
  //screen loading
  const [yesLabel, setYesLabel] = useState("");
  const [noLabel, setNoLabel] = useState("");
  const [knowledgeBaseBasicLabel, setKnowledgeBaseBasicLabel] = useState("");
  const [knowledgeBaseAIPoweredLabel, setKnowledgeBaseAIPoweredLabel] = useState("");
  const [knowledgeBaseAPIValid, setKnowledgeBaseAPIValid] = useState<Boolean>(false)
  const [GPTFeatureLabel, setGPTFeatureLabel] = useState("");
  const [timezonesToSelect, setTimeZonesToSelect] = useState<Array<TPKeyValue>>([]);
  const [fields, setFields] = useState<CustomFieldRow[]>([{ statusName: "", statusType: "=", isActive: "0" }]);
  const [storedString, setStoredString] = useState<string>("");
  const [collapsedSections, setCollapsedSections] = useState<{ [key: string]: boolean }>({});
  const [someChange, setSomeChange] = useState<Boolean>(false)
  const [selectedMenuId, setSelectedMenuId] = useState<string>('');
  const [isLoadingScreen, setIsLoadingScreen] = useState(false);
  const componentFileName: string = "ConfigurationValues";
  const resourceSet: string = "TPConfigKeyValueContainer";
  const [categoryList, setCategoriList] = useState<
    | {
      [key in CategoriesEnum]: ICategory;
    }
    | null
  >(null);

  const menuKeys = Object.keys(MenusEnum).filter((k) => isNaN(Number(k)));
  const [menuList, setMenuList] = useState<{ [key: string]: IMenu }>({});

  //screen data
  const [keyValues, setKeyValues] = useState<{ [key: string]: any }>({});
  const initialTreeProps: TreeManagementProps = {
    userTreeBranchTeamMapping: "",
    userTreeBranchTeamMappingcurrentBranch: "",
    userTreeBranchTeamMappingcurrentDescriptionBranch: "",
    userTreeBranchTeamMappingdefaultBranchHierarchyDescription: "",
    userTreeBranchTeamMappingSubsetType: "WHOLETREE",
    //Area
    userTreeBranchAreaMapping: "",
    userTreeBranchAreaMappingcurrentBranch: "",
    userTreeBranchAreaMappingcurrentDescriptionBranch: "",
    userTreeBranchAreaMappingdefaultBranchHierarchyDescription: "",
    userTreeBranchAreaMappingSubsetType: "WHOLETREE",
  };
  const [treeProps, setTreeProps] =
    useState<TreeManagementProps>(initialTreeProps);
  const [textToSearch, setTextToSearch] = useState("");

  const [categories, setCategories] = useState<
    Array<TPConfigValueCategoryItem>
  >([]);

  //SelectLanguageItem
  const [languages, setLanguages] = useState<TPKeyValue[]>([]);
  //Unit term list
  const [unitTermList, setUnitTermList] = useState<TPKeyValue[]>([]);
  //logo
  const [fileName, setFileName] = useState("");
  const [base64String, setBase64String] = useState("");

  //screen state
  const [enableUserTeamTree, setEnabledUserTeamTree] = useState(false);
  const [enableUserDepartmentTree, setEnabledUserDepartmentTree] =
    useState(false);
  const [treeList, setTreeListState] = useState<Array<TPKeyValue>>([]);
  const [treeHandle, setTreeHandle] = useState<Array<TPKeyValue>>([]);
  const [modalAcceptLabel, setModalAcceptLabel] = useState("");
  const [modalCancelLabel, setModalCancelLabel] = useState("");
  const [modalTitleLabel, setmodalTitleLabel] = useState("");
  const [emptyLabel, setEmptyLabel] = useState("");
  const [languageLabel, setLanguageLabel] = useState("");
  const [outboundMailList, setOutboundMailList] = useState<Array<TPKeyValue>>(
    []
  );
  const [additionalDataList, setAdditionalDataList] = useState<
    Array<TPKeyValue>
  >([]);

  const [languageData, setLanguageData] = useState<Array<TPKeyValue>>([]);
  const [languageData2, setLanguageData2] = useState<Array<TPKeyValue>>([]);

  const [isLoading, setIsLoading] = useState(false); // state to track loading status
  const [
    autocompleteAvailableLanguagesOptions,
    setAutocompleteAvailableLanguagesOptions,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteAvailableLanguagesTopNOptions,
    setAutocompleteAvailableLanguagesTopNOptions,
  ] = useState<Array<TPKeyValue>>([]);

  const [
    autocompleteAvailableLanguagesOptions2,
    setAutocompleteAvailableLanguagesOptions2,
  ] = useState<Array<TPKeyValue>>([]);
  const [
    autocompleteAvailableLanguagesTopNOptions2,
    setAutocompleteAvailableLanguagesTopNOptions2,
  ] = useState<Array<TPKeyValue>>([]);

  // AvailableLanguages
  const initialModalAvailableLanguagesScreenState: modalAvailableLanguagesScreenType =
  {
    newAvailableLanguages: [],
    reportAvailableLanguages: "",
    newAvailableLanguagesErrorMessage: "",
    reportAvailableLanguagesErrorMessage: "",
  };
  const [
    modalAvailableLanguagesScreenState,
    setModalAvailableLanguagesScreenState,
  ] = useState<modalAvailableLanguagesScreenType>(
    initialModalAvailableLanguagesScreenState
  );

  const initialModalAvailableLanguagesScreenState2: modalAvailableLanguagesScreenType2 =
  {
    newAvailableLanguages: [],
    reportAvailableLanguages: "",
    newAvailableLanguagesErrorMessage: "",
    reportAvailableLanguagesErrorMessage: "",
  };
  const [
    modalAvailableLanguagesScreenState2,
    setModalAvailableLanguagesScreenState2,
  ] = useState<modalAvailableLanguagesScreenType2>(
    initialModalAvailableLanguagesScreenState2
  );

  const handleDelete = (keyToDelete: any) => {
    const updatedData = languageData.filter((item) => item.key !== keyToDelete);
    setLanguageData(updatedData);
  };

  const handleDelete2 = (keyToDelete: any) => {
    const updatedData = languageData2.filter(
      (item) => item.key !== keyToDelete
    );
    setLanguageData2(updatedData);
  };

  //Error State
  const initialErrorState: ErrorStateType = {
    defaultLanguageErrorMessage: "",
    escalatedTaskTermErrorMessage: "",
    escalatedTaskTermUnitErrorMessage: "",
    dateFormatErrorMessage: "",
    dateTimeFormatErrorMessage: "",
    userTreeBranchTeamMappingErrorMessage: "",
    topViewCustomerHistoryErrorMessage: "",
    userTreeBranchTeamMappingSubsetTypeErrorMessage: "",
    userTreeBranchAreaMappingSubsetTypeErrorMessage: "",
    userTreeBranchAreaMappingErrorMessage: "",
    userBranchIdAreaMappingErrorMessage: "",
    userBranchIdTeamMappingErrorMessage: "",
    storyFaiServiceUrlErrorMessage: "",
    storyFaiApiKeyErrorMessage: "",
    tPGenAIServiceUrlErrorMessage: "",
    tPGenAIApiKeyErrorMessage: "",
    tPGenAIMinimumWordCountErrorMessage: "",
    characterNumberCutStringCommentsErrorMessage: "",
    defaultOutboundMailAccountIdErrorMessage: "",
    modeSendingCaseEmailErrorMessage: "",
    topExportErrorMessage: "",
    topViewErrorMessage: "",
    daysToKeepRecentAgentErrorMessage: "",
    inboundEmailCaseNumberPatternErrorMessage: "",
    mailReaderMaxLengthAddressErrorMessage: "",
    maximumLengthMailBodyErrorMessage: "",
    cognitiveAnalysisURLErrorMessage: "",
    cognitiveAnalysisAzureKeyErrorMessage:"",
    taskOrderErrorMessage: "",
    getFirstDayOfWeekErrorMessage:"",
    userAddaFormErrorMessage: "",
  };
  const [errorState, setErrorState] = useState(initialErrorState);
  const changeValueForKey = (key: string, newValue: string) => {
    setSomeChange(true)
    // Check if key already exists in keyValues
    if (key in keyValues) {
      const keyValuesCopy = { ...keyValues };
      keyValuesCopy[key] = {
        ...keyValuesCopy[key],
        value: newValue,
      };
      setKeyValues(keyValuesCopy);
      const newErrorState = { ...errorState };
      newErrorState[`${key}ErrorMessage`] = "";
      setErrorState(newErrorState);
    } else {
      // If key doesn't exist, add it to keyValues
      setKeyValues((prevKeyValues) => ({
        ...prevKeyValues,
        [key]: { value: newValue },
      }));

      const newErrorState = { ...errorState };
      newErrorState[`${key}ErrorMessage`] = "";
      setErrorState(newErrorState);
    }
  };

  const changeTreeValueForKey = (key: string, newValue: string) => {
    const treeValuesCopy = { ...treeProps };
    treeValuesCopy[key] = newValue;
    if (key === "userTreeBranchTeamMapping") {
      treeValuesCopy.userTreeBranchTeamMappingcurrentBranch = "";
      treeValuesCopy.userTreeBranchTeamMappingcurrentDescriptionBranch = "";
      treeValuesCopy.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
        "";
      treeValuesCopy.userTreeBranchTeamMappingSubsetType = "WHOLETREE";
    }
    if (key === "userTreeBranchTeamMappingSubsetType") {
      treeValuesCopy.userTreeBranchTeamMappingcurrentBranch = "";
      treeValuesCopy.userTreeBranchTeamMappingcurrentDescriptionBranch = "";
      treeValuesCopy.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
        "";
    }

    if (key === "userTreeBranchAreaMapping") {
      treeValuesCopy.userTreeBranchAreaMappingcurrentBranch = "";
      treeValuesCopy.userTreeBranchAreaMappingcurrentDescriptionBranch = "";
      treeValuesCopy.userTreeBranchAreaMappingdefaultBranchHierarchyDescription =
        "";
      treeValuesCopy.userTreeBranchAreaMappingSubsetType = "WHOLETREE";
    }

    setTreeProps(treeValuesCopy);

    const newErrorState = { ...errorState };
    newErrorState[`${key}ErrorMessage`] = "";
    setErrorState(newErrorState);
  };

  const parseLanguageData = (inputString: string) => {
    if (!inputString) return []; // Handle case where inputString might be undefined or null

    return inputString.split("|").map((entry) => {
      const [key, value] = entry.split("$");
      return {
        key,
        value: value.charAt(0).toUpperCase() + value.slice(1), // Capitalize first letter
      };
    });
  };

  const getAllConfigurationValues = async () => {
    setModalAcceptLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalAcceptLabel")
    );

    setModalCancelLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "ModalCancelLabel")
    );

    setmodalTitleLabel(
      await TPI18N.GetText(
        TPGlobal.globalResourceSet,
        "ModalSelectBranchTitleLabel"
      )
    );

    setEmptyLabel(
      await TPI18N.GetText(TPGlobal.globalResourceSet, "EmptyLabel")
    );

    setLanguageLabel(await TPI18N.GetText(resourceSet, "Language"));

    setYesLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionYes"));

    setNoLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "OptionNo"));

    setGPTFeatureLabel(await TPI18N.GetText(resourceSet, "GPTFeatureLabel")); // ADD resource
    setKnowledgeBaseBasicLabel(await TPI18N.GetText(resourceSet, "Basic")); // ADD resource
    setKnowledgeBaseAIPoweredLabel(await TPI18N.GetText(resourceSet, "AIPowered")); // ADD resource

    let serviceClient = new ConfigurationService();
    let expectedCodes: Array<number> = [200, 404];

    try {
      setIsLoadingScreen(true);
      //TODO: change method by getAllConfigurationKeys when backend be ready
      let responseRequest = await serviceClient.getAllConfigurationKeys(
        false,
        true,
        expectedCodes
      );
      setIsLoadingScreen(false);
      if (responseRequest && responseRequest.responseData) {
        let configurationValueList: Array<TPKeyValue> = [
          ...responseRequest.responseData.data,
        ];
        const configurationValues: { [key: string]: any } = Object.fromEntries(
          configurationValueList.map(({ key, ...rest }) => [
            key[0].toLowerCase() + key.slice(1),
            rest,
          ])
        );

        if (configurationValues.storyFaiAvailableLanguages) {
          setLanguageData(
            parseLanguageData(
              configurationValues.storyFaiAvailableLanguages.value
            )
          );
        }

        if (configurationValues.tPGenAIAvailableLanguages) {
          setLanguageData2(
            parseLanguageData(
              configurationValues.tPGenAIAvailableLanguages.value
            )
          );
        }

        console.log("🚀 ~ configurationValues:", configurationValues)
        setKeyValues(configurationValues);
        const treeValuesCopy = { ...treeProps };
        let changeState: boolean = false;
        if (
          configurationValues?.userTreeBranchAreaMapping &&
          configurationValues?.userTreeBranchAreaMapping.value
        ) {
          let userTreeBranchAreaMappingJSON: any = JSON.parse(
            configurationValues?.userTreeBranchAreaMapping.value
          );
          if (
            userTreeBranchAreaMappingJSON.ParentCode.toString().indexOf(
              "[Root:"
            ) > -1
          ) {
            treeValuesCopy.userTreeBranchAreaMapping =
              userTreeBranchAreaMappingJSON.TreeCode;
            treeValuesCopy.userTreeBranchAreaMappingSubsetType = "WHOLETREE";
          } else {
            treeValuesCopy.userTreeBranchAreaMappingSubsetType = "PARTIAL";
            treeValuesCopy.userTreeBranchAreaMapping =
              userTreeBranchAreaMappingJSON.TreeCode;
            //get branch by id in order to get hierarchy description of branch
            let branchData: BranchViewModel | null = null;
            branchData = await getBranchInfo(
              userTreeBranchAreaMappingJSON.ParentCode
            );
            if (branchData == null) {
              //todo logs
              return false;
            }
            treeValuesCopy.userTreeBranchAreaMappingcurrentBranch =
              branchData.id;
            treeValuesCopy.userTreeBranchAreaMappingdefaultBranchHierarchyDescription =
              branchData.hierarchyDescription;
          }

          setEnabledUserDepartmentTree(true);
          changeState = true;
        }
        if (
          configurationValues?.userTreeBranchTeamMapping &&
          configurationValues?.userTreeBranchTeamMapping.value
        ) {
          let userTreeBranchTeamMappingJSON: any = JSON.parse(
            configurationValues?.userTreeBranchTeamMapping.value
          );

          if (
            userTreeBranchTeamMappingJSON.ParentCode.toString().indexOf(
              "[Root:"
            ) > -1
          ) {
            treeValuesCopy.userTreeBranchTeamMapping =
              userTreeBranchTeamMappingJSON.TreeCode;
            treeValuesCopy.userTreeBranchTeamMappingSubsetType = "WHOLETREE";
          } else {
            treeValuesCopy.userTreeBranchTeamMappingSubsetType = "PARTIAL";
            treeValuesCopy.userTreeBranchTeamMapping =
              userTreeBranchTeamMappingJSON.TreeCode;
            //get branch by id in order to get hierarchy description of branch
            let branchData: BranchViewModel | null = null;
            branchData = await getBranchInfo(
              userTreeBranchTeamMappingJSON.ParentCode
            );
            if (branchData == null) {
              //todo logs
              return false;
            }

            treeValuesCopy.userTreeBranchTeamMappingcurrentBranch =
              branchData.id;
            treeValuesCopy.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
              branchData.hierarchyDescription;
          }

          setEnabledUserTeamTree(true);
          changeState = true;
        }
        if (changeState) {
          setTreeProps(treeValuesCopy);
        }

        //load categories
        const newCategoryList = {
          [CategoriesEnum.General]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="GeneralCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
          [CategoriesEnum.CustomerInformation]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="CustomerInformationCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
          [CategoriesEnum.CaseHandling]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="CaseHandlingCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
          [CategoriesEnum.SiteAndSecurity]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="SiteAndSecurityCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
          [CategoriesEnum.ModulesActivation]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="ModulesActivationCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
          [CategoriesEnum.Language]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="LanguageCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
          [CategoriesEnum.Logo]: {
            id: uuid(),
            title: (
              <TPLanguage
                resourceId="LogoCategoryTitle"
                resourceSet={componentFileName}
              />
            ),
            description: "",
            accordionCollapsed: false,
          },
        };
        setCategoriList(newCategoryList);

        //Load Menu categories
        let newMenuList: { [key: string]: IMenu } = {};
        menuKeys.forEach((key, index) => {
          const menu: IMenu = {
            id: uuid(),
            description: (
              <TPLanguage
                resourceId={`${key}Description`}
                resourceSet={resourceSet}
              />
            ),
            title: (
              <TPLanguage
                resourceId={`${key}Title`}
                resourceSet={resourceSet}
              />
            ),
          };

          newMenuList[MenusEnum[index]] = menu;
        });
        setMenuList(newMenuList);

        //Load Languages
        await getLanguages();
        //load unit term from parameters
        await getUnitTermFromParameters();
        //Load Languages
        await loadFromInfo();
      }

      setTreeHandle([
        {
          key: "WHOLETREE",
          value: await TPI18N.GetText(resourceSet, "WholeTreeItemLabel"), // "Whole tree",
        },
        {
          key: "PARTIAL",
          value: await TPI18N.GetText(resourceSet, "PartialTreeItemLabel"), // "Partial tree",
        },
      ]);

      await getTimezones();
    } catch (error) {
      console.error("ERROR!");

      TPLog.Log(
        `Error ${componentFileName} getAllConfigurationValues ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} getAllConfigurationValues ex`,
        error
      );
      setIsLoadingScreen(false);
    }
  };

  const loadFromInfo = async () => {
    let serviceClient = new OutboundMailAccountService();
    let expectedCodes: Array<number> = [200, 404];
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getAllMailAccounts(
        false,
        true,
        expectedCodes
      );
      let newEmailAccountListState: Array<TPKeyValue> = [];
      responseRequest.forEach((item: any) => {
        newEmailAccountListState.push({
          key: item.idAccount,
          value: item.description,
          ...item,
        });
      });

      newEmailAccountListState.unshift({ key: "--", value: "--" });
      setOutboundMailList(newEmailAccountListState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getAllMailAccounts ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getAllMailAccounts ex`);
      setIsLoadingScreen(false);
    }
  };

  const getUnitTermFromParameters = async () => {
    let parametersService = new ParametersService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest =
        await parametersService.getByParentIdAndFilterIsActive(
          SystemParametersEnum.TEUNI,
          TPActiveOptions.ACTIVE.toString(),
          false,
          true,
          expectedCodes
        );

      let newTypeKeyValueList: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return {
            key: item.id.toLowerCase(),
            value: item.localizedDescription,
          };
        }
      );
      newTypeKeyValueList.unshift({ key: "", value: "--" });
      setUnitTermList(newTypeKeyValueList);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getUnitTermFromParameters ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getUnitTermFromParameters ex`);
      return;
    }
  };

  const getLanguages = async () => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    const response = await languageClient.getLanguageByFilter(
      TPLanguageFilterEnum.ConfiguredForTPClientUI,
      false,
      true,
      expectedCodes
    );

    const languages = response.responseData.data;

    const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
      (k: any) => {
        return {
          key: k.id,
          value: k.name,
        };
      }
    );
    languagesToSelectConverted.unshift({ key: "", value: "--" });
    setLanguages(languagesToSelectConverted);
  };

  const getTreeIdList = async () => {
    let serviceClient = new TreeService();
    let expectedCodes: Array<number> = [200];
    let i: number;
    try {
      setIsLoadingScreen(true);
      let responseRequest = await serviceClient.getTreesByFilter(
        TPActiveOptions.ACTIVE.toString(),
        false,
        true,
        expectedCodes
      );
      let newTreeListState: Array<TPKeyValue> = [];
      let treeListData: Array<TreeViewModel>;
      treeListData = responseRequest;
      for (i = 0; i <= treeListData.length - 1; i++) {
        let keyvalue: TPKeyValue = {
          key: treeListData[i].id,
          value: treeListData[i].localizedName,
        };
        newTreeListState.push(keyvalue);
      }

      let intitialKeyValue: TPKeyValue = { key: "", value: "--" };
      newTreeListState.unshift(intitialKeyValue);
      setTreeListState(newTreeListState);
      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getTreeIdList ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getTreeIdList ex`);
      setIsLoadingScreen(false);
    }
  };

  const getBranchInfo = async (branchId: string) => {
    let serviceClient = new BranchService();
    let expectedCodes: Array<number> = [200];
    let branchData: BranchViewModel | null = null;
    try {
      let responseRequest = await serviceClient.getBranchById(
        branchId,
        false,
        true,
        expectedCodes
      );
      branchData = responseRequest;
      return branchData;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} getBranchInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} getBranchInfo ex`);
      return null;
    }
  };

  const getAdditonalData = async () => {
    //Load Form List
    let formService = new AdditionalDataFormService();
    let expectedCodes: Array<number> = [200];
    try {
      let responseRequest = await formService.getByFiltersIsActive(
        "1",
        "S_USERSADD",
        false,
        true,
        expectedCodes
      );
      let newFormListState: Array<TPKeyValue> = responseRequest.map(
        function (item) {
          return { key: item.id, value: item.localizedDescription };
        }
      );
      newFormListState.unshift({ key: "", value: "--" });
      setAdditionalDataList(newFormListState);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
      return;
    }
  };

  const getTimezones = async () => {
    const TimeZoneClient = new TimeZonesService();
    const expectedCodes: Array<number> = [200, 404];
    const timezones = await TimeZoneClient.getTimeZonesCollection(
      false,
      true,
      expectedCodes
    );
    const timezonesToSelectConverted: Array<TPKeyValue> = timezones.map((k) => {
      return {
        key: k.id,
        value: k.displayName,
      };
    });
    timezonesToSelectConverted.unshift({ key: "", value: "--" });
    setTimeZonesToSelect(timezonesToSelectConverted);
  };

  useEffect(() => {
    getAllConfigurationValues();
    getTreeIdList();
    getAdditonalData();
  }, []);

  const handleOnBranchChange = async (
    branchId: string,
    branchDescription: string,
    branchHierachyDescription: string,
    keyString: string
  ) => {
    let newTreeKeyValues = { ...treeProps };

    newTreeKeyValues[`${keyString}currentBranch`] = branchId;
    newTreeKeyValues[`${keyString}currentDescriptionBranch`] =
      branchDescription;
    newTreeKeyValues[`${keyString}defaultBranchHierarchyDescription`] =
      branchHierachyDescription;
    setTreeProps(newTreeKeyValues);

    let newErrorState = { ...errorState };
    if (keyString === "userTreeBranchAreaMapping") {
      if (newTreeKeyValues[`${keyString}currentBranch`]) {
        newErrorState.userBranchIdAreaMappingErrorMessage = "";
      }
      setErrorState(newErrorState);
    }
    if (keyString === "userTreeBranchTeamMapping") {
      if (newTreeKeyValues[`${keyString}currentBranch`]) {
        newErrorState.userBranchIdTeamMappingErrorMessage = "";
      }
      setErrorState(newErrorState);
    }
  };

  //handle upload attachment change
  const handleUploadFilesDraggedChange = (filesList: any) => {
    if (filesList.length > 0) {
      let file = filesList[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // Use a regex to remove data url part
        if (reader.result) {
          setBase64String(
            reader.result.toString().replace("data:", "").replace(/^.+,/, "")
          );
        }
      };
      setFileName(filesList[0].name);
    }
  };

  const handleOnSaveClick = async () => {
    let recordInputDTO: ConfigurationValuesMassiveInputDTO = {
      values: [],
    };
    let keyNamesList = Object.keys(keyValues);
    for (let i = 0; i < keyNamesList.length; i++) {
      const keyName = keyNamesList[i];

      switch (keyName) {
        case "companyLogo":
          let newConfiguration: ConfigurationValuesInputDTO = {
            key: keyName,
            value: base64String,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration);
          break;
        case "dateFormat":
          let newConfiguration2: ConfigurationValuesInputDTO = {
            key: keyName,
            value: `${keyValues["dateFormat"].value}`,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration2);
          break;
        case "dateTimeFormat":
          let newConfiguration22: ConfigurationValuesInputDTO = {
            key: keyName,
            value: `${keyValues["dateTimeFormat"].value}`,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration22);
          break;
        case "userTreeBranchAreaMapping":
          let realValue: string = "";
          if (enableUserDepartmentTree) {
            let objToSJON: any;
            if (
              treeProps["userTreeBranchAreaMappingSubsetType"] === "WHOLETREE"
            ) {
              objToSJON = {
                TreeCode: treeProps["userTreeBranchAreaMapping"],
                ParentCode: `[Root:${treeProps["userTreeBranchAreaMapping"]}]`,
              };
            } else {
              objToSJON = {
                TreeCode: treeProps["userTreeBranchAreaMapping"],
                ParentCode: treeProps["userTreeBranchAreaMappingcurrentBranch"],
              };
            }
            realValue = JSON.stringify(objToSJON);
          } else {
            realValue = "";
          }
          let newConfiguration3: ConfigurationValuesInputDTO = {
            key: keyName,
            value: realValue,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration3);
          break;
        case "userTreeBranchTeamMapping":
          let realValue2: string = "";
          if (enableUserTeamTree) {
            let objToSJON: any;
            if (
              treeProps["userTreeBranchTeamMappingSubsetType"] === "WHOLETREE"
            ) {
              objToSJON = {
                TreeCode: treeProps["userTreeBranchTeamMapping"],
                ParentCode: `[Root:${treeProps["userTreeBranchTeamMapping"]}]`,
              };
            } else {
              objToSJON = {
                TreeCode: treeProps["userTreeBranchTeamMapping"],
                ParentCode: treeProps["userTreeBranchTeamMappingcurrentBranch"],
              };
            }
            realValue2 = JSON.stringify(objToSJON);
          } else {
            realValue2 = "";
          }
          let newConfiguration4: ConfigurationValuesInputDTO = {
            key: keyName,
            value: realValue2,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration4);
          break;
        case "enableStoryFai":
          let newConfiguration5: ConfigurationValuesInputDTO = {
            key: keyName,
            value: keyValues[keyName].value,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration5);
          break;

        case "enableTPGenAI":
          let newConfiguration6: ConfigurationValuesInputDTO = {
            key: keyName,
            value: keyValues[keyName].value,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration6);
          break;
        default:
          let newConfiguration7: ConfigurationValuesInputDTO = {
            key: keyName,
            value: keyValues[keyName].value,
            notes: keyValues[keyName].notes,
            isPublic: keyValues[keyName].isPublic,
            dummy: keyValues[keyName].dummy,
          };
          recordInputDTO.values.push(newConfiguration7);
          break;
      }
    }

    let changeState: boolean = false;
    let newErrorState = { ...errorState };
    if (!keyValues["defaultLanguage"]?.value) {
      newErrorState.defaultLanguageErrorMessage = await TPI18N.GetText(
        resourceSet,
        "defaultLanguageEmpty"
      );
      changeState = true;
    }
    if (
      !keyValues["escalatedTaskTerm"]?.value ||
      keyValues["escalatedTaskTerm"]?.value === "0"
    ) {
      newErrorState.escalatedTaskTermErrorMessage = await TPI18N.GetText(
        resourceSet,
        "escalatedTaskTermEmpty"
      );
      changeState = true;
    }
    if (!keyValues["escalatedTaskTermUnit"]?.value) {
      newErrorState.escalatedTaskTermUnitErrorMessage = await TPI18N.GetText(
        resourceSet,
        "escalatedTaskTermUnitEmpty"
      );
      changeState = true;
    }
    if (!keyValues["dateFormat"]?.value) {
      newErrorState.dateFormatErrorMessage = await TPI18N.GetText(
        resourceSet,
        "dateFormatEmpty"
      );
      changeState = true;
    }

    if (!keyValues["inboundEmailCaseNumberPattern"]?.value) {
      newErrorState.inboundEmailCaseNumberPatternErrorMessage =
        await TPI18N.GetText(resourceSet, "inboundEmailCaseNumberPatternEmpty");
      changeState = true;
    }

    if (!keyValues["maximumLengthMailBody"]?.value) {
      newErrorState.maximumLengthMailBodyErrorMessage = await TPI18N.GetText(
        resourceSet,
        "maximumLengthMailBody"
      );
      changeState = true;
    }

    if (!keyValues["mailReaderMaxLengthAddress"]?.value) {
      newErrorState.mailReaderMaxLengthAddressErrorMessage =
        await TPI18N.GetText(resourceSet, "mailReaderMaxLengthAddressEmpty");
      changeState = true;
    }
    if (!keyValues["cognitiveAnalysisURL"]?.value) {
      newErrorState.cognitiveAnalysisURLErrorMessage =
        await TPI18N.GetText(resourceSet, "cognitiveAnalysisURLEmpty");
      changeState = true;
    }
    // if (!keyValues["cognitiveAnalysisAzureKey"]?.value) {
    //   newErrorState.cognitiveAnalysisAzureKeyErrorMessage = await TPI18N.GetText(
    //     resourceSet,
    //     "cognitiveAnalysisAzureKeyEmpty"
    //   );
    //   changeState = true;
    // }
    if (!keyValues["dateTimeFormat"]?.value) {
      newErrorState.dateTimeFormatErrorMessage = await TPI18N.GetText(
        resourceSet,
        "dateTimeFormatEmpty"
      );
      changeState = true;
    }

    if (!keyValues["defaultOutboundMailAccountId"]?.value) {
      newErrorState.defaultOutboundMailAccountIdErrorMessage =
        await TPI18N.GetText(resourceSet, "defaultOutboundMailAccountIdEmpty");
      changeState = true;
    }
    if (!keyValues["modeSendingCaseEmail"]?.value) {
      newErrorState.modeSendingCaseEmailErrorMessage = await TPI18N.GetText(
        resourceSet,
        "modeSendingCaseEmailEmpty"
      );
      changeState = true;
    }
    if (!keyValues["taskOrder"]?.value) {
      newErrorState.taskOrderErrorMessage = await TPI18N.GetText(
        resourceSet,
        "taskOrderEmpty"
      );
      changeState = true;
    }
    if (!keyValues["getFirstDayOfWeek"]?.value) {
      newErrorState.getFirstDayOfWeekErrorMessage = await TPI18N.GetText(
        resourceSet,
        "getFirstDayOfWeekEmpty"
      );
      changeState = true;
    }

    if (!keyValues["userAddaForm"]?.value) {
      newErrorState.userAddaFormErrorMessage = await TPI18N.GetText(
        resourceSet,
        "userAddaFormEmpty"
      );
      changeState = true;
    }
    if (enableUserTeamTree) {
      if (!treeProps["userTreeBranchTeamMapping"]) {
        newErrorState.userTreeBranchTeamMappingErrorMessage =
          await TPI18N.GetText(resourceSet, "userTreeBranchTeamMappingEmpty");
        changeState = true;
      }
    }

    if (enableUserDepartmentTree) {
      if (!treeProps["userTreeBranchAreaMapping"]) {
        newErrorState.userTreeBranchAreaMappingErrorMessage =
          await TPI18N.GetText(resourceSet, "userTreeBranchAreaMappingEmpty");
        changeState = true;
      }
      if (
        treeProps["userTreeBranchAreaMappingSubsetType"] === "PARTIAL" &&
        !treeProps["userTreeBranchAreaMappingcurrentBranch"]
      ) {
        newErrorState.userBranchIdAreaMappingErrorMessage =
          await TPI18N.GetText(resourceSet, "userBranchIdAreaMappingEmpty");
        changeState = true;
      }
    }

    if (enableUserTeamTree) {
      if (!treeProps["userTreeBranchTeamMapping"]) {
        newErrorState.userTreeBranchTeamMappingErrorMessage =
          await TPI18N.GetText(resourceSet, "userTreeBranchTeamMappingEmpty");
        changeState = true;
      }
      if (
        treeProps["userTreeBranchTeamMappingSubsetType"] === "PARTIAL" &&
        !treeProps["userTreeBranchTeamMappingcurrentBranch"]
      ) {
        newErrorState.userBranchIdTeamMappingErrorMessage =
          await TPI18N.GetText(resourceSet, "userBranchIdTeamMappingEmpty");
        changeState = true;
      }
    }

    if (
      !keyValues["topViewCustomerHistory"]?.value ||
      keyValues["topViewCustomerHistory"]?.value === "0"
    ) {
      newErrorState.topViewCustomerHistoryErrorMessage = await TPI18N.GetText(
        resourceSet,
        "topViewCustomerHistoryEmpty"
      );
      changeState = true;
    }

    if (
      !keyValues["topExport"]?.value ||
      keyValues["topExport"]?.value === "0"
    ) {
      newErrorState.topExportErrorMessage = await TPI18N.GetText(
        resourceSet,
        "topExportEmpty"
      );
      changeState = true;
    }

    if (!keyValues["topView"]?.value || keyValues["topView"]?.value === "0") {
      newErrorState.topViewErrorMessage = await TPI18N.GetText(
        resourceSet,
        "topViewEmpty"
      );
      changeState = true;
    }

    if (
      !keyValues["characterNumberCutStringComments"]?.value ||
      keyValues["characterNumberCutStringComments"]?.value === "0"
    ) {
      newErrorState.characterNumberCutStringCommentsErrorMessage =
        await TPI18N.GetText(
          resourceSet,
          "characterNumberCutStringCommentsEmpty"
        );
      changeState = true;
    }

    if (!keyValues["storyFaiServiceUrl"]?.value) {
      newErrorState.storyFaiServiceUrlErrorMessage = await TPI18N.GetText(
        resourceSet,
        "storyFaiServiceUrlEmpty"
      );
      changeState = true;
    }

    if (!keyValues["storyFaiApiKey"]?.value) {
      newErrorState.storyFaiApiKeyErrorMessage = await TPI18N.GetText(
        resourceSet,
        "stroyFaiApiKeyEmpty"
      );
      changeState = true;
    }

    if (!keyValues["tPGenAIServiceUrl"]?.value) {
      newErrorState.tPGenAIServiceUrlErrorMessage = await TPI18N.GetText(
        resourceSet,
        "tPGenAIServiceUrlEmpty"
      );
      changeState = true;
    }
    if (!keyValues["tPGenAIApiKey"]?.value) {
      newErrorState.tPGenAIApiKeyErrorMessage = await TPI18N.GetText(
        resourceSet,
        "tPGenAIApiKeyEmpty"
      );
      changeState = true;
    }
    if (!keyValues["tPGenAIMinimumWordCount"]?.value) {
      newErrorState.tPGenAIMinimumWordCountErrorMessage = await TPI18N.GetText(
        resourceSet,
        "tPGenAIMinimumWordCountEmpty"
      );
      changeState = true;
    }

    if (!keyValues["daysToKeepRecentAgent"]?.value) {
      newErrorState.daysToKeepRecentAgentErrorMessage = await TPI18N.GetText(
        resourceSet,
        "daysToKeepRecentAgentEmpty"
      );
      changeState = true;
    }

    if (changeState) {
      setErrorState(newErrorState);
      return;
    }

    await updateMassive(recordInputDTO);
  };
  const updateMassive = async (
    inputDTO: ConfigurationValuesMassiveInputDTO
  ) => {
    let serviceClient = new ConfigurationService();
    let expectedCodes: Array<number> = [200];
    try {
      setIsLoadingScreen(true);

      for (let i: number = 0; i <= inputDTO.values.length - 1; i++) {
        inputDTO.values[i].value = TPGlobal.stringToUTF8(inputDTO.values[i].value as string).toString();
      }

      // const updatedValues = inputDTO.values.map(item => ({
      //     ...item, // Spread existing properties
      //     value: TPGlobal.stringToUTF8(item.value as string).toString() //Convert value to UTF8 if it's a string
      // }));
            
      // inputDTO.values = updatedValues; 

      let responseRequest = await serviceClient.updateMassive(
        inputDTO,
        true,
        true,
        expectedCodes
      );

      const defaultLanguage = inputDTO?.values?.find(({ key }) => key === "defaultLanguage")?.value;
      if (defaultLanguage) { TPGlobal.TPClientDefaultLanguage = defaultLanguage; }

      setIsLoadingScreen(false);
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} updateMassive ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${componentFileName} updateMassive ex`);
      setIsLoadingScreen(false);
    }
  };

  const handleAvailableLanguagesChange = (newSelectedValue: any) => {
    // Check if the newLanguage key already exists in languageData
    let newmodalAvailableLanguagesScreenState = {
      ...modalAvailableLanguagesScreenState,
    };
    newmodalAvailableLanguagesScreenState.newAvailableLanguages =
      newSelectedValue;
    const keyExists = languageData.some(
      (language) => language.key === newSelectedValue[0]?.key
    );
    if (!keyExists) {
      // If key does not exist, add the new language to languageData
      setLanguageData((prevLanguageData) => [
        ...prevLanguageData,
        newSelectedValue[0],
      ]);
    }
    newmodalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
      "";
    setModalAvailableLanguagesScreenState(
      newmodalAvailableLanguagesScreenState
    );
  };

  const handleAvailableLanguagesChange2 = (newSelectedValue: any) => {
    // Check if the newLanguage key already exists in languageData
    let newmodalAvailableLanguagesScreenState = {
      ...modalAvailableLanguagesScreenState2,
    };
    newmodalAvailableLanguagesScreenState.newAvailableLanguages =
      newSelectedValue;
    const keyExists = languageData2.some(
      (language) => language.key === newSelectedValue[0]?.key
    );
    if (!keyExists) {
      // If key does not exist, add the new language to languageData
      setLanguageData2((prevLanguageData) => [
        ...prevLanguageData,
        newSelectedValue[0],
      ]);
    }
    newmodalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
      "";
    setModalAvailableLanguagesScreenState2(
      newmodalAvailableLanguagesScreenState
    );
  };

  useEffect(() => {
    // Whenever languageData changes, format the string and update keyValues
    const formattedString = languageData
      .map((item) => `${item.key}$${item.value}`)
      .join("|");
    setKeyValues((prevKeyValues) => ({
      ...prevKeyValues,
      storyFaiAvailableLanguages: { value: formattedString },
    }));
  }, [languageData]);

  useEffect(() => {
    // Whenever languageData changes, format the string and update keyValues
    const formattedString = languageData2
      .map((item) => `${item.key}$${item.value}`)
      .join("|");
    setKeyValues((prevKeyValues) => ({
      ...prevKeyValues,
      tPGenAIAvailableLanguages: { value: formattedString },
    }));
  }, [languageData2]);

  useEffect(() => {
    if (storedString) {
      const parsedFields: CustomFieldRow[] = storedString.split("|").map(field => {
        const [statusName, statusType, isActive] = field.split(";");
        return { statusName, statusType, isActive: isActive === "1" ? "1" : "0" };
      });
      setFields(parsedFields);
    } else {
      setFields([{ statusName: "", statusType: "=", isActive: "0" }]);
    }
  }, [storedString]);

  const handleAvailableLanguagesOnAutocompleteQuery = async (query: string) => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      const response = await languageClient.getLanguageByFilter(
        TPLanguageFilterEnum.All,
        false,
        true,
        expectedCodes
      );

      const languages = response.responseData.data;
      const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
        (k: any) => {
          return {
            key: k.id,
            value: k.name,
          };
        }
      );

      setAutocompleteAvailableLanguagesOptions(languagesToSelectConverted);
      return languagesToSelectConverted;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleTemplatOnAutocompleteQuery ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} handleAvailableLanguagesOnAutocompleteQuery ex`
      );
      return [];
    }
    document
      .getElementById("tpgenai-autocomplete")
      ?.scrollIntoView({ behavior: "auto" });
  };

  const handleAvailableLanguagesOnAutocompleteQuery2 = async (
    query: string
  ) => {
    const languageClient = new LanguageService();
    const expectedCodes: Array<number> = [200, 404];
    try {
      const response = await languageClient.getLanguageByFilter(
        TPLanguageFilterEnum.All,
        false,
        true,
        expectedCodes
      );

      const languages = response.responseData.data;
      const languagesToSelectConverted: Array<TPKeyValue> = languages.map(
        (k: any) => {
          return {
            key: k.id,
            value: k.name,
          };
        }
      );

      setAutocompleteAvailableLanguagesOptions2(languagesToSelectConverted);
      return languagesToSelectConverted;
    } catch (error) {
      TPLog.Log(
        `Error ${componentFileName} handleTemplatOnAutocompleteQuery2 ex`,
        TPLogType.ERROR,
        error
      );
      console.error(
        `Error ${componentFileName} handleAvailableLanguagesOnAutocompleteQuery2 ex`
      );
      return [];
    }
    document
      .getElementById("tpgenai-autocomplete")
      ?.scrollIntoView({ behavior: "auto" });
  };

  const handleAvailableLanguagesOnAutocompleteKeyDown = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      setAutocompleteAvailableLanguagesOptions([]);
      let newModalAvailableLanguagesScreenState = {
        ...modalAvailableLanguagesScreenState,
      };
      newModalAvailableLanguagesScreenState.newAvailableLanguages = [];
      newModalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
        "";
      setModalAvailableLanguagesScreenState(
        newModalAvailableLanguagesScreenState
      );
    }
    document
      .getElementById("storyfai-autocomplete")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const handleAvailableLanguagesOnAutocompleteKeyDown2 = (event: any) => {
    //left arrow 37
    //right arror 39
    //enter 13
    //home 36
    //end  35
    if (
      event.keyCode != 37 &&
      event.keyCode != 39 &&
      event.keyCode != 13 &&
      event.keyCode != 35 &&
      event.keyCode != 36
    ) {
      setAutocompleteAvailableLanguagesOptions2([]);
      let newModalAvailableLanguagesScreenState = {
        ...modalAvailableLanguagesScreenState2,
      };
      newModalAvailableLanguagesScreenState.newAvailableLanguages = [];
      newModalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
        "";
      setModalAvailableLanguagesScreenState2(
        newModalAvailableLanguagesScreenState
      );
    }
    document
      .getElementById("tpgenai-autocomplete")
      ?.scrollIntoView({ behavior: "auto" });
  };

  const handleAutoCompleteAvailableLanguagespNClick = async () => {
    let newAvailableLanguagespTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteAvailableLanguagesTopNOptions.length === 0) {
      newAvailableLanguagespTopNOptions =
        await handleAvailableLanguagesOnAutocompleteQuery("");

      if (newAvailableLanguagespTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteAvailableLanguagesTopNOptions([
          ...newAvailableLanguagespTopNOptions,
        ]);
        setAutocompleteAvailableLanguagesOptions([
          ...newAvailableLanguagespTopNOptions,
        ]);
        let newModalAvailableLanguagesScreenState = {
          ...modalAvailableLanguagesScreenState,
        };
        newModalAvailableLanguagesScreenState.newAvailableLanguages = [];
        newModalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
          "";
        setModalAvailableLanguagesScreenState(
          newModalAvailableLanguagesScreenState
        );
      }
    } else {
      //use cached values;
      setAutocompleteAvailableLanguagesOptions([
        ...autocompleteAvailableLanguagesTopNOptions,
      ]);
      let newModalAvailableLanguagesScreenState = {
        ...modalAvailableLanguagesScreenState,
      };
      newModalAvailableLanguagesScreenState.newAvailableLanguages = [];
      newModalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
        "";
      setModalAvailableLanguagesScreenState(
        newModalAvailableLanguagesScreenState
      );
    }
    document
      .getElementById("storyfai-autocomplete")
      ?.scrollIntoView({ behavior: "auto" });
  };

  const handleAutoCompleteAvailableLanguagespNClick2 = async () => {
    let newAvailableLanguagespTopNOptions: Array<TPKeyValue> = [];
    if (autocompleteAvailableLanguagesTopNOptions2.length === 0) {
      newAvailableLanguagespTopNOptions =
        await handleAvailableLanguagesOnAutocompleteQuery2("");

      if (newAvailableLanguagespTopNOptions.length >= 1) {
        //save on cache
        setAutocompleteAvailableLanguagesTopNOptions2([
          ...newAvailableLanguagespTopNOptions,
        ]);
        setAutocompleteAvailableLanguagesOptions2([
          ...newAvailableLanguagespTopNOptions,
        ]);
        let newModalAvailableLanguagesScreenState = {
          ...modalAvailableLanguagesScreenState2,
        };
        newModalAvailableLanguagesScreenState.newAvailableLanguages = [];
        newModalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
          "";
        setModalAvailableLanguagesScreenState2(
          newModalAvailableLanguagesScreenState
        );
      }
    } else {
      //use cached values;
      setAutocompleteAvailableLanguagesOptions2([
        ...autocompleteAvailableLanguagesTopNOptions2,
      ]);
      let newModalAvailableLanguagesScreenState = {
        ...modalAvailableLanguagesScreenState2,
      };
      newModalAvailableLanguagesScreenState.newAvailableLanguages = [];
      newModalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage =
        "";
      setModalAvailableLanguagesScreenState2(
        newModalAvailableLanguagesScreenState
      );
    }
    document
      .getElementById("tpgenai-autocomplete")
      ?.scrollIntoView({ behavior: "auto" });
  };

  const menuStructure: { categoryKey: CategoriesEnum; menuKeys: MenusEnum[] }[] = [
    {
      categoryKey: CategoriesEnum.Language,
      menuKeys: [MenusEnum.defaultLanguage],
    },
    {
      categoryKey: CategoriesEnum.CaseHandling,
      menuKeys: [
        MenusEnum.TaskEscalationTerm,
        MenusEnum.DateFormat,
        MenusEnum.UserClassifierMapping,
        MenusEnum.TopRowCountForCases,
        MenusEnum.ParameterToShortenComments,
        MenusEnum.CloneCaseWithTodayDate,
        MenusEnum.SLARecalculationOnCaseReclassification,
        MenusEnum.CaseResponseTaskOrder,
        MenusEnum.FirstDayOfWeek,
      ],
    },
    {
      categoryKey: CategoriesEnum.ModulesActivation,
      menuKeys: [
        MenusEnum.PrivateCasesAndTasks,
        MenusEnum.StoryfAITranslation,
        MenusEnum.TPGenAIIntegration,
        MenusEnum.RecentAgents,
        MenusEnum.MailReaderModule,
        MenusEnum.EventsManager,
        MenusEnum.TimeTracking,
        MenusEnum.KnowledgeBaseManagement,
        MenusEnum.CognitiveAnalysisAndGrammarChecker,
      ],
    },
    {
      categoryKey: CategoriesEnum.Logo,
      menuKeys: [MenusEnum.logo],
    },
    {
      categoryKey: CategoriesEnum.CustomerInformation,
      menuKeys: [
        MenusEnum.EnableAnonimous,
        MenusEnum.CustomerContactsAndProducts,
      ],
    },
    {
      categoryKey: CategoriesEnum.General,
      menuKeys: [MenusEnum.UserAdditionalData],
    },
  ];

  const handleSubMenuItemClick = (href: string) => {
    const menuId = href.substring(1);
    setSelectedMenuId(menuId);
    window.location.href = href;
  };

  const handelValidate = async () => {
    setKnowledgeBaseAPIValid(true)
  }

  useEffect(() => {
    document.querySelectorAll(".menu-item").forEach((section) => {
      if (
        section.textContent?.toLowerCase().includes(textToSearch.toLowerCase())
      )
        section.scrollIntoView({ behavior: "smooth" });
    });
  }, [textToSearch]);

  return (
    <ContentVerticalNoTabsStyled>
      {categoryList && Object.keys(menuList).length > 0 && (
        <>
          <TPConfigKeyValueContainerStyled>
            <Marker mark={textToSearch}>
              <TPConfigValueHeader>
                <TPConfigValueHeaderContainer>
                  <TPConfigValueHeaderTitle>
                    <TPPageTitle>
                      <TPLanguage
                        resourceSet={resourceSet}
                        resourceId="TitleLabel"
                      />
                    </TPPageTitle>
                  </TPConfigValueHeaderTitle>

                </TPConfigValueHeaderContainer>
              </TPConfigValueHeader>

              <MenuContainer>
                <MenuStyled>
                  {menuStructure.map(({ categoryKey, menuKeys }) => (
                    <MenuItemStyled key={categoryKey}>
                      <CategoryItemContainer>
                        <span>{categoryList[categoryKey].title}</span>
                      </CategoryItemContainer>

                      <SubMenuStyled>
                        {menuKeys.map((menuKey) => {
                          const href = `#${menuList[MenusEnum[menuKey]].id}`;
                          const isSelected = selectedMenuId === menuList[MenusEnum[menuKey]].id;

                          return (
                            <SubMenuItemStyled
                              key={menuKey}
                              className={isSelected ? 'selected' : ''}
                              onClick={() => handleSubMenuItemClick(href)}
                            >
                              <a href={href}>
                                {menuList[MenusEnum[menuKey]].title}
                                <TPIcon iconType={isSelected ? TPIconTypes.expandRight : TPIconTypes.expandDown} />
                              </a>
                            </SubMenuItemStyled>
                          );
                        })}
                      </SubMenuStyled>
                    </MenuItemStyled>
                  ))}
                </MenuStyled>
              </MenuContainer>

              <ContentContainer>
                <TPLoadingOverlay active={isLoadingScreen}>
                  {/* Language */}
                  <CategoryContentContainer>
                    <CategoryContentTitle>
                      <span className='menu-item'>
                        <TPLanguage
                          resourceId="LanguageCategoryTitle"
                          resourceSet={componentFileName}
                        />
                      </span>
                      <TPIcon
                        iconType={TPIconTypes.expandMore}
                        onClick={() => {
                          let newCategoryList = { ...categoryList };
                          newCategoryList[
                            CategoriesEnum.Language
                          ].accordionCollapsed =
                            !newCategoryList[CategoriesEnum.Language]
                              .accordionCollapsed;
                          setCategoriList(newCategoryList);
                        }}
                      />
                    </CategoryContentTitle>
                    <CategoryContentBody
                      collapsed={
                        categoryList[CategoriesEnum.Language].accordionCollapsed
                      }
                    >
                      <MenuContentContainer>
                        <MenuContentTitle className='menu-item'>
                          {menuList[MenusEnum[MenusEnum.defaultLanguage]].title}
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.defaultLanguage]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.Language]}
                            menu={
                              menuList[MenusEnum[MenusEnum.defaultLanguage]]
                            }
                          >
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`defaultLanguageLabel`}
                                    resourceSet={componentFileName}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["defaultLanguage"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "defaultLanguage",
                                    e.target.value
                                  )
                                }
                                errorMessage={
                                  errorState.defaultLanguageErrorMessage
                                }
                                dataSource={languages}
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                    </CategoryContentBody>
                  </CategoryContentContainer>

                  {/* case handling */}
                  <CategoryContentContainer>
                    <CategoryContentTitle>
                      <span className='menu-item'>
                        <TPLanguage
                          resourceId="CaseHandlingCategoryTitle"
                          resourceSet={componentFileName}
                        />
                      </span>
                      <TPIcon
                        iconType={TPIconTypes.expandMore}
                        onClick={() => {
                          let newCategoryList = { ...categoryList };
                          newCategoryList[
                            CategoriesEnum.CaseHandling
                          ].accordionCollapsed =
                            !newCategoryList[CategoriesEnum.CaseHandling]
                              .accordionCollapsed;
                          setCategoriList(newCategoryList);
                        }}
                      />
                    </CategoryContentTitle>
                    <CategoryContentBody
                      collapsed={
                        categoryList[CategoriesEnum.CaseHandling]
                          .accordionCollapsed
                      }
                    >
                      <MenuContentContainer>
                        {/* TaskEscalationTerm */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[MenusEnum[MenusEnum.TaskEscalationTerm]]
                              .title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.TaskEscalationTerm]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[MenusEnum[MenusEnum.TaskEscalationTerm]]
                            }
                          >
                            <TPFieldContainer>
                              <TPNumeric
                                id="IdTPNumeric"
                                labelText={
                                  <TPLanguage
                                    resourceId={`escalatedTaskTermLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["escalatedTaskTerm"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey("escalatedTaskTerm", e)
                                }
                                errorMessage={
                                  errorState.escalatedTaskTermErrorMessage
                                }
                              />
                            </TPFieldContainer>
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`escalatedTaskTermUnitLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={
                                  keyValues["escalatedTaskTermUnit"]?.value
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "escalatedTaskTermUnit",
                                    e.target.value
                                  )
                                }
                                errorMessage={
                                  errorState.escalatedTaskTermUnitErrorMessage
                                }
                                dataSource={unitTermList}
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>

                        {/* DateTimeFormat */}
                        <MenuContentTitle className='menu-item'>
                          {menuList[MenusEnum[MenusEnum.DateFormat]].title}
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.DateFormat]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={menuList[MenusEnum[MenusEnum.DateFormat]]}
                          >
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`DateFormatLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["dateFormat"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "dateFormat",
                                    e.target.value
                                  )
                                }
                                errorMessage={errorState.dateFormatErrorMessage}
                                dataSource={[
                                  { key: "", value: "..." },
                                  {
                                    key: "yyyy/MM/dd",
                                    value: "yyyy/MM/dd",
                                  },
                                  {
                                    key: "MM/dd/yyyy",
                                    value: "MM/dd/yyyy",
                                  },
                                  {
                                    key: "dd/MM/yyyy",
                                    value: "dd/MM/yyyy",
                                  },
                                ]}
                              />
                            </TPFieldContainer>
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`dateTimeFormatLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["dateTimeFormat"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "dateTimeFormat",
                                    e.target.value
                                  )
                                }
                                errorMessage={
                                  errorState.dateTimeFormatErrorMessage
                                }
                                dataSource={[
                                  { key: "", value: "..." },
                                  {
                                    key: "yyyy/MM/dd HH:mm:ss",
                                    value: "yyyy/MM/dd HH:mm:ss",
                                  },
                                  {
                                    key: "MM/dd/yyyy HH:mm:ss",
                                    value: "MM/dd/yyyy HH:mm:ss",
                                  },
                                  {
                                    key: "dd/MM/yyyy HH:mm:ss",
                                    value: "dd/MM/yyyy HH:mm:ss",
                                  },
                                ]}
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>

                        {/* UserClassifierMapping */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[MenusEnum[MenusEnum.UserClassifierMapping]]
                              .title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.UserClassifierMapping]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[
                              MenusEnum[MenusEnum.UserClassifierMapping]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                checked={enableUserTeamTree}
                                onChange={(e: any) => {
                                  let newTreeProps = { ...treeProps };
                                  newTreeProps.userTreeBranchTeamMapping = "";
                                  newTreeProps.userTreeBranchTeamMappingcurrentBranch =
                                    "";
                                  newTreeProps.userTreeBranchTeamMappingcurrentDescriptionBranch =
                                    "";
                                  newTreeProps.userTreeBranchTeamMappingdefaultBranchHierarchyDescription =
                                    "";
                                  setTreeProps(newTreeProps);
                                  setEnabledUserTeamTree(!enableUserTeamTree);
                                }}
                                labelText={
                                  <TPLanguage
                                    resourceSet={resourceSet}
                                    resourceId="EnableUserTeamTreeLabel"
                                  />
                                }
                              ></TPCheckBox>
                            </TPFieldContainer>
                            {enableUserTeamTree && (
                              <>
                                <TPFieldContainer>
                                  <TPSelect
                                    id="IdSelect"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      changeTreeValueForKey(
                                        "userTreeBranchTeamMapping",
                                        e.target.value
                                      )
                                    }
                                    dataSource={treeList}
                                    value={
                                      treeProps["userTreeBranchTeamMapping"]
                                    }
                                    labelText={
                                      <TPLanguage
                                        resourceSet={resourceSet}
                                        resourceId="TeamTreeMappingLabel"
                                      />
                                    }
                                    errorMessage={
                                      errorState.userTreeBranchTeamMappingErrorMessage
                                    }
                                    isHorizontal={false}
                                  ></TPSelect>
                                </TPFieldContainer>
                                {treeProps["userTreeBranchTeamMapping"] && (
                                  <TPFieldContainer>
                                    <TPSelect
                                      id="IdSelect"
                                      isMandatory={true}
                                      onChange={(e: any) =>
                                        changeTreeValueForKey(
                                          "userTreeBranchTeamMappingSubsetType",
                                          e.target.value
                                        )
                                      }
                                      dataSource={treeHandle}
                                      value={
                                        treeProps[
                                        "userTreeBranchTeamMappingSubsetType"
                                        ]
                                      }
                                      labelText={
                                        <TPLanguage
                                          resourceSet={resourceSet}
                                          resourceId="SubsetTypeLabel"
                                        />
                                      }
                                      errorMessage={
                                        errorState.userTreeBranchTeamMappingSubsetTypeErrorMessage
                                      }
                                      isHorizontal={false}
                                    ></TPSelect>
                                  </TPFieldContainer>
                                )}
                                {treeProps["userTreeBranchTeamMapping"] &&
                                  treeProps[
                                  "userTreeBranchTeamMappingSubsetType"
                                  ] === "WHOLETREE" && (
                                    <TPFieldContainer>
                                      <TPTextBox
                                        id="IdTextBox"
                                        onChange={TPGlobal.foo}
                                        value={`[root:${treeProps["userTreeBranchTeamMapping"]}]`}
                                        disabled={true}
                                        labelText={
                                          <TPLanguage
                                            resourceSet={resourceSet}
                                            resourceId="TeamTreeParentBranchLabel"
                                          />
                                        }
                                      />
                                    </TPFieldContainer>
                                  )}
                                {treeProps["userTreeBranchTeamMapping"] &&
                                  treeProps[
                                  "userTreeBranchTeamMappingSubsetType"
                                  ] === "PARTIAL" && (
                                    <TPFieldContainer>
                                      <TPBranchSelection2
                                        labelText={
                                          <TPLanguage
                                            resourceSet={resourceSet}
                                            resourceId={"TeamTreeMappingLabel"}
                                          />
                                        }
                                        disabledTextBox={false}
                                        treeId={
                                          treeProps["userTreeBranchTeamMapping"]
                                        }
                                        modalAcceptLabel={modalAcceptLabel}
                                        modalCancelLabel={modalCancelLabel}
                                        modalSelectedBranchLabel={
                                          "modalSelectedBranchLabel"
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={false}
                                        value={{
                                          branchId:
                                            treeProps[
                                            `userTreeBranchTeamMappingcurrentBranch`
                                            ],
                                          branchDescription:
                                            treeProps[
                                            `userTreeBranchTeamMappingcurrentDescriptionBranch`
                                            ],
                                          branchHierarchyDescription:
                                            treeProps[
                                            `userTreeBranchTeamMappingdefaultBranchHierarchyDescription`
                                            ],
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string
                                        ) => {
                                          handleOnBranchChange(
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                            "userTreeBranchTeamMapping"
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage={
                                          errorState.userBranchIdTeamMappingErrorMessage
                                        }
                                        onIsLoadingProgress={(
                                          value: boolean
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </TPFieldContainer>
                                  )}
                              </>
                            )}
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                className={"mt-4"}
                                checked={enableUserDepartmentTree}
                                onChange={(e: any) => {
                                  let newTreeProps = { ...treeProps };
                                  newTreeProps.userTreeBranchAreaMapping = "";
                                  newTreeProps.userTreeBranchAreaMappingcurrentBranch =
                                    "";
                                  newTreeProps.userTreeBranchAreaMappingcurrentDescriptionBranch =
                                    "";
                                  newTreeProps.userTreeBranchAreaMappingdefaultBranchHierarchyDescription =
                                    "";
                                  setTreeProps(newTreeProps);
                                  setEnabledUserDepartmentTree(
                                    !enableUserDepartmentTree
                                  );
                                }}
                                labelText={
                                  <TPLanguage
                                    resourceSet={resourceSet}
                                    resourceId="EnableUserDepartmentTreeLabel"
                                  />
                                }
                              ></TPCheckBox>
                            </TPFieldContainer>
                            {enableUserDepartmentTree && (
                              <>
                                <TPFieldContainer>
                                  <TPSelect
                                    id="IdSelect"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      changeTreeValueForKey(
                                        "userTreeBranchAreaMapping",
                                        e.target.value
                                      )
                                    }
                                    dataSource={treeList}
                                    value={
                                      treeProps["userTreeBranchAreaMapping"]
                                    }
                                    labelText={
                                      <TPLanguage
                                        resourceSet={resourceSet}
                                        resourceId="DepartmentTreeMappingLabel"
                                      />
                                    }
                                    errorMessage={
                                      errorState.userTreeBranchAreaMappingErrorMessage
                                    }
                                    isHorizontal={false}
                                  ></TPSelect>
                                </TPFieldContainer>
                                {treeProps["userTreeBranchAreaMapping"] && (
                                  <TPFieldContainer>
                                    <TPSelect
                                      id="IdSelect"
                                      isMandatory={true}
                                      onChange={(e: any) =>
                                        changeTreeValueForKey(
                                          "userTreeBranchAreaMappingSubsetType",
                                          e.target.value
                                        )
                                      }
                                      dataSource={treeHandle}
                                      value={
                                        treeProps[
                                        "userTreeBranchAreaMappingSubsetType"
                                        ]
                                      }
                                      labelText={
                                        <TPLanguage
                                          resourceSet={resourceSet}
                                          resourceId="SubsetTypeLabel"
                                        />
                                      }
                                      errorMessage={
                                        errorState.userTreeBranchAreaMappingSubsetTypeErrorMessage
                                      }
                                      isHorizontal={false}
                                    ></TPSelect>
                                  </TPFieldContainer>
                                )}
                                {treeProps["userTreeBranchAreaMapping"] &&
                                  treeProps[
                                  "userTreeBranchAreaMappingSubsetType"
                                  ] === "WHOLETREE" && (
                                    <TPFieldContainer>
                                      <TPTextBox
                                        id="IdTextBox"
                                        onChange={TPGlobal.foo}
                                        value={`[root:${treeProps["userTreeBranchAreaMapping"]}]`}
                                        disabled={true}
                                        labelText={
                                          <TPLanguage
                                            resourceSet={resourceSet}
                                            resourceId="AreaTreeParentBranchLabel"
                                          />
                                        }
                                      />
                                    </TPFieldContainer>
                                  )}
                                {treeProps["userTreeBranchAreaMapping"] &&
                                  treeProps[
                                  "userTreeBranchAreaMappingSubsetType"
                                  ] === "PARTIAL" && (
                                    <TPFieldContainer>
                                      <TPBranchSelection2
                                        labelText={
                                          <TPLanguage
                                            resourceSet={resourceSet}
                                            resourceId="DepartmentTreeMappingLabel"
                                          />
                                        }
                                        disabledTextBox={false}
                                        treeId={
                                          treeProps["userTreeBranchAreaMapping"]
                                        }
                                        modalAcceptLabel={modalAcceptLabel}
                                        modalCancelLabel={modalCancelLabel}
                                        modalSelectedBranchLabel={
                                          "modalSelectedBranchLabel"
                                        }
                                        modalTitle={modalTitleLabel}
                                        mustSelectLastLevelBranch={false}
                                        value={{
                                          branchId:
                                            treeProps[
                                            `userTreeBranchAreaMappingcurrentBranch`
                                            ],
                                          branchDescription:
                                            treeProps[
                                            `userTreeBranchAreaMappingcurrentDescriptionBranch`
                                            ],
                                          branchHierarchyDescription:
                                            treeProps[
                                            `userTreeBranchAreaMappingdefaultBranchHierarchyDescription`
                                            ],
                                        }}
                                        onChange={(
                                          branchId: string,
                                          branchDescription: string,
                                          branchHierachyDescription: string
                                        ) => {
                                          handleOnBranchChange(
                                            branchId,
                                            branchDescription,
                                            branchHierachyDescription,
                                            "userTreeBranchAreaMapping"
                                          );
                                        }}
                                        emptyLabel={emptyLabel}
                                        errorMessage={
                                          errorState.userBranchIdAreaMappingErrorMessage
                                        }
                                        onIsLoadingProgress={(
                                          value: boolean
                                        ) => {
                                          setIsLoadingScreen(value);
                                        }}
                                      ></TPBranchSelection2>
                                    </TPFieldContainer>
                                  )}
                              </>
                            )}
                          </TPConfigKeyValueItem>
                        </MenuContentBody>

                        {/* TopRowCountForCases */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[MenusEnum[MenusEnum.TopRowCountForCases]]
                              .title
                          }
                        </MenuContentTitle>

                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.TopRowCountForCases]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[MenusEnum[MenusEnum.TopRowCountForCases]]
                            }
                          >
                            <TPFieldContainer>
                              <TPNumeric
                                id="IdTPNumeric"
                                labelText={
                                  <TPLanguage
                                    resourceId={`topViewLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["topView"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey("topView", e)
                                }
                                errorMessage={errorState.topViewErrorMessage}
                              />
                            </TPFieldContainer>

                            <TPFieldContainer>
                              <TPNumeric
                                id="IdTPNumeric"
                                labelText={
                                  <TPLanguage
                                    resourceId={`topExportLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["topExport"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey("topExport", e)
                                }
                                errorMessage={errorState.topExportErrorMessage}
                              />
                            </TPFieldContainer>

                            <TPFieldContainer>
                              <TPNumeric
                                id="IdTPNumeric"
                                labelText={
                                  <TPLanguage
                                    resourceId={`topViewCustomerHistoryLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={
                                  keyValues["topViewCustomerHistory"]?.value
                                }
                                onChange={(e: any) =>
                                  changeValueForKey("topViewCustomerHistory", e)
                                }
                                errorMessage={
                                  errorState.topViewCustomerHistoryErrorMessage
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                        {/* ParameterToShortenComments */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[
                              MenusEnum[MenusEnum.ParameterToShortenComments]
                            ].title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[
                              MenusEnum[MenusEnum.ParameterToShortenComments]
                            ].description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[
                              MenusEnum[MenusEnum.ParameterToShortenComments]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPNumeric
                                id="IdTPNumeric"
                                labelText={
                                  <TPLanguage
                                    resourceId={`charactersNumberLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={
                                  keyValues["characterNumberCutStringComments"]
                                    ?.value
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "characterNumberCutStringComments",
                                    e
                                  )
                                }
                                errorMessage={
                                  errorState.characterNumberCutStringCommentsErrorMessage
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                        {/* cloneCaseWithTodayDate */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[
                              MenusEnum[MenusEnum.CloneCaseWithTodayDate]
                            ].title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[
                              MenusEnum[MenusEnum.CloneCaseWithTodayDate]
                            ].description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[
                              MenusEnum[MenusEnum.CloneCaseWithTodayDate]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`cloneCaseWithTodayDateLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["cloneCaseWithTodayDate"]?.value ===
                                  "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "cloneCaseWithTodayDate",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>

                        <MenuContentTitle>
                          {
                            menuList[
                              MenusEnum[
                              MenusEnum.SLARecalculationOnCaseReclassification
                              ]
                            ].title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[
                              MenusEnum[
                              MenusEnum.SLARecalculationOnCaseReclassification
                              ]
                            ].description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[
                              MenusEnum[
                              MenusEnum
                                .SLARecalculationOnCaseReclassification
                              ]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`caseSLARecalculateLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["reclassifyMovesLimitDate"]
                                    ?.value === "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "reclassifyMovesLimitDate",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                        {/* CASE RESPONSE TASK ORDER */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[MenusEnum[MenusEnum.CaseResponseTaskOrder]]
                              .title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.CaseResponseTaskOrder]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={
                              menuList[
                              MenusEnum[MenusEnum.CaseResponseTaskOrder]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`taskOrderLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["taskOrder"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey("taskOrder", e.target.value)
                                }
                                errorMessage={errorState.taskOrderErrorMessage}
                                dataSource={[
                                  { key: "", value: "..." },
                                  {
                                    key: "1",
                                    value: "Ascending",
                                  },
                                  {
                                    key: "0",
                                    value: "Descending",
                                  },
                                ]}
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                        {/* FIRST DAY OF WEEK */}
                        <MenuContentTitle className='menu-item'>
                          {menuList[MenusEnum[MenusEnum.FirstDayOfWeek]].title}
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.FirstDayOfWeek]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.CaseHandling]}
                            menu={menuList[MenusEnum[MenusEnum.FirstDayOfWeek]]}
                          >
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`firstDayOfWeekLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["getFirstDayOfWeek"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "getFirstDayOfWeek",
                                    e.target.value
                                  )
                                }
                                errorMessage={
                                  errorState.getFirstDayOfWeekErrorMessage
                                }
                                dataSource={[
                                  { key: "", value: "..." },
                                  {
                                    key: "0",
                                    value: "Sunday",
                                  },
                                  {
                                    key: "1",
                                    value: "Monday",
                                  },
                                ]}
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                    </CategoryContentBody>
                  </CategoryContentContainer>

                  {/* Modules Activation */}
                  <CategoryContentContainer>
                    <CategoryContentTitle>
                      <span className='menu-item'>
                        <TPLanguage
                          resourceId="ModulesActivationCategoryTitle"
                          resourceSet={componentFileName}
                        />
                      </span>
                      <TPIcon
                        iconType={TPIconTypes.expandMore}
                        onClick={() => {
                          let newCategoryList = { ...categoryList };
                          newCategoryList[
                            CategoriesEnum.ModulesActivation
                          ].accordionCollapsed =
                            !newCategoryList[CategoriesEnum.ModulesActivation]
                              .accordionCollapsed;
                          setCategoriList(newCategoryList);
                        }}
                      />
                    </CategoryContentTitle>

                    <CategoryContentBody
                      collapsed={
                        categoryList[CategoriesEnum.ModulesActivation]
                          .accordionCollapsed
                      }
                    >
                      {/* PrivateCasesAndTasks */}
                      <MenuContentContainer>
                        <MenuContentTitle>
                          {
                            menuList[MenusEnum[MenusEnum.PrivateCasesAndTasks]]
                              .title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.PrivateCasesAndTasks]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[
                              MenusEnum[MenusEnum.PrivateCasesAndTasks]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`privateCasesAndTasksLabel`}
                                    resourceSet={componentFileName}
                                  />
                                }
                                checked={
                                  keyValues["privateCasesAndTasks"]?.value ===
                                  "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "privateCasesAndTasks",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                      {/* PrivateCasesAndTasks End */}

                      {/* StoryfAITranslation */}
                      <MenuContentContainer>
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[MenusEnum[MenusEnum.StoryfAITranslation]]
                              .title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.StoryfAITranslation]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.StoryfAITranslation]]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`EnableStoryFai`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["enableStoryFai"]?.value === "1"
                                    ? true
                                    : false
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "enableStoryFai",
                                    e.target.checked === true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                            {keyValues["enableStoryFai"]?.value === "1" && (
                              <>
                                <TPFieldContainer>
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      changeValueForKey(
                                        "storyFaiServiceUrl",
                                        e.target.value
                                      )
                                    }
                                    value={
                                      keyValues["storyFaiServiceUrl"]?.value
                                    }
                                    labelText={
                                      <TPLanguage
                                        resourceId={`StoryFaiServiceUrl`}
                                        resourceSet={resourceSet}
                                      />
                                    }
                                    errorMessage={
                                      errorState.storyFaiServiceUrlErrorMessage
                                    }
                                  />
                                </TPFieldContainer>
                                <TPFieldContainer>
                                  <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                      changeValueForKey(
                                        "storyFaiApiKey",
                                        e.target.value
                                      )
                                    }
                                    value={keyValues["storyFaiApiKey"]?.value}
                                    labelText={
                                      <TPLanguage
                                        resourceId={`StroyFaiApiKey`}
                                        resourceSet={resourceSet}
                                      />
                                    }
                                    errorMessage={
                                      errorState.storyFaiApiKeyErrorMessage
                                    }
                                  />
                                </TPFieldContainer>
                                <TPFieldContainer>
                                  <div style={{ marginTop: "6px" }}>
                                    <TPCheckBox
                                      id="IdCheckBox"
                                      labelText={
                                        <TPLanguage
                                          resourceId={`StoryFaiPreview`}
                                          resourceSet={resourceSet}
                                        />
                                      }
                                      checked={
                                        keyValues["storyFaiPreview"]?.value ===
                                          "1"
                                          ? true
                                          : false
                                      }
                                      onChange={(e: any) =>
                                        changeValueForKey(
                                          "storyFaiPreview",
                                          e.target.checked === true ? "1" : "0"
                                        )
                                      }
                                    />
                                  </div>
                                </TPFieldContainer>
                                <TPFieldContainer>
                                  <TPAutoComplete
                                    id="storyfai-autocomplete"
                                    isMandatory={true}
                                    labelText={
                                      <TPLanguage
                                        resourceId={`StoryFaiAvailableLanguages`}
                                        resourceSet={resourceSet}
                                      />
                                    }
                                    onValueChange={
                                      handleAvailableLanguagesChange
                                    }
                                    onSearch={(query: string) =>
                                      handleAvailableLanguagesOnAutocompleteQuery(
                                        query
                                      )
                                    }
                                    isLoading={isLoading}
                                    options={
                                      autocompleteAvailableLanguagesOptions
                                    }
                                    withIcon={true}
                                    emptyLabel={emptyLabel}
                                    onKeyDown={
                                      handleAvailableLanguagesOnAutocompleteKeyDown
                                    }
                                    selected={
                                      modalAvailableLanguagesScreenState.newAvailableLanguages
                                    }
                                    errorMessage={
                                      modalAvailableLanguagesScreenState.newAvailableLanguagesErrorMessage
                                    }
                                    downArrowClick={
                                      handleAutoCompleteAvailableLanguagespNClick
                                    }
                                  />
                                </TPFieldContainer>
                                <TPFieldContainer>
                                  <TPStyledTable>
                                    <TPStyledHead>
                                      <TPStyledHeaderRow>
                                        <TPStyledHeadCell></TPStyledHeadCell>
                                        <TPStyledHeadCell>
                                          <TPStyledDiv>
                                            <span>{languageLabel}</span>
                                            <LanguageCountBadge>
                                              {languageData.length}
                                            </LanguageCountBadge>
                                          </TPStyledDiv>
                                        </TPStyledHeadCell>
                                      </TPStyledHeaderRow>
                                    </TPStyledHead>
                                    <TPStyledBody>
                                      {languageData.map((item) => (
                                        <TPStyledBodyRow key={item.key}>
                                          <TPStyledBodyCell
                                            onClick={() =>
                                              handleDelete(item.key)
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="20"
                                              height="20"
                                              fill="#EB0027"
                                              className="bi bi-trash3-fill"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                            </svg>
                                          </TPStyledBodyCell>
                                          <TPStyledBodyCell>
                                            {item.value}
                                          </TPStyledBodyCell>
                                        </TPStyledBodyRow>
                                      ))}
                                    </TPStyledBody>
                                  </TPStyledTable>
                                </TPFieldContainer>
                                <TPFieldContainer>
                                  <TPSelect
                                    id="IdSelect"
                                    labelText={
                                      <TPLanguage
                                        resourceId={`StoryFaiLanguageBase`}
                                        resourceSet={resourceSet}
                                      />
                                    }
                                    isMandatory={true}
                                    value={
                                      keyValues["storyFaiLanguageBase"]?.value
                                    }
                                    onChange={(e: any) =>
                                      changeValueForKey(
                                        "storyFaiLanguageBase",
                                        e.target.value
                                      )
                                    }
                                    // errorMessage={
                                    //   errorState.defaultLanguageErrorMessage
                                    // }
                                    dataSource={languageData}
                                  />
                                </TPFieldContainer>
                              </>
                            )}
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                      {/* StoryfAITranslation End */}

                      {/* TPGenAITranslation */}
                      {/* <MenuContentContainer> */}
                      <MenuContentTitle className='menu-item'>
                        {
                          menuList[MenusEnum[MenusEnum.TPGenAIIntegration]]
                            .title
                        }
                      </MenuContentTitle>
                      <MenuContentDescription className="alert alert-info">
                        {
                          menuList[MenusEnum[MenusEnum.TPGenAIIntegration]]
                            .description
                        }
                      </MenuContentDescription>
                      <MenuContentBody>
                        <TPConfigKeyValueItem
                          category={
                            categoryList[CategoriesEnum.ModulesActivation]
                          }
                          menu={
                            menuList[MenusEnum[MenusEnum.TPGenAIIntegration]]
                          }
                        >
                          <TPFieldContainer>
                            <TPCheckBox
                              id="IdCheckBox"
                              labelText={
                                <TPLanguage
                                  resourceId={`EnableTPGenAI`}
                                  resourceSet={resourceSet}
                                />
                              }
                              checked={
                                keyValues["enableTPGenAI"]?.value === "1"
                                  ? true
                                  : false
                              }
                              onChange={(e: any) =>
                                changeValueForKey(
                                  "enableTPGenAI",
                                  e.target.checked === true ? "1" : "0"
                                )
                              }
                            />
                          </TPFieldContainer>
                          {keyValues["enableTPGenAI"]?.value === "1" && (
                            <>
                              <TPFieldContainer>
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={true}
                                  onChange={(e: any) =>
                                    changeValueForKey(
                                      "tPGenAIServiceUrl",
                                      e.target.value
                                    )
                                  }
                                  value={keyValues["tPGenAIServiceUrl"]?.value}
                                  labelText={
                                    <TPLanguage
                                      resourceId={`TPGenAIServiceUrl`}
                                      resourceSet={resourceSet}
                                    />
                                  }
                                  errorMessage={
                                    errorState.tPGenAIServiceUrlErrorMessage
                                  }
                                />
                              </TPFieldContainer>
                              <TPFieldContainer>
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={true}
                                  onChange={(e: any) =>
                                    changeValueForKey(
                                      "tPGenAIApiKey",
                                      e.target.value
                                    )
                                  }
                                  value={keyValues["tPGenAIApiKey"]?.value}
                                  labelText={
                                    <TPLanguage
                                      resourceId={`TPGenAIApiKey`}
                                      resourceSet={resourceSet}
                                    />
                                  }
                                  errorMessage={
                                    errorState.tPGenAIApiKeyErrorMessage
                                  }
                                />
                              </TPFieldContainer>
                              {/* <TPFieldContainer>
                                <div style={{ marginTop: "6px" }}>
                                  <TPCheckBox
                                    id="IdCheckBox"
                                    labelText={
                                      <TPLanguage
                                        resourceId={`StoryFaiPreview`}
                                        resourceSet={resourceSet}
                                      />
                                    }
                                    checked={keyValues["storyFaiPreview"]?.value === "1" ? true : false}
                                    onChange={(e: any) => changeValueForKey("storyFaiPreview", e.target.checked === true ? "1" : "0")}
                                  />
                                </div>
                              </TPFieldContainer> */}
                              <TPFieldContainer>
                                <TPAutoComplete
                                  id="tpgenai-autocomplete"
                                  isMandatory={true}
                                  labelText={
                                    <TPLanguage
                                      resourceId={`TPGenAIAvailableLanguages`}
                                      resourceSet={resourceSet}
                                    />
                                  }
                                  onValueChange={
                                    handleAvailableLanguagesChange2
                                  }
                                  onSearch={(query: string) =>
                                    handleAvailableLanguagesOnAutocompleteQuery2(
                                      query
                                    )
                                  }
                                  isLoading={isLoading}
                                  options={
                                    autocompleteAvailableLanguagesOptions2
                                  }
                                  withIcon={true}
                                  emptyLabel={emptyLabel}
                                  onKeyDown={
                                    handleAvailableLanguagesOnAutocompleteKeyDown2
                                  }
                                  selected={
                                    modalAvailableLanguagesScreenState2.newAvailableLanguages
                                  }
                                  errorMessage={
                                    modalAvailableLanguagesScreenState2.newAvailableLanguagesErrorMessage
                                  }
                                  downArrowClick={
                                    handleAutoCompleteAvailableLanguagespNClick2
                                  }
                                ></TPAutoComplete>
                              </TPFieldContainer>
                              <TPFieldContainer>
                                <TPStyledTable>
                                  <TPStyledHead>
                                    <TPStyledHeaderRow>
                                      <TPStyledHeadCell></TPStyledHeadCell>
                                      <TPStyledHeadCell>
                                        <TPStyledDiv>
                                          <span>{languageLabel}</span>
                                          <LanguageCountBadge>
                                            {languageData2.length}
                                          </LanguageCountBadge>
                                        </TPStyledDiv>
                                      </TPStyledHeadCell>
                                    </TPStyledHeaderRow>
                                  </TPStyledHead>
                                  <TPStyledBody>
                                    {languageData2.map((item) => (
                                      <TPStyledBodyRow key={item.key}>
                                        <TPStyledBodyCell
                                          onClick={() =>
                                            handleDelete2(item.key)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#EB0027"
                                            className="bi bi-trash3-fill"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                          </svg>
                                        </TPStyledBodyCell>
                                        <TPStyledBodyCell>
                                          {item.value}
                                        </TPStyledBodyCell>
                                      </TPStyledBodyRow>
                                    ))}
                                  </TPStyledBody>
                                </TPStyledTable>
                              </TPFieldContainer>
                              <TPFieldContainer>
                                <TPSelect
                                  id="IdSelect"
                                  labelText={
                                    <TPLanguage
                                      resourceId={`TPGenAILanguageBase`}
                                      resourceSet={resourceSet}
                                    />
                                  }
                                  isMandatory={true}
                                  value={
                                    keyValues["tPGenAILanguageBase"]?.value
                                  }
                                  onChange={(e: any) =>
                                    changeValueForKey(
                                      "tPGenAILanguageBase",
                                      e.target.value
                                    )
                                  }
                                  // errorMessage={
                                  //   errorState.defaultLanguageErrorMessage
                                  // }
                                  dataSource={languageData2}
                                />
                              </TPFieldContainer>

                              <TPFieldContainer>
                                <TPTextBox
                                  id="IdTextBox"
                                  isMandatory={true}
                                  onChange={(e: any) =>
                                    changeValueForKey(
                                      "tPGenAIMinimumWordCount",
                                      e.target.value
                                    )
                                  }
                                  value={
                                    keyValues["tPGenAIMinimumWordCount"]?.value
                                  }
                                  labelText={
                                    <TPLanguage
                                      resourceId={`TPGenAIMinimumWordCount`}
                                      resourceSet={resourceSet}
                                    />
                                  }
                                  errorMessage={
                                    errorState.tPGenAIMinimumWordCountErrorMessage
                                  }
                                />
                              </TPFieldContainer>
                            </>
                          )}
                        </TPConfigKeyValueItem>
                      </MenuContentBody>
                      {/* TPGenAITranslation End */}

                      <MenuContentTitle className='menu-item'>
                        {menuList[MenusEnum[MenusEnum.RecentAgents]].title}
                      </MenuContentTitle>
                      <MenuContentDescription className="alert alert-info">
                        {
                          menuList[MenusEnum[MenusEnum.RecentAgents]]
                            .description
                        }
                      </MenuContentDescription>
                      <MenuContentBody>
                        <TPConfigKeyValueItem
                          category={
                            categoryList[CategoriesEnum.ModulesActivation]
                          }
                          menu={menuList[MenusEnum[MenusEnum.RecentAgents]]}
                        >
                          <TPFieldContainer>
                            <TPCheckBox
                              id="IdCheckBox"
                              labelText={
                                <TPLanguage
                                  resourceId={`customerPreferredAgentLabel`}
                                  resourceSet={resourceSet}
                                />
                              }
                              checked={
                                keyValues["customerPreferredAgent"]?.value ===
                                "1"
                              }
                              onChange={(e: any) =>
                                changeValueForKey(
                                  "customerPreferredAgent",
                                  e.target.checked == true ? "1" : "0"
                                )
                              }
                            />
                          </TPFieldContainer>
                          <TPFieldContainer>
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={true}
                              onChange={(e: any) =>
                                changeValueForKey(
                                  "daysToKeepRecentAgent",
                                  e.target.value
                                )
                              }
                              value={keyValues["daysToKeepRecentAgent"]?.value}
                              labelText={
                                <TPLanguage
                                  resourceId={`daysToKeepRecentAgentLabel`}
                                  resourceSet={resourceSet}
                                />
                              }
                              errorMessage={
                                errorState.daysToKeepRecentAgentErrorMessage
                              }
                            />
                          </TPFieldContainer>
                        </TPConfigKeyValueItem>
                      </MenuContentBody>

                      {/* </MenuContentContainer> */}
                      {/* TPGenAITranslation End */}

                      {/* mail reader module */}
                      <CollapsableMenuContainer>
                        <CollapsableMenuContentTitle
                          onClick={() => setCollapsedSections(prevState => ({
                            ...prevState,
                            MailReaderModule: !prevState['MailReaderModule'],
                          }))}
                        >
                          <span>{menuList[MenusEnum[MenusEnum.MailReaderModule]].title}</span>
                          <TPIcon
                            iconType={
                              collapsedSections['MailReaderModule']
                                ? TPIconTypes.expandMore
                                : TPIconTypes.expandLess
                            }
                          />
                        </CollapsableMenuContentTitle>

                        <TPSwitch
                          checked={keyValues["mailReaderModuleActive"]?.value === "1"}
                          onChange={() => {
                            changeValueForKey(
                              "mailReaderModuleActive",
                              keyValues["mailReaderModuleActive"]?.value === "1" ? "0" : "1"
                            )
                          }}
                          labelText={
                            <TPLanguage
                              resourceId={`ActiveLabel`}
                              resourceSet={resourceSet}
                            />
                          }
                        />
                      </CollapsableMenuContainer>

                      <CollapsableCategoryContentBody
                        collapsed={collapsedSections['MailReaderModule']}
                      >
                        <CollapsableMenuContentDescription>
                          {
                            menuList[MenusEnum[MenusEnum.MailReaderModule]]
                              .description
                          }
                        </CollapsableMenuContentDescription>
                        <CollapsableMenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.MailReaderModule]]
                            }
                          >
                            <TPCollapsableFieldContainer>
                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={true}
                                onChange={(e: any) =>
                                  changeValueForKey("inboundEmailCaseNumberPattern", e.target.value)
                                }
                                value={keyValues["inboundEmailCaseNumberPattern"]?.value}
                                labelText={
                                  <TPLanguage
                                    resourceId={`inboundEmailCaseNumberPatternLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                errorMessage={errorState.inboundEmailCaseNumberPatternErrorMessage}
                              />
                            </TPCollapsableFieldContainer>

                            <TPCollapsableFieldContainer>
                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={true}
                                onChange={(e: any) =>
                                  changeValueForKey("mailReaderMaxLengthAddress", e.target.value)
                                }
                                value={keyValues["mailReaderMaxLengthAddress"]?.value}
                                labelText={
                                  <TPLanguage
                                    resourceId={`MailReaderMaxLengthAddressLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                errorMessage={errorState.mailReaderMaxLengthAddressErrorMessage}
                              />
                            </TPCollapsableFieldContainer>

                            <TPCollapsableFieldContainer>
                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={true}
                                onChange={(e: any) =>
                                  changeValueForKey("maximumLengthMailBody", e.target.value)
                                }
                                value={keyValues["maximumLengthMailBody"]?.value}
                                labelText={
                                  <TPLanguage
                                    resourceId={`maximumLengthMailBodyLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                errorMessage={errorState.maximumLengthMailBodyErrorMessage}
                              />
                            </TPCollapsableFieldContainer>
                          </TPConfigKeyValueItem>
                        </CollapsableMenuContentBody>
                      </CollapsableCategoryContentBody>
                      {/* mail reader module end */}

                      {/* Events Manager */}
                      <CollapsableMenuContainer>
                        <CollapsableMenuContentTitle
                          onClick={() => setCollapsedSections(prevState => ({
                            ...prevState,
                            EventsManager: !prevState['EventsManager'],
                          }))}
                        >
                          <span>{menuList[MenusEnum[MenusEnum.EventsManager]].title}</span>
                          <TPIcon
                            iconType={
                              collapsedSections['EventsManager']
                                ? TPIconTypes.expandMore
                                : TPIconTypes.expandLess
                            }
                          />
                        </CollapsableMenuContentTitle>

                        <TPSwitch
                          checked={keyValues["eventsManagerActive"]?.value === "1"}
                          onChange={() => {
                            changeValueForKey(
                              "eventsManagerActive",
                              keyValues["eventsManagerActive"]?.value === "1" ? "0" : "1"
                            )
                          }}
                          labelText={
                            <TPLanguage
                              resourceId={`ActiveLabel`}
                              resourceSet={resourceSet}
                            />
                          }
                        />
                      </CollapsableMenuContainer>

                      <CollapsableCategoryContentBody
                        collapsed={collapsedSections['EventsManager']}
                      >
                        <CollapsableMenuContentDescription>
                          {
                            menuList[MenusEnum[MenusEnum.EventsManager]]
                              .description
                          }
                        </CollapsableMenuContentDescription>
                        <CollapsableMenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.EventsManager]]
                            }
                          />
                        </CollapsableMenuContentBody>
                      </CollapsableCategoryContentBody>
                      {/* Events Manager End */}

                      {/* Time Tracking */}
                      <CollapsableMenuContainer>
                        <CollapsableMenuContentTitle
                          onClick={() => setCollapsedSections(prevState => ({
                            ...prevState,
                            TimeTracking: !prevState['TimeTracking'],
                          }))}
                        >
                          <span>{menuList[MenusEnum[MenusEnum.TimeTracking]].title}</span>
                          <TPIcon
                            iconType={
                              collapsedSections['TimeTracking']
                                ? TPIconTypes.expandMore
                                : TPIconTypes.expandLess
                            }
                          />
                        </CollapsableMenuContentTitle>

                        <TPSwitch
                          checked={keyValues["timeTrackingActive"]?.value === "1"}
                          onChange={() => {
                            changeValueForKey(
                              "timeTrackingActive",
                              keyValues["timeTrackingActive"]?.value === "1" ? "0" : "1"
                            )
                          }}
                          labelText={
                            <TPLanguage
                              resourceId={`ActiveLabel`}
                              resourceSet={resourceSet}
                            />
                          }
                        />
                      </CollapsableMenuContainer>

                      <CollapsableCategoryContentBody
                        collapsed={collapsedSections['TimeTracking']}
                      >
                        <CollapsableMenuContentDescription>
                          {
                            menuList[MenusEnum[MenusEnum.TimeTracking]]
                              .description
                          }
                        </CollapsableMenuContentDescription>
                        <CollapsableMenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.TimeTracking]]
                            }
                          />
                        </CollapsableMenuContentBody>
                      </CollapsableCategoryContentBody>
                      {/* Time Tracking End */}

                      {/* Knowledge Base Management */}
                      <CollapsableMenuContainer>
                        <CollapsableMenuContentTitle
                          onClick={() => setCollapsedSections(prevState => ({
                            ...prevState,
                            KnowledgeBaseManagement: !prevState['KnowledgeBaseManagement'],
                          }))}
                        >
                          <span>{menuList[MenusEnum[MenusEnum.KnowledgeBaseManagement]].title}</span>
                          <TPIcon
                            iconType={
                              collapsedSections['KnowledgeBaseManagement']
                                ? TPIconTypes.expandMore
                                : TPIconTypes.expandLess
                            }
                          />
                        </CollapsableMenuContentTitle>

                        <TPSwitch
                          checked={keyValues["knowledgeBaseManagementActive"]?.value === "1"}
                          onChange={() => {
                            changeValueForKey(
                              "knowledgeBaseManagementActive",
                              keyValues["knowledgeBaseManagementActive"]?.value === "1" ? "0" : "1"
                            )
                          }}
                          labelText={
                            <TPLanguage
                              resourceId={`ActiveLabel`}
                              resourceSet={resourceSet}
                            />
                          }
                        />
                      </CollapsableMenuContainer>

                      <CollapsableCategoryContentBody
                        collapsed={collapsedSections['KnowledgeBaseManagement']}
                      >
                        <CollapsableMenuContentDescription>
                          {
                            menuList[MenusEnum[MenusEnum.KnowledgeBaseManagement]]
                              .description
                          }
                        </CollapsableMenuContentDescription>
                        <CollapsableMenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.KnowledgeBaseManagement]]
                            }
                          />
                        </CollapsableMenuContentBody>
                      </CollapsableCategoryContentBody>
                      {/* Knowledge Base Management End */}

                      {/* Cognitive Analysis and Grammar Checker */}
                      <CollapsableMenuContainer>
                        <CollapsableMenuContentTitle
                          onClick={() => setCollapsedSections(prevState => ({
                            ...prevState,
                            CognitiveAnalysisAndGrammarChecker: !prevState['CognitiveAnalysisAndGrammarChecker'],
                          }))}
                        >
                          <span>{menuList[MenusEnum[MenusEnum.CognitiveAnalysisAndGrammarChecker]].title}</span>
                          <TPIcon
                            iconType={
                              collapsedSections['CognitiveAnalysisAndGrammarChecker']
                                ? TPIconTypes.expandMore
                                : TPIconTypes.expandLess
                            }
                          />
                        </CollapsableMenuContentTitle>

                        <TPSwitch
                          checked={keyValues["enableCognitiveAnalysisAndGrammarChecker"]?.value === "1"}
                          onChange={() => {
                            changeValueForKey(
                              "enableCognitiveAnalysisAndGrammarChecker",
                              keyValues["enableCognitiveAnalysisAndGrammarChecker"]?.value === "1" ? "0" : "1"
                            )
                          }}
                          labelText={
                            <TPLanguage
                              resourceId={`ActiveLabel`}
                              resourceSet={resourceSet}
                            />
                          }
                        />
                      </CollapsableMenuContainer>

                      <CollapsableCategoryContentBody
                        collapsed={collapsedSections['CognitiveAnalysisAndGrammarChecker']}
                      >
                        <CollapsableMenuContentDescription>
                          {
                            menuList[MenusEnum[MenusEnum.CognitiveAnalysisAndGrammarChecker]]
                              .description
                          }
                        </CollapsableMenuContentDescription>
                        <CollapsableMenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.ModulesActivation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.CognitiveAnalysisAndGrammarChecker]]
                            }
                          />
                          {keyValues["enableCognitiveAnalysisAndGrammarChecker"]?.value === "1" && <>
                          {/* <TPCollapsableFieldContainer> */}
                            <TPFieldContainer>
                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={true}
                                onChange={(e: any) =>
                                  changeValueForKey("cognitiveAnalysisURL", e.target.value)
                                }
                                value={keyValues["cognitiveAnalysisURL"]?.value}
                                labelText={
                                  <TPLanguage
                                    resourceId={`cognitiveAnalysisURLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                errorMessage={errorState.cognitiveAnalysisURLErrorMessage}
                              />
                              </TPFieldContainer>
                            {/* </TPCollapsableFieldContainer> */}

                            {/* <TPCollapsableFieldContainer> */}
                            <TPFieldContainer>

                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={false}
                                onChange={(e: any) =>
                                  changeValueForKey("cognitiveAnalysisAzureKey", e.target.value)
                                }
                                value={keyValues["cognitiveAnalysisAzureKey"]?.value}
                                labelText={
                                  <TPLanguage
                                    resourceId={`cognitiveAnalysisAzureKeyLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                errorMessage={errorState.cognitiveAnalysisAzureKeyErrorMessage}
                              />
                                </TPFieldContainer>
                            {/* </TPCollapsableFieldContainer> */}
                            </>}
                        </CollapsableMenuContentBody>
                        
                      </CollapsableCategoryContentBody>
                      {/* Cognitive Analysis and Grammar Checker End */}

                    </CategoryContentBody>
                  </CategoryContentContainer>

                  {/* logo */}
                  <CategoryContentContainer>
                    <CategoryContentTitle>
                      <span className='menu-item'>
                        <TPLanguage
                          resourceId="LogoCategoryTitle"
                          resourceSet={componentFileName}
                        />
                      </span>
                      <TPIcon
                        iconType={TPIconTypes.expandMore}
                        onClick={() => {
                          let newCategoryList = { ...categoryList };
                          newCategoryList[
                            CategoriesEnum.Logo
                          ].accordionCollapsed =
                            !newCategoryList[CategoriesEnum.Logo]
                              .accordionCollapsed;
                          setCategoriList(newCategoryList);
                        }}
                      />
                    </CategoryContentTitle>
                    <CategoryContentBody
                      collapsed={
                        categoryList[CategoriesEnum.Logo].accordionCollapsed
                      }
                    >
                      <MenuContentContainer>
                        {/* Logo */}
                        <MenuContentTitle className='menu-item'>
                          {menuList[MenusEnum[MenusEnum.logo]].title}
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {menuList[MenusEnum[MenusEnum.logo]].description}
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.Logo]}
                            menu={menuList[MenusEnum[MenusEnum.logo]]}
                          >
                            <TPFieldContainer>
                              <div className="row">
                                <div className="col">
                                  <TPLabel
                                    labelText={
                                      <TPLanguage
                                        resourceId={"CurrentLogoLabel"}
                                        resourceSet={resourceSet}
                                      ></TPLanguage>
                                    }
                                  />
                                  <div>
                                    {contextData.companyLogo &&
                                      !base64String && (
                                        <img
                                          src={
                                            "data:image/png;base64, " +
                                            contextData.companyLogo
                                          }
                                        />
                                      )}
                                    {base64String && (
                                      <>
                                        <div>
                                          <img
                                            src={
                                              "data:image/png;base64, " +
                                              base64String
                                            }
                                          />
                                        </div>
                                        {fileName}
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <FileUploader
                                      label={
                                        <TPLanguage
                                          resourceId={"DropFilesHereLabel"}
                                          resourceSet={resourceSet}
                                        ></TPLanguage>
                                      }
                                      multiple={true}
                                      handleChange={
                                        handleUploadFilesDraggedChange
                                      }
                                      UploadButtonText={
                                        <TPLanguage
                                          resourceId={"UploadFileButtonLabel"}
                                          resourceSet={resourceSet}
                                        ></TPLanguage>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                    </CategoryContentBody>
                  </CategoryContentContainer>

                  {/* Customer Information */}
                  <CategoryContentContainer>
                    <CategoryContentTitle>
                      <span className='menu-item'>
                        <TPLanguage
                          resourceId="CustomerInformationTitle"
                          resourceSet={componentFileName}
                        />
                      </span>
                      <TPIcon
                        iconType={TPIconTypes.expandMore}
                        onClick={() => {
                          let newCategoryList = { ...categoryList };
                          newCategoryList[
                            CategoriesEnum.CustomerInformation
                          ].accordionCollapsed =
                            !newCategoryList[CategoriesEnum.CustomerInformation]
                              .accordionCollapsed;
                          setCategoriList(newCategoryList);
                        }}
                      />
                    </CategoryContentTitle>
                    <CategoryContentBody
                      collapsed={
                        categoryList[CategoriesEnum.CustomerInformation]
                          .accordionCollapsed
                      }
                    >
                      <MenuContentContainer>
                        {/* EnableAnonimous */}
                        <MenuContentTitle className='menu-item'>
                          {menuList[MenusEnum[MenusEnum.EnableAnonimous]].title}
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.EnableAnonimous]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.CustomerInformation]
                            }
                            menu={
                              menuList[MenusEnum[MenusEnum.EnableAnonimous]]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`showAnonymousLinkCasesLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["showAnonymousLinkCases"]?.value ===
                                  "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "showAnonymousLinkCases",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                        {/* CUSTOMER CONTACTS AND PRODUCT */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[
                              MenusEnum[MenusEnum.CustomerContactsAndProducts]
                            ].title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[
                              MenusEnum[MenusEnum.CustomerContactsAndProducts]
                            ].description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={
                              categoryList[CategoriesEnum.CustomerInformation]
                            }
                            menu={
                              menuList[
                              MenusEnum[MenusEnum.CustomerContactsAndProducts]
                              ]
                            }
                          >
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`showCustomerContactsLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["showCustomerContacts"]?.value ===
                                  "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "showCustomerContacts",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`showCustomerProductsLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["showCustomerProducts"]?.value ===
                                  "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "showCustomerProducts",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                            <TPFieldContainer>
                              <TPCheckBox
                                id="IdCheckBox"
                                labelText={
                                  <TPLanguage
                                    resourceId={`adminCustomerProductsLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                checked={
                                  keyValues["adminCustomerProducts"]?.value ===
                                  "1"
                                }
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "adminCustomerProducts",
                                    e.target.checked == true ? "1" : "0"
                                  )
                                }
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                    </CategoryContentBody>
                  </CategoryContentContainer>

                  <CategoryContentContainer>
                    <CategoryContentTitle>
                      <span className='menu-item'>
                        <TPLanguage
                          resourceId="GeneralTitle"
                          resourceSet={componentFileName}
                        />
                      </span>
                      <TPIcon
                        iconType={TPIconTypes.expandMore}
                        onClick={() => {
                          let newCategoryList = { ...categoryList };
                          newCategoryList[
                            CategoriesEnum.General
                          ].accordionCollapsed =
                            !newCategoryList[CategoriesEnum.General]
                              .accordionCollapsed;
                          setCategoriList(newCategoryList);
                        }}
                      />
                    </CategoryContentTitle>
                    <CategoryContentBody
                      collapsed={
                        categoryList[CategoriesEnum.CustomerInformation]
                          .accordionCollapsed
                      }
                    >
                      <MenuContentContainer>
                        {/* CUSTOMER CONTACTS AND PRODUCT */}
                        <MenuContentTitle className='menu-item'>
                          {
                            menuList[MenusEnum[MenusEnum.UserAdditionalData]]
                              .title
                          }
                        </MenuContentTitle>
                        <MenuContentDescription className="alert alert-info">
                          {
                            menuList[MenusEnum[MenusEnum.UserAdditionalData]]
                              .description
                          }
                        </MenuContentDescription>
                        <MenuContentBody>
                          <TPConfigKeyValueItem
                            category={categoryList[CategoriesEnum.General]}
                            menu={
                              menuList[MenusEnum[MenusEnum.UserAdditionalData]]
                            }
                          >
                            <TPFieldContainer>
                              <TPSelect
                                id="IdSelect"
                                labelText={
                                  <TPLanguage
                                    resourceId={`userAddaFormLabel`}
                                    resourceSet={resourceSet}
                                  />
                                }
                                isMandatory={true}
                                value={keyValues["userAddaForm"]?.value}
                                onChange={(e: any) =>
                                  changeValueForKey(
                                    "userAddaForm",
                                    e.target.value
                                  )
                                }
                                errorMessage={
                                  errorState.userAddaFormErrorMessage
                                }
                                dataSource={additionalDataList}
                              />
                            </TPFieldContainer>
                          </TPConfigKeyValueItem>
                        </MenuContentBody>
                      </MenuContentContainer>
                    </CategoryContentBody>
                  </CategoryContentContainer>
                </TPLoadingOverlay>
              </ContentContainer>

              <FooterContainer>
                <div className="d-flex justify-content-end align-items-center">
                  <TPButton
                    id="IdButton"
                    onClick={() => {
                      handleOnSaveClick();
                    }}
                    type={TPButtonTypes.primary}
                    disabled={!someChange}
                  >
                    <TPLanguage
                      resourceId="SaveButtonLabel"
                      resourceSet={TPGlobal.globalResourceSet}
                    />
                  </TPButton>
                </div>
              </FooterContainer>
            </Marker>
          </TPConfigKeyValueContainerStyled>
        </>
      )}
    </ContentVerticalNoTabsStyled>
  );
};

export default ConfigurationValuesNew;
