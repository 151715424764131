import allThemes from "@/assets/styles/theme";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import { TPBadge } from "@/components/TPBadge/TPBadge";
import { TPChip } from "@/components/TPChip/TPChip";
import { TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPPaginator } from "@/components/TPPaginator/TPPaginator";
import { TPRadio } from "@/components/TPRadio/TPRadio";
import TPCaseViewerContext from "@/contexts/TPCaseViewerContext";
import TPGlobal from "@/helpers/TPGlobal";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import { CaseStatus } from "@/models/Cases/CaseStatus";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { GroupTakeNextGroupModel } from "@/models/GroupTakeNext/GroupTakeNextModel";
import { CaseService } from "@/services/CaseService";
import { FollowUpService } from "@/services/FollowUpService";
import { GroupTakeNextService } from "@/services/GroupTakeNextService";
import { TPI18N } from "@/services/I18nService";
import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { PaginationComponent } from "react-data-table-component/dist/DataTable/types";
import { orderColumnsEnum, realFoldersTypeEnum } from "../MyTasks/MyTasks";
import GroupTakeNextCard from "./GroupTakeNextCard";
import "./Styles.css";

interface TableColumn {
  name: any;
  selector: (row: any) => any;
  sortable: boolean;
  sortFunction?: (
    a: GroupTakeNextGroupModel,
    b: GroupTakeNextGroupModel,
  ) => number;
}
const ViewOptions = {
  list: "list",
  card: "card",
};
const OrderOptions = {
  date: "date",
  task: "task",
};
const prioritySortFunction = function (
  rowA: GroupTakeNextGroupModel,
  rowB: GroupTakeNextGroupModel,
): number {
  if (rowA.priorityWeight > rowB.priorityWeight) return 1;
  if (rowA.priorityWeight < rowB.priorityWeight) return -1;
  return 0;
};

const labelsResourceSet = "GroupTakeNext";

const GroupTakeNextComponent = function () {
  const casecontext: any = React.useContext(TPCaseViewerContext);
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false);
  const [currentData, setCurrentData] = useState<GroupTakeNextGroupModel[]>([]);
  const [filteredData, setFilteredData] = useState<GroupTakeNextGroupModel[]>(
    [],
  );
  const [selectedItems, setSelectedItems] = useState<GroupTakeNextGroupModel[]>(
    [],
  );
  const [currentView, setCurrentView] = useState<string>(ViewOptions.list);
  const [selectedOrder, setSelectedOrder] = useState<string>(OrderOptions.date);
  const [showCases, setShowCases] = useState<boolean>(true);
  const [showRecords, setShowRecords] = useState<boolean>(true);
  const [pendingCases, setPendingCases] = useState<number>(0);
  const [columns, setColumns] = useState<TableColumn[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [takeNextCooldown, setTakeNextCooldown] = useState<number>(5);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [currentCaseId, setCurrentCaseId] = useState<number>(-1);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [goOnlineCooldown, setGoOnlineCooldown] = useState<number>(1);
  const [dataRefreshTimer, setDataRefreshTimer] = useState<number>(20);

  const [pageTitle, setPageTitle] = useState<string>("");
  const [infoText, setInfoText] = useState<string>("");
  const [readyLabel, setReadyLabel] = useState<string>("");
  const [notReadyLabel, setNotReadyLabel] = useState<string>("");
  const [pendingCasesLabel, setPendingCasesLabel] = useState<string>("");
  const [orderByTaskLabel, setOrderByTaskLabel] = useState<string>("");
  const [orderByDateLabel, setOrderByDateLabel] = useState<string>("");
  const [searchLabel, setSearchLabel] = useState<string>("");
  const [casesLabel, setCasesLabel] = useState<string>("");
  const [recordLabel, setRecordLabel] = useState<string>("");
  const [currentMomentId, setCurrentMomentId] = useState<string>("");

  const [groupHeaderLabel, setGroupHeaderLabel] = useState<string>("");
  const [typeHeaderLabel, setTypeHeaderLabel] = useState<string>("");
  const [priorityHeaderLabel, setPriorityHeaderLabel] = useState<string>("");
  const [readyHeaderLabel, setReadyHeaderLabel] = useState<string>("");
  const [processingHeaderLabel, setProcessingHeaderLabel] =
    useState<string>("");
  const [inQueueHeaderLabel, setInQueueHeaderLabel] = useState<string>("");
  const [readyNotReadyHeaderLabel, setReadyNotReadyHeaderLabel] =
    useState<string>("");

  const [lowPriorityLabel, setLowPriorityLabel] = useState<string>("");
  const [mediumPriorityLabel, setMediumPriorityLabel] = useState<string>("");
  const [highPriorityLabel, setHighPriorityLabel] = useState<string>("");
  const [notResolvedLabel, setNotResolvedLabel] = useState<string>("");
  const [availableLabel, setAvailableLabel] = useState<string>("");
  const [notAvailableLabel, setNotAvailableLabel] = useState<string>("");
  const [noDataLabel, setNoDataLabel] = useState<string>("");
  const [caseLabel, setCaseLabel] = useState<string>("");

  const loadLabels = async function () {
    setPageTitle(await TPI18N.GetText(labelsResourceSet, "PageTitle"));
    setInfoText(await TPI18N.GetText(labelsResourceSet, "InfoText"));
    setReadyLabel(await TPI18N.GetText(labelsResourceSet, "OnlineLabel"));
    setNotReadyLabel(await TPI18N.GetText(labelsResourceSet, "GoOnlineLabel"));
    setPendingCasesLabel(
      await TPI18N.GetText(labelsResourceSet, "PendingCasesLabel"),
    );
    setOrderByTaskLabel(
      await TPI18N.GetText(labelsResourceSet, "OrderByTaskLabel"),
    );
    setOrderByDateLabel(
      await TPI18N.GetText(labelsResourceSet, "OrderByDateLabel"),
    );
    setSearchLabel(await TPI18N.GetText(labelsResourceSet, "SearchLabel"));
    setCasesLabel(await TPI18N.GetText(labelsResourceSet, "CasesLabel"));
    setRecordLabel(await TPI18N.GetText(labelsResourceSet, "RecordLabel"));
    setGroupHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "GroupHeaderLabel"),
    );
    setTypeHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "TypeHeaderLabel"),
    );
    setPriorityHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "PriorityHeaderLabel"),
    );
    setReadyHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "ReadyHeaderLabel"),
    );
    setProcessingHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "ProcessingHeaderLabel"),
    );
    setInQueueHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "InQueueHeaderLabel"),
    );
    setReadyNotReadyHeaderLabel(
      await TPI18N.GetText(labelsResourceSet, "ReadyNotReadyHeaderLabel"),
    );
    setLowPriorityLabel(
      await TPI18N.GetText(labelsResourceSet, "LowPriorityLabel"),
    );
    setMediumPriorityLabel(
      await TPI18N.GetText(labelsResourceSet, "MediumPriorityLabel"),
    );
    setHighPriorityLabel(
      await TPI18N.GetText(labelsResourceSet, "HighPriorityLabel"),
    );
    setNotResolvedLabel(
      await TPI18N.GetText(labelsResourceSet, "NotResolvedLabel"),
    );
    setAvailableLabel(
      await TPI18N.GetText(labelsResourceSet, "AvailableLabel"),
    );
    setNotAvailableLabel(
      await TPI18N.GetText(labelsResourceSet, "NotAvailableLabel"),
    );
    setNoDataLabel(await TPI18N.GetText(labelsResourceSet, "NoDataLabel"));
    setCaseLabel(await TPI18N.GetText(labelsResourceSet, "CaseLabel"));
    setLabelsLoaded(true);
  };

  const resetTakeNext = () => {
    setTakeNextCooldown(5);
    setCurrentCaseId(-1);
  };

  const refreshData = function () {
    setSearchInput("");
    setLoadingContent(true);
    const gtnServiceInstance = new GroupTakeNextService();
    gtnServiceInstance
      .getTakeNext(TPGlobal.currentUserGuid, false, false, [200])
      .then((response) => {
        setCurrentData(response);
        setFilteredData(response);
        setLoadingContent(false);
      })
      .catch((error) => console.error(error));
    const followUpServiceInstance = new FollowUpService();
    followUpServiceInstance
      .getFollowUpCasesByFolderAndFilter(
        {
          followUpType: realFoldersTypeEnum.PendingCases,
          userGuid: TPGlobal.currentUserGuid,
          startRecord: 1,
          endRecord: 999,
          orderedColumn: orderColumnsEnum.CASENUMBER,
          orderDirection: "asc",
          tempTaskSearchFilter: [],
        },
        false,
        false,
        [200],
      )
      .then((response) => {
        if (response) setPendingCases(response.length);
      })
      .catch((error) => console.error(error));
  };

  const fishCase = function () {
    if (selectedItems.length > 0) {
      setIsReady(true);
      GroupTakeNextService.fishCase(
        {
          orderType: "case",
          usersReadyByGroup: selectedItems.map((item) => {
            return {
              groupId: item.groupId,
              usersReady: item.ready,
            };
          }),
          userId: TPGlobal.currentUserGuid,
        },
        false,
        false,
        [200],
      )
        .then((response) => {
          if (response && Number(response.caseId) > 0) {
            setCurrentCaseId(Number(response.caseId));
            casecontext.showTakeNextCase(response, resetTakeNext);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  const handleButtonClick = function () {
    if (!isReady) {
      if (selectedItems.length > 0) setIsReady(true);
    } else {
      resetTakeNext();
      setIsReady(false);
    }
  };

  useEffect(() => {
    loadLabels();
    GroupTakeNextService.insertMoment(
      {
        userId: TPGlobal.currentUserGuid,
        sortOrder: "case",
        isReady: isReady,
      },
      false,
      false,
      [200],
    )
      .then((response) => {
        if (response)
          setCurrentMomentId(response.keyList[0].value || currentMomentId);
      })
      .catch((error) => console.error(error));
    refreshData();
    setLoadingContent(false);
  }, []);

  useEffect(() => {
    if (currentCaseId > 0 && takeNextCooldown == 0) {
      const caseServiceInstance = new CaseService();
      caseServiceInstance
        .getCaseInfoByCaseNumber(currentCaseId, false, false, [200])
        .then((caseData) => {
          if (caseData.caseStatusId == CaseStatus.SO) {
            resetTakeNext();
          }
        })
        .catch((error) => console.error(error));
    }
  }, [takeNextCooldown]);

  useEffect(() => {
    setTakeNextCooldown(0);
    if (goOnlineCooldown <= 0) {
      GroupTakeNextService.insertStatistics(
        currentMomentId,
        {
          isReady: isReady,
          userId: TPGlobal.currentUserGuid,
          usersReadyByGroup: selectedItems.map((item) => {
            return {
              groupId: item.groupId,
              usersReady: item.ready,
            };
          }),
        },
        false,
        false,
        [200],
      );
      setGoOnlineCooldown(2);
    }
  }, [isReady]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (takeNextCooldown > 0 && isReady) {
        setTakeNextCooldown(takeNextCooldown - 1);
      } else if (
        takeNextCooldown == 0 &&
        selectedItems.length > 0 &&
        currentCaseId < 0 &&
        isReady
      ) {
        fishCase();
      }
      if (takeNextCooldown <= 0) {
        if (isReady) {
          GroupTakeNextService.keepAlive(currentMomentId, false, false, [200]);
        }
        setTakeNextCooldown(5);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (dataRefreshTimer > 0) {
        setDataRefreshTimer(dataRefreshTimer - 1);
      } else {
        refreshData();
        setDataRefreshTimer(20);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (goOnlineCooldown > 0) setGoOnlineCooldown(goOnlineCooldown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [goOnlineCooldown]);

  useEffect(() => {
    searchInput && searchInput.length > 0
      ? setFilteredData(
          currentData.filter((data) =>
            data.description.toLowerCase().includes(searchInput.toLowerCase()),
          ),
        )
      : setFilteredData(currentData);
    setCurrentPage(1);
  }, [searchInput]);

  useEffect(() => {
    if (allSelected) {
      setSelectedItems([...filteredData]);
    } else {
      setSelectedItems([]);
    }
  }, [allSelected]);

  useEffect(() => {
    setColumns([
      {
        name: <b>{groupHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) => row.description,
        sortable: true,
      },
      {
        name: <b>{typeHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) => (
          <TPChip
            backgroundColor="#FBEBFF"
            iconColor="#C30091"
            icon={TPIconTypes.circle}
            label={caseLabel}
          />
        ),
        sortable: true,
      },
      {
        name: <b>{priorityHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) => {
          if (row.priorityWeight >= 1 || row.priorityWeight <= 5) {
            return (
              <TPChip
                backgroundColor="#D3EAFF"
                iconColor="#3047B0"
                icon={TPIconTypes.circle}
                label={lowPriorityLabel}
              />
            );
          } else if (row.priorityWeight >= 6 || row.priorityWeight <= 10) {
            return (
              <TPChip
                backgroundColor="#FFEAC5"
                iconColor="#FD9F00"
                icon={TPIconTypes.circle}
                label={mediumPriorityLabel}
              />
            );
          } else if (row.priorityWeight > 10) {
            return (
              <TPChip
                backgroundColor="#FFD7DD"
                iconColor="#EB0027"
                icon={TPIconTypes.circle}
                label={highPriorityLabel}
              />
            );
          }
          return (
            <TPChip
              backgroundColor="#e0e0e0"
              iconColor="#a0a0a0"
              icon={TPIconTypes.circle}
              label={notResolvedLabel}
            />
          );
        },
        sortable: true,
        sortFunction: prioritySortFunction,
      },
      {
        name: <b>{readyHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) => row.ready,
        sortable: true,
      },
      {
        name: <b>{processingHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) => row.processing,
        sortable: true,
      },
      {
        name: <b>{inQueueHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) => row.inQueue,
        sortable: true,
      },
      {
        name: <b>{readyNotReadyHeaderLabel.toUpperCase()}</b>,
        selector: (row: GroupTakeNextGroupModel) =>
          row.ready > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#B1F2D7",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <Icon icon="gg:check-o" />
              </div>
              {availableLabel}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div
                style={{
                  backgroundColor: "#FFD7DD",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4px",
                }}
              >
                <Icon icon="akar-icons:circle-alert" />
              </div>
              {notAvailableLabel}
            </div>
          ),
        sortable: false,
      },
    ]);
  }, [loadingContent, labelsLoaded]);

  return (
    <ContentVerticalNoTabsStyled>
      <div
        id="group-take-next"
        className="container-fluid"
        style={{ overflow: "auto", height: "85vh", flex: 1 }}
      >
        <TPLoadingOverlay active={loadingContent}>
          <TPPageTitle id="GTN-page-title">{pageTitle}</TPPageTitle>
          <div className="master-container">
            <div id="GTN-header">
              <div id="GTN-info">
                <Icon
                  icon="tabler:alert-circle"
                  width="20px"
                  height="20px"
                  color="#3047B0"
                />
                <label>{infoText}</label>
              </div>
              <div id="GTN-status-toggle-container">
                {!isReady ? (
                  <button
                    id="GTN-status-select"
                    type="button"
                    className="btn btn-warning"
                    onClick={handleButtonClick}
                    // onClick={() => { handleReadyNoReady() }}
                  >
                    <Icon icon="material-symbols:circle" color="#989898" />
                    {notReadyLabel}
                  </button>
                ) : (
                  <button
                    id="GTN-status-select"
                    style={{ backgroundColor: "#B1F2D7" }}
                    type="button"
                    className="btn btn-warning"
                    onClick={handleButtonClick}
                  >
                    <Icon icon="material-symbols:circle" color="#04B469" />
                    {readyLabel}
                  </button>
                )}
              </div>
            </div>
            <div id="GTN-main-controls">
              <div id="GTN-main-controls-left">
                <div className="view-buttons">
                  <button
                    id="card-view-button"
                    className="view-toggle"
                    type="button"
                    style={{
                      backgroundColor:
                        currentView == ViewOptions.card
                          ? allThemes.base.primary
                          : allThemes.base.borderGrayColor,
                      color:
                        currentView == ViewOptions.card ? "white" : "black",
                    }}
                    onClick={() => setCurrentView(ViewOptions.card)}
                  >
                    <Icon
                      icon="ph:squares-four-fill"
                      height="100%"
                      width="100%"
                    />
                  </button>
                  <button
                    id="list-view-button"
                    className="view-toggle"
                    type="button"
                    style={{
                      backgroundColor:
                        currentView == ViewOptions.list
                          ? allThemes.base.primary
                          : allThemes.base.borderGrayColor,
                      color:
                        currentView == ViewOptions.list ? "white" : "black",
                    }}
                    onClick={() => setCurrentView(ViewOptions.list)}
                  >
                    <Icon
                      icon="material-symbols:list-alt"
                      height="100%"
                      width="100%"
                    />
                  </button>
                </div>
                <div id="GTN-radio-group">
                  <TPRadio
                    id="GTN-radio-date"
                    label={orderByDateLabel}
                    checked={selectedOrder == OrderOptions.date}
                    onClick={() => setSelectedOrder(OrderOptions.date)}
                  />
                  <TPRadio
                    id="GTN-radio-task"
                    label={orderByTaskLabel}
                    checked={selectedOrder == OrderOptions.task}
                    onClick={() => setSelectedOrder(OrderOptions.task)}
                  />
                </div>
              </div>
              <div id="GTN-main-controls-right">
                <button
                  id="GTN-pending-cases-button"
                  style={{
                    border: "none",
                    color: "#3047B0",
                    backgroundColor: "white",
                  }}
                  type="button"
                  onClick={() => casecontext.showMyTasks()}
                >
                  <u>{pendingCasesLabel}</u>
                </button>
                <TPBadge
                  label={pendingCases}
                  backgroundColor="#FF0082"
                  accentColor="white"
                />
              </div>
            </div>
            <div id="GTN-table-container">
              <div id="GTN-lower-controls">
                <div id="GTN-search-box">
                  <input
                    type="text"
                    style={{ border: "none" }}
                    placeholder={searchLabel}
                    onChange={(event) => setSearchInput(event.target.value)}
                    value={searchInput}
                  />
                  <Icon icon="material-symbols:search" />
                </div>
                <div id="GTN-lower-controls-right">
                  <TPCheckBox
                    checked={showCases}
                    labelText={casesLabel}
                    onChange={() => setShowCases(!showCases)}
                  />
                  <TPCheckBox
                    checked={showRecords}
                    labelText={recordLabel}
                    onChange={() => setShowRecords(!showRecords)}
                  />
                  <button
                    id="GTN-refresh-button"
                    type="button"
                    style={{ border: "none", backgroundColor: "white" }}
                    onClick={() => refreshData()}
                  >
                    <Icon
                      icon="heroicons-outline:refresh"
                      width="24px"
                      height="24px"
                    />
                  </button>
                  <TPSelect
                    onChange={() => console.log("ok")}
                    dataSource={[{ key: "preferences", value: "Preferences" }]}
                    value={"test"}
                    minWidth={200}
                  />
                </div>
              </div>
              {currentView == ViewOptions.list ? (
                <DataTable
                  fixedHeaderScrollHeight="400px"
                  fixedHeader
                  columns={columns}
                  data={filteredData}
                  selectableRows
                  onSelectedRowsChange={(rows) => setSelectedItems(rows.selectedRows)}
                  pagination
                  paginationComponent={TPPaginator as PaginationComponent}
                  onChangePage={(value: number) => setCurrentPage(value)}
                  dense
                  noDataComponent={<label>{noDataLabel}</label>}
                  paginationTotalRows={currentData?.length || 0}
                  responsive
                  striped
                />
              ) : (
                <>
                  <TPCheckBox
                    checked={allSelected}
                    onChange={() => setAllSelected(!allSelected)}
                    labelText={"Select all"}
                  />
                  {filteredData?.length > 0 ? (
                    <>
                      <div id="GTN-card-table">
                        {filteredData
                          .slice(
                            (currentPage - 1) * rowsPerPage,
                            (currentPage - 1) * rowsPerPage + rowsPerPage,
                          )
                          .map((dataItem) => (
                            <GroupTakeNextCard
                              data={dataItem}
                              selected={Boolean(
                                selectedItems.find(
                                  (selected) =>
                                    selected.groupId == dataItem.groupId,
                                ),
                              )}
                              onSelect={() => {
                                Boolean(
                                  selectedItems.find(
                                    (selected) =>
                                      selected.groupId == dataItem.groupId,
                                  ),
                                )
                                  ? setSelectedItems(
                                      [...selectedItems].filter(
                                        (selected) =>
                                          selected.groupId != dataItem.groupId,
                                      ),
                                    )
                                  : setSelectedItems([
                                      ...selectedItems,
                                      dataItem,
                                    ]);
                              }}
                            />
                          ))}
                      </div>
                      <TPPaginator
                        currentPage={currentPage}
                        onChangePage={(value: number) => setCurrentPage(value)}
                        onChangeRowsPerPage={(value: number) => setRowsPerPage(value)}
                        rowCount={filteredData?.length || 0}
                        rowsPerPage={rowsPerPage}
                      />
                    </>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <label>{noDataLabel}</label>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </TPLoadingOverlay>
      </div>
    </ContentVerticalNoTabsStyled>
  );
};

export default GroupTakeNextComponent;
