import { StylesConfig } from "react-select";

export interface OptionType {
  value: string;
  label: string;
}

export const getCustomSelectStyles = (
  width: string
): StylesConfig<OptionType> => ({
  control: (styles, state) => ({
    ...styles,
    fontSize: "14px",
    borderColor: state.isFocused ? "#780096" : styles.borderColor,
    boxShadow: state.isFocused ? "0 0 0 1px #780096" : styles.boxShadow,
    "&:hover": {
      borderColor: state.isFocused ? "#780096" : styles.borderColor,
    },
    minHeight: "32px",
    height: "32px",
    padding: "0",
    width,
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: 1,
    borderRadius: 0,
    width,
  }),
  valueContainer: (styles) => ({
    ...styles,
    flexWrap: "nowrap",
    padding: "0 8px",
    height: "32px",
    width: "100%",
  }),
  input: (styles) => ({
    ...styles,
    margin: "0",
    padding: "0",
    width: "100%",
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    height: "32px",
  }),
  option: (styles, state: any) => ({
    ...styles,
    fontSize: "14px",
    padding: "4px 8px",
    color: state.isHovered || state.isFocused ? "white" : "black",
    backgroundColor: state.isFocused
      ? "#780096"
      : state.isHovered
        ? "#9002B1"
        : "transparent",
    "&:hover": {
      backgroundColor: "#9002B1",
      color: "white",
    },
  }),
  menuList: (styles) => ({
    ...styles,
    padding: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    width: "100%",
  }),
  multiValue: (styles) => ({
    ...styles,
    width: "100%",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    padding: "1px",
    paddingLeft: "6px",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "#656565",
    ":hover": {
      backgroundColor: "#dcdfe4",
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    fontSize: "14px",
  }),
});
