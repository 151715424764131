import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import styled from "styled-components";
import { Short } from "./DynamicTable";

type TableContainerProps = {
  maxWidth?: string;
};

const uiIcon = (size: string = "24px") => `
	.ui-icon {
		font-size: ${size};
		width: ${size};
		height: ${size};
	}
`;
export const SortItemStyles = styled.p<{ direction: Short | null }>`
  margin: 0;
  padding: 0 1em 0 0;
  display: flex;
  gap: 0.2rem;
  cursor: pointer;
  width: max-content;
  position: relative;
  font-family: "Noto Sans", sans-serif;

  .ui-icon {
    transform: rotate(
      ${(p) =>
        p.direction !== null && p.direction === "ascending" ? "0deg" : "180deg"}
    );
    transition: 0.1s ease-in-out;
    display: flex;
    align-items: center;
    font-size: 21px;
    height: 24px;
    width: 24px;
    opacity: ${(p) => (p.direction === null ? "0" : "1")};
    right: -12px;
    position: absolute;
  }

  &:hover {
    opacity: 0.8;

    .ui-icon {
      opacity: 1;
    }
  }
`;

export const StyledDynamicTable = styled.div<TableContainerProps>(
  ({ theme, maxWidth }) => `
  max-width: ${maxWidth ?? "98%"};
`
);

export const StyledTableHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  margin-bottom: 2em;
`;

export const StyledTableIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  ${uiIcon("22px")}

  .ui-icon {
    cursor: pointer;
    color: #2e2e2e;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      color: black;
    }
  }
`;

export const StyledTable = styled.div<{ height: string | undefined }>`
  overflow: auto;
  height: ${(p) => (p.height ? `${p.height}` : "54vh")};
`;

export const StyledPager = styled.div<{ selection: number }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
  margin-top: 1.5em;
  flex-wrap: wrap;

  p,
  .select-page > select {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
  }

  .select-page > select {
    border: 1px solid #dddddd;
    border-radius: 4px;
    padding: 0.2em 0.6em;
    outline-color: purple;
  }

  .selected-rows {
    margin-left: 2em;
    opacity: ${(p) => (p.selection > 0 ? 1 : 0)};
  }

  .pager-content,
  .select-page {
    display: flex;
    align-items: center;
    gap: 1.5em;
    flex-wrap: wrap;
  }

  .select-page {
    gap: 0.5em;
  }

  .pager-content > .rows-selected {
    color: #b9b9b9;
  }
`;

export const StyledHeaderOptions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap-reverse;
`;

export const MenuItemButton = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  padding: 0.5rem;
  max-width: 200px;
  font-family: "Noto Sans", sans-serif;
  gap: 1em;
  color: #2e2e2e;

  &:hover {
    background-color: #ffb8dc;
  }

  ${uiIcon()}
`;

export const StyledPreferences = styled.div`
  .preference-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 13em;
    gap: 0.5em;
    padding: 0.2em 0.6em;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    cursor: pointer;

    p {
      font-size: 14px;
      color: #989898;
      margin: 0;
    }

    ${uiIcon()}
  }
`;

export const StyledCheckPreferences = styled.div`
  display: flex;
  flex-direction: column;
  width: 14em;
  box-sizing: border-box;
  padding: 1.5em;

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    gap: 0.3em;
    margin-bottom: 3px;
  }

  label > p {
    line-height: 1.3;
  }

  label.active > p {
    font-weight: 600;
  }

  label.disabled > p {
    color: #bfbfbf;
    cursor: default;
  }

  button {
    margin-bottom: 10px;
  }
`;

export const StyledNoDataImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5em;
  height: 50vh;

  p {
    font-size: 20px;
    font-weight: 600;
    max-width: 380px;
    text-align: center;
    text-wrap: pretty;
    margin: 0;
  }
`;

export const StyledSwitchView = styled.div(
  ({ theme }) => `
	display: flex;
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 4px;

	.ui-icon {
		padding: 9px;
		font-size: 19px;
		cursor: pointer;
	}
	
	.ui-active {
		border-radius: 4px;
		color: white;
		background-color: ${theme.colors.cim.button.primary.background};
	}
`
);

export const StyledDynamicCards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(24em, 20em));
  gap: 1em;

  .default-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #bfbfbf;
    border-radius: 4px;
    padding: 0.5em 1em;
    gap: 1em;

    .default-card-item {
      display: flex;
      gap: 1em;
      font-size: 14px;

      p {
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        text-wrap: no-wrap;
      }
    }
  }
`;

export const StyledTableChildren = styled.div`
  margin-bottom: 1em;
`;

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontWeight: "700",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "none",
    maxWidth: "30em",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  [`.ui-icon`]: {
    fontSize: 24,
    height: 24,
    width: 24,
    cursor: "pointer",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  transition: "background-color 0.1s ease-in-out",

  "&:nth-of-type(odd)": {
    backgroundColor: "#F4F4F4",
  },
  "&:hover": {
    backgroundColor: "#EEF2FF",
  },
}));

export const StyledDisabledCheck = styled.div`
  width: 16px;
  height: 16px;
  background-color: lightgray;
  border-radius: 2px;
  border: 1px solid #bfbfbf;
`;
