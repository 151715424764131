import TPGlobal from "@/helpers/TPGlobal";
import { TPHTTPService } from "@/helpers/TPHttpService";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import { HTTPResponse } from "@/helpers/TPStandardResponse";
import { FunctionsInputDTO } from "@/models/Functions/FunctionsInputDTO";

export class RemindersService {
  serviceFileName: string = "RemindersService.ts";
  public async getByFilter(
    id: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<Array<any>> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/reminders?userId=";
      url = url + id.toUpperCase();//"EB656E09-50EE-4320-B1D6-A26D4DFA9D13"//

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFunctionsByFilter ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getFunctionsByFilter`);
      throw new Error(`Error ${this.serviceFileName} getFunctionsByFilter`);
    }
  }

  public async getRemindersId(
    functionId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/functions/";
    url = url + functionId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response.responseData.data[0];
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getFunctionById`);
      throw new Error(`Error ${this.serviceFileName} getFunctionById`);
    }
  }

  public async deleteRemindersById(
    functionId: string,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/functions/" + functionId;

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.deleteData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} deleteFunctionById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} deleteFunctionById`);
      throw new Error(`Error ${this.serviceFileName} deleteFunctionById`);
    }
  }

  public async insertReminders(
    inputDTO: FunctionsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/functions";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.postData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} insertFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} insertFunction`);
      throw new Error(`Error ${this.serviceFileName} insertFunction`);
    }
  }

  public async updateReminders(
    inputDTO: FunctionsInputDTO,
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/functions";

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.putData(
        finalURL,
        inputDTO,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );
      return response;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} updateFunction ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} updateFunction`);
      throw new Error(`Error ${this.serviceFileName} updateFunction`);
    }
  }




  public async getRemindersCount(    
    showPositiveMessage: boolean,
    showNegativeMessage: boolean,
    expectedCodes: Array<number>
  ): Promise<any> {
    let httpclient = new TPHTTPService();
    let url: string;
    url = "/cases/reminders?userId=";
      url = url + TPGlobal.currentUserGuid; //"EB656E09-50EE-4320-B1D6-A26D4DFA9D13&end=1"//

    let finalURL: string =
      TPGlobal.baseAPIGateway + "api/tenant/" + TPGlobal.tenantHex + url;
    let headers: Array<TPKeyValue> = [...TPGlobal.standardHeaders];

    let response: HTTPResponse;
    try {
      response = await httpclient.getData(
        finalURL,
        expectedCodes,
        showPositiveMessage,
        showNegativeMessage,
        headers
      );

     if(response.responseData.data[0]){
      return response.responseData.data[0].totalRecords;
     }

    
      return 0;
    } catch (error) {
      TPLog.Log(
        `Error ${this.serviceFileName} getFunctionById ex`,
        TPLogType.ERROR,
        error
      );
      console.error(`Error ${this.serviceFileName} getFunctionById`);
      throw new Error(`Error ${this.serviceFileName} getFunctionById`);
    }
  }


}
