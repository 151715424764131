import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
    TPPageAcceptCancelButtonsContainer,
    TPPageSection,
    TPPageSectionTitle,
    TPPageSubTitle,
    TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalLanguageList from "@/layouts/TPModalLanguageList/TPModalLanguageList";
import { useModal } from "@/layouts/TPModalLanguageList/useModal";
import { ContentVerticalTabInsertUpdateStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import {
    SequenceGeneratorSequencesNameEnum,
    TaskTypeComponentEnum,
    TPButtonTypes,
    TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { ComponentCanAddAdditionalDataConst } from "@/models/Global/TPGlobalModels";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { MultilanguageFieldValueViewModel } from "@/models/multilanguage/MultilanguageFieldValueModel";
import {
    ExportCaseExtendedTaskModel,
    ExportCaseExtendedTaskValidator,
    ExternalExtendedTaskModel,
    ExternalExtendedTaskValidator,
    SendEmailExtendedTaskModel,
    SendEmailExtendedTaskValidator,
    TaskTypeInputDTO,
    TaskTypeInputDTOValidator,
} from "@/models/TaskType/TaskTypeInputDTO";
import { TaskTypeViewModel } from "@/models/TaskType/TaskTypeModels";
import { ComponentService } from "@/services/ComponentService";
import { TPI18N } from "@/services/I18nService";
import { MultilanguageFieldValueService } from "@/services/MultilanguageFieldValueService";
import { SequenceService } from "@/services/SequenceService";
import { TaskTypeService } from "@/services/TaskTypeService";
import { FC, ReactElement, useEffect, useState } from "react";
import { OutboundMailAccountService } from "../../services/OutboundMailAccountService";
import TPAutoComplete from "../../components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import { UserService } from "../../services/UserService";
import { EmailTemplateService } from "../../services/EmailTemplateService";
import { EmailTemplateViewModel } from "../../models/EmailTemplates/EmailTemplateModels";
import { error } from "handsontable/helpers";

type InsertUpdateProps = {
    mode: string;
    recordId: string;
    callBackResult: Function;
};

type InsertUpdateStateType = {
    id: string;
    componentId: string;
    isDecision: boolean;
    escalates: boolean;
    addable: boolean;
    isCommentRequiered: boolean;
    isPrivate: boolean;
    isVisibleTaskReport: boolean;
    isActive: boolean;
    externalTaskRSAPublicKey: string;
    recordLanguageList: Array<TPKeyValue>;
    recordLanguageHelpNotesList: Array<TPKeyValue>;
    //validator
    idErrorMessage: string;
    externalTaskRSAPublicKeyErrorMessage: string;
    descriptionErrorMessages: Array<string>;
    componentIdErrorMessage: string;
    helpNotesErrorMessages: Array<string>;
    //Additional elements
    componentCanAddAdditionalData: boolean;
    automaticAnswer: boolean;
    disabledTaskReport: boolean;
    isExternalTask: boolean;
    isExportCaseTask: boolean;
    isExportCaseAutoTask: boolean;
    isEmailTask: boolean;
    OutboundAccount: string;
    EmailTemplate: string;
};

type modalTemplateScreenType = {
    newTemplate: Array<TPKeyValue>;
    reportTemplate: string;
    newTemplateErrorMessage: string;
    reportTemplateErrorMessage: string;
    [x: string]: any;
};


type SendEmailExtendedTaskType = {
    outboundMailAccountId: string;
    emailTemplateId: string;
    //Validations
    emailTemplateIdErrorMessage: string;
};

type ExportCaseExtendedTaskType = {
    exportDestinationId: string;
    exportComments: string;
    //Validations
    exportDestinationIdErrorMessage: string;
    exportCommentsErrorMessage: string;
};

type ExternalExtendedTaskType = {
    externalPublicKey: string;
    externalTaskURL: string;
    //Validations
    externalTaskURLErrorMessage: string;
};

const TaskTypeInsertUpdate: FC<InsertUpdateProps> = ({
    mode,
    recordId,
    callBackResult,
}): ReactElement => {
    //#region  Init
    const componentFileName: string = "TaskTypeInsertUpdate.tsx";
    const resourceSetTaskViewer = 'TaskViewerComponent';

    // valid resources
    const resourceInputDTOInvalidHelpNotes: string =
        "TaskTypeInsertUpdateComponent|InputDTOInvalidHelpNotes";

    const initialModalTemplateScreenState: modalTemplateScreenType = {
        newTemplate: [],
        reportTemplate: '',
        newTemplateErrorMessage: '',
        reportTemplateErrorMessage: ''
    };
    const [modalTemplateScreenState, setModalTemplateScreenState] = useState<modalTemplateScreenType>(
        initialModalTemplateScreenState
    );
    const [autocompleteTemplateTopNOptions, setAutocompleteTemplateTopNOptions] = useState<Array<TPKeyValue>>([]);
    const [autocompleteTemplateOptions, setAutocompleteTemplateOptions] = useState<Array<TPKeyValue>>([]);

    const [templateLanguageValue, setTemplateLanguageValue] = useState('--');
    const [templateLanguageValueErrorMessage, setTemplateLanguagevalueErrorMessage] = useState('');

    const [automaticTaskAnswerError, setAutomaticTaskAnswerError] = useState('');
    const [emailAccountError, setEmailAccountError] = useState('');
    const [emailTemplateError, setEmailTemplateError] = useState('');


    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);

    //Screen resources
    const resourceSet: string = "TaskTypeInsertUpdateComponent";
    const resourceSetEmailTaskComponent: string = "EmailTaskComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [subTitleLabel, setSubTitleLabel] = useState("");
    const [descriptionSectionLabel, setDescriptionSectionLabel] = useState("");
    const [parametersSectionLabel, setParametersSectionLabel] = useState("");
    const [helpNotesSectionLabel, setHelpNotesSectionLabel] = useState("");
    const [idLabel, setIdLabel] = useState("");
    const [nameLabel, setNameLabel] = useState("");
    const [helpNotesLabel, setHelpNotesLabel] = useState("");
    const [componentLabel, setComponentLabel] = useState("");
    const [exportDestinationLabel, setExportDestinationLabel] = useState("");
    const [outboundMailAccountLabel, setOutboundMailAccountLabel] = useState("");
    const [emailTemplateLabel, setEmailTemplateLabel] = useState("");
    const [isDecisionLabel, setIsDecisionLabel] = useState("");
    const [escalatesLabel, setEscalatesLabel] = useState("");
    const [addableLabel, setAddableLabel] = useState("");
    const [isCommentRequieredLabel, setIsCommentRequieredLabel] = useState("");
    const [isPrivateLabel, setIsPrivateLabel] = useState("");
    const [isVisibleTaskReportLabel, setIsVisibleTaskReportLabel] = useState("");
    const [isActiveLabel, setIsActiveLabel] = useState("");
    const [externalTaskURLLabel, setExternalTaskURLLabel] = useState("");
    const [externalTaskRSAPublicKeyLabel, setExternalTaskRSAPublicKeyLabel] =
        useState("");
    const [comentsToExportLabel, setComentsToExportLabel] = useState("");
    const [comentsToAutomaticAnswerLabel, setComentsAutomaticAnswerLabel] = useState("");
    const [saveButtonLabel, setSaveButtonLabel] = useState("");
    const [cancelButtonLabel, setCancelButtonLabel] = useState("");
    const [languageListLabel, setLanguageListLabel] = useState("");
    const [originalRecordDescription, setOriginalRecordDescription] =
        useState("");
    const [realMode, setRealMode] = useState(mode);
    const [realRecordId, setRealRecordId] = useState(recordId);
    const [canAddAdditionalData, setCanAddAdditionalData] = useState(false);
    const [disabledIsPrivate, setDisabledIsPrivate] = useState(false);
    const [referenceId, setReferenceId] = useState("");
    const [emptyLabel, setEmptyLabel] = useState('');
    const [require, setRequire] = useState('');


    const [templateLabel, setTemplateLabel] = useState('');

    //Screen state
    let initialErrorMessages: Array<string> = [];
    for (
        let i: number = 0;
        i <= TPGlobal.TPClientAvailableLanguages.length - 1;
        i++
    ) {
        initialErrorMessages.push("");
    }

    let insertUpdateInitialState: InsertUpdateStateType = {
        id: recordId,
        componentId: "",
        isDecision: false,
        escalates: false,
        addable: false,
        isCommentRequiered: false,
        isPrivate: false,
        isVisibleTaskReport: true,
        isActive: true,
        recordLanguageList: [],
        recordLanguageHelpNotesList: [],
        externalTaskRSAPublicKey: "",
        idErrorMessage: "",
        externalTaskRSAPublicKeyErrorMessage: "",
        descriptionErrorMessages: [...initialErrorMessages],
        componentIdErrorMessage: "",
        helpNotesErrorMessages: [...initialErrorMessages],
        automaticAnswer: false,
        disabledTaskReport: true,
        isExternalTask: false,
        isExportCaseTask: false,
        isExportCaseAutoTask: false,
        isEmailTask: false,
        componentCanAddAdditionalData: false,
        OutboundAccount: "",
        EmailTemplate: "",
    };

    let externalExtendedTaskInitialState: ExternalExtendedTaskType = {
        externalTaskURL: "",
        externalPublicKey: "",
        externalTaskURLErrorMessage: "",
    };
    let exportCaseExtendedTaskTypeInitialState: ExportCaseExtendedTaskType = {
        exportDestinationId: "",
        exportComments: "",
        exportDestinationIdErrorMessage: "",
        exportCommentsErrorMessage: "",
    };
    let sendEmailExtendedTaskTypeInitialState: SendEmailExtendedTaskType = {
        outboundMailAccountId: "",
        emailTemplateId: "",
        emailTemplateIdErrorMessage: "",
    };
    const [insertUpdateState, setInsertUpdateState] = useState(
        insertUpdateInitialState,
    );
    const [externalExtendedTaskState, setExternalExtendedTaskState] = useState(
        externalExtendedTaskInitialState,
    );
    const [exportCaseExtendedTaskTypeState, setExportCaseExtendedTaskTypeState] =
        useState(exportCaseExtendedTaskTypeInitialState);

    const [automaticTaskAnswer, setAutomaticTaskAnswer] =
        useState<TPKeyValue>({ key: "", value: "" });

    const [sendEmailExtendedTaskTypeState, setSendEmailExtendedTaskTypeState] =
        useState(sendEmailExtendedTaskTypeInitialState);

    let initialStateComponentList: Array<TPKeyValue> = [];
    const [componentKeyValue, setComponentKeyValue] = useState(
        initialStateComponentList,
    );

    //Initalize rule definition
    const [ruleListValue, setRuleListValue] = useState<String>("");
    const [baseListValue, setBaseListValue] = useState<String>("");
    const [comparisionListValue, setComparisionListValue] = useState<String>("");
    const [referenceValue, setReferenceValue] = useState<string>("");
    const [logicalOperatorValue, setLogicalOperatorValue] = useState<string>("");
    const [ruleListValueSecond, setRuleListValueSecond] = useState<String>("");
    const [baseListValueSecond, setBaseListValueSecond] = useState<String>("");
    const [comparisionListValueSecond, setComparisionListValueSecond] =
        useState<String>("");
    const [referenceValueSecond, setReferenceValueSecond] = useState<string>("");

    const rulesDefinitionList: {
        RuleNumber: number;
        BaseField: string;
        ComparisionOperator: String;
        ReferenceValue: string;
        Connector: string;
    }[] = [];
    const ruleDefinitionFirstObj = {
        RuleNumber: 0,
        BaseField: `${ruleListValue}.${baseListValue}`,
        ComparisionOperator: comparisionListValue,
        ReferenceValue: referenceValue,
        Connector: "",
    };
    const ruleDefinitionSecondObj = {
        RuleNumber: 1,
        BaseField: `${ruleListValueSecond}.${baseListValueSecond}`,
        ComparisionOperator: comparisionListValueSecond,
        ReferenceValue: referenceValueSecond,
        Connector: "",
    };

    //TODO Remove Fakelist
    let initialStateFakeList: Array<TPKeyValue> = [];
    const [
        fakeDataExportDestinationListState,
        setFakeDataExportDestinationListState,
    ] = useState(initialStateFakeList);
    const [fakeDataOutboundMailAccountList, setFakeDataOutboundMailAccountList] =
        useState(initialStateFakeList);
    const [fakeDataEmailTemplateList, setFakeDataEmailTemplateList] =
        useState(initialStateFakeList);

    //Rule Definition FakeList
    const [fakeDataRuleListFirstState, setFakeDataRuleListFirstState] =
        useState(initialStateFakeList);
    const [fakeDataBaseListFirstState, setFakeDataBaseListFirstState] =
        useState(initialStateFakeList);
    const [
        fakeDataComparisionListFirstState,
        setFakeDataComparisionListFirstState,
    ] = useState(initialStateFakeList);
    const [fakeDataLogicalOperator, setFakDataLogicalOperatore] =
        useState(initialStateFakeList);

    //Multilanguage const
    const multilanguageTableName: String = "TAskTYpe";
    const multilanguageFieldName: String = "Description_TATY";
    const multilanguageFieldHelp: String = "HelpNotes_TATY";
    //#endregion

    const loadResourcesAndLoadInfo = async () => {
        let i: number;
        //resources state
        setTitleLabel(
            await TPI18N.GetText(resourceSet, "Title" + realMode + "Label"),
        );
        setSubTitleLabel(await TPI18N.GetText(resourceSet, "SubtitleLabel"));
        setDescriptionSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "DescriptionSectionLabel",
            ),
        );
        setParametersSectionLabel(
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "ParametersSectionLabel",
            ),
        );
        setHelpNotesSectionLabel(
            await TPI18N.GetText(resourceSet, "HelpNotesSectionLabel"),
        );
        setSaveButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "SaveButton"),
        );
        setCancelButtonLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
        );
        setIdLabel(await TPI18N.GetText(resourceSet, "IdLabel"));
        setNameLabel(await TPI18N.GetText(resourceSet, "NameLabel"));
        setHelpNotesLabel(await TPI18N.GetText(resourceSet, "HelpNotesLabel"));
        setIsActiveLabel(await TPI18N.GetText(resourceSet, "IsActiveLabel"));
        setComponentLabel(await TPI18N.GetText(resourceSet, "ComponentLabel"));
        setIsDecisionLabel(await TPI18N.GetText(resourceSet, "IsDecisionLabel"));
        setEscalatesLabel(await TPI18N.GetText(resourceSet, "EscalatesLabel"));
        setAddableLabel(await TPI18N.GetText(resourceSet, "AddableLabel"));
        setIsCommentRequieredLabel(
            await TPI18N.GetText(resourceSet, "IsCommentRequieredLabel"),
        );
        setIsPrivateLabel(await TPI18N.GetText(resourceSet, "IsPrivateLabel"));
        setIsVisibleTaskReportLabel(
            await TPI18N.GetText(resourceSet, "IsVisibleTaskReportLabel"),
        );
        setExternalTaskURLLabel(
            await TPI18N.GetText(resourceSet, "ExternalTaskURL"),
        );
        setExternalTaskRSAPublicKeyLabel(
            await TPI18N.GetText(resourceSet, "ExternalTaskPublicKeyLabel"),
        );
        setExportDestinationLabel(
            await TPI18N.GetText(resourceSet, "ExportDestinationLabel"),
        );
        setComentsToExportLabel(
            await TPI18N.GetText(resourceSet, "ComentsToExportLabel"),
        );
        setOutboundMailAccountLabel(
            await TPI18N.GetText(resourceSet, "OutboundMailAccountLabel"),
        );
        setEmailTemplateLabel(
            await TPI18N.GetText(resourceSet, "EmailTemplateLabel"),
        );
        setLanguageListLabel(
            await TPI18N.GetText(TPGlobal.globalResourceSet, "LanguageList"),
        );
        setTemplateLabel(await TPI18N.GetText(resourceSetEmailTaskComponent, 'TemplateLabel'));
        setEmptyLabel(await TPI18N.GetText(resourceSetTaskViewer, 'EmptyLabel'));
        setRequire(await TPI18N.GetText(TPGlobal.globalResourceSet, 'InputDTORequired'));
        setComentsAutomaticAnswerLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'ParametersSectionLabel'));

        //Load Component List
        let componentService = new ComponentService();
        let expectedCodes: Array<number> = [200];
        try {
            //TODO Change constants
            let responseRequest = await componentService.GetByTypeIsActive(
                "1",
                "FRMTYPTSK",
                false,
                true,
                expectedCodes,
            );

            let newComponentListState: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return { key: item.id, value: item.localizedDescription };
                },
            );
            newComponentListState.unshift({ key: "", value: "--" });
            setComponentKeyValue(newComponentListState);

            //Rules Definition Fake List Data
            let newFakeDataRulesList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                { value: "Case Classifier", key: "TACASECLAS" },
                { value: "Customer Data", key: "TACUSTDATA" },
            ];
            setFakeDataRuleListFirstState(newFakeDataRulesList);

            let newFakeBaseFieldCaseClasifierList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                { value: "Contact Method", key: "Classify1_Id_BRAN" },
                { value: "Type", key: "Classify2_Id_BRAN" },
                { value: "Process", key: "Classify3_Id_BRAN" },
                { value: "Location", key: "Classify4_Id_BRAN" },
                { value: "Priority", key: "Classify5_Id_BRAN" },
            ];
            let newFakeBaseFieldCustomerDataList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                { value: "Document", key: "DocumentType_Id_PARA" },
                { value: "Name", key: "Name_CLIE" },
                { value: "Last Name", key: "LastName_CLIE" },
                { value: "City", key: "Geography_SUBS" },
                { value: "Email", key: "PreferredEmail_SUBS" },
                { value: "Address", key: "PreferredAddress_SUBS" },
                { value: "Email", key: "PreferredEmail_SUBS" },
                { value: "Phone", key: "PreferredPhone_SUBS" },
            ];

            if (ruleListValue === "TACASECLAS")
                setFakeDataBaseListFirstState(newFakeBaseFieldCaseClasifierList);

            if (ruleListValue === "TACUSTDATA")
                setFakeDataBaseListFirstState(newFakeBaseFieldCustomerDataList);

            let newFakeComparisonOperatorList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                { value: "=", key: "EQUAL" },
                { value: ">", key: "GREATER" },
                { value: "<", key: "LESS" },
                { value: ">=", key: "GREATEROREQUALTHAN" },
                { value: "<=", key: "LESSOREQUALTHAN" },
                { value: "<>", key: "DIFFERENT" },
                { value: "Between", key: "BETWEEN" },
                { value: "Begins with", key: "BEGINS" },
                { value: "Does not begin with", key: "NOBEGINS" },
                { value: "Ends in", key: "ENDS" },
                { value: "Does not end in", key: "NOENDS" },
                { value: "Contains", key: "CONTAINS" },
                { value: "Does not contain", key: "NOCONTAINS" },
                { value: "In List", key: "INLISTS" },
                { value: "Not In List", key: "NOTINLISTS" },
                {
                    value: "Matches regular expression .NET",
                    key: "REGURALEXPRESSIONPUNTONET",
                },
            ];
            setFakeDataComparisionListFirstState(newFakeComparisonOperatorList);

            let newFakeDataOperatorList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                { value: "And", key: "AND" },
                { value: "Or", key: "OR" },
            ];
            setFakDataLogicalOperatore(newFakeDataOperatorList);

            //TODO delete fake data
            let newFakeDataExportDestinationList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                { value: "TPClientColombiaxx", key: "EXPOTPCO" },
            ];
            setFakeDataExportDestinationListState(newFakeDataExportDestinationList);



            let newFakeDataEmailTemplateList: Array<TPKeyValue> = [
                { value: "--", key: "" },
                {
                    value: "Default Response",
                    key: "DEMODEFA",
                },
                { value: "Standar Response", key: "DEMOESRE" },
            ];
            setFakeDataEmailTemplateList(newFakeDataEmailTemplateList);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} loadResourcesAndLoadInfo ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} loadResourcesAndLoadInfo ex`);
            return;
        }

        LoadDataOutboundMailAccountList();

        //screen state
        if (realMode == "Update") {
            await getById(realRecordId);
        }
        if (realMode == "Insert") {
            let newInsertUpdateState = { ...insertUpdateState };
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
            }
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageHelpNotesList.push(keyValueElement);
            }
            setInsertUpdateState(newInsertUpdateState);
            setIsLoadingScreen(false);
        }



    };

    /*123*/
    const LoadDataOutboundMailAccountList = async () => {
        let newfakeDataOutboundMailAccountList: Array<TPKeyValue> = [
            { value: "--", key: "" }
        ];

        let serviceClient = new OutboundMailAccountService();
        let expectedCodes: Array<number> = [200, 404];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getAllMailAccounts(
                false,
                false,
                expectedCodes
            );

            let tempList = responseRequest.map((data: any) => {
                return ({
                    key: data.idAccount,
                    value: data.fromAddress
                });
            });

            if (Array.isArray(tempList))
                tempList.push({
                    key: "",
                    value: "--"
                });
            setFakeDataOutboundMailAccountList(tempList);

            setIsLoadingScreen(false);
            return [...responseRequest];
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} reloadDataGrid ex`,
                TPLogType.ERROR,
                error
            );
            console.error(`Error ${componentFileName} reloadDataGrid ex`);
            setIsLoadingScreen(false);
            return [];
        }


    }



    const getEmailById = async (pRecordId: string) => {
        let serviceClient = new EmailTemplateService();
        let expectedCodes: Array<number> = [200];
        let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
        let i: number;
        let j: number;

        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getEmailTemplateByTemplateId(
                pRecordId,
                false,
                true,
                expectedCodes,
            );
            let recordInfo: EmailTemplateViewModel;
            recordInfo = { ...responseRequest };

            let task = { key: recordInfo.id, value: recordInfo.description };
            let data = { ...modalTemplateScreenState };


            let dataEmail = { ...autocompleteTemplateOptions };
            if (Array.isArray(dataEmail))
                dataEmail.push(task);
            else
                dataEmail = [task];

            data.newTemplate = dataEmail;
            setAutocompleteTemplateOptions(dataEmail);
            setModalTemplateScreenState(data);


            setIsLoadingScreen(false);
            return;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getEmailTemplateById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getEmailTemplateById ex`);

            setIsLoadingScreen(false);
            return null;
        }
    };


    //Auto

    const handleTemplateChange = (newSelectedValue: Array<TPKeyValue>) => {
        let newmodalTemplateScreenState = { ...modalTemplateScreenState };
        newmodalTemplateScreenState.newTemplate = newSelectedValue;
        newmodalTemplateScreenState.newTemplateErrorMessage = "";
        setModalTemplateScreenState(newmodalTemplateScreenState);
    };

    const handleTemplateLanguageValueChange = (e: any) => {
        let fromValue: string = e.target.value;
        setTemplateLanguageValue(fromValue);
        setTemplateLanguagevalueErrorMessage('');
    };

    const handleTemplateOnAutocompleteQuery = async (query: string) => {
        let tasktypeService = new UserService();
        let expectedCodes: Array<number> = [200, 404];

        try {
            //Load users by search
            let responseRequest = await tasktypeService.getEmailTemplatesBySearch(
                query,
                "1",
                templateLanguageValue,
                false,
                true,
                expectedCodes
            );
            let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(
                function (item) {
                    return {
                        key: item.id,
                        value: `${item.localizedDescription}`,
                    };
                }
            );
            setAutocompleteTemplateOptions(newToKeyValueList);
            return newToKeyValueList;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} handleTemplatOnAutocompleteQuery ex`,
                TPLogType.ERROR,
                error
            );
            console.error(
                `Error ${componentFileName} handleTemplateOnAutocompleteQuery ex`
            );
            return [];
        }
    };

    const handleTemplateOnAutocompleteKeyDown = (event: any) => {
        //left arrow 37
        //right arror 39
        //enter 13
        //home 36
        //end  35
        if (
            event.keyCode != 37 &&
            event.keyCode != 39 &&
            event.keyCode != 13 &&
            event.keyCode != 35 &&
            event.keyCode != 36
        ) {
            setAutocompleteTemplateOptions([]);
            let newModalTemplateScreenState = { ...modalTemplateScreenState };
            newModalTemplateScreenState.newTemplate = [];
            newModalTemplateScreenState.newTemplateErrorMessage = "";
            setModalTemplateScreenState(newModalTemplateScreenState);
        }
    };

    const handleAutoCompleteTemplatepNClick = async () => {
        let newTemplatepTopNOptions: Array<TPKeyValue> = [];
        if (autocompleteTemplateTopNOptions.length === 0) {
            newTemplatepTopNOptions = await handleTemplateOnAutocompleteQuery("");

            if (newTemplatepTopNOptions.length >= 1) {
                //save on cache
                setAutocompleteTemplateTopNOptions([...newTemplatepTopNOptions]);
                setAutocompleteTemplateOptions([...newTemplatepTopNOptions]);
                let newModalTemplateScreenState = { ...modalTemplateScreenState };
                newModalTemplateScreenState.newTemplate = [];
                newModalTemplateScreenState.newTemplateErrorMessage = "";
                setModalTemplateScreenState(newModalTemplateScreenState);
            }
        } else {
            //use cached values;
            setAutocompleteTemplateOptions([...autocompleteTemplateTopNOptions]);
            let newModalTemplateScreenState = { ...modalTemplateScreenState };
            newModalTemplateScreenState.newTemplate = [];
            newModalTemplateScreenState.newTemplateErrorMessage = "";
            setModalTemplateScreenState(newModalTemplateScreenState);
        }
    };




    const handleOnIdChange = (newId: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.id = newId;
        newInsertUpdateState.idErrorMessage = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleLanguageChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageList[index].value = newName;
        newInsertUpdateState.descriptionErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleLanguageHelpNotesChange = (index: number, newName: string) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.recordLanguageHelpNotesList[index].value = newName;
        newInsertUpdateState.helpNotesErrorMessages[index] = "";
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsActiveChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isActive = !newInsertUpdateState.isActive;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsisDecisionChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isDecision = !newInsertUpdateState.isDecision;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleEscalatesChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.escalates = !newInsertUpdateState.escalates;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleAddableChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.addable = !newInsertUpdateState.addable;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsCommentRequieredChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isCommentRequiered =
            !newInsertUpdateState.isCommentRequiered;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsPrivateChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isPrivate = !newInsertUpdateState.isPrivate;
        setInsertUpdateState(newInsertUpdateState);
    };

    const handleIsVisibleTaskReportChange = () => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.isVisibleTaskReport =
            !newInsertUpdateState.isVisibleTaskReport;
        setInsertUpdateState(newInsertUpdateState);
    };

    const Utf8 = (data: any) => {
        let tempJson = new TextEncoder().encode(JSON.stringify(data)).join(',');
        return JSON.stringify(data);
    }


    const handleOkButtonClick = async () => {
        rulesDefinitionList.push(ruleDefinitionFirstObj);
        (logicalOperatorValue === "AND" || logicalOperatorValue === "OR") &&
            rulesDefinitionList.push(ruleDefinitionSecondObj);

        let isInValid: boolean = false;
        let i: number;
        let n: number;
        let recordInputDTO: TaskTypeInputDTO = {
            // id: insertUpdateState.id,
            id: realMode === "Insert" ? referenceId : recordId,
            isActive: insertUpdateState.isActive,
            addable: insertUpdateState.addable,
            componentId: insertUpdateState.componentId,
            description: insertUpdateState.recordLanguageList[0].value,
            escalates: insertUpdateState.escalates,
            externalTaskRSAPublicKey: insertUpdateState.externalTaskRSAPublicKey,
            helpNotes: insertUpdateState.recordLanguageHelpNotesList[0].value,
            helpNotesLocalizedValues: [],
            isCommentRequired: insertUpdateState.isCommentRequiered,
            isDecision: insertUpdateState.isDecision,
            isPrivate: insertUpdateState.isPrivate,
            isVisibleTaskReport: insertUpdateState.isVisibleTaskReport,
            jSONParameters: Utf8(rulesDefinitionList),
            nameLocalizedValues: [],
            OutboundAccount: sendEmailExtendedTaskTypeState.outboundMailAccountId   

        };
       

        setEmailAccountError("");
        setEmailTemplateError("");

        if (sendEmailExtendedTaskTypeState.outboundMailAccountId == "") setEmailAccountError(require);
        if (!sendEmailExtendedTaskTypeState.outboundMailAccountId) setEmailAccountError(require);
        if (!handleEmailTemplate()) setEmailTemplateError(require);

        n = insertUpdateState.recordLanguageList.length;
        for (i = 0; i <= n - 1; i++) {
            let item: TPKeyValue;
            item = insertUpdateState.recordLanguageList[i];
            if (item.value !== "") {
                recordInputDTO.nameLocalizedValues.push({
                    order: i + 1,
                    languageId: item.key,
                    localizedValue: item.value,
                });
            }
        }
        n = insertUpdateState.recordLanguageHelpNotesList.length;
        for (i = 0; i < n; i++) {
            let item: TPKeyValue;
            item = insertUpdateState.recordLanguageHelpNotesList[i];
            if (item.value !== "") {
                recordInputDTO.helpNotesLocalizedValues.push({
                    order: i + 1,
                    languageId: item.key,
                    localizedValue: item.value,
                });
            }
        }

        switch (insertUpdateState.componentId) {
            case TaskTypeComponentEnum.caseResponseEmailTaskComponent:
            case TaskTypeComponentEnum.caseResponseAnswerAutoTaskComponent:
                recordInputDTO.jSONParameters = Utf8(
                    automaticTaskAnswer
                );
                isInValid = false;              
                break;
            case TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent:
                let recordInputDTOExtendedTaskModel: SendEmailExtendedTaskModel = {
                    emailTemplateId: handleEmailTemplate() ? handleEmailTemplate() : "",
                    outboundMailAccountId:
                        sendEmailExtendedTaskTypeState.outboundMailAccountId,
                };
                let inputDTOSendEmailTaskValidator = new SendEmailExtendedTaskValidator(
                    insertUpdateState.componentId,
                );
                let resultValidatorEmailTask = inputDTOSendEmailTaskValidator.validate(
                    recordInputDTOExtendedTaskModel,
                );

                if (!TPGlobal.TPIsEmpty(resultValidatorEmailTask)) {
                    let newSendEmailExtendedTaskTypeState = {
                        ...sendEmailExtendedTaskTypeState,
                    };
                    if (resultValidatorEmailTask.emailTemplateId) {
                        newSendEmailExtendedTaskTypeState.emailTemplateIdErrorMessage =
                            await TPI18N.GetResource(
                                resultValidatorEmailTask.emailTemplateId,
                            );
                    } else {
                        newSendEmailExtendedTaskTypeState.emailTemplateIdErrorMessage = "";
                    }
                    setSendEmailExtendedTaskTypeState(newSendEmailExtendedTaskTypeState);
                    isInValid = true;
                } else {
                    recordInputDTO.jSONParameters = Utf8(
                        recordInputDTOExtendedTaskModel,
                    );
                }
                break;
            case TaskTypeComponentEnum.caseResponseExchangeTaskComponent:
            case TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent:
                let recordInputExportCaseExtendedTaskModel: ExportCaseExtendedTaskModel =
                {
                    exportComments: exportCaseExtendedTaskTypeState.exportComments,
                    exportDestinationId:
                        exportCaseExtendedTaskTypeState.exportDestinationId,
                };
                let inputDTOExportCaseExtendedTaskValidator =
                    new ExportCaseExtendedTaskValidator(insertUpdateState.componentId);
                let resultValidatorSendEmail =
                    inputDTOExportCaseExtendedTaskValidator.validate(
                        recordInputExportCaseExtendedTaskModel,
                    );
                if (!TPGlobal.TPIsEmpty(resultValidatorSendEmail)) {
                    let newExportCaseExtendedTaskTypeState = {
                        ...exportCaseExtendedTaskTypeState,
                    };
                    if (resultValidatorSendEmail.exportComments) {
                        newExportCaseExtendedTaskTypeState.exportCommentsErrorMessage =
                            await TPI18N.GetResource(resultValidatorSendEmail.exportComments);
                    } else {
                        newExportCaseExtendedTaskTypeState.exportCommentsErrorMessage = "";
                    }
                    if (resultValidatorSendEmail.exportDestinationId) {
                        newExportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage =
                            await TPI18N.GetResource(
                                resultValidatorSendEmail.exportDestinationId,
                            );
                    } else {
                        newExportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage =
                            "";
                    }
                    setExportCaseExtendedTaskTypeState(
                        newExportCaseExtendedTaskTypeState,
                    );
                    isInValid = true;
                } else {
                    recordInputDTO.jSONParameters = Utf8(
                        recordInputExportCaseExtendedTaskModel,
                    );
                }
                break;
            case TaskTypeComponentEnum.externalTaskComponent:
                let recordInputExternalTaskModel: ExternalExtendedTaskModel = {
                    externalTaskURL: externalExtendedTaskState.externalTaskURL,
                    externalPublicKey: externalExtendedTaskState.externalPublicKey
                };
                let inputDTOExternalTaskValidator = new ExternalExtendedTaskValidator();
                let resultValidatorExternalTask =
                    inputDTOExternalTaskValidator.validate(recordInputExternalTaskModel);
                if (!TPGlobal.TPIsEmpty(resultValidatorExternalTask)) {
                    let newexternalExtendedTaskState = {
                        ...externalExtendedTaskState,
                    };
                    if (resultValidatorExternalTask.externalTaskURL) {
                        newexternalExtendedTaskState.externalTaskURLErrorMessage =
                            await TPI18N.GetResource(
                                resultValidatorExternalTask.externalTaskURL,
                            );
                    } else {
                        newexternalExtendedTaskState.externalTaskURLErrorMessage = "";
                    }

                    setExternalExtendedTaskState(newexternalExtendedTaskState);
                    isInValid = true;
                } else {
                    recordInputDTO.jSONParameters = Utf8(
                        recordInputExternalTaskModel,
                    );
                }
                break;
        }

        let inputDTOValidator = new TaskTypeInputDTOValidator();
        let resultValidator = inputDTOValidator.validate(recordInputDTO);
        if (!TPGlobal.TPIsEmpty(resultValidator)) {
            let newInsertUpdateState = { ...insertUpdateState };
            if (resultValidator.id) {
                newInsertUpdateState.idErrorMessage = await TPI18N.GetResource(
                    resultValidator.id,
                );
            } else {
                newInsertUpdateState.idErrorMessage = "";
            }
            if (resultValidator.description) {
                newInsertUpdateState.descriptionErrorMessages[0] =
                    await TPI18N.GetResource(resultValidator.description);
            } else {
                newInsertUpdateState.descriptionErrorMessages[0] = "";
            }
            if (resultValidator.componentId) {
                newInsertUpdateState.componentIdErrorMessage = await TPI18N.GetResource(
                    resultValidator.componentId,
                );
            } else {
                newInsertUpdateState.componentIdErrorMessage = "";
            }
            if (resultValidator.externalTaskRSAPublicKey) {
                newInsertUpdateState.externalTaskRSAPublicKeyErrorMessage =
                    await TPI18N.GetResource(resultValidator.externalTaskRSAPublicKey);
            } else {
                newInsertUpdateState.externalTaskRSAPublicKeyErrorMessage = "";
            }

            if (resultValidator.nameLocalizedValues) {
                n = insertUpdateState.recordLanguageList.length;
                for (i = 1; i <= n - 1; i++) {
                    if (resultValidator.nameLocalizedValues[i] != null) {
                        newInsertUpdateState.descriptionErrorMessages[i] =
                            await TPI18N.GetResource(
                                String(resultValidator.nameLocalizedValues[i]),
                            );
                    }
                }
            } else {
                n = insertUpdateState.recordLanguageList.length;
                for (i = 1; i <= n - 1; i++) {
                    newInsertUpdateState.descriptionErrorMessages[i] = "";
                }
            }
            if (resultValidator.helpNotesLocalizedValues) {
                if (
                    resultValidator.helpNotesLocalizedValues ==
                    resourceInputDTOInvalidHelpNotes
                ) {
                    newInsertUpdateState.helpNotesErrorMessages[0] =
                        await TPI18N.GetResource(String(resourceInputDTOInvalidHelpNotes));
                } else {
                    n = insertUpdateState.recordLanguageHelpNotesList.length;
                    for (i = 0; i <= n - 1; i++) {
                        if (resultValidator.helpNotesLocalizedValues[i] != null) {
                            newInsertUpdateState.helpNotesErrorMessages[i] =
                                await TPI18N.GetResource(
                                    String(resultValidator.helpNotesLocalizedValues[i]),
                                );
                        }
                    }
                }
            } else {
                n = insertUpdateState.recordLanguageHelpNotesList.length;
                for (i = 0; i <= n - 1; i++) {
                    newInsertUpdateState.helpNotesErrorMessages[i] = "";
                }
            }

            setInsertUpdateState(newInsertUpdateState);
            isInValid = true;
        }

        if (recordInputDTO.helpNotes == "") {
            recordInputDTO.helpNotesLocalizedValues = [];
        }
              

        if (!isInValid) {
            if (realMode == "Insert") {
                await insert(recordInputDTO);
            } else {
                await update(recordInputDTO);
            }
        }
    };

    const handleEmailTemplate = () => {
        if (!modalTemplateScreenState) return "";
        if (!modalTemplateScreenState.newTemplate) return "";
        if (modalTemplateScreenState.newTemplate.length <= 0) return "";
        if (!modalTemplateScreenState.newTemplate[0].key) return "";
        if (modalTemplateScreenState.newTemplate[0].key == "") return "";
        return modalTemplateScreenState.newTemplate[0].key;
    }

    const handleExternalTaskChange = (newTask: string) => {
        let newExternalExtendedTaskState = { ...externalExtendedTaskState };
        newExternalExtendedTaskState.externalTaskURL = newTask;
        newExternalExtendedTaskState.externalTaskURLErrorMessage = "";
        setExternalExtendedTaskState(newExternalExtendedTaskState);
    };

    const handleExternalTaskRSAPublicKeyChange = (
        newTaskRSAPublickey: string,
    ) => {
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.externalTaskRSAPublicKey = newTaskRSAPublickey;
        setInsertUpdateState(newInsertUpdateState);
    };

    const insert = async (inputDTO: TaskTypeInputDTO) => {
        let serviceClient = new TaskTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.insertTaskType(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            if (responseRequest.responseResult) {
                setCanAddAdditionalData(
                    ComponentCanAddAdditionalDataConst.includes(
                        insertUpdateState.componentId,
                    ),
                );

                let newInsertUpdateState = { ...insertUpdateState };
                newInsertUpdateState.componentCanAddAdditionalData =
                    canAddAdditionalData;
                setInsertUpdateState(newInsertUpdateState);
                if (
                    ComponentCanAddAdditionalDataConst.includes(
                        insertUpdateState.componentId,
                    )
                ) {
                    callBackResult({
                        result: "save_general_insert_and_change_mode",
                        recordId: inputDTO.id,
                    });
                    //change mode
                    setTitleLabel(await TPI18N.GetText(resourceSet, "TitleUpdateLabel"));
                    setRealRecordId(inputDTO.id);
                    setOriginalRecordDescription(
                        inputDTO.nameLocalizedValues[0].localizedValue.length > 100
                            ? inputDTO.nameLocalizedValues[0].localizedValue.substring(
                                0,
                                100,
                            ) + "..."
                            : inputDTO.nameLocalizedValues[0].localizedValue,
                    );
                    setRealMode("Update");
                } else {
                    callBackResult({
                        result: "save_general_insert",
                        recordId: inputDTO.id,
                    });
                }
            }

            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} insert ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} insert ex`);
            setIsLoadingScreen(false);
        }
    };

    const update = async (inputDTO: TaskTypeInputDTO) => {
        let serviceClient = new TaskTypeService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.updateTaskType(
                inputDTO,
                true,
                true,
                expectedCodes,
            );
            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                callBackResult({
                    result: "save_general_update",
                    recordId: inputDTO.id,
                });
            }
        } catch (error) {
            TPLog.Log(`Error ${componentFileName} update ex`, TPLogType.ERROR, error);
            console.error(`Error ${componentFileName} update ex`);
            setIsLoadingScreen(false);
        }
    };

    const getById = async (pRecordId: string) => {
        let serviceClient = new TaskTypeService();
        let expectedCodes: Array<number> = [200];
        let recordLanguagesList: Array<MultilanguageFieldValueViewModel> = [];
        let recordLanguagesHelpNotesList: Array<MultilanguageFieldValueViewModel> =
            [];
        let i: number;
        let j: number;
        try {
            setIsLoadingScreen(true);
            let responseRequest = await serviceClient.getTaskTypeById(
                pRecordId,
                false,
                true,
                expectedCodes,
            );
            let recordInfo: TaskTypeViewModel;
            recordInfo = { ...responseRequest };
            setDisabledIsPrivate(responseRequest.isPrivate);
            setOriginalRecordDescription(recordInfo.description);

            let newInsertUpdateState = { ...insertUpdateState };
            newInsertUpdateState.recordLanguageList = [];
            newInsertUpdateState.recordLanguageHelpNotesList = [];
            for (i = 0; i <= TPGlobal.TPClientAvailableLanguages.length - 1; i++) {
                let item: LanguagesViewModel = TPGlobal.TPClientAvailableLanguages[i];
                let keyValueElement: TPKeyValue = { key: item.id, value: "" };
                newInsertUpdateState.recordLanguageList.push(keyValueElement);
                newInsertUpdateState.recordLanguageHelpNotesList.push({
                    ...keyValueElement,
                });
            }
            newInsertUpdateState.addable = recordInfo.isAddable;
            newInsertUpdateState.componentId = recordInfo.componentId;
            newInsertUpdateState.escalates = recordInfo.escalates;
            newInsertUpdateState.externalTaskRSAPublicKey =
                recordInfo.externalTaskRSAPublicKey;
            newInsertUpdateState.id = recordInfo.id;
            newInsertUpdateState.isActive = recordInfo.isActive;
            newInsertUpdateState.isPrivate = recordInfo.isPrivate;
            newInsertUpdateState.isCommentRequiered = recordInfo.isCommentRequired;
            newInsertUpdateState.isDecision = recordInfo.isDecision;
            newInsertUpdateState.isVisibleTaskReport = recordInfo.isVisibleTaskReport;           
            newInsertUpdateState.recordLanguageList[0].value = recordInfo.description;
            newInsertUpdateState.recordLanguageList[0].value = recordInfo.helpNotes;
            let expectedcodes: Array<number> = [200];
            recordLanguagesList = await getRecordLanguageList(
                pRecordId,
                multilanguageTableName,
                multilanguageFieldName,
                expectedcodes,
            );
            if (recordLanguagesList.length == 0) {
                TPLog.Log(
                    `Error ${componentFileName} getById getRecordLanguageList`,
                    TPLogType.ERROR,
                    "recordLanguagesList is empty",
                );
                console.error(
                    `Error ${componentFileName} getById getRecordLanguageList is empty`,
                );
                setIsLoadingScreen(false);
                return;
            }
            for (
                i = 0;
                i <= newInsertUpdateState.recordLanguageList.length - 1;
                i++
            ) {
                for (j = 0; j <= recordLanguagesList.length - 1; j++) {
                    if (
                        newInsertUpdateState.recordLanguageList[i].key.toLowerCase() ==
                        recordLanguagesList[j].languageId.toLowerCase()
                    ) {
                        newInsertUpdateState.recordLanguageList[i].value =
                            recordLanguagesList[j].recordDescription;
                        break;
                    }
                }
            }
            let expectedcodes2: Array<number> = [200, 404];
            recordLanguagesHelpNotesList = await getRecordLanguageList(
                pRecordId,
                multilanguageTableName,
                multilanguageFieldHelp,
                expectedcodes2,
            );
            if (recordLanguagesHelpNotesList.length == 0) {
                TPLog.Log(
                    `Error ${componentFileName} getById getRecordLanguageList`,
                    TPLogType.ERROR,
                    "recordLanguagesHelpNotesList is empty",
                );
                console.error(
                    `Error ${componentFileName} getById recordLanguagesHelpNotesList is empty`,
                );
            }

            for (
                i = 0;
                i <= newInsertUpdateState.recordLanguageHelpNotesList.length - 1;
                i++
            ) {
                for (j = 0; j <= recordLanguagesHelpNotesList.length - 1; j++) {
                    if (
                        newInsertUpdateState.recordLanguageHelpNotesList[
                            i
                        ].key.toLowerCase() ==
                        recordLanguagesHelpNotesList[j].languageId.toLowerCase()
                    ) {
                        newInsertUpdateState.recordLanguageHelpNotesList[i].value =
                            recordLanguagesHelpNotesList[j].recordDescription;
                        break;
                    }
                }
            }
            showAdditionalDataMenuItem(recordInfo.componentId);

            switch (recordInfo.componentId) {
                case TaskTypeComponentEnum.caseResponseEmailTaskComponent:
                case TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent:
                    let newSendEmailExtendedTaskTypeState: SendEmailExtendedTaskType =
                        JSON.parse(recordInfo.jsonParameters) as SendEmailExtendedTaskType;
                    setSendEmailExtendedTaskTypeState({
                        ...newSendEmailExtendedTaskTypeState,
                    });
                    realMode == "Update"
                    getEmailById(newSendEmailExtendedTaskTypeState.emailTemplateId);

                    newInsertUpdateState.isEmailTask = true;
                    break;
                case TaskTypeComponentEnum.caseResponseExchangeTaskComponent:
                    let newExportCaseExtendedTaskTypeState: ExportCaseExtendedTaskType =
                        JSON.parse(recordInfo.jsonParameters) as ExportCaseExtendedTaskType;
                    setExportCaseExtendedTaskTypeState({
                        ...newExportCaseExtendedTaskTypeState,
                    });
                    newInsertUpdateState.isExportCaseTask = true;
                    break;
                case TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent:
                    let newExportCaseExtendedTaskTypeState2: ExportCaseExtendedTaskType =
                        JSON.parse(recordInfo.jsonParameters) as ExportCaseExtendedTaskType;
                    setExportCaseExtendedTaskTypeState({
                        ...newExportCaseExtendedTaskTypeState2,
                    });
                    newInsertUpdateState.isExportCaseTask = true;
                    newInsertUpdateState.isExportCaseAutoTask = true;
                    break;
                case TaskTypeComponentEnum.externalTaskComponent:
                    let newExternalExtendedTaskState: ExternalExtendedTaskType =
                        JSON.parse(recordInfo.jsonParameters) as ExternalExtendedTaskType;
                    setExternalExtendedTaskState({ ...newExternalExtendedTaskState });
                    newInsertUpdateState.isExternalTask = true;
                    break;
                case TaskTypeComponentEnum.caseResponseAnswerAutoTaskComponent:
                    let data =
                        JSON.parse(recordInfo.jsonParameters) as TPKeyValue;
                    setAutomaticTaskAnswer(data);
                    newInsertUpdateState.automaticAnswer = true;
                    break;
            }


            newInsertUpdateState.componentCanAddAdditionalData =
                ComponentCanAddAdditionalDataConst.includes(recordInfo.componentId);

            setInsertUpdateState(newInsertUpdateState);
            setIsLoadingScreen(false);
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getById ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getById ex`);
            setIsLoadingScreen(false);
        }
    };

    const showAdditionalDataMenuItem = (componentId: string) => {
        let newComponentCanAdditionalData: boolean =
            ComponentCanAddAdditionalDataConst.includes(componentId);
        setCanAddAdditionalData(newComponentCanAdditionalData);
        if (!newComponentCanAdditionalData) {
            callBackResult({
                result: "remove_item_from_aside_menu",
                itemLabel: "Additional data",
            });
        } else {
            callBackResult({
                result: "add_additional_data_item_for_aside_menu",
            });
        }
    };

    const getRecordLanguageList = async (
        pRecordId: String,
        _multilanguageTableName: String,
        _multilanguageFieldName: String,
        _expectedCodes: Array<number>,
    ): Promise<Array<MultilanguageFieldValueViewModel>> => {
        let serviceMultilanguageFieldValue = new MultilanguageFieldValueService();
        try {
            let responseRequest =
                await serviceMultilanguageFieldValue.getTPClientUIByTableAndRecordIdAndFieldName(
                    _multilanguageTableName,
                    _multilanguageFieldName,
                    pRecordId,
                    false,
                    true,
                    _expectedCodes,
                );

            let recordInfo: Array<MultilanguageFieldValueViewModel>;
            recordInfo = [...responseRequest];
            return recordInfo;
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} getRecordLanguageList ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} getRecordLanguageList ex`);
            return [];
        }
    };

    const handleCancelButtonClick = () => {
        callBackResult({ result: "cancel_general", recordId: recordId });
    };

    const handleComponentChange = (e: any) => {
        //123
        let componentId: string = e.target.value;
        let newInsertUpdateState = { ...insertUpdateState };
        newInsertUpdateState.componentId = componentId;

        //draw Elements
        newInsertUpdateState.isExternalTask = false;
        newInsertUpdateState.isExportCaseTask = false;
        newInsertUpdateState.isExportCaseAutoTask = false;
        newInsertUpdateState.isEmailTask = false;

        newInsertUpdateState.isDecision = false;
        newInsertUpdateState.isCommentRequiered = false;
        newInsertUpdateState.isVisibleTaskReport = true;
        newInsertUpdateState.automaticAnswer = false;

        newInsertUpdateState.disabledTaskReport = true;
        if (componentId === "") {
            setInsertUpdateState(newInsertUpdateState);
            return;
        }

        if (realMode == "Update") {
            showAdditionalDataMenuItem(componentId);
        }

        newInsertUpdateState.componentIdErrorMessage = "";
        switch (componentId) {
            case TaskTypeComponentEnum.caseResponseEmailAutoTaskComponent:
            case TaskTypeComponentEnum.caseResponseEmailTaskComponent:
                newInsertUpdateState.isEmailTask = true;
                break;
            case TaskTypeComponentEnum.externalTaskComponent:
                newInsertUpdateState.isExternalTask = true;
                break;
            case TaskTypeComponentEnum.caseResponseExchangeTaskComponent:
                newInsertUpdateState.isExportCaseTask = true;
                break;
            case TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent:
                newInsertUpdateState.isExportCaseTask = true;
                newInsertUpdateState.isExportCaseAutoTask = true;
                break;
            case TaskTypeComponentEnum.caseResponseDecisionAutoTaskComponent:
            case TaskTypeComponentEnum.caseResponseDecisionRetryTaskComponent:
            case TaskTypeComponentEnum.caseResponseDecisionTaskComponent:
                newInsertUpdateState.isDecision = true;
                newInsertUpdateState.isVisibleTaskReport = false;
                newInsertUpdateState.disabledTaskReport = false;
                break;
            case TaskTypeComponentEnum.caseResponseAnswerAutoTaskComponent:
                newInsertUpdateState.automaticAnswer = true;
                break;
        }

        setInsertUpdateState(newInsertUpdateState);
    };

    const handleExportDestinationChange = (e: any) => {
        let newExportCaseExtendedTaskTypeState = {
            ...exportCaseExtendedTaskTypeState,
        };
        newExportCaseExtendedTaskTypeState.exportDestinationId = e.target.value;
        newExportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage = "";
        setExportCaseExtendedTaskTypeState(newExportCaseExtendedTaskTypeState);
    };

    const handleExportCommentsChange = (newExportComments: string) => {
        let newexportCaseExtendedTaskTypeState = {
            ...exportCaseExtendedTaskTypeState,
        };
        newexportCaseExtendedTaskTypeState.exportComments = newExportComments;
        newexportCaseExtendedTaskTypeState.exportCommentsErrorMessage = "";
        setExportCaseExtendedTaskTypeState(newexportCaseExtendedTaskTypeState);
    };

    const handleOutboundMailAccountIdChange = (e: any) => {
        let newsendEmailExtendedTaskTypeState = {
            ...sendEmailExtendedTaskTypeState,
        };
        newsendEmailExtendedTaskTypeState.outboundMailAccountId = e.target.value;
        setSendEmailExtendedTaskTypeState(newsendEmailExtendedTaskTypeState);
    };

    const handleEmailTemplateIdChange = (e: any) => {
        let newsendEmailExtendedTaskTypeState = {
            ...sendEmailExtendedTaskTypeState,
        };
        newsendEmailExtendedTaskTypeState.emailTemplateId = e.target.value;
        newsendEmailExtendedTaskTypeState.emailTemplateIdErrorMessage = "";
        setSendEmailExtendedTaskTypeState(newsendEmailExtendedTaskTypeState);
    };

    const {
        isOpen: isOpenModalLanguageList,
        openModal: handleOpenModalLanguageList,
        closeModal: handleCloseModalLanguageList,
        saveChanges: handleSaveChangesModalLanguageList,
    } = useModal(false);

    const {
        isOpen: isOpenModalHelpNotesList,
        openModal: handleOpenHelpNotesList,
        closeModal: handleCloseHelpNotesList,
        saveChanges: handleSaveChangesHelpNotesList,
    } = useModal(false);

    //Only once to set resources and load function in update mode
    //Select the handleRuleList
    const handleRuleListDefinition = (e: any, type: string) => {
        const { value } = e.target;
        switch (type) {
            case "ruleList":
                setRuleListValue(value);
                break;
            case "baseList":
                setBaseListValue(value);
                break;
            case "comparisionList":
                setComparisionListValue(value);
                break;
            case "reference":
                setReferenceValue(value);
                break;
            case "logicalOperatorList":
                setLogicalOperatorValue(value);
                break;
            case "ruleListSecond":
                setRuleListValueSecond(value);
                break;
            case "baseListSecond":
                setBaseListValueSecond(value);
                break;
            case "comparisionListSecond":
                setComparisionListValueSecond(value);
                break;
            case "referenceSecond":
                setReferenceValueSecond(value);
                break;
            default:
                console.error("Invalid type");
        }
    };

    const generalAutomaticId = async () => {
        let serviceClient = new SequenceService();
        let expectedCodes: Array<number> = [200];
        try {
            setIsLoadingScreen(true);

            let responseRequest = await serviceClient.generalAutomaticId(
                false,
                true,
                expectedCodes,
                SequenceGeneratorSequencesNameEnum.SQTATY,
            );

            setIsLoadingScreen(false);
            if (responseRequest.responseResult) {
                let result = responseRequest?.responseData?.data[0]?.sequenceCode;
                setReferenceId(result);
            }
        } catch (error) {
            TPLog.Log(
                `Error ${componentFileName} updatetFunction ex`,
                TPLogType.ERROR,
                error,
            );
            console.error(`Error ${componentFileName} updatetFunction ex`);
            setIsLoadingScreen(false);
        }
    };

    //to set resources and load function in update mode and when ruleListValue state changes
    useEffect(() => {
        loadResourcesAndLoadInfo();
        realMode === "Insert" && generalAutomaticId();
    }, [ruleListValue, ruleListValueSecond]);

    return (
        //#region  Render
        <ContentVerticalTabInsertUpdateStyled>
            <TPLoadingOverlay active={isLoadingScreen}>
                <div className="row">
                    <div className="col-10">
                        <TPPageTitle>{titleLabel}</TPPageTitle>
                        {realMode == "Update" ? (
                            <TPPageSubTitle>{`${subTitleLabel} ${recordId} / ${originalRecordDescription}`}</TPPageSubTitle>
                        ) : null}
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TPPageSectionTitle>{descriptionSectionLabel}</TPPageSectionTitle>
                    </div>
                </div>

                <div className="row">
                    <div className="col-10">
                        <TPPageSection>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPTextBox
                                            id="IdTextBox"
                                            labelText={idLabel}
                                            isMandatory={true}
                                            // value={insertUpdateState.id}
                                            value={realMode === "Insert" ? referenceId : recordId}
                                            onChange={
                                                realMode === "Insert"
                                                    ? (e: any) => handleOnIdChange(e.target.value)
                                                    : () => {
                                                        TPGlobal.foo();
                                                    }
                                            }
                                            maxLength={20}
                                            // disabled={realMode !== "Insert"}
                                            disabled
                                            errorMessage={insertUpdateState.idErrorMessage}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {insertUpdateState.recordLanguageList.length > 0 &&
                                    TPGlobal.TPClientAvailableLanguages.map(
                                        (item, index) =>
                                            index === 0 && (
                                                <div className="col-6" key={`languageItem-${item.id}`}>
                                                    <div className="form-group">
                                                        <TPTextBox
                                                            id="IdTextBox"
                                                            isMandatory={index === 0}
                                                            labelText={`${nameLabel} (${item.name})`}
                                                            value={
                                                                insertUpdateState.recordLanguageList[index]
                                                                    .value
                                                            }
                                                            onChange={(e: any) =>
                                                                handleLanguageChange(index, e.target.value)
                                                            }
                                                            maxLength={500}
                                                            errorMessage={
                                                                insertUpdateState.descriptionErrorMessages[
                                                                index
                                                                ]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                    )}
                                {insertUpdateState.recordLanguageList.length > 1 && (
                                    <>
                                        <div className="col-4">
                                            <div className="pt-4">
                                                <TPButton
                                                    type={TPButtonTypes.icon}
                                                    icon={TPIconTypes.language}
                                                    text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                                                    tooltip={languageListLabel}
                                                    className={"pt-3"}
                                                    onClick={handleOpenModalLanguageList}
                                                />
                                            </div>
                                        </div>
                                        <TPModalLanguageList
                                            isOpen={isOpenModalLanguageList}
                                            title={languageListLabel}
                                            acceptLabel={saveButtonLabel}
                                            cancelLabel={cancelButtonLabel}
                                            saveChanges={handleSaveChangesModalLanguageList}
                                            closeModal={handleCloseModalLanguageList}
                                        >
                                            <div
                                                className="row overflow-auto"
                                                style={{ height: "200px" }}
                                            >
                                                {TPGlobal.TPClientAvailableLanguages.map(
                                                    (item, index) =>
                                                        index > 0 && (
                                                            <div
                                                                className="col-12"
                                                                key={`languageItem-${item.id}`}
                                                            >
                                                                <div className="form-group">
                                                                    <TPTextBox
                                                                        id="IdTextBox"
                                                                        isMandatory={index === 0}
                                                                        labelText={`${nameLabel} (${item.name})`}
                                                                        value={
                                                                            insertUpdateState.recordLanguageList[
                                                                                index
                                                                            ].value
                                                                        }
                                                                        onChange={(e: any) =>
                                                                            handleLanguageChange(
                                                                                index,
                                                                                e.target.value,
                                                                            )
                                                                        }
                                                                        maxLength={500}
                                                                        errorMessage={
                                                                            insertUpdateState
                                                                                .descriptionErrorMessages[index]
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ),
                                                )}
                                            </div>
                                        </TPModalLanguageList>
                                    </>
                                )}
                            </div>
                        </TPPageSection>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TPPageSectionTitle>{parametersSectionLabel}</TPPageSectionTitle>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10">
                        <TPPageSection>
                            <div className="row">
                                <div className="col-6">
                                    <TPSelect
                                        id="IdSelect"
                                        isMandatory={true}
                                        onChange={handleComponentChange}
                                        dataSource={componentKeyValue}
                                        value={insertUpdateState.componentId}
                                        labelText={componentLabel}
                                        errorMessage={insertUpdateState.componentIdErrorMessage}
                                    ></TPSelect>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={isDecisionLabel}
                                            checked={insertUpdateState.isDecision}
                                            onChange={(e: any) => handleIsisDecisionChange()}
                                            disabled={true}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={escalatesLabel}
                                            checked={insertUpdateState.escalates}
                                            onChange={(e: any) => handleEscalatesChange()}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={addableLabel}
                                            checked={insertUpdateState.addable}
                                            onChange={(e: any) => handleAddableChange()}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={isCommentRequieredLabel}
                                            checked={insertUpdateState.isCommentRequiered}
                                            onChange={(e: any) => handleIsCommentRequieredChange()}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={isPrivateLabel}
                                            disabled={disabledIsPrivate}
                                            checked={insertUpdateState.isPrivate}
                                            onChange={(e: any) => handleIsPrivateChange()}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={isVisibleTaskReportLabel}
                                            checked={insertUpdateState.isVisibleTaskReport}
                                            onChange={(e: any) => handleIsVisibleTaskReportChange()}
                                            disabled={insertUpdateState.disabledTaskReport}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <TPCheckBox
                                            id="IdCheckBox"
                                            labelText={isActiveLabel}
                                            checked={insertUpdateState.isActive}
                                            onChange={(e: any) => handleIsActiveChange()}
                                        ></TPCheckBox>
                                    </div>
                                </div>
                            </div>
                        </TPPageSection>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <TPPageSectionTitle>{helpNotesSectionLabel}</TPPageSectionTitle>
                    </div>
                </div>

                <div className="row">
                    {insertUpdateState.recordLanguageList.length > 0 &&
                        TPGlobal.TPClientAvailableLanguages.map(
                            (item, index, array) =>
                                index === 0 && (
                                    <div className="col-6">
                                        <div className="form-group" style={{ maxWidth: "83%" }} >
                                            <TPTextBox
                                                id="IdTextBox"
                                                isMandatory={false}
                                                labelText={`${helpNotesLabel} (${array[index].name})`}
                                                value={
                                                    insertUpdateState.recordLanguageHelpNotesList[index]
                                                        .value
                                                }
                                                onChange={(e: any) =>
                                                    handleLanguageHelpNotesChange(index, e.target.value)
                                                }
                                                maxLength={5000}
                                                errorMessage={
                                                    insertUpdateState.helpNotesErrorMessages[index]
                                                }
                                            />
                                        </div>
                                    </div>
                                ),
                        )}

                    {insertUpdateState.recordLanguageList.length > 1 && (
                        <>
                            <div className="col-4">
                                <div className="pt-4" style={{ marginLeft: "-90px" }}>
                                    <TPButton
                                        type={TPButtonTypes.icon}
                                        icon={TPIconTypes.language}
                                        text={`+${insertUpdateState.recordLanguageList.length - 1}`}
                                        tooltip={"Help Notes"}
                                        className={"pt-3"}
                                        onClick={handleOpenHelpNotesList}
                                    />
                                </div>
                            </div>
                            <TPModalLanguageList
                                isOpen={isOpenModalHelpNotesList}
                                title={"Help Notes"}
                                acceptLabel={saveButtonLabel}
                                cancelLabel={cancelButtonLabel}
                                saveChanges={handleSaveChangesHelpNotesList}
                                closeModal={handleCloseHelpNotesList}
                            >
                                <div className="row overflow-auto" style={{ height: "200px" }}>
                                    {/* {TPGlobal.TPClientAvailableLanguages.map(
                    (item, index) =>
                      index > 0 && (
                        <div
                          className="col-12"
                          key={`languageItem-${item.id}`}
                        >
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={index === 0}
                              labelText={`${nameLabel} (${item.name})`}
                              value={
                                insertUpdateState.recordLanguageList[
                                  index
                                ].value
                              }
                              onChange={(e: any) =>
                                handleLanguageChange(
                                  index,
                                  e.target.value
                                )
                              }
                              maxLength={500}
                              errorMessage={
                                insertUpdateState
                                  .descriptionErrorMessages[index]
                              }
                            />
                          </div>
                        </div>
                      )
                  )} */}
                                    {insertUpdateState.recordLanguageHelpNotesList.length > 0
                                        ? TPGlobal.TPClientAvailableLanguages.map(
                                            function (item, index, array) {
                                                let elementJsx;
                                                if (index % 2 === 0) {
                                                    elementJsx = (
                                                        <div
                                                            className="row"
                                                            key={"languageItem" + array[index].id}
                                                        >
                                                            <div className="col-12">
                                                                <div className="form-group">
                                                                    <TPTextBox
                                                                        id="IdTextBox"
                                                                        isMandatory={false}
                                                                        labelText={`${helpNotesLabel} (${array[index].name})`}
                                                                        value={
                                                                            insertUpdateState
                                                                                .recordLanguageHelpNotesList[index]
                                                                                .value
                                                                        }
                                                                        onChange={(e: any) =>
                                                                            handleLanguageHelpNotesChange(
                                                                                index,
                                                                                e.target.value,
                                                                            )
                                                                        }
                                                                        maxLength={5000}
                                                                        errorMessage={
                                                                            insertUpdateState
                                                                                .helpNotesErrorMessages[index]
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {index + 1 <= array.length - 1 ? (
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <TPTextBox
                                                                            id="IdTextBox"
                                                                            isMandatory={false}
                                                                            labelText={`${helpNotesLabel} (${array[index + 1].name})`}
                                                                            value={
                                                                                insertUpdateState
                                                                                    .recordLanguageHelpNotesList[
                                                                                    index + 1
                                                                                ].value
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                handleLanguageHelpNotesChange(
                                                                                    index + 1,
                                                                                    e.target.value,
                                                                                )
                                                                            }
                                                                            maxLength={5000}
                                                                            errorMessage={
                                                                                insertUpdateState
                                                                                    .helpNotesErrorMessages[index + 1]
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    );
                                                } else {
                                                    elementJsx = null;
                                                }

                                                return elementJsx;
                                            },
                                        )
                                        : null}
                                </div>
                            </TPModalLanguageList>
                        </>
                    )}
                </div>

                <div className="row">
                    <div className="col-10">
                        <TPPageSection>
                            {/* {insertUpdateState.recordLanguageHelpNotesList.length > 0
                ? TPGlobal.TPClientAvailableLanguages.map(function (
                  item,
                  index,
                  array
                ) {
                  let elementJsx;
                  if (index % 2 === 0) {
                    elementJsx = (
                      <div
                        className="row"
                        key={"languageItem" + array[index].id}
                      >
                        <div className="col-6">
                          <div className="form-group">
                            <TPTextBox
                              id="IdTextBox"
                              isMandatory={false}
                              labelText={`${helpNotesLabel} (${array[index].name})`}
                              value={
                                insertUpdateState.recordLanguageHelpNotesList[
                                  index
                                ].value
                              }
                              onChange={(e: any) =>
                                handleLanguageHelpNotesChange(
                                  index,
                                  e.target.value
                                )
                              }
                              maxLength={5000}
                              errorMessage={
                                insertUpdateState.helpNotesErrorMessages[
                                index
                                ]
                              }
                            />
                          </div>
                        </div>
                        {index + 1 <= array.length - 1 ? (
                          <div className="col-6">
                            <div className="form-group">
                              <TPTextBox
                                id="IdTextBox"
                                isMandatory={false}
                                labelText={`${helpNotesLabel} (${array[index + 1].name
                                  })`}
                                value={
                                  insertUpdateState
                                    .recordLanguageHelpNotesList[index + 1]
                                    .value
                                }
                                onChange={(e: any) =>
                                  handleLanguageHelpNotesChange(
                                    index + 1,
                                    e.target.value
                                  )
                                }
                                maxLength={5000}
                                errorMessage={
                                  insertUpdateState.helpNotesErrorMessages[
                                  index + 1
                                  ]
                                }
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  } else {
                    elementJsx = null;
                  }

                  return elementJsx;
                })
                : null} */}
                            {insertUpdateState.isExternalTask && (
                                <>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <TPTextBox
                                                    id="IdTextBox"
                                                    isMandatory={true}
                                                    labelText={externalTaskURLLabel}
                                                    value={externalExtendedTaskState.externalTaskURL}
                                                    onChange={(e: any) =>
                                                        handleExternalTaskChange(e.target.value)
                                                    }
                                                    maxLength={1000}
                                                    errorMessage={
                                                        externalExtendedTaskState.externalTaskURLErrorMessage
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <TPTextBox
                                                    id="IdTextBox"
                                                    isMandatory={false}
                                                    labelText={externalTaskRSAPublicKeyLabel}
                                                    value={insertUpdateState.externalTaskRSAPublicKey}
                                                    onChange={(e: any) =>
                                                        handleExternalTaskRSAPublicKeyChange(e.target.value)
                                                    }
                                                    maxLength={1000}
                                                    errorMessage={
                                                        insertUpdateState.externalTaskRSAPublicKeyErrorMessage
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="row">
                                {insertUpdateState.isExportCaseTask && (
                                    <>
                                        <div className="col-6">
                                            <TPSelect
                                                id="IdSelect"
                                                isMandatory={
                                                    insertUpdateState.componentId ===
                                                    TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent
                                                }
                                                onChange={handleExportDestinationChange}
                                                dataSource={fakeDataExportDestinationListState}
                                                value={
                                                    exportCaseExtendedTaskTypeState.exportDestinationId
                                                }
                                                labelText={exportDestinationLabel}
                                                errorMessage={
                                                    exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                                }
                                            ></TPSelect>
                                        </div>
                                    </>
                                )}
                                {insertUpdateState.isExportCaseAutoTask && (
                                    <>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <TPTextBox
                                                    id="IdTextBox"
                                                    isMandatory={
                                                        insertUpdateState.componentId ===
                                                        TaskTypeComponentEnum.caseResponseExchangeAutoTaskComponent
                                                    }
                                                    labelText={comentsToExportLabel}
                                                    value={exportCaseExtendedTaskTypeState.exportComments}
                                                    onChange={(e: any) =>
                                                        handleExportCommentsChange(e.target.value)
                                                    }
                                                    maxLength={1000}
                                                    errorMessage={
                                                        emailAccountError
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                            {insertUpdateState.isEmailTask && (
                                <>
                                    <div className="row">
                                        <div className="col-6">
                                            <TPSelect
                                                id="IdSelect"
                                                isMandatory={true}
                                                onChange={handleOutboundMailAccountIdChange}
                                                dataSource={fakeDataOutboundMailAccountList}
                                                value={
                                                    sendEmailExtendedTaskTypeState.outboundMailAccountId
                                                }
                                                labelText={outboundMailAccountLabel}
                                                errorMessage={emailAccountError}
                                            ></TPSelect>
                                        </div>

                                        <div className="col-md-6">
                                            <TPAutoComplete
                                                isMandatory={true}
                                                labelText={templateLabel}
                                                onValueChange={handleTemplateChange}
                                                onSearch={(query: string) => {
                                                    handleTemplateOnAutocompleteQuery(query);
                                                }}
                                                isLoading={false}
                                                options={autocompleteTemplateOptions}
                                                withIcon={true}
                                                emptyLabel={emptyLabel}
                                                onKeyDown={handleTemplateOnAutocompleteKeyDown}
                                                selected={modalTemplateScreenState.newTemplate}
                                                errorMessage={emailTemplateError}
                                                downArrowClick={handleAutoCompleteTemplatepNClick}
                                            ></TPAutoComplete>
                                        </div>
                                    </div>
                                </>
                            )}


                            {insertUpdateState.automaticAnswer && (
                                /*123*/
                                <>
                                    <div className="row">
                                        <div className="col-6">
                                            <TPTextBox
                                                id="id-automatic-answer"
                                                isMandatory={false}
                                                labelText={comentsToAutomaticAnswerLabel}
                                                value={automaticTaskAnswer.value}
                                                onChange={(e: any) => {
                                                    setAutomaticTaskAnswer({ value: e.target.value, key: "" });
                                                }
                                                }
                                                maxLength={1000}
                                                errorMessage={
                                                    automaticTaskAnswerError
                                                }
                                            />
                                        </div>

                                        <div className="col-md-6">

                                        </div>

                                    </div>
                                </>
                            )}
                        </TPPageSection>
                    </div>
                </div>

                {/* Add a Rules Definiton  */}
                {insertUpdateState.componentId ===
                    TaskTypeComponentEnum.caseResponseDecisionAutoTaskComponent && (
                        <div
                            className="container mt-2"
                            style={{ padding: "12px 12px 12px 0px" }}
                        >
                            <h5>Rules Definition</h5>
                            <div className="row">
                                <div className="col-6">
                                    <TPSelect
                                        id="IdSelect"
                                        isMandatory={true}
                                        onChange={(e: any) => handleRuleListDefinition(e, "ruleList")}
                                        dataSource={fakeDataRuleListFirstState}
                                        value={ruleListValue}
                                        labelText={"Rule Type1"}
                                        errorMessage={
                                            exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                        }
                                    ></TPSelect>
                                </div>
                                <div className="col-6">
                                    <TPSelect
                                        id="IdSelect"
                                        isMandatory={true}
                                        onChange={(e: any) => handleRuleListDefinition(e, "baseList")}
                                        dataSource={fakeDataBaseListFirstState}
                                        value={baseListValue}
                                        labelText={"Base Field1"}
                                        errorMessage={
                                            exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                        }
                                    ></TPSelect>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-6">
                                    <TPSelect
                                        id="IdSelect"
                                        isMandatory={true}
                                        onChange={(e: any) =>
                                            handleRuleListDefinition(e, "comparisionList")
                                        }
                                        dataSource={fakeDataComparisionListFirstState}
                                        value={comparisionListValue}
                                        labelText={"Comparison Operator1"}
                                        errorMessage={
                                            exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                        }
                                    ></TPSelect>
                                </div>
                                <div className="col-6">
                                    <TPTextBox
                                        id="IdTextBox"
                                        isMandatory={true}
                                        labelText={"Reference Value1"}
                                        value={referenceValue}
                                        onChange={(e: any) =>
                                            handleRuleListDefinition(e, "reference")
                                        }
                                        maxLength={1000}
                                        errorMessage={
                                            exportCaseExtendedTaskTypeState.exportCommentsErrorMessage
                                        }
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col"></div>
                                <div className="col-4">
                                    <div className="col">
                                        <TPSelect
                                            id="IdSelect"
                                            isMandatory={true}
                                            onChange={(e: any) =>
                                                handleRuleListDefinition(e, "logicalOperatorList")
                                            }
                                            dataSource={fakeDataLogicalOperator}
                                            value={logicalOperatorValue}
                                            labelText={"Logical Operator"}
                                            errorMessage={
                                                exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                            }
                                        ></TPSelect>
                                    </div>
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                    )}

                {(logicalOperatorValue === "AND" || logicalOperatorValue === "OR") && (
                    <div>
                        <div className="row">
                            <div className="col-6">
                                <TPSelect
                                    id="IdSelect"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                        handleRuleListDefinition(e, "ruleListSecond")
                                    }
                                    dataSource={fakeDataRuleListFirstState}
                                    value={ruleListValueSecond}
                                    labelText={"Rule Type2"}
                                    errorMessage={
                                        exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                    }
                                ></TPSelect>
                            </div>
                            <div className="col-6">
                                <TPSelect
                                    id="IdSelect"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                        handleRuleListDefinition(e, "baseListSecond")
                                    }
                                    dataSource={fakeDataBaseListFirstState}
                                    value={baseListValueSecond}
                                    labelText={"Base Field2"}
                                    errorMessage={
                                        exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                    }
                                ></TPSelect>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <TPSelect
                                    id="IdSelect"
                                    isMandatory={true}
                                    onChange={(e: any) =>
                                        handleRuleListDefinition(e, "comparisionListSecond")
                                    }
                                    dataSource={fakeDataComparisionListFirstState}
                                    value={comparisionListValueSecond}
                                    labelText={"Comparison Operator2"}
                                    errorMessage={
                                        exportCaseExtendedTaskTypeState.exportDestinationIdErrorMessage
                                    }
                                ></TPSelect>
                            </div>
                            <div className="col-6">
                                <TPTextBox
                                    id="IdTextBox"
                                    isMandatory={true}
                                    labelText={"Reference Value2"}
                                    value={referenceValueSecond}
                                    onChange={(e: any) =>
                                        handleRuleListDefinition(e, "referenceSecond")
                                    }
                                    maxLength={1000}
                                    errorMessage={
                                        exportCaseExtendedTaskTypeState.exportCommentsErrorMessage
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="row mt-4" style={{ marginBottom: "50px" }}>
                    <div className="col-10">
                        <TPPageAcceptCancelButtonsContainer>
                            <TPButton
                                type={TPButtonTypes.primary}
                                onClick={handleOkButtonClick}
                            >
                                {saveButtonLabel}
                            </TPButton>
                            <TPButton
                                type={TPButtonTypes.link}
                                onClick={handleCancelButtonClick}
                                className={"ms-2"}
                            >
                                {cancelButtonLabel}
                            </TPButton>
                        </TPPageAcceptCancelButtonsContainer>
                    </div>
                </div>
            </TPLoadingOverlay>
        </ContentVerticalTabInsertUpdateStyled >

        //#endregion
    );
};

export default TaskTypeInsertUpdate;
