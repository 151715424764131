import TagButton from "@/components/bootstrap/components/tagButton/TPTagButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import { TPFormControlContainerStyled } from "@/helpers/generalStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import { MouseEventHandler, ReactElement, useEffect, useId } from "react";
import { TPTextBoxStyled } from "./tpTextboxStyles";

interface TPTextBoxInterface {
  onClick?: Function;
  onKeyPress?: Function;
  onChange: Function;
  className?: string;
  value: string | number | undefined;
  disabled?: boolean;
  maxLength?: number;
  hasFocus?: boolean;
  labelText?: string | JSX.Element;
  isMandatory?: boolean;
  errorMessage?: string;
  isHorizontal?: boolean;
  placeholder?: string;
  withIcon?: boolean;
  icon?: TPIconTypes;
  type?: string;
  iconClick?: any;
  containerStyle?: any;
  labelStyle?: any;
  textStyle?: any;
  customIcon?: JSX.Element;
  isPrefixIcon?: boolean;
  inputRef?: any;
  isTag?: boolean;
  id?: string;
  tooltip?: string;
  withCircleText?: boolean;
  tagHandler?: MouseEventHandler<HTMLButtonElement>;
}

const TPTextBox = ({
  onClick,
  onKeyPress,
  onChange,
  className = "",
  value,
  disabled,
  maxLength,
  hasFocus,
  labelText,
  isMandatory = false,
  errorMessage = "",
  isHorizontal = false,
  placeholder = "",
  withIcon = false,
  icon = TPIconTypes.default,
  type = "text",
  iconClick = undefined,
  containerStyle,
  labelStyle,
  textStyle,
  customIcon,
  isPrefixIcon = false,
  inputRef,
  isTag = false,
  id,
  tooltip,
  withCircleText,
  tagHandler = undefined,
}: TPTextBoxInterface): ReactElement => {
  // const inputRef = useRef(null);
  const theId = `textbox-${useId()}`;

  const handleOnChange = (e: any) => {
    onChange && onChange(e);
  };
  const handleOnClick = (e: any) => {
    onClick && onClick(e);
  };

  const handleOnKeyPress = (e: any) => {
    onKeyPress && onKeyPress(e);
  };
  const theInput = (
    <TPTextBoxStyled
      placeholder={placeholder}
      id={id ? id : theId}
      ref={inputRef}
      type={type}
      value={value}
      className={`form-control ${className} ${
        errorMessage != "" ? "is-invalid" : ""
      } ${withIcon ? "border-end-0 with-icon" : ""}`}
      onChange={handleOnChange}
      onClick={handleOnClick}
      onKeyPress={handleOnKeyPress}
      disabled={disabled}
      maxLength={maxLength}
      autoCorrect={"off"}
      autoComplete={"off"}
      style={textStyle}
    />
  );

  useEffect(() => {
    let inputElement: any;
    if (hasFocus) {
      if (inputRef.current) {
        inputElement = inputRef.current;
        inputElement.focus();
      }
    }
  }, [hasFocus, inputRef]);

  const drawLabel = () => {
    return (
      <TPLabel
        withCircleText={withCircleText}
        htmlFor={id ? id : theId}
        isMandatory={isMandatory}
        labelText={labelText}
        style={labelStyle}
      />
    );
  };

  const drawInvalidFeedBack = () => {
    return (
      <div
        className="invalid-feedback"
        style={{ display: isTag ? "block" : "" }}
      >
        {errorMessage}
      </div>
    );
  };

  return (
    <>
      {!isHorizontal && drawLabel()}
      <TPFormControlContainerStyled
        isHorizontal={isHorizontal}
        className={`${withIcon ? "input-group" : ""}`}
        style={containerStyle}
        isTag={isTag}
      >
        {isHorizontal && drawLabel()}
        {withIcon && isPrefixIcon && (
          <span className="input-group-prepend">
            {icon === TPIconTypes.custom && <>{customIcon}</>}
            {icon != TPIconTypes.custom && (
              <TPIcon iconType={icon} onClick={iconClick} />
            )}
          </span>
        )}
        {theInput}
        {isTag && (
          <TagButton className="tag-btn" tagHandler={tagHandler}>
            {"[Tag]"}
          </TagButton>
        )}
        {withIcon && !isPrefixIcon && (
          <span className="input-group-append" title={tooltip}>
            {icon === TPIconTypes.custom && <>{customIcon}</>}
            {icon != TPIconTypes.custom && (
              <TPIcon iconType={icon} onClick={iconClick} />
            )}
          </span>
        )}

        {!withIcon && errorMessage != "" && !isTag && drawInvalidFeedBack()}
      </TPFormControlContainerStyled>
      {isTag && drawInvalidFeedBack()}
      {withIcon && errorMessage != "" && (
        <div>
          <div className="is-invalid"></div>
          {drawInvalidFeedBack()}
        </div>
      )}
    </>
  );
};

export default TPTextBox;
