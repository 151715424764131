import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import { CIMTitleSection, TPPageTitle } from "@/components/TPPage/tpPageStyles";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import {
  RecordStatus,
  UserRecord,
} from "@/models/LoadHistory/load-history.model";
import DynamicTable, {
  ColumnStyles,
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { throwAlertError } from "@/modules/core/utils/errors-management";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TableDateItem from "@/modules/core/utils/table-micro-components/TableDateItem";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { convertValuesToUppercase } from "@/modules/core/utils/text-regex";
import { TPI18N } from "@/services/I18nService";
import {
  GetUserRecordsProps,
  LoadHistoryService,
} from "@/services/LoadHistoryService";
import { FC, ReactElement, useEffect, useState } from "react";

type SolvedRecordsProps = {
  recordId: string;
  records: UserRecord[];
  callBackResult: Function;
};

interface TableRecord {
  ticketId: string;
  ticketType: string;
  workedBy: string;
  time: string;
  workedDate: string;
  outcome: string;
}

const SolvedRecords: FC<SolvedRecordsProps> = ({
  recordId,
  records,
  callBackResult,
}): ReactElement => {
  //logic useState variables
  const [isLoadingScreen, setIsLoadingScreen] = useState<boolean>(false);
  const [isResourcesLoaded, setIsResourcesLoaded] = useState<boolean>(false);
  const [tableRecords, setTableRecords] = useState<TableRecord[]>([]);

  //label useState variables
  const component: string = "SolvedRecords";

  const [titleLabel, setTitleLabel] = useState<string>("");
  const [closeLabel, setCloseLabel] = useState<string>("");
  const [eventLabel, setEventLabel] = useState<string>("");
  const [ticketIdLabel, setTicketIdLabel] = useState<string>("");
  const [ticketTypeLabel, setTicketTypeLabel] = useState<string>("");
  const [workedByLabel, setWorkedByLabel] = useState<string>("");
  const [timeLabel, setTimeLabel] = useState<string>("");
  const [workedDateLabel, setWorkedDateLabel] = useState<string>("");
  const [outcomeLabel, setOutcomeLabel] = useState<string>("");

  const getRecordsBy = async (id: string) => {
    const loadService = new LoadHistoryService();

    const params: GetUserRecordsProps = {
      eventLoadId: id,
      recordStatus: RecordStatus.resolved,
    };

    try {
      setIsLoadingScreen(true);
      return await loadService.getUserRecordsBy(params);
    } catch (error) {
      setIsLoadingScreen(false);
      throwAlertError(error, component, "getTableData");
      return [];
    }
  };

  const refreshTableData = (id: string) => {
    getRecordsBy(id).then((response) => {
      if (response.length > 0) setRecordsToTableData(response);
      setIsLoadingScreen(false);
    });
  };

  const columnsNames: CustomColumnNames<TableRecord> = {
    ticketId: ticketIdLabel,
    ticketType: ticketTypeLabel,
    workedBy: workedByLabel,
    time: timeLabel,
    workedDate: workedDateLabel,
    outcome: outcomeLabel,
  };

  const customColumns: ColumnStyles<TableRecord> = {
    ticketId: ({ value, item }) => (
      <TablePrimaryItem value={value} onClick={() => handleShowRecord(item)} />
    ),
    ticketType: () => (
      <TableChip
        value={true}
        onLabel={eventLabel}
        offLabel={eventLabel}
        justify="flex-start"
        beforeShape
      />
    ),
    workedDate: ({ value }) => <TableDateItem value={value} />,
  };

  const handleCloseTab = () => {
    callBackResult({ result: "CANCEL", recordId });
  };

  const handleShowRecord = (record: TableRecord) => {
    //handleClickOpen(); TODO: for the next sprint
  };

  const setRecordsToTableData = (records: UserRecord[]) => {
    const tableRecords: TableRecord[] = records.map(
      ({
        ticketId,
        ticketType,
        workedUserName,
        timeWorked,
        workDate,
        interactionOutcomesDescription,
      }: UserRecord) => ({
        ticketId,
        ticketType,
        workedBy: workedUserName,
        time: timeWorked,
        workedDate: workDate,
        outcome: interactionOutcomesDescription,
      })
    );
    setTableRecords(tableRecords);
  };

  const handleIconClick = (event: TPIconTypes) => {
    if (event === TPIconTypes.loop) refreshTableData(recordId);
  };

  const loadUtilsResources = async () => {
    setTitleLabel(await TPI18N.GetText(component, "Title"));
    setCloseLabel(await TPI18N.GetText(component, "Close"));
    setEventLabel(await TPI18N.GetText(component, "Event"));
    setTicketIdLabel(await TPI18N.GetText(component, "TicketId"));
    setTicketTypeLabel(await TPI18N.GetText(component, "TicketType"));
    setWorkedByLabel(await TPI18N.GetText(component, "WorkedBy"));
    setTimeLabel(await TPI18N.GetText(component, "Time"));
    setWorkedDateLabel(await TPI18N.GetText(component, "WorkedDate"));
    setOutcomeLabel(await TPI18N.GetText(component, "Outcome"));
  };

  useEffect(() => {
    if (records.length > 0) setRecordsToTableData(records);
  }, [records]);

  useEffect(() => {
    loadUtilsResources().then(() => setIsResourcesLoaded(true));
  }, []);

  return (
    <TPLoadingOverlay active={isLoadingScreen}>
      {isResourcesLoaded && (
        <div className="row">
          <div className="col">
            <CIMTitleSection>
              <TPPageTitle style={{ margin: 0 }}>{titleLabel}</TPPageTitle>
              <TPButton
                id="close-solve-records"
                isDesignSystem
                customType={ButtonCustomType.tertiary}
                onClick={handleCloseTab}
                style={{ padding: "1px 18px" }}
              >
                {closeLabel}
              </TPButton>
            </CIMTitleSection>
            <DynamicTable
              id="records"
              data={tableRecords}
              columnStyles={customColumns}
              columnNames={convertValuesToUppercase(columnsNames)}
              searchPosition="right"
              onIconClicked={(event) => handleIconClick(event)}
            />
          </div>
        </div>
      )}
    </TPLoadingOverlay>
  );
};

export default SolvedRecords;
