import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPModal from "@/layouts/TPModal/TPModal";
import { TPButtonTypes } from "@/models/Global/TPGlobalEnums";
import { FC } from "react";
import useListAdminLabels from "../assets/Labeling";

interface ListAdminCancelModalProperties {
  callbackAnswer: (accept: boolean) => void
}

const ListAdminCancelModal: FC<ListAdminCancelModalProperties> = function ({ callbackAnswer }) {

  const { labels } = useListAdminLabels();

  return (
    <TPModal
      modalState={{
        acceptLabel: labels.Yes,
        callBackAnswer: callbackAnswer,
        cancelLabel: labels.No,
        isShown: true,
        titleModal: "",
        hideFooterButtons: true,
        modalWidth: '512px'
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        gap: '32px',
        padding: '32px'
      }}>
        <h3><b>{labels.SystemAlert}</b></h3>
        <label>{labels.CancelConfirm}</label>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '50%', gap: '16px' }}>
          <TPButton onClick={() => callbackAnswer(false)} type={TPButtonTypes.alternative}>{labels.No}</TPButton>
          <TPButton onClick={() => callbackAnswer(true)} type={TPButtonTypes.primary}>{labels.Yes}</TPButton>
        </div>
      </div>
    </TPModal>
  )
}

export default ListAdminCancelModal;