import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import { ButtonCustomType } from "@/components/bootstrap/components/buttons/tpButtonStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable from "@/modules/core/components/dynamic-table/DynamicTable";
import UserProfile, {
  ThemeCase,
} from "@/modules/core/design-system/user-data/UserProfile";
import TableChip from "@/modules/core/utils/table-micro-components/TableChip";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import { ColumnStyles } from "@/pages/Projects/ProjectDetail/ProjectDetailDynamicTable";
import React, { FC, ReactElement, useState } from "react";
import {
  StyledScheduleContent,
  StyledScheduleDot,
  StyledUserProfileCompleted,
} from "../supervisor-cockpit-styles";
import { CockpitView, InfoCardTypes } from "../supervisor-cockpit.model";
import CockpitInfoCard from "./CockpitInfoCard";

type CockpitTeamActivityTableProps = {
  view?: CockpitView;
  withChildren?: boolean;
};

interface TeamActivityData {
  employee: string;
  schedule: "onSchedule" | "offSchedule";
  status: InfoCardTypes;
  duration: string;
  ready: boolean;
  pendingTickets: number;
  queue: string;
}

const CockpitTeamActivityTable: FC<CockpitTeamActivityTableProps> = React.memo(
  ({ view = "team", withChildren }): ReactElement => {
    const component: string = "CockpitTeamActivityTable";

    const [profileStyle, setProfileStyle] = useState<ThemeCase>(
      Math.floor(Math.random() * 5) as ThemeCase
    );

    const data: TeamActivityData[] = [
      {
        employee: "Employee 1",
        schedule: "onSchedule",
        status: "ready",
        duration: "01:30:00",
        ready: true,
        pendingTickets: 5,
        queue: "Queue 1",
      },
      {
        employee: "Employee 2",
        schedule: "offSchedule",
        status: "working",
        duration: "02:15:00",
        ready: false,
        pendingTickets: 3,
        queue: "Queue 2",
      },
      {
        employee: "Employee 3",
        schedule: "onSchedule",
        status: "worked",
        duration: "00:45:00",
        ready: true,
        pendingTickets: 0,
        queue: "Queue 1",
      },
      {
        employee: "Employee 4",
        schedule: "onSchedule",
        status: "online",
        duration: "01:00:00",
        ready: false,
        pendingTickets: 2,
        queue: "Queue 3",
      },
      {
        employee: "Employee 5",
        schedule: "offSchedule",
        status: "offline",
        duration: "00:30:00",
        ready: true,
        pendingTickets: 1,
        queue: "Queue 2",
      },
      {
        employee: "Employee 6",
        schedule: "onSchedule",
        status: "ready",
        duration: "01:45:00",
        ready: true,
        pendingTickets: 4,
        queue: "Queue 1",
      },
      {
        employee: "Employee 7",
        schedule: "offSchedule",
        status: "working",
        duration: "02:30:00",
        ready: false,
        pendingTickets: 2,
        queue: "Queue 3",
      },
    ];

    const RenderUserProfile = React.memo(
      ({ value }: { value: string }): ReactElement => {
        return (
          <StyledUserProfileCompleted>
            <UserProfile
              completeName={value}
              customSize={{ font: "16px", size: "34px" }}
              defaultTheme={profileStyle}
            />
            <p className="user-name">{value}</p>
          </StyledUserProfileCompleted>
        );
      }
    );

    const RenderScheduleDot = React.memo(
      ({ value }: { value: string }): ReactElement => {
        return (
          <StyledScheduleContent>
            <StyledScheduleDot
              color={value === "onSchedule" ? "#A1D66D" : "#989898"}
            >
              <TPIcon
                iconType={
                  TPIconTypes[value === "onSchedule" ? "work" : "prohibited"]
                }
              />
            </StyledScheduleDot>
            <p>{value === "onSchedule" ? "On Schedule" : "Off Schedule"}</p>
          </StyledScheduleContent>
        );
      }
    );

    const customColumns: ColumnStyles<TeamActivityData> = {
      employee: ({ value }) => <RenderUserProfile value={value} />,
      schedule: ({ value }) => <RenderScheduleDot value={value} />,
      status: ({ value }) => <CockpitInfoCard type={value} isSmallCard />,
      pendingTickets: ({ value }) => (
        <TablePrimaryItem
          value={value}
          onClick={() => console.log("Go to cases/tickets")}
        />
      ),
      ready: ({ value }) => (
        <TableChip
          value={value}
          onLabel={"Yes"}
          offLabel={"No"}
          justify="flex-start"
        />
      ),
    };

    return (
      <DynamicTable
        id={`team-activity${view && `-${view}`}`}
        searchPosition={withChildren ? "right" : "left"}
        data={data}
        columnStyles={customColumns}
        childrenPosition={withChildren ? "left" : "right"}
        customHeight={withChildren ? "300px" : "auto"}
        hiddenColumns={
          view === "team"
            ? ["ready", "queue"]
            : view === "group"
              ? ["queue"]
              : []
        }
        maxWithTableContainer="100%"
        withPreferences={!withChildren}
        onSelectionChange={(e) => console.log(e)}
        selectable
        persistSelectionAfterFilterChange
        isHeaderCheckboxDisabled
      >
        {withChildren && (
          <>
            <TPButton
              id="go-to-team-activity"
              onClick={() =>
                console.log("redirection to team activity component: ")
              }
              customType={ButtonCustomType.tertiary}
              isDesignSystem
            >
              Team Activity
            </TPButton>
          </>
        )}
      </DynamicTable>
    );
  }
);

export default CockpitTeamActivityTable;
