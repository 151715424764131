import { forwardRef, useEffect, useRef, useState } from "react";

import { TPEditor } from "@/components/TPEditor/TPEditor";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPAutoComplete from "@/components/bootstrap/forms/TPAutoComplete/TPAutoComplete";
import TPLabel from "@/components/bootstrap/forms/TPLabel/TPLabel";
import TPCheckBox from "@/components/bootstrap/forms/checkbox/TPCheckBox";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import {
    Detail,
    FormDesignerRowModel,
    FormesignerInputDTO,
    ItemRow,
    SectionModel
} from '@/models/FormDesigner/FormDesigner';
import { TPButtonTypes, TPIconTypes } from '@/models/Global/TPGlobalEnums';
import { RecordLocalizedModel } from '@/models/Global/TPGlobalModels';
import { AdditionalDataFormService } from '@/services/AdditionalDataFormService';
import { AdditionalDataService } from '@/services/AdditionalDataService';
import { TPI18N } from '@/services/I18nService';
import {
    closestCenter,
    closestCorners,
    DndContext,
    DragEndEvent,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    horizontalListSortingStrategy,
    SortableContext,
    verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { useAppConctextFormDesigner } from './ContextFormDesignerAdmin';
import FormDesignerRow from './FormDesignerColumn';
import DraggableView from './FormDesignerDraggable';
import {
    AbsoluteBtnSaveStyle,
    AbsoluteStyle,
    AddSectionStyle,
    BtnMenuIco,
    BtnMoveSectionStyle,
    ChildContainerStyle,
    ColorStyle,
    ContainerAditionalData,
    ContainerBtnEditStyle,
    ContainerCheckStyle,
    ContainerFormStyle,
    ContainerInputSelectStyle,
    ContainerRowDesign,
    ContainerRowItemStyle,
    ContainerSectionStyle,
    ContainerTextBox,
    ItemSelect,
    MenuRow,
    NewCustomerFormDesign,
    RowVerticalStyle,
    SelectStyle
} from './StyleFromDesigner';

interface FormDesignerTableModel {
    callBackCommands: Function;
    callBackCommandsPreview: Function;
}

const styleBtn = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '5px',
    width: '100%'
};

const FormDesignerBuilder = forwardRef(({ callBackCommands, callBackCommandsPreview }: FormDesignerTableModel) => {
    const resourceSet: string = 'FormDesignerComponent';

    const [column, setColumn] = useState([
        { code: 1, columns: 1 },
        { code: 2, columns: 2 },
        { code: 3, columns: 2 },
        { code: 4, columns: 2 },
        { code: 5, columns: 3 },
        { code: 6, columns: 3 },
        { code: 7, columns: 3 },
        { code: 8, columns: 3 },
        { code: 9, columns: 4 },
        { code: 10, columns: 4 },
        { code: 11, columns: 4 },
        { code: 12, columns: 4 },
        { code: 13, columns: 4 },
        { code: 14, columns: 4 }
    ]);

    //const [listRow, setListRow] = useState(new Array<FormDesignerRowModel>);
    const [autocompleteToOptions, setAutocompleteToOptions] = useState<Array<TPKeyValue>>([]);
    const [selectedAutocompleteTaskTypeOption, setSelectedAutocompleteTaskTypeOption] = useState<Array<TPKeyValue>>([]);
    //    const [allAdditionalDataService, setAllAdditionalDataService] = useState<Array<AdditionalDataViewModel>>([]);
    const [activeEdit, setActiveEdit] = useState(false);
    const [countAvailable, setCountAvailable] = useState(0);

    const [searchLabel, setSearchLabel] = useState('');
    const [additionalDataSelection, setAdditionalDataSelection] = useState('');
    const [rowDesignLabel, setRowDesignLabel] = useState('');
    const [selectTemplateRow, setSelectTemplateRow] = useState('');
    const [addNow, setAddNowLabel] = useState('');
    const [sectionTitleLabel, setSectionTitle] = useState('');
    const [titleForm, setTitleForm] = useState('');
    const [title, setTitleLabel] = useState('');
    const [idLabel, setIdLabel] = useState('');
    const [label, setLabel] = useState('');
    const [colorBackGround, setColorBackGround] = useState('');
    const [isMandatory, setIsMandatory] = useState('');
    const [isVisible, setIsVisible] = useState('');
    const [isSearchable, setisSearchable] = useState('');
    const [isReadOnly, setIsReadOnly] = useState('');
    const [hidePersonalData, setHidePersonalData] = useState('');
    const [newLabel, setNewLabel] = useState('');
    const [defaulValue, setDefaulValue] = useState('');
    const [typeDefaulValue, setTypeDefaulValue] = useState('');
    const [ChangeRowLayoutLabel, setChangeRowLayoutLabel] = useState('');
    const [MoveRowDown, setMoveRowDown] = useState('');
    const [MoveRowUpLabel, setMoveRowUpLabel] = useState('');
    const [DeleteLabel, setDeleteLabel] = useState('');
    const [applyLabel, setApplyLabel] = useState('');
    const [cleanLabel, setCleanLabel] = useState('');
    const [newSectionLabel, setNewSectionLabel] = useState('');
    const [sectionNameLabel, setSectionNameLabel] = useState('');
    const [placeholderLabel, setPlaceholderLabel] = useState('');
    const [editPlaceholderLabel, setEditPlaceholderLabel] = useState('');
    const [previewLabel, setPreviewLabel] = useState('');
    const [saveLabel, setSaveLabel] = useState('');
    const [insertFieldLabel, setInsertFieldLabel] = useState('');
    const [exampleDataSelection, setExampleDataSelection] = useState('');
    const [additionalLanguagesLabel, setAdditionalLanguagesLabel] = useState('');
    const [defaultPlainText, setDefaultPlainText] = useState('');
    const [letterLimit, setLetterLimit] = useState('');

    //List Form
    const [sectionActive, setSectionActive] = useState('');
    const [rowFormActive, setRowFormActive] = useState('');
    const [activeMenu, setActiveMenu] = useState('');
    const [activeControl, setActiveControl] = useState(new ItemRow());

    // Used only when the selectable list is displayed
    const [rowActive, setRowActive] = useState({ code: 0, column: 0 });

    const editorRef = useRef<any>(null);
    const [editorValue, setEditorValue] = useState('');

    const [availableCharacters, setAvailableCharacters] = useState('');
    const [activeSelectMenu, setActiveSelectMenu] = useState('');
    const [activeSelect, setActiveSelect] = useState(false);
    const [activeColorSelect, setActiveColorSelect] = useState(false);
    const [activeSelectChanged, setActiveSelectChanged] = useState(false);

    const [aditionalDataAndStyle, setAditionalDataAndStyle] = useState(new ItemRow());
    const [textTPEdit, setTextTPEdit] = useState("");

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8
            }
        })
    );

    const value = useAppConctextFormDesigner();
    const {
        sectionFormDesingner,
        rowsFormDesingner,
        isUpdate,
        handleBuilderAddNewRowForm,
        handleOrderUpAndDown,
        handleBuilderUpdateRowForm,
        handleBuilderDeleteRowForm,
        handleBuilderAddNewControl,
        handleBuilderAddNewSectionForm,
        handleBuilderDeleteSection,
        handleOrderUpAndDownSection,
        handleColorForm,
        handleEditTitleSection,
        handleTitleForm,
        handleEditForm,
        setStatusLoading,
        setIsUpdate,
        handleLoadSection,
        handleOrderUpAndDownColumn,
        additionalData,
        setAdditionalData
    } = value;

    const loadResourcesAndLoadInfo = async () => {
        setStatusLoading(true);
        setDefaultPlainText(await TPI18N.GetText(resourceSet, 'DefaultPlainText'));
        setAdditionalLanguagesLabel(await TPI18N.GetText(resourceSet, 'AdditionalLanguagesLabel'));
        setSelectTemplateRow(await TPI18N.GetText(resourceSet, 'SelectTemplateRow'));
        setTitleForm(await TPI18N.GetText(resourceSet, 'TitleForm'));
        setExampleDataSelection(await TPI18N.GetText(resourceSet, 'ExampleDataSelection'));
        setDeleteLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'DeleteLabel'));
        setMoveRowUpLabel(await TPI18N.GetText(resourceSet, 'MoveRowUpLabel'));
        setMoveRowDown(await TPI18N.GetText(resourceSet, 'MoveRowDownLabel'));
        setChangeRowLayoutLabel(await TPI18N.GetText(resourceSet, 'ChangeRowLayoutLabel'));
        setSearchLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, 'Search'));
        setSaveLabel(await TPI18N.GetText(resourceSet, 'SaveLabel'));
        setPreviewLabel(await TPI18N.GetText(resourceSet, 'PreviewLabel'));
        setNewSectionLabel(await TPI18N.GetText(resourceSet, 'NewSectionLabel'));
        setHidePersonalData(await TPI18N.GetText(resourceSet, 'HidePersonalDataLabel'));
        setAdditionalDataSelection(await TPI18N.GetText(resourceSet, 'AdditionalDataSelectionLabel'));
        setRowDesignLabel(await TPI18N.GetText(resourceSet, 'RowDesignLabel'));
        setAddNowLabel(await TPI18N.GetText(resourceSet, 'AddNowLabel'));
        setSectionTitle(await TPI18N.GetText(resourceSet, 'SectionTitleLabel'));
        setInsertFieldLabel(await TPI18N.GetText(resourceSet, 'InsertFieldLabel'));

        setTitleLabel(await TPI18N.GetText(resourceSet, 'TitleLabel'));
        setLabel(await TPI18N.GetText(resourceSet, 'Label'));
        setIdLabel(await TPI18N.GetText(resourceSet, 'IdLabel'));
        setColorBackGround(await TPI18N.GetText(resourceSet, 'ColorBackGround'));
        setIsMandatory(await TPI18N.GetText(resourceSet, 'IsMandatoryLabel'));
        setIsVisible(await TPI18N.GetText(resourceSet, 'IsVisibleLabel'));
        setisSearchable(await TPI18N.GetText(resourceSet, 'IsSearchableLabel'));
        setIsReadOnly(await TPI18N.GetText(resourceSet, 'IsReadOnlyLabel'));
        setNewLabel(await TPI18N.GetText(resourceSet, 'NewLabel'));
        setDefaulValue(await TPI18N.GetText(resourceSet, 'DefaulValue'));
        setTypeDefaulValue(await TPI18N.GetText(resourceSet, 'TypeDefaulValue'));
        setApplyLabel(await TPI18N.GetText(resourceSet, 'ApplyLabel'));
        setCleanLabel(await TPI18N.GetText(resourceSet, 'CleanLabel'));
        setPlaceholderLabel(await TPI18N.GetText(resourceSet, 'PlaceholderLabel'));
        setEditPlaceholderLabel(await TPI18N.GetText(resourceSet, ' EditPlaceholderLabel'));
        setLetterLimit(await TPI18N.GetText(resourceSet, 'LetterLimitLabel'));
        setStatusLoading(false);
    };

    const callBackVerticalTabTemp = () => {
        let command: any = { command: 'new', recordId: 0 };
        callBackCommands(command);
    };

    const handleClear = () => {
        setAditionalDataAndStyle(new ItemRow());
        setAutocompleteToOptions([]);
        setSelectedAutocompleteTaskTypeOption([]);
        setActiveEdit(false);
        setTextTPEdit("");
    };

    const handleRowActiveDropdownList = (value: any) => {
        let item = column.find(s => s.code == value);
        if (item) {
            setRowActive({ code: item?.code, column: item?.columns });
            setActiveSelect(false);
        }
    };

    const handleEditorChange = (value: any) => {
        setEditorValue(value);
        if (value.length > 0) {
            // setEditorErrorMessage("");
        }
    };

    //Section
    const handleBuilderAddNewSectionFormAction = () => {
        let data = new SectionModel();
        let item = [...sectionFormDesingner].find(s => s.id == sectionActive);
        let existControl;

        data.order = sectionFormDesingner.length + 1;
        data.title = sectionNameLabel != '' ? sectionNameLabel + data.order : '';
        handleBuilderAddNewSectionForm(data);
        setActiveControl(new ItemRow());
        handleClear();
    };

    const handleLoadSectionFormAction = () => {
        let data = new SectionModel();

        if (isUpdate) {
            handleLoadSection();
        } else if (sectionFormDesingner == undefined || sectionFormDesingner.length <= 0) {

            data.order = 1;
            data.title = sectionNameLabel + data.order;
            handleBuilderAddNewSectionForm(data);
        }
    };

    //Crud
    const handleBuilderAddNewRowFormAction = () => {
        let data = new FormDesignerRowModel();
        if (sectionFormDesingner[0]) {
            if (sectionActive != '') data.idSection = sectionActive;
            else data.idSection = sectionFormDesingner[0].id;

            if (rowsFormDesingner.rows.length == 0) data.order = 0;
            else data.order = rowsFormDesingner.rows.length;

            if (rowActive.code > 0) {
                data.columnTypeIdentifier = rowActive.code;
                data.numberOfColumns = rowActive.column;
                handleBuilderAddNewRowForm(data);
                setRowFormActive(data.id);
                setActiveControl(new ItemRow());
            }
            handleClear();
        }
    };

    const handleBuilderUpdateNewRowFormAction = (columType: Detail, item: FormDesignerRowModel) => {
        let form = rowsFormDesingner;

        let list = [...item.controls];

        item.columnTypeIdentifier = columType.code;
        item.numberOfColumns = columType.columns;
        item.controls = new Array<ItemRow>();
        let count = 0;

        list.forEach(e => {
            if (count < item.numberOfColumns) {
                let temp = "";
                e.title.split("").forEach((t) => {
                    if (temp.length <= 30) temp += t;
                })
                e.title = temp;
                item.controls.push(e);
                count++;
            }
        });

        form.rows.forEach(f => {
            if (f.id == item.id) {
                f = item;
            }
        });

        handleEditForm(form);
        handleClear();
        setActiveSelectMenu('');
    };

    const handleBuilderAddNewControlAction = () => {
        let data = new ItemRow();
        let item = [...rowsFormDesingner.rows].find(s => s.id == rowFormActive);
        let existControl;

        if (aditionalDataAndStyle.id != '') {


            let aditional = additionalData.find(s => s.id === aditionalDataAndStyle.idControl);
            let data = new ItemRow();
            data.id = aditionalDataAndStyle.id;
            data.idControl = aditionalDataAndStyle.idControl;
            data.idRow = aditionalDataAndStyle.idRow;
            data.title = textTPEdit;
            data.aditionalDataName = aditionalDataAndStyle.aditionalDataName;
            data.isMandatory = aditionalDataAndStyle.isMandatory;
            data.isVisible = aditionalDataAndStyle.isVisible;
            data.isSearchable = aditionalDataAndStyle.isSearchable;
            data.hidePersonalData = aditionalDataAndStyle.hidePersonalData;
            data.isReadOnly = aditionalDataAndStyle.isReadOnly;
            data.placeholder = aditionalDataAndStyle.placeholder;
            data.order = aditionalDataAndStyle.order;
            handleBuilderAddNewControl(data);

        } else {
           
            let aditional = additionalData.find(s => s.id === aditionalDataAndStyle.idControl);

            if (item != undefined && aditional != undefined) {
                data.idControl = aditionalDataAndStyle.idControl;
                data.aditionalDataName = aditional.additionalDataTypeDescription;
                data.idRow = rowFormActive;
                data.order = item.controls.length;
                data.title = textTPEdit;
                data.isMandatory = aditionalDataAndStyle.isMandatory;
                data.isReadOnly = aditionalDataAndStyle.isReadOnly;
                data.isVisible = aditionalDataAndStyle.isVisible;
                data.isSearchable = aditionalDataAndStyle.isSearchable;
                data.hidePersonalData = aditionalDataAndStyle.hidePersonalData;
                data.placeholder = aditionalDataAndStyle.placeholder;
                handleBuilderAddNewControl(data);
                handleClear();
            }
        }



       
    };



    const handleLoadControlSelect = (item: ItemRow) => {
        setSelectedAutocompleteTaskTypeOption([]);
        setAditionalDataAndStyle(new ItemRow());
               

        if (item.idControl != undefined && item.idControl != '') setActiveEdit(true);
        let data = new ItemRow();
        data.id = item.id;
        data.idControl = item.idControl;
        data.idRow = item.idRow;
        data.title = item.title;
        setTextTPEdit(item.title);
        data.aditionalDataName = item.aditionalDataName;
        data.isMandatory = item.isMandatory;
        data.isVisible = item.isVisible;
        data.isSearchable = item.isSearchable;
        data.hidePersonalData = item.hidePersonalData;
        data.isReadOnly = item.isReadOnly;
        data.placeholder = item.placeholder;
        data.order = item.order;

        setAditionalDataAndStyle(data);
        setCountAvailable(handleAvailableCharacters(extractPlainText(item.title).length));    
    };

    const handleSelectRowFormActive = (e: string) => {
        setRowFormActive(e);
    };

    const handleSelectRowFormActiveMenu = (event: any, id: string) => {
        event.stopPropagation();
        if (activeMenu == id) {
            setActiveMenu('');
        } else {
            setActiveMenu(id);
        }
    };

    const handleOrderUpAndDownAction = (order: boolean, item: FormDesignerRowModel) => {
        if (order) {
            if (item.order == 0) {
                handleOrderUpAndDown(item);
            } else if (item.order - 1 >= 0) {
                item.order = item.order - 1;
                handleOrderUpAndDown(item);
            }
        } else {
            if (rowsFormDesingner.rows.length >= item.order + 1) {
                item.order = item.order + 1;

                handleOrderUpAndDown(item);
            }
        }

        setActiveSelectChanged(order);
    };

    const handleOrderUpAndDownSectionAction = (order: boolean, item: SectionModel) => {
        if (order) {
            if (item.order - 1 >= 0) {
                item.order = item.order - 1;
                handleOrderUpAndDownSection(item);
            }
        } else {
            if (sectionFormDesingner.length >= item.order + 1) {
                item.order = item.order + 1;
                handleOrderUpAndDownSection(item);
            }
        }

        setActiveSelectChanged(order);
    };

    const handlGetColor = (): string => {
        if (rowsFormDesingner) return rowsFormDesingner.color;
        else return 'red';
    };

    //AditionalData
    const onValueChangeTPAutoComplete = (e: any) => {
        let aditional = { ...aditionalDataAndStyle };

        if (e != undefined && e[0] && e.length > 0) {
            aditional.idControl = e[0].key;
            aditional.aditionalDataName = e[0].value;
            setSelectedAutocompleteTaskTypeOption(e);  
            aditional.title = "<p>" + aditional.aditionalDataName + "</p>";
            setTextTPEdit("<p>" + aditional.aditionalDataName + "</p>");
            setAditionalDataAndStyle(aditional);
        }
    };

    const handleToOnAutocompleteQuery = async (query: string) => {
        setAutocompleteToOptions(new Array<TPKeyValue>());
        let serviceClient = new AdditionalDataService();
        let expectedCodes: Array<number> = [200, 404];

        setAutocompleteToOptions(new Array<TPKeyValue>());

        try {
            let responseRequest = await serviceClient.getAll(false, true, expectedCodes);

            let itemDataModel = [...additionalData];

            let allControlIds: string[] = rowsFormDesingner.rows.flatMap(row =>
                row.controls.map(control => control.idControl)
            );

            if (responseRequest) {
                responseRequest.forEach(e => {
                    if (itemDataModel.filter(s => s.id == e.id).length <= 0) itemDataModel.push(e);
                });
            }

            if (itemDataModel) {
                itemDataModel.forEach(e => {
                    if (itemDataModel.filter(s => s.id == e.id).length <= 0) itemDataModel.push(e);
                });

                setAdditionalData(itemDataModel);
            }

            let newToKeyValueList: Array<TPKeyValue> = responseRequest.map(function (item) {
                return {
                    key: item.id,
                    value: `${item.description}`
                };
            });

            if (allControlIds && itemDataModel) {
                let filteredList = newToKeyValueList.filter(item => !allControlIds.includes(item.key));

                setAutocompleteToOptions(filteredList);
            }

            //setAdditionalDataService(result);
            //  setAutocompleteToOptions(newToKeyValueList);

            return [...responseRequest];
        } catch (error) {
            TPLog.Log(`Error ${resourceSet} reloadDataGrid ex`, TPLogType.ERROR, error);
            console.error(`Error ${resourceSet} reloadDataGrid ex`);

            return [];
        }
    };

    const handleOnAutocompleteKeyDown = (event: any) => {
        //tab 9
        //left arrow 37
        //right arror 39
        //enter 13
        //home 36
        //end  35
        //ArrowUp  38
        //ArrowDown  40
        if (
            event.keyCode != 9 &&
            event.keyCode != 37 &&
            event.keyCode != 39 &&
            event.keyCode != 13 &&
            event.keyCode != 35 &&
            event.keyCode != 36 &&
            event.keyCode != 38 &&
            event.keyCode != 40
        ) {
            setAutocompleteToOptions([]);
            setSelectedAutocompleteTaskTypeOption([]);
        }
    };

    const handleAvailableCharacters = (countData: number) => {

        let count = 0;
        let countLimit = 90;
        let row = rowsFormDesingner.rows.find(s => s.id == aditionalDataAndStyle.idRow);

        if (!row)
            row = rowsFormDesingner.rows.find(s => s.id == rowFormActive);

        let countColumn = 0;
        if (row) {
            countColumn = row.numberOfColumns;
            if (countColumn > 1)
                countLimit = countLimit / countColumn;
            count = (countLimit - countData);
        }

        return count;
    };


    function extractPlainText(input: string): string {
        if (!input) return '';

        // Eliminar etiquetas HTML con una expresi�n regular
        const plainText = input.replace(/<[^>]+>/g, '');

        return plainText;
    }

    const handleSaveForm = async (event: any) => {
        setStatusLoading(true);
        let insert = new FormesignerInputDTO();

        let serviceClient = new AdditionalDataFormService();
        let expectedCodes: Array<number> = [200];
        try {
            insert.jsonDesigner = new TextEncoder().encode(JSON.stringify(rowsFormDesingner))
                .join(',');

            insert.id = rowsFormDesingner.id;
            insert.description = rowsFormDesingner.recordLocalizedModel[0].localizedValue;
            insert.formTypeId = rowsFormDesingner.formType ? rowsFormDesingner.formType.key : '';
            insert.applyPersonalData = rowsFormDesingner.isHidePersonalData;
            insert.isActive = rowsFormDesingner.showAsActive;
            insert.languageId = rowsFormDesingner.primaryLanguage ? rowsFormDesingner.primaryLanguage.key : '';
            insert.logo = rowsFormDesingner.img ? rowsFormDesingner.img.value : '';
            insert.descriptionLocalizedValues = rowsFormDesingner.recordLocalizedModel
                ? rowsFormDesingner.recordLocalizedModel
                : new Array<RecordLocalizedModel>();

            let responseRequest;

            responseRequest = await serviceClient.updateFormDesingner(insert, true, true, expectedCodes);
        } catch (error) {
            TPLog.Log(`Error ${resourceSet} insert ex`, TPLogType.ERROR, error);
            console.error(`Error ${resourceSet} insert ex`);
        }
        setStatusLoading(false);
    };

    useEffect(() => {
        loadResourcesAndLoadInfo();
        handleToOnAutocompleteQuery('');
        handleLoadSectionFormAction();
    }, []);

    const handleDragEnd = (event: DragEndEvent, list: Array<FormDesignerRowModel>) => {
        const { active, over } = event;
        if (active && active.id) handleSelectRowFormActive(active.id.toString());

        if (over) {
            let itemOver = { ...list.find(s => s.id == over.id) };

            let itemActive = list.find(s => s.id == active.id);

            if (itemOver && itemActive) {
                if (itemOver.order != undefined) {
                    itemActive.order = itemOver.order;
                    handleOrderUpAndDown(itemActive);
                }
            }
        }
    };

    const handleDragEndSection = (event: DragEndEvent, list: Array<SectionModel>) => {
        const { active, over } = event;

        if (active) setSectionActive(active.id.toString());

        if (over) {
            let itemOver = { ...list.find(s => s.id == over.id) };

            let itemActive = list.find(s => s.id == active.id);

            if (itemOver && itemActive) {
                if (itemOver.order != undefined) {
                    itemActive.order = itemOver.order;
                    handleOrderUpAndDownSection(itemActive);
                }
            }
        }
    };

    const handleDragEndColumn = (event: DragEndEvent, listColumn: Array<any>, row: FormDesignerRowModel) => {
        const { active, over } = event;

        if (active && active.id) handleSelectRowFormActive(active.id.toString());

        if (over) {
            let itemOver = listColumn.findIndex(s => s.id == over.id);

            let itemActive = listColumn.findIndex(s => s.id == active.id);

            let newList = arrayMove(listColumn, itemActive, itemOver);


            if (row) {
                if (newList != undefined) {
                    handleOrderUpAndDownColumn(newList);
                }
            }
        }
    };

    const handleChangedGeneral = () => {
        setActiveMenu('');
        setActiveSelect(false);
        setActiveColorSelect(false);
    };

    return (
        <NewCustomerFormDesign onClick={() => handleChangedGeneral()}>
            <ContainerAditionalData>
                <TPLabel
                    className="form-check-label"
                    style={{ textAlign: 'left', width: '90%' }}
                    labelText={additionalDataSelection}
                />
                <div style={{ marginTop: 28 }}></div>
                <ContainerTextBox>
                    <TPAutoComplete
                        isMandatory={false}
                        labelText={''}
                        onValueChange={(e: any) => {
                            onValueChangeTPAutoComplete(e);
                        }}
                        onSearch={(event: any) => {
                            handleToOnAutocompleteQuery(event);
                        }} // handleToOnAutocompleteQuery(event)
                        isLoading={false}
                        options={autocompleteToOptions}
                        withIcon={true}
                        emptyLabel={exampleDataSelection}
                        onKeyDown={(event: any) => {
                            handleOnAutocompleteKeyDown(event);
                        }}
                        selected={selectedAutocompleteTaskTypeOption}
                        errorMessage={''}
                        downArrowClick={handleToOnAutocompleteQuery}
                    ></TPAutoComplete>
                </ContainerTextBox>

                {!activeEdit && (
                    <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: '30px' }}>
                        <TPButton
                            id="IdButton"
                            style={
                                aditionalDataAndStyle.idControl == ''
                                    ? {
                                        backgroundColor: '#E6E6E6',
                                        borderRadius: '4px',
                                        marginRight: '13px',
                                        color: '#989898',
                                        padding: '5px'
                                    }
                                    : {
                                        backgroundColor: '#A00095',
                                        borderRadius: '4px',
                                        marginRight: '13px',
                                        color: 'white',
                                        padding: '5px'
                                    }
                            }
                            type={TPButtonTypes.empty}
                            onClick={() => {
                                if (aditionalDataAndStyle.idControl != '') setActiveEdit(true);
                            }}
                            text={insertFieldLabel}
                            icon={TPIconTypes.default}
                        >
                            {insertFieldLabel}
                        </TPButton>
                    </div>
                )}

                {aditionalDataAndStyle.idControl != '' && activeEdit && (
                    <>
                        <div style={{ marginTop: 32 }}></div>
                        <TPLabel className="form-check-label" style={{ textAlign: 'left', width: '90%' }} labelText={idLabel} />
                        <ContainerTextBox>
                            <TPTextBox
                                id="IdTextBox"
                                icon={TPIconTypes.custom}
                                withIcon={false}
                                disabled={true}
                                value={aditionalDataAndStyle.idControl}
                                placeholder={''}
                                onChange={(e: any) => () => { }}
                                isHorizontal={true}
                            />
                        </ContainerTextBox>
                        <div style={{ marginTop: 14 }}></div>
                        <TPLabel className="form-check-label" style={{ textAlign: 'left', width: '90%' }} labelText={label} />
                        <ContainerTextBox>
                            <TPTextBox
                                id="IdTextBox"
                                icon={TPIconTypes.search}
                                withIcon={false}
                                disabled={true}
                                value={aditionalDataAndStyle.aditionalDataName}
                                placeholder={''}
                                onChange={(e: any) => () => { }}
                                isHorizontal={true}
                            />
                        </ContainerTextBox>

                        <div style={{ marginTop: 14 }}></div>
                        <TPLabel className="form-check-label" style={{ textAlign: 'left', width: '90%' }} labelText={newLabel} />
                        <div style={{ width: '270px' }}>
                            <TPEditor
                                referece={(editor: any) => (editorRef.current = editor)}
                                // placeholder={typeDefaulValue}
                                value={textTPEdit}
                                onChange={(value: any) => {

                                    if (value.length > 0 && handleAvailableCharacters(extractPlainText(value).length) > 1) {                                      
                                        setTextTPEdit(value);
                                        setCountAvailable(handleAvailableCharacters(extractPlainText(value).length));
                                    } 
                                }}
                                initialValue=""
                                isVisible={false}
                                style={{ width: '90%' }}
                            />
                            {countAvailable > 0 &&
                                <TPLabel
                                    className="form-check-label"
                                    style={{ textAlign: 'left', width: '90%' }}
                                    labelText={(letterLimit + " " + (countAvailable - 2))}
                                />

                            }
                        </div>
                        <div style={{ marginTop: 14 }}></div>
                        <TPLabel
                            className="form-check-label"
                            style={{ textAlign: 'left', width: '90%' }}
                            labelText={placeholderLabel}
                        />
                        <ContainerTextBox>
                            <TPTextBox
                                id="editPlaceholderLabel"
                                containerStyle={{ width: '102%', marginLeft: '-5px' }}
                                icon={TPIconTypes.search}
                                withIcon={false}
                                value={aditionalDataAndStyle.placeholder}
                                placeholder={editPlaceholderLabel}
                                onChange={(e: any) => {
                                    let temp = { ...aditionalDataAndStyle };
                                    temp.placeholder = e.target.value;
                                    setAditionalDataAndStyle(temp);
                                }}
                                isHorizontal={true}
                            />
                        </ContainerTextBox>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                width: '100%',
                                paddingLeft: '20px',
                                marginTop: '15px',
                                fontWeight: 'bold'
                            }}
                        >
                            {additionalLanguagesLabel}
                        </div>
                        <div style={{ marginTop: 14 }}></div>

                        <div style={{ marginTop: 14 }}></div>
                        <ContainerCheckStyle>
                            <TPCheckBox
                                id="IdIsMandatory"
                                labelText={isMandatory}
                                checked={aditionalDataAndStyle.isMandatory}
                                onChange={(e: any) => {
                                    let temp = { ...aditionalDataAndStyle };
                                    temp.isMandatory = !temp.isMandatory;
                                    setAditionalDataAndStyle(temp);
                                }}
                            ></TPCheckBox>

                            <TPCheckBox
                                id="IdIsVisible"
                                labelText={isVisible}
                                checked={aditionalDataAndStyle.isVisible}
                                onChange={(e: any) => {
                                    let temp = { ...aditionalDataAndStyle };
                                    temp.isVisible = !temp.isVisible;
                                    setAditionalDataAndStyle(temp);
                                }}
                            ></TPCheckBox>

                            <TPCheckBox
                                id="isSearchable"
                                labelText={isSearchable}
                                checked={aditionalDataAndStyle.isSearchable}
                                onChange={(e: any) => {
                                    let temp = { ...aditionalDataAndStyle };
                                    temp.isSearchable = !temp.isSearchable;
                                    setAditionalDataAndStyle(temp);
                                }}
                            ></TPCheckBox>

                            <TPCheckBox
                                id="isReadOnly"
                                labelText={isReadOnly}
                                checked={aditionalDataAndStyle.isReadOnly}
                                onChange={(e: any) => {
                                    let temp = { ...aditionalDataAndStyle };
                                    temp.isReadOnly = !temp.isReadOnly;
                                    setAditionalDataAndStyle(temp);
                                }}
                            ></TPCheckBox>

                            <TPCheckBox
                                id="hidePersonalData"
                                labelText={hidePersonalData}
                                checked={aditionalDataAndStyle.hidePersonalData}
                                onChange={(e: any) => {
                                    let temp = { ...aditionalDataAndStyle };
                                    temp.hidePersonalData = !temp.hidePersonalData;
                                    setAditionalDataAndStyle(temp);
                                }}
                            ></TPCheckBox>
                        </ContainerCheckStyle>

                        <div style={{ marginTop: 14 }}></div>
                        <ContainerBtnEditStyle>
                            <TPButton
                                style={{
                                    backgroundColor: 'white',
                                    border: '0px solid',
                                    color: '#A00095',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textDecoration: 'underline',
                                    fontWeight: 400,
                                    borderRadius: 4,
                                    padding: 5
                                }}
                                type={TPButtonTypes.empty}
                                text={'1'}
                                onClick={() => {
                                    handleClear();
                                }}
                                icon={TPIconTypes.default}
                            >
                                {cleanLabel}
                            </TPButton>

                            <TPButton
                                style={{
                                    backgroundColor: '#A00095',
                                    border: '1px solid',
                                    color: 'white',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontWeight: 400,
                                    borderRadius: 4,
                                    padding: 5,
                                    minWidth: 'auto'
                                }}
                                type={TPButtonTypes.empty}
                                text={'1'}
                                onClick={() => {
                                    handleBuilderAddNewControlAction();
                                }}
                                icon={TPIconTypes.default}
                            >
                                {applyLabel}
                            </TPButton>
                        </ContainerBtnEditStyle>
                    </>
                )}
            </ContainerAditionalData>

            <ContainerRowDesign>
                <TPLabel className="form-check-label" style={{ textAlign: 'left', width: '90%' }} labelText={rowDesignLabel} />
                <TPLabel
                    className="form-check-label"
                    style={{ textAlign: 'left', width: '90%', marginTop: '30px' }}
                    labelText={selectTemplateRow}
                    isMandatory={true}
                />
                <div style={{ display: 'flex', gap: 5, position: 'relative' }}>
                    <ContainerInputSelectStyle
                        onClick={e => {
                            e.stopPropagation();
                            setActiveSelect(!activeSelect);
                        }}
                    >
                        <SelectStyle>
                            <ItemSelect>
                                <FormDesignerRow
                                    isSelectDropdownList={rowActive.code}
                                    isDropdownList={true}
                                    rowNumber={rowActive.code}
                                    color={handlGetColor()}
                                ></FormDesignerRow>
                            </ItemSelect>
                            <TPButton
                                id="IdButton"
                                type={TPButtonTypes.icon}
                                onClick={() => { }}
                                text={''}
                                icon={TPIconTypes.arrowDropDown}
                            />
                        </SelectStyle>

                        {activeSelect && (
                            <ChildContainerStyle>
                                {column.map((item, index) => (
                                    <FormDesignerRow
                                        aditionalDataModels={additionalData}
                                        isSelectDropdownList={rowActive.code}
                                        isDropdownList={true}
                                        rowNumber={item.code}
                                        color={handlGetColor()}
                                        // rowActive={rowActive.code}
                                        handleSelectDropdownList={e => handleRowActiveDropdownList(e)}
                                    ></FormDesignerRow>
                                ))}
                            </ChildContainerStyle>
                        )}
                    </ContainerInputSelectStyle>

                    <TPButton
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid',
                            color: '#A00095',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            fontWeight: 400,
                            borderRadius: 4,
                            padding: 5
                        }}
                        type={TPButtonTypes.empty}
                        text={'1'}
                        onClick={() => {
                            handleBuilderAddNewRowFormAction();
                        }}
                        icon={TPIconTypes.default}
                    >
                        {addNow}
                    </TPButton>

                    <AbsoluteStyle>
                        <TPLabel className="form-check-label" style={{}} labelText={colorBackGround} isMandatory={false} />
                        <ContainerInputSelectStyle
                            onClick={e => {
                                e.stopPropagation();
                                setActiveColorSelect(!activeColorSelect);
                            }}
                            width="50px"
                        >
                            <SelectStyle>
                                <ItemSelect>
                                    <ColorStyle color={handlGetColor()}></ColorStyle>
                                </ItemSelect>
                                <TPButton
                                    id="IdButton"
                                    type={TPButtonTypes.icon}
                                    onClick={() => { }}
                                    text={''}
                                    icon={TPIconTypes.arrowDropDown}
                                />
                            </SelectStyle>

                            {activeColorSelect && (
                                <ChildContainerStyle width="50px">
                                    <ColorStyle
                                        color={'#F9F9F9'}
                                        onClick={() => {
                                            handleColorForm('#F9F9F9');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#F9E2FF'}
                                        onClick={() => {
                                            handleColorForm('#F9E2FF');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#FFE4DA'}
                                        onClick={() => {
                                            handleColorForm('#FFE4DA');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#F1F1F1'}
                                        onClick={() => {
                                            handleColorForm('#F1F1F1');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#E3F3FF'}
                                        onClick={() => {
                                            handleColorForm('#E3F3FF');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#EBEFFF'}
                                        onClick={() => {
                                            handleColorForm('#EBEFFF');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#DCFFF7'}
                                        onClick={() => {
                                            handleColorForm('#DCFFF7');
                                        }}
                                    ></ColorStyle>
                                    <ColorStyle
                                        color={'#DCFFDD'}
                                        onClick={() => {
                                            handleColorForm('#DCFFDD');
                                        }}
                                    ></ColorStyle>
                                </ChildContainerStyle>
                            )}
                        </ContainerInputSelectStyle>
                    </AbsoluteStyle>

                    <AbsoluteBtnSaveStyle>
                        <TPButton
                            style={{
                                backgroundColor: 'white',
                                border: '1px solid',
                                color: '#A00095',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 400,
                                borderRadius: 4,
                                padding: 5
                            }}
                            type={TPButtonTypes.empty}
                            text={'1'}
                            onClick={(e: any) => {
                                callBackCommandsPreview();
                            }}
                            icon={TPIconTypes.default}
                        >
                            {previewLabel}
                        </TPButton>
                        <TPButton
                            style={{
                                backgroundColor: '#A00095',
                                border: '1px solid #A00095',
                                color: 'white',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 400,
                                borderRadius: 4,
                                padding: 5
                            }}
                            type={TPButtonTypes.empty}
                            text={'1'}
                            onClick={(e: any) => {
                                handleSaveForm(e);
                            }}
                            icon={TPIconTypes.default}
                        >
                            {saveLabel}
                        </TPButton>
                    </AbsoluteBtnSaveStyle>
                </div>
                <TPLabel className="form-check-label" style={{ textAlign: 'left', width: '90%' }} labelText={title} />
                <TPTextBox
                    id="IdTextTitle"
                    containerStyle={{ textAlign: 'left', width: '99%', marginLeft: '-5px' }}
                    icon={TPIconTypes.custom}
                    withIcon={false}
                    value={rowsFormDesingner.title}
                    placeholder={titleForm}
                    onChange={(e: any) => {
                        let temp = { ...rowsFormDesingner };
                        temp.title = e.target.value;
                        handleTitleForm(e.target.value);
                    }}
                    isHorizontal={true}
                />

                <ContainerFormStyle>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCorners}
                        onDragEnd={(e: any) => handleDragEndSection(e, sectionFormDesingner)}
                    >
                        {sectionFormDesingner.map((section, indexSection) => (
                            <ContainerSectionStyle key={section.id}>
                                <ContainerRowItemStyle
                                    active={false}
                                    key={section.id}
                                    onClick={() => setSectionActive(section.id)}
                                    color={''}
                                >
                                    {activeMenu === section.id && (
                                        <MenuRow>
                                            <TPButton
                                                type={TPButtonTypes.empty}
                                                style={styleBtn}
                                                text={'1'}
                                                onClick={() => handleOrderUpAndDownSectionAction(true, section)}
                                                icon={TPIconTypes.default}
                                            >
                                                {MoveRowUpLabel}
                                                <TPIcon iconType={TPIconTypes.arrowDropUp} style={{}} />
                                            </TPButton>

                                            <TPButton
                                                type={TPButtonTypes.empty}
                                                style={styleBtn}
                                                text={'1'}
                                                onClick={() => handleOrderUpAndDownSectionAction(false, section)}
                                                icon={TPIconTypes.default}
                                            >
                                                {MoveRowDown}
                                                <TPIcon iconType={TPIconTypes.arrowDropDown} style={{}} />
                                            </TPButton>

                                            <TPButton
                                                type={TPButtonTypes.empty}
                                                style={styleBtn}
                                                text={'1'}
                                                onClick={() => handleBuilderDeleteSection(section)}
                                                icon={TPIconTypes.delete}
                                            >
                                                {DeleteLabel}
                                                <TPIcon iconType={TPIconTypes.delete} style={{}} />
                                            </TPButton>
                                        </MenuRow>
                                    )}

                                    <SortableContext items={sectionFormDesingner} strategy={verticalListSortingStrategy}>
                                        <DraggableView id={section.id}>
                                            <ContainerRowItemStyle
                                                active={false}
                                                key={section.id}
                                                onClick={() => setSectionActive(section.id)}
                                                color={''}
                                            >
                                                <BtnMoveSectionStyle>
                                                    <TPIcon iconType={TPIconTypes.mdDragIndicator} style={{}} />
                                                </BtnMoveSectionStyle>
                                                <TPTextBox
                                                    id="IdTSection"
                                                    containerStyle={{ textAlign: 'left', width: '94%', marginLeft: '-5px' }}
                                                    icon={TPIconTypes.custom}
                                                    withIcon={false}
                                                    value={section.title}
                                                    placeholder={defaultPlainText + ' ' + indexSection /* sectionTitleLabel */}
                                                    onChange={(e: any) => {
                                                        section.title = e.target.value;
                                                        if (section) handleEditTitleSection(section);
                                                    }}
                                                    isHorizontal={true}
                                                />
                                            </ContainerRowItemStyle>
                                        </DraggableView>
                                    </SortableContext>
                                    <BtnMenuIco onClick={e => handleSelectRowFormActiveMenu(e, section.id)}>
                                        <TPIcon iconType={TPIconTypes.moreVert} style={{}} />
                                    </BtnMenuIco>
                                </ContainerRowItemStyle>

                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={(e: any) =>
                                        handleDragEnd(
                                            e,
                                            rowsFormDesingner.rows.filter(s => s.idSection == section.id)
                                        )
                                    }
                                >
                                    {rowsFormDesingner.rows
                                        .filter(s => s.idSection == section.id)
                                        .map((item, index) => (
                                            <SortableContext
                                                items={rowsFormDesingner.rows.filter(s => s.idSection == section.id)}
                                                strategy={verticalListSortingStrategy}
                                            >
                                                <div style={{ position: 'relative', minHeight: '80px', width: '100%', borderRadius: '3px' }}>
                                                    <BtnMenuIco
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            handleSelectRowFormActiveMenu(e, item.id);
                                                        }}
                                                    >
                                                        <TPIcon iconType={TPIconTypes.moreVert} style={{ marginLeft: '15px' }} />
                                                    </BtnMenuIco>

                                                    <DraggableView id={item.id}>
                                                        <RowVerticalStyle onClick={() => handleSelectRowFormActive(item.id)}>
                                                            <ContainerRowItemStyle key={item.id} active={rowFormActive === item.id}>
                                                                <DndContext
                                                                    sensors={sensors}
                                                                    collisionDetection={closestCorners}
                                                                    onDragEnd={e => handleDragEndColumn(e, item.controls, item)}
                                                                >
                                                                    <SortableContext items={item.controls} strategy={horizontalListSortingStrategy}>
                                                                        <FormDesignerRow
                                                                            isDropdownList={false}
                                                                            rowNumber={item.columnTypeIdentifier}
                                                                            listControl={item.controls}
                                                                            controlActive={activeControl}
                                                                            aditionalDataModels={additionalData}
                                                                            handleSelectControl={(e: ItemRow) => {
                                                                                setActiveControl(e);
                                                                                if (e.id != '') setRowFormActive(e.idRow);
                                                                                handleLoadControlSelect(e);
                                                                            }}
                                                                            color={handlGetColor()}
                                                                        />
                                                                    </SortableContext>
                                                                </DndContext>
                                                            </ContainerRowItemStyle>

                                                            <TPIcon iconType={TPIconTypes.mdDragIndicator} style={{ transform: 'rotate(88deg)' }} />
                                                        </RowVerticalStyle>
                                                    </DraggableView>

                                                    {activeMenu === item.id && (
                                                        <MenuRow>
                                                            <TPButton
                                                                type={TPButtonTypes.empty}
                                                                style={styleBtn}
                                                                text={'1'}
                                                                onClick={() => {
                                                                    setActiveSelectMenu(item.id);
                                                                    setActiveMenu('');
                                                                }}
                                                                icon={TPIconTypes.default}
                                                            >
                                                                {ChangeRowLayoutLabel}
                                                                <TPIcon iconType={TPIconTypes.chevronRight} style={{}} />
                                                            </TPButton>

                                                            <TPButton
                                                                type={TPButtonTypes.empty}
                                                                style={styleBtn}
                                                                text={'1'}
                                                                onClick={() => handleOrderUpAndDownAction(true, item)}
                                                                icon={TPIconTypes.default}
                                                            >
                                                                {MoveRowUpLabel}
                                                                <TPIcon iconType={TPIconTypes.arrowDropUp} style={{}} />
                                                            </TPButton>

                                                            <TPButton
                                                                type={TPButtonTypes.empty}
                                                                style={styleBtn}
                                                                text={'1'}
                                                                onClick={() => handleOrderUpAndDownAction(false, item)}
                                                                icon={TPIconTypes.default}
                                                            >
                                                                {MoveRowDown}
                                                                <TPIcon iconType={TPIconTypes.arrowDropDown} style={{}} />
                                                            </TPButton>

                                                            <TPButton
                                                                type={TPButtonTypes.empty}
                                                                style={styleBtn}
                                                                text={'1'}
                                                                onClick={() => handleBuilderDeleteRowForm(item)}
                                                                icon={TPIconTypes.delete}
                                                            >
                                                                {DeleteLabel}
                                                                <TPIcon iconType={TPIconTypes.delete} style={{}} />
                                                            </TPButton>
                                                        </MenuRow>
                                                    )}

                                                    {activeSelectMenu === item.id && (
                                                        <ChildContainerStyle style={{ left: 'auto', right: '0px' }}>
                                                            {column.map((colum, index) => (
                                                                <FormDesignerRow
                                                                    isDropdownList={true}
                                                                    rowNumber={colum.code}
                                                                    color={handlGetColor()}
                                                                    // rowActive={rowActive.code}
                                                                    handleSelectDropdownList={e => {
                                                                        handleBuilderUpdateNewRowFormAction(colum, item);
                                                                        setRowFormActive(colum.code + "")
                                                                    }}
                                                                ></FormDesignerRow>
                                                            ))}
                                                        </ChildContainerStyle>
                                                    )}
                                                </div>
                                            </SortableContext>
                                        ))}
                                </DndContext>
                            </ContainerSectionStyle>
                        ))}
                    </DndContext>

                    <AddSectionStyle
                        onClick={() => {
                            handleBuilderAddNewSectionFormAction();
                        }}
                    >
                        <TPIcon iconType={TPIconTypes.libraryAdd} style={{}} />
                        <TPLabel className="form-check-label" labelText={newSectionLabel} style={{ cursor: 'ponter' }} />
                    </AddSectionStyle>
                </ContainerFormStyle>

                <div style={{ marginTop: 28 }}></div>
            </ContainerRowDesign>
        </NewCustomerFormDesign>
    );
});

export default FormDesignerBuilder;
