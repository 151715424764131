import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import AnimatedNumber from "@/modules/core/design-system/animations/AnimatedNumber";
import React, { FC, ReactElement, useState } from "react";
import {
  StyledInformationCard,
  StyledSmallInformationCard,
} from "../supervisor-cockpit-styles";
import {
  InfoCardColor,
  InfoCardLabels,
  InfoCardTypes,
} from "../supervisor-cockpit.model";

type CockpitInfoCardProps = {
  type: InfoCardTypes;
  value?: number;
  customStyle?: InfoCardColor;
  isSmallCard?: boolean;
};

type BaseColors = {
  [K in InfoCardTypes]: InfoCardColor;
};

const CockpitInfoCard: FC<CockpitInfoCardProps> = React.memo(
  ({ type, value, customStyle, isSmallCard }): ReactElement => {
    const component: string = "CockpitInfoCard";

    const initialComponentLabels: InfoCardLabels = {
      offline: "Offline",
      online: "Online",
      working: "Working",
      ready: "Ready",
      worked: "Worked",
    };

    const [componentLabels, setComponentLabels] = useState<InfoCardLabels>(
      initialComponentLabels
    );

    const baseColors: BaseColors = {
      offline: {
        color: "#989898",
        variant: "#E6E6E6",
      },
      working: {
        color: "#780096",
        variant: "#F4E0FE",
      },
      online: {
        color: "#3047B0",
        variant: "#D3EAFF",
      },
      ready: {
        color: "#009A58",
        variant: "#B1F2D7",
      },
      worked: {
        color: "#E16403",
        variant: "#FFE4B3",
      },
    };

    return (
      <>
        {isSmallCard ? (
          <StyledSmallInformationCard
            baseStyle={baseColors[type]}
            customStyle={customStyle}
          >
            <div className="form"></div>
            <p className="value">{initialComponentLabels[type]}</p>
          </StyledSmallInformationCard>
        ) : (
          <StyledInformationCard
            baseStyle={baseColors[type]}
            customStyle={customStyle}
          >
            <div className="card-info">
              <p className="label">{initialComponentLabels[type]}</p>
              <p className="value">
                <AnimatedNumber targetNumber={value ?? 0} />
              </p>
            </div>
            <div className="card-icon">
              <TPIcon iconType={type as TPIconTypes} />
            </div>
          </StyledInformationCard>
        )}
      </>
    );
  }
);

export default CockpitInfoCard;
