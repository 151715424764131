import { ConfigurationValuesViewModel } from "@/models/Configuration/ConfigurationValuesModels";
import { TPLanguageFilterEnum } from "@/models/Global/TPGlobalEnums";
import { LanguagesViewModel } from "@/models/Languages/LanguagesViewModel";
import { GlobalPermissionsViewModel } from "@/models/Permission/GlobalPermissionsViewModel";
import ConfigurationService from "@/services/ConfigurationService";
import { TPI18N } from "@/services/I18nService";
import LanguageService from "@/services/LanguageService";
import { PermissionService } from "@/services/PermissionService";
import { UserService } from "@/services/UserService";
import { TPKeyValue } from "./TPKeyValue";
import { TPLog, TPLogType } from "./TPLog";

const Sanitizer = require("sanitize-html");

class TPGlobal {
    public static tenantSelectorURL: string = "";
    public static tenantHex: string = "";
    
    /**
     * This language is selected when you log in through the tenan 
     * and is responsible for displaying the visual interface 
     * in the selected language.
     */
    public static language: string = "";
    public static jwt: string = "";
    public static currentYear = new Date().getFullYear();

    //local "https://localhost:5005/"; //Azure URL: "https://tpclientcloud.dev.ontp.app/apigateway/"
    public static baseAPIGateway: string = process.env
        .REACT_APP_BASE_URL_APIGATEWAY as string;

    //"api://bf14c0e6-29fe-4b0c-9db1-e20b99c38447/API.Access"
    public static scopeAPI: string = process.env.REACT_APP_SCOPE_API as string;

    //https://localhost:5023/tpClientCloudRealTimeHub
    public static realTimeURL: string = process.env
        .REACT_APP_REALTIME_SERVER_URL as string;
    public static isDevelopEnviroment: string = process.env
        .REACT_APP_IS_DEVELOP as string;
    public static alreadyShowDashBoard: boolean = false;

    public static encryptedAESKey: string = "";

    public static baseAPITenant: string = "api/tenant/";
    public static defaultExpectedCodes: Array<number> = [200, 404];
    public static newTabException: Array<string> = [];
    public static dateFormat: string;
    public static sessionId: string = "";
    public static currentUserGuid: string = "";
    public static currentUserName: string = "";
    public static globalPermissions: GlobalPermissionsViewModel;
    public static currentUserTimeZoneId: string = "";
    public static stationId: string = "";
    public static userTreeBranchAreaMapping: string = "";
    public static userTreeBranchTeamMapping: string = "";
    public static companyLogo: string = "";
    public static taskTypeCanBeCompletedMassively: string = "";
    public static characterNumberCutStringComments: number = 0;
    public static enableCaseAverageHandleTime: number = 0;
    public static EnableStoryFai: string = "";
    // public static StoryFaiAvailableLanguages: string = "";
    public static StoryFaiAvailableLanguages: string = "";
    public static StoryFaiPreview: string = "";
    public static enableTPGenAI: string;

    public static allowedImageExtensions: string = "";
    public static imageWeightAllowed: number = 0;

    /**
     * task status
     */
    public static TASK_STATUS = "TASKSTATUS";

    //Email
    public static sRecema: "S_RECEMA";
    public static S_CADTDATBF: "S_CADTDATBF";

    public static standardHeaders: Array<TPKeyValue> = [];
    public static globalResourceSet: string = "GlobalResource";
    public static paginationComponentOptions: any = {
        rowsPerPageText: "",
        rangeSeparatorText: "",
        selectAllRowsItem: true,
        selectAllRowsItemText: "",
    };

    public static Tree: any = {
        NA_TreeCode: "NA_TREE",
    };

    public static Branch: any = {
        NA_BranchCode: "NA_BRANCH",
    };

    public static AdditionalDataTypes: any = {
        DATE: "DATE",
    };

    public static idTreeForCustomStates: string = "S_CCSTTY";
    public static additionalDataRetryId: string = "S_RETRIES";

    public static dateFormatAdditionalData: string = "YYYY/MM/DD";

    public static cookieOrganization: string = "";
    public static cookieRelation: string = "";

    public static Attachment: any = {
        
        MAILREADER: "MailReader"
     
    };

    public static PathEmlStorage: any = {

        MAILREADER: "MailReader/EML/"

    };

    

    public static TaskTypeComponent: any = {
        //Decision
        CaseResponseDecisionAutoTaskComponent: "CAREDEAUTA",
        CaseResponseDecisionRetryTaskComponent: "CAREDERETRY",
        CaseResponseDecisionTaskComponent: "CAREDETA",

        //Email
        CaseResponseEmailAutoTaskComponent: "CARESEEMAUTA",
        CaseResponseEmailTaskComponent: "CARESEEMAIL",

        //Case Exchange
        CaseResponseExchangeAutoTaskComponent: "CAREEXCAINAU",
        CaseResponseExchangeTaskComponent: "CAREEXCASEIN",

        //Social Media
        CaseResponseFacebookGroupTaskComponent: "CAREFBGRRE",
        CaseResponseFacebookTaskComponent: "CAREFBRE",
        CaseResponseInstagramTaskComponent: "CAREINRE",
        CaseResponseInstantMessageTaskComponent: "CAREINMERE",
        CaseResponseTweetTaskComponent: "CARETWRE",

        //KB
        CaseResponsePublishDocumentTaskComponent: "CAREKBPUDO",

        //Others
        CaseResponseAnswerAutoTaskComponent: "CAREAUANTA",
        CaseResponseRestartWorkflowTaskComponent: "CARERECUWF",
        CaseResponseStandardTaskComponent: "CARESTCO",
        CaseResponseSystemTaskComponent: "CARESYTA",

        //External Task
        ExternalTaskComponent: "CAREEXTA",
    };

    public static CaseType: any = {
        CASE_MANAGEMENT: "CASEMANAGEMENT",
        CASE_API: "CASEAPI",
        CASE_CTI: "CASECTI",
        CASE_PUSH: "CASEPUSH",
        MAIL_INBOUND: "MAILINBOUND",
        MAIL_OUTBOUND: "MAILOUTBOUND",
        INSTANT_MESSAGE_CHAT_TWILIO: "INSTANTMESSAGECHATTWILIO",
        INSTANT_MESSAGE_CHAT_BOT_YELLOWAI: "INSTANTMESSAGECHATBOTYELLOWAI",
        INSTANT_MESSAGE_CHAT_WHATSAPP_YELLOWIA:
            "INSTANTMESSAGECHATWHATSAPPYELLOWIA",
        SOCIAL_MEDIA_X: "SOCIALMEDIAX",
        SOCIAL_MEDIA_FACEBOOK: "SOCIALMEDIAFACEBOOK",
        SOCIAL_MEDIA_INSTAGRAM: "SOCIALMEDIAINSTAGRAM",
    };


    public static UserSystem: any = {
        SYSTEM: "SYSTEM",
       
    };

    public static StatusUser: any = {
        ONLINE: "ONLINE",
        OFFLINE:"OFFLINE"      
    };

    /**
     * This language is the one configured in the modules menu tab 
     * and is responsible for establishing the user's default language.
     */
    public static TPClientDefaultLanguage: string = "";
    public static TPGenAIMinimumWordCount: number = 500;
    public static TPGenAIEnabled: number = 0;
    public static TPGenAIAvailableLanguages: Array<{
        languageName: string;
        languageCode: string;
    }> = [];
    public static TPClientAvailableLanguages: Array<LanguagesViewModel> = [];
    public static TPClientConfigurationValues: Array<TPKeyValue> = [];
    public static TPGeneralHttpException: string =
        "A communication error with the server has occurred";
    public static TPGeneralAccessDenied = "Access denied...";
    public static PersonWhoReportsTemplateType: string = "TTDATOPI"; //todo system record o sacar de otra parte
    public static initialWorkflowDetail: number = 0;
    public static regularExpressionCheckEmail: RegExp =
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    public static startUp = async (tpGeneralContext: any) => {
        //global resources
        TPGlobal.paginationComponentOptions.rowsPerPageText = await TPI18N.GetText(
            TPGlobal.globalResourceSet,
            "DataTableRowsPerPage",
        );
        TPGlobal.paginationComponentOptions.rangeSeparatorText =
            await TPI18N.GetText(TPGlobal.globalResourceSet, "DataTableOfCounter");

        TPGlobal.paginationComponentOptions.selectAllRowsItemText =
            await TPI18N.GetText(
                TPGlobal.globalResourceSet,
                "DataTableAllRecordsFilter",
            );

        //available languages
        await TPGlobal.loadAvailableLanguages();

        //ConfigurationValues
        await TPGlobal.loadConfigurationValues();
        tpGeneralContext.setCompanyLogo(TPGlobal.companyLogo);

        //user information
        await TPGlobal.loadUserInfoFromToken(tpGeneralContext);

        //load global permissions
        await TPGlobal.loadGlobalPermissions();
    };

    //en$English|fr$Français|pt$Português|it$Italiano|es$Español

    private static loadAvailableLanguages = async () => {
        let svcLanguage = new LanguageService();
        let expectedCodes: Array<number> = [];
        let languagesList: Array<LanguagesViewModel>;
        try {
            //available languages
            expectedCodes = [];
            expectedCodes.push(200);
            let responseRequest = await svcLanguage.getLanguageByFilter(
                TPLanguageFilterEnum.ConfiguredForTPClientUI,
                false,
                true,
                expectedCodes,
            );
            languagesList = responseRequest.responseData.data;
            let defaultLanguage = languagesList.find((x) => x.order == 1)?.id;
            if (!defaultLanguage) {
                TPLog.Log(
                    "TPGlobal.tsx loadAvailableLanguages getLanguageByFilter",
                    TPLogType.ERROR,
                    "no default language order=1",
                );
                console.error(
                    "TPGlobal.tsx loadAvailableLanguages getLanguageByFilter no default language order=1",
                );
            }
            TPGlobal.TPClientDefaultLanguage = defaultLanguage
                ? defaultLanguage
                : "en";
            let sortedLanguages: Array<LanguagesViewModel> = languagesList.sort(
                function (a: LanguagesViewModel, b: LanguagesViewModel) {
                    return a.order - b.order;
                },
            );
            TPGlobal.TPClientAvailableLanguages = sortedLanguages;
        } catch (error) {
            TPLog.Log(
                "TPGlobal.tsx loadAvailableLanguages getLanguageByFilter ex",
                TPLogType.ERROR,
                error,
            );
            console.error(
                "TPGlobal.tsx loadAvailableLanguages getLanguageByFilter ex",
            );
        }
    };

    private static loadUserInfoFromToken = async (tpGeneralContext: any) => {
        let svcUser = new UserService();
        let expectedCodes: Array<number> = [];
        let userInfo: any; //todo
        try {
            //available languages
            expectedCodes = [];
            expectedCodes.push(200);
            let responseRequest = await svcUser.getUserGuidFromToken(
                false,
                true,
                expectedCodes,
            );
            userInfo = responseRequest;
            if (userInfo) {
                tpGeneralContext.setGlobalUserInfo(userInfo);
            }
            TPGlobal.currentUserTimeZoneId = userInfo.timeZoneId;
            TPGlobal.currentUserGuid = userInfo.userGuid;
            TPGlobal.currentUserName = userInfo.name;
        } catch (error) {
            TPLog.Log(
                "TPGlobal.tsx loadUserInfoFromToken getUserGuidFromToken ex",
                TPLogType.ERROR,
                error,
            );
            console.error(
                "TPGlobal.tsx loadUserInfoFromToken getUserGuidFromToken ex",
            );
        }
    };

    private static loadGlobalPermissions = async () => {
        let svc = new PermissionService();
        let expectedCodes: Array<number> = [200];
        try {
            let responseRequest = await svc.getGlobalPermissions(
                false,
                true,
                expectedCodes,
            );
            TPGlobal.globalPermissions = {...responseRequest};
        } catch (error) {
            TPLog.Log(
                "TPGlobal.tsx loadGlobalPermissions getGlobalPermissions ex",
                TPLogType.ERROR,
                error,
            );
            console.error(
                "TPGlobal.tsx loadGlobalPermissions getGlobalPermissions ex",
            );
        }
    };

    private static loadConfigurationValues = async () => {
        let svcConfig = new ConfigurationService();
        let expectedCodes: Array<number> = [];
        let configValuesList: Array<ConfigurationValuesViewModel>;
        let i: number;
        try {
            expectedCodes = [];
            expectedCodes.push(200);
            let responseRequest = await svcConfig.getAllPublicConfigurationKeys(
                false,
                true,
                expectedCodes,
            );
            configValuesList = responseRequest.responseData.data;
            for (i = 0; i <= configValuesList.length - 1; i++) {
                let item = configValuesList[i];
                let configElement: TPKeyValue = {key: item.key, value: item.value};
                TPGlobal.TPClientConfigurationValues.push(configElement);
                switch (item.key.toLowerCase()) {
                    case "ComponentsOpenNewTabNotRecycle".toLowerCase():
                        TPGlobal.newTabException = item.value.split("|");
                        break;
                    case "DefaultLanguage".toLowerCase():
                        TPGlobal.TPClientDefaultLanguage = item.value;
                        break;
                    case "DateFormat".toLowerCase():
                        TPGlobal.dateFormat = item.value;
                        break;
                    case "UserTreeBranchAreaMapping".toLowerCase():
                        TPGlobal.userTreeBranchAreaMapping = item.value;
                        break;
                    case "UserTreeBranchTeamMapping".toLowerCase():
                        TPGlobal.userTreeBranchTeamMapping = item.value;
                        break;
                    case "CompanyLogo".toLowerCase():
                        TPGlobal.companyLogo = item.value;
                        break;
                    case "TaskTypeCanBeCompletedMassively".toLowerCase():
                        TPGlobal.taskTypeCanBeCompletedMassively = item.value;
                        break;
                    case "EnableCaseAverageHandleTime".toLowerCase():
                        TPGlobal.enableCaseAverageHandleTime = Number(
                            item.value ? item.value : 0,
                        );
                        break;
                    case "EnableStoryFai".toLowerCase():
                        TPGlobal.EnableStoryFai = item.value;
                        break;
                    case "StoryFaiAvailableLanguages".toLowerCase():
                        TPGlobal.StoryFaiAvailableLanguages = item.value;
                        break;
                    case "StoryFaiPreview".toLowerCase():
                        TPGlobal.StoryFaiPreview = item.value;
                        break;
                    case "TPGenAIMinimumWordCount".toLowerCase():
                        TPGlobal.TPGenAIMinimumWordCount = Number(item.value || 500);
                        break;
                    case "EnableTPGenAI".toLowerCase():
                        TPGlobal.TPGenAIEnabled = Number(item.value || 0);
                        break;
                    case "TPGenAIAvailableLanguages".toLowerCase():
                        TPGlobal.TPGenAIAvailableLanguages = item.value
                            .split("|")
                            .map((lang) => lang.split("$"))
                            .map((pair) => {
                                return {
                                    languageCode: pair[0],
                                    languageName: pair[1],
                                };
                            });
                        break;
                    case "ValidExtensionsImageList".toLowerCase():
                        TPGlobal.allowedImageExtensions = item.value;
                        break;
                    case "MaxSizeFileUpload".toLowerCase():
                        TPGlobal.imageWeightAllowed = Number.parseInt(item.value);
                        break;
                    case "CharacterNumberCutStringComments":
                        TPGlobal.characterNumberCutStringComments = Number(
                            item.value ? item.value : 0,
                        );
                        break;
                }
            }
        } catch (error) {
            TPLog.Log(
                "TPGlobal.tsx loadConfigurationValues getAllPublicConfigurationKeys ex",
                TPLogType.ERROR,
                error,
            );
            console.error(
                "TPGlobal.tsx loadConfigurationValues getAllPublicConfigurationKeys ex",
            );
        }
    };

    public static TPIsEmpty(obj: any): boolean {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
    }

    public static checkSpecialCharacters: any = (valueToCheck: string) => {
        let i: number;
        let specialCharacters = TPGlobal.TPClientConfigurationValues.find(
            (x) => x.key == "CodeBlackList",
        )?.value;
        if (
            TPGlobal.TPIsEmpty(specialCharacters) ||
            specialCharacters == undefined
        ) {
            //this should not happen
            return {result: true, character: ""};
        }
        //not allow & or ;
        valueToCheck = valueToCheck.replaceAll("&", "|").replaceAll(";", "|");
        for (i = 0; i <= specialCharacters.length - 1; i++) {
            if (valueToCheck.includes(specialCharacters[i])) {
                return {result: false, character: specialCharacters[i]};
            }
        }
        return {result: true, character: ""};
    };

    public static checkSpecialUserLoginCharacters: any = (
        valueToCheck: string,
    ) => {
        let i: number;
        let specialCharacters = TPGlobal.TPClientConfigurationValues.find(
            (x) => x.key == "CodeBlackList",
        )
            ?.value.replaceAll(";.", "")
            .replaceAll(";@", "");
        //let specialCharacters: string = `<;;!;";#;$;%;/;(;);=;?;¡;\;*;-;+;°;';¿;,;{;[;^;};];´;¨;|;>;; ;:;~;`;
        if (
            TPGlobal.TPIsEmpty(specialCharacters) ||
            specialCharacters == undefined
        ) {
            //this should not happen
            return {result: true, character: ""};
        }
        //not allow & or ;
        valueToCheck = valueToCheck.replaceAll("&", "|").replaceAll(";", "|");
        for (i = 0; i <= specialCharacters.length - 1; i++) {
            if (valueToCheck.includes(specialCharacters[i])) {
                return {result: false, character: specialCharacters[i]};
            }
        }
        return {result: true, character: ""};
    };

    public static validateIPv4Address(ipAddress: string): boolean {
        const ipSegments = ipAddress.split(".");
        if (ipSegments.length !== 4) {
            return false;
        }
        for (let i = 0; i < 4; i++) {
            const segment = parseInt(ipSegments[i], 10);
            if (isNaN(segment) || segment < 0 || segment > 255) {
                return false;
            }
        }
        return true;
    }

    public static TPSanitize(valueToSanitize: string): string {
        let sanitizedValue: string;
        sanitizedValue = Sanitizer(valueToSanitize);
        return sanitizedValue;
    }

    public static TPSanitizeWithoutLinks(valueToSanitize: string): string {
        let sanitizedValue: string;
        let allowedTags: Array<string>;
        allowedTags = [...Sanitizer.defaults.allowedTags];
        let newTags: Array<string> = allowedTags.filter((x: string) => x !== "a");

        sanitizedValue = Sanitizer(valueToSanitize, {
            allowedTags: newTags,
            allowedAttributes: {
                span: ["style"],
            },
        });
        return sanitizedValue;
    }

    public static getLanguageDescriptionByCode(code: string) {
        return TPGlobal.TPClientAvailableLanguages.filter((x) => x.id === code)[0]
            .name;
    }

    public static datatableCustomSort: any = (
        rows: any,
        selector: any,
        direction: any,
    ) => {
        return rows.sort((rowA: any, rowB: any) => {
            // use the selector function to resolve your field names by passing the sort comparitors

            let aField: any;
            let bField: any;

            typeof aField === "string"
                ? (aField = selector(rowA).toLowerCase())
                : (aField = selector(rowA));
            typeof bField === "string"
                ? (bField = selector(rowB).toLowerCase())
                : (bField = selector(rowB));

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === "desc" ? comparison * -1 : comparison;
        });
    };

    public static HHMM_FromNumber(value: number): string {
        //value in hours
        let hours: number;
        let minutes: number;
        hours = Math.trunc(value);
        minutes = Math.trunc((value - hours) * 60.0);
        return (
            (hours <= 9 ? "0" + hours.toString() : hours.toString()) +
            ":" +
            (minutes <= 9 ? "0" + minutes.toString() : minutes.toString())
        );
    }

    public static HHMMSS_FromNumber(value: number): string {
        //value in seconds
        let hours: number;
        let minutes: number;
        let seconds: number;
        //value= 3715
        hours = Math.trunc(value / 3600.0);
        //hours= 1
        minutes = Math.trunc((value - hours * 3600.0) / 60.0);
        //minutes = 1
        seconds = value - hours * 3600.0 - minutes * 60.0;
        //seconds = 55
        return (
            (hours <= 9 ? "0" + hours.toString() : hours.toString()) +
            ":" +
            (minutes <= 9 ? "0" + minutes.toString() : minutes.toString()) +
            ":" +
            (seconds <= 9 ? "0" + seconds.toString() : seconds.toString())
        );
    }

    public static complexObjectCustomSortNumeric: any = (
        complexObject: any,
        sortField: any,
        direction: any,
    ) => {
        return complexObject.sort((rowA: any, rowB: any) => {
            // use the selector function to resolve your field names by passing the sort comparitors
            const aField = rowA[sortField];
            const bField = rowB[sortField];

            let comparison = 0;

            if (aField > bField) {
                comparison = 1;
            } else if (aField < bField) {
                comparison = -1;
            }

            return direction === "desc" ? comparison * -1 : comparison;
        });
    };

    public static foo = () => {
        //do nothing
    };

    public static initRealTime = async () => {
    };

    public static trimChars = (valueToTrim: any, maxChars: number) => {
        let suffix: string = "";
        if (valueToTrim && valueToTrim.length > maxChars) {
            suffix = "...";
        }
        return valueToTrim
            ? valueToTrim.toString().substring(0, maxChars) + suffix
            : "";
    };

    public static TPReplaceEnter(valueToReplace: string): string {
        if (valueToReplace !== null && valueToReplace !== undefined) {
            valueToReplace = valueToReplace.trim();
            valueToReplace = valueToReplace.replaceAll("\r\n", "<br />");
            valueToReplace = valueToReplace.replaceAll("\r", "<br />");
            valueToReplace = valueToReplace.replaceAll("\n", "<br />");
        }
        return valueToReplace;
    }

    public static createColumnNameObject = (data: any[]): any[] => {
      const result: { [key: string]: string } = {};
      data.forEach(item => {
        result[item.columnName] = "";
      });
      return [result];
    };

    public static stringToUTF8(str: string): Uint8Array {
        return new TextEncoder().encode(str);
    }

    public static uint8ArrayToString(str: string): string | undefined {
        try {
            const uint8Array = new Uint8Array(str.split(",").map(Number));
            return new TextDecoder("utf-8").decode(uint8Array);
        } catch (e) {
            console.error(`the value ${str} cannot be decoded`);
            console.error(e);
            return;
        }
    }

    public static RemoveHtmlTinyWhiteSpaces = (textValue: string) => {
        textValue = textValue
            .replaceAll("&nbsp;", "")
            .replaceAll("<br>", "")
            .trim();
        textValue = decodeURIComponent(
            encodeURIComponent(textValue)
                .replaceAll("%20", "") //spaces
                .replaceAll("%0A", ""), //line break
        );
        textValue = textValue.replaceAll("<p></p>", "");

        return textValue;
    };

    public static RemoveHtmlOnlyContent = (htmlString: string): string => {
        const tmp = document.createElement("div");
        tmp.innerHTML = htmlString;
        let result = tmp.textContent || tmp.innerText || "";
        return result.trim();
    };

    public static validateEmail(email: string): boolean {
        if (email === null || email === undefined || email.trim() === "") {
            return false;
        }
        return TPGlobal.regularExpressionCheckEmail.test(email);
    }
}

export default TPGlobal;
