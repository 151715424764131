import { UserViewModel } from "../Users/UserModels";

export interface GroupsViewModel {
  groupId: string;
  isRotating: boolean;
  isUpdatable: boolean;
  isPicking: boolean;
  finishPendingCaseBeforeRecivingNewOne: boolean;
  preferredAgent: boolean;
  relatedCase: boolean
  recentAgent: boolean;
  prioritizeRelatedCases: boolean;
  topSearchCoefficient: number;
  assignCasesToUsersLogged: boolean;
  minutesLogoutReassign?: number;
  maximumCasesPerUser?: number;
  taskResponsibleForSocialMediaAnswer: number;
  priorityWeight: number;
  isEventTakeNextGroup: boolean;
  user: UserViewModel;
}

export interface QueueAdminModel {
  groupId: number
  interactionOutcomes: string
  clasificationOptions: string | null
  customFields: string | null
  activateWarningAfterMinutes: number
  activateWarningAfterSeconds: number
  enventGroupType: string
}

export enum QueueFilter {
  Profile = "PROF",
  Team = "TEAM",
  Function = "FUNC",
  Superior = "SUPE"
}

export interface AssignableGroupUser {
  groupId: number,
  user: UserViewModel
}

export interface GroupSearchFilter {
  itemId: string,
  itemDescription: string,
  itemType: string
}

export interface UserSearchByFilterModel {
  groupId: number
  id_FUNC: string | null
  id_PROF: string | null
  superior_Id_USER: string | null
  team_Id_BRAN: string | null
  name: string | null
}

export interface GroupUsersReassignModel {
  usersIds: number[]
  idGroup: number
}

export enum QueueTabs {
  GeneralInfo,
  Administration,
  AssignUsers
}