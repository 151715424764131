import { forwardRef, useImperativeHandle, useState } from "react";
import { TPIconTypes } from "@/models/Global/TPGlobalEnums";
import DynamicTable, {
  ColumnStyles,
  CustomColumnNames,
} from "@/modules/core/components/dynamic-table/DynamicTable";
import { MinorOption } from "@/modules/core/components/dynamic-table/TableActionItem";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import TablePrimaryItem from "@/modules/core/utils/table-micro-components/TablePrimaryItem";
import {
  CustomStateAdminTableEvents,
  CustomStateAdminTableProps,
  CustomStateCommandType,
  CustomStateListModel,
  CustomStateModel,
  CustomStateAdminEnum as e,
} from "@/models/CustomState/CustomState";
import { CustomStateService } from "@/services/CustomStateService";

const CustomStateAdminTable = forwardRef(
  ({ state, pageDispatch, loadData }: CustomStateAdminTableProps, ref) => {
    const [modalQuestionState, setModalQuestionState] =
      useState<TPModalQuestionState>({
        isShown: false,
        callBackData: {},
      });

    useImperativeHandle(
      ref,
      () =>
        ({
          load() {},
        }) as CustomStateAdminTableEvents
    );

    const columns: CustomColumnNames<CustomStateListModel> = {
      customStateFlowId: `${state.messages?.[e.IdTableColumnLabel]?.value ?? ""}`,
      order: `${state.messages?.[e.OrderTableColumnLabel]?.value ?? ""}`,
      taskDescription: `${state.messages?.[e.BaseField1TableColumnLabel]?.value ?? ""}`,
      caseStatus: `${state.messages?.[e.ReferenceValue1TableColumnLabel]?.value ?? ""}`,
      taskStatus: `${state.messages?.[e.BaseField2TableColumnLabel]?.value ?? ""}`,
      customStateTypeDescription: `${state.messages?.[e.CustomStateTableColumnLabel]?.value ?? ""}`,
      weight: `${state.messages?.[e.WeightTableColumnLabel]?.value ?? ""}`,
    };

    const columnStyles: ColumnStyles<CustomStateListModel> = {
      customStateFlowId: ({ value, item }) =>
        value ? (
          <TablePrimaryItem
            value={value}
            onClick={() => onClickUpdateHandler(item)}
          />
        ) : (
          <></>
        ),
      caseStatus: ({ value }) => <>{value ?? ""}</>,
      taskStatus: ({ value }) => <>{value ?? ""}</>,
      customStateTypeDescription: ({ value }) => <>{value ?? ""}</>,
      weight: ({ value }) => <>{value > 0 ? `${value}` : ""}</>,
    };

    const options: MinorOption<CustomStateListModel>[] = [
      {
        key: `${state.messages?.[e.UpdateActionLabel]?.value ?? ""}`,
        type: "edit",
        icon: TPIconTypes.edit,
        onOptionChange: (item) => onClickUpdateHandler(item),
      },
      {
        key: `${state.messages?.[e.DeleteActionLabel]?.value ?? ""}`,
        type: "delete",
        icon: TPIconTypes.delete,
        onOptionChange: ({ customStateFlowId: id }) => onClickDeleteHandler(id),
      },
    ];

    function onClickUpdateHandler(element: CustomStateListModel) {
      pageDispatch({
        type: CustomStateCommandType.redirectToUpdate,
        payload: element,
      });
    }

    function onClickCloneHandler(element: CustomStateListModel) {
      pageDispatch({
        type: CustomStateCommandType.redirectToClone,
        payload: element,
      });
    }

    function onClickDeleteHandler(id: string) {
      setModalQuestionState({
        ...modalQuestionState,
        isShown: true,
        callBackData: { recordId: id },
      } as TPModalQuestionState);
    }

    const onClickIconHandler = (event: TPIconTypes) => {
      if (event === TPIconTypes.loop) loadData();
    };

    function callBackModalHandler(confirmDelete: boolean, callBackData: any) {
      setModalQuestionState({
        ...modalQuestionState,
        isShown: false,
        callBackData: {},
      });
      if (confirmDelete) {
        const { deleteOne } = new CustomStateService();
        deleteOne(callBackData.recordId)
          .then(() => loadData())
          .catch((error) => console.log(error));
      }
    }

    return (
      <>
        <DynamicTable
          id="CustomStateAdminTable"
          data={state.datasource.value ?? []}
          columnNames={columns}
          columnStyles={columnStyles}
          hiddenColumns={["workFlowTypeId", "workFlowDetailId", "taskTypeId", "customStateTypeId"]}
          minorOptions={options}
          onIconClicked={(event) => onClickIconHandler(event)}
        />

        {modalQuestionState?.isShown &&
          modalQuestionState.callBackData.recordId && (
            <TPModalQuestion
              id="IdModalQuestion"
              title={`${state.messages?.[e.titleModalLabel]?.value ?? ""}`}
              yesLabel={`${state.messages?.[e.YesModalButtonLabel]?.value ?? ""}`}
              noLabel={`${state.messages?.[e.NoModalButtonLabel]?.value ?? ""}`}
              question={`${state.messages?.[e.QuestionModalLabel]?.value ?? ""}`?.replace(
                "{recordId}",
                modalQuestionState.callBackData.recordId
              )}
              callBackData={modalQuestionState.callBackData}
              isShown={modalQuestionState.isShown}
              callBackAnswer={callBackModalHandler}
            />
          )}
      </>
    );
  }
);

export default CustomStateAdminTable;
