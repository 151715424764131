import TPGlobal from "@/helpers/TPGlobal";
import { TPI18N } from "@/services/I18nService";
import { useEffect, useState } from "react";

const resourceSet = "ListAdmin";

interface ListAdminLabelsModel {
  ListAdministration: string;
  NewList: string;
  Id: string;
  ListName: string;
  Active: string;
  AlphabeticalOrder: string;
  ListItems: string;
  AddItems: string;
  Actions: string;
  ItemName: string;
  Cancel: string;
  Save: string;
  AddNewList: string;
  LanguageList: string;
  Edit: string;
  Delete: string;
  EditItem: string;
  ShowAsActive: string;
  OtherLanguagesForTheName: string;
  Clone: string;
  ItemInsertInstructions: string;
  Yes: string;
  No: string;
  SystemAlert: string;
  CancelConfirm: string;
  Type: string;
  Name: string;
  EditList: string;
  CloneList: string;
  CreateList: string;
  SaveChanges: string;
  ElementToClone: string;
  DuplicateItems: string;
  CreatedBy: string;
}

const useListAdminLabels = function () {

  const [labels, setLabels] = useState<ListAdminLabelsModel>({} as ListAdminLabelsModel);
  const [labelsLoaded, setLabelsLoaded] = useState<boolean>(false);

  const loadLabels = async function () {
    setLabels({
      ListAdministration: await TPI18N.GetText(resourceSet, 'ListAdministration'),
      NewList: await TPI18N.GetText(resourceSet, 'NewList'),
      Id: await TPI18N.GetText(resourceSet, 'Id'),
      ListName: await TPI18N.GetText(resourceSet, 'ListName'),
      Active: await TPI18N.GetText(resourceSet, 'Active'),
      AlphabeticalOrder: await TPI18N.GetText(resourceSet, 'AlphabeticalOrder'),
      ListItems: await TPI18N.GetText(resourceSet, 'ListItems'),
      AddItems: await TPI18N.GetText(resourceSet, 'AddItems'),
      Actions: await TPI18N.GetText(resourceSet, 'Actions'),
      ItemName: await TPI18N.GetText(resourceSet, 'ItemName'),
      Cancel: await TPI18N.GetText(resourceSet, 'Cancel'),
      Save: await TPI18N.GetText(resourceSet, 'Save'),
      AddNewList: await TPI18N.GetText(resourceSet, 'AddNewList'),
      LanguageList: await TPI18N.GetText(resourceSet, 'LanguageList'),
      Edit: await TPI18N.GetText(resourceSet, 'Edit'),
      Delete: await TPI18N.GetText(TPGlobal.globalResourceSet, 'DeleteLabel'),
      EditItem: await TPI18N.GetText(resourceSet, 'EditItem'),
      ShowAsActive: await TPI18N.GetText(resourceSet, 'ShowAsActive'),
      OtherLanguagesForTheName: await TPI18N.GetText(resourceSet, 'OtherLanguagesForTheName'),
      Clone: await TPI18N.GetText(resourceSet, 'Clone'),
      ItemInsertInstructions: await TPI18N.GetText(resourceSet, 'ItemInsertInstructions'),
      Yes: await TPI18N.GetText(resourceSet, 'Yes'),
      No: await TPI18N.GetText(resourceSet, 'No'),
      SystemAlert: await TPI18N.GetText(resourceSet, 'SystemAlert'),
      CancelConfirm: await TPI18N.GetText(resourceSet, 'CancelConfirm'),
      Type: await TPI18N.GetText(TPGlobal.globalResourceSet, 'Type'),
      Name: await TPI18N.GetText(TPGlobal.globalResourceSet, 'Name'),
      EditList: await TPI18N.GetText(resourceSet, 'EditList'),
      CloneList: await TPI18N.GetText(resourceSet, 'CloneList'),
      CreateList: await TPI18N.GetText(resourceSet, 'CreateList'),
      SaveChanges: await TPI18N.GetText(resourceSet, 'SaveChanges'),
      ElementToClone: await TPI18N.GetText(resourceSet, 'ElementToClone'),
      DuplicateItems: await TPI18N.GetText(resourceSet, 'DuplicateItems'),
      CreatedBy: await TPI18N.GetText(resourceSet, 'CreatedBy'),
    })
  }

  TPGlobal.globalResourceSet

  useEffect(() => {
    if (!labelsLoaded) loadLabels();
  }, [])

  return { labels, labelsLoaded };
}

export default useListAdminLabels;