import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  TableContainer,
  tableStyles,
  IsActiveIcon,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import { TreeViewModel } from "@/models/Tree/TreeModels";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { TreeService } from "@/services/TreeService";
import { TPI18N } from "@/services/I18nService";
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useReducer,
} from "react";
import DataTable from "react-data-table-component";
import { ContentVerticalNoTabsStyled } from "@/layouts/VerticalTabs/menuVerticalTabStyled";
import ContainerFolder from "./ImagesAdminContainerFolder";
import ContainerFiles from "./ImagesAdminContainerFiles";
import {
  AppProviderImagesAdmin,
  useAppConctextImageAdmin,
} from "./ImagesAdminContextImagesAdmin";
import ImagesAdminContainerFiles from "./ImagesAdminContainerFiles";
import {
  ContainerImageAdminDivStyle,
  ContainerVerticalDivStyle,
} from "./StyleImageAdmin";
import { MdVerticalAlignTop } from "react-icons/md";
import { TreeInputDTOValidator } from "@/models/Tree/TreeInputDTO";
import ImagesAdminContainerFilter from "./ImagesAdminContainerFilter";
import ImagesAdminSlider from "./ImagesAdminSlider";

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<TreeViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface ImagesAdminInterface {
    modeSelectFolder: boolean;
    parentFolderIdActive: number;
    ChangeImageSelection?: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}
type commandType = {
  type: commandsEnum;
  payload: any;
};

const ImagesAdmin = ({ modeSelectFolder, parentFolderIdActive, ChangeImageSelection }: ImagesAdminInterface) => {
    const componentFileName: string = "ImagesAdmin.tsx";
    const [statusFolderActive, setStatusFolderActive] = useState<boolean>(false);
    const value = useAppConctextImageAdmin();
    const {
        modeSelect,
        setModeSelect,
        parentFolderId,
        setParentFolderId,
        handleSelected,
        handleInitializeFilesModeSelect,
        listFolderModel,
        listTempFolder,
        handleInitializeFolders
    } = value;

    useEffect(() => {
        setModeSelect(modeSelectFolder);
        if (modeSelectFolder && !statusFolderActive && listTempFolder.length > 0) {
            //  handleInitializeFolders(parentFolderIdActive, modeSelectFolder);
            setParentFolderId(parentFolderIdActive);
            handleInitializeFilesModeSelect(parentFolderIdActive);
            setStatusFolderActive(true);
            console.log("FOLDER", listTempFolder);
        }
       
    }, [listTempFolder]);


    useEffect(() => {
       
            console.log("modeSelectFolder", modeSelectFolder);
            handleInitializeFolders(parentFolderIdActive, modeSelectFolder);

    }, []);

  return (
    <ContentVerticalNoTabsStyled>
        <ContainerImageAdminDivStyle>
          <ContainerFolder></ContainerFolder>
          <ContainerVerticalDivStyle>
            <ImagesAdminContainerFilter></ImagesAdminContainerFilter>
                  <ImagesAdminContainerFiles ChangeImageSelection={ChangeImageSelection}></ImagesAdminContainerFiles>
          </ContainerVerticalDivStyle>
        </ContainerImageAdminDivStyle>
    </ContentVerticalNoTabsStyled>
  );
};

export default ImagesAdmin;
