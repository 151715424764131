import {
  TPFilterAndSearch,
  TPPageActions,
  TPPageFilterContainer,
  TPPageFirstRow,
  TPPageSearchContainer,
  TPPageTitle,
} from "@/components/TPPage/tpPageStyles";
import TPButton from "@/components/bootstrap/components/buttons/TPButton";
import {
  DataTableContainer,
  IsActiveIcon,
  TableContainer,
  TableHeaderContainer,
  TableSearchContainer,
  TableToolbar,
  tableStyles,
} from "@/components/bootstrap/content/tables/tpTableStyles";
import TPIcon from "@/components/bootstrap/extend/TPIcons/TPIcon";
import TPLoadingOverlay from "@/components/bootstrap/extend/TPLoadingSpinner/TPLoadingOverlay";
import TPSelect from "@/components/bootstrap/forms/select/TPSelect";
import TPTextBox from "@/components/bootstrap/forms/textbox/TPTextBox";
import TPGlobal from "@/helpers/TPGlobal";
import { TPKeyValue } from "@/helpers/TPKeyValue";
import { TPLog, TPLogType } from "@/helpers/TPLog";
import TPModalQuestion, {
  TPModalQuestionState,
} from "@/layouts/ModalQuestion/TPModalQuestion";
import {
  TPActiveOptions,
  TPButtonTypes,
  TPIconTypes,
} from "@/models/Global/TPGlobalEnums";
import { WorkflowTypeViewModel } from "@/models/Workflow/WorkflowTypeModels";
import { TPI18N } from "@/services/I18nService";
import { WorkflowTypeService } from "@/services/WorkflowTypeService";
import { HotTable } from "@handsontable/react";
import * as FileSaver from "file-saver";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import React, {
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";

// register Handsontable's modules
registerAllModules();

type AdminStateType = {
  selectedFilter: string;
  gridColumns: Array<any>;
  gridData: Array<WorkflowTypeViewModel>;
  filterIsLoaded: boolean;
  columnsAreLoaded: boolean;
  searchPattern: string;
};

interface WorkflowTypeAdminInterface {
  callBackCommands: Function;
}

enum commandsEnum {
  "set_filterIsLoaded" = 0,
  "setup_grid_columns" = 1,
  "reload_grid" = 2,
  "change_selectedFilter" = 3,
  "change_search_pattern" = 4,
}

type commandType = {
  type: commandsEnum;
  payload: any;
};

const WorkflowTypeAdmin = React.forwardRef(
  ({ callBackCommands }: WorkflowTypeAdminInterface, ref) => {
    const componentFileName: string = "WorkflowTypeAdmin.tsx";
    //Functions called form parent WorkflowTypeAdminContainer
    useImperativeHandle(ref, () => ({
      refreshGridFromParent() {
        reloadGridCommand();
      },
    }));
    //#region Init
    //screen loading
    const [isLoadingScreen, setIsLoadingScreen] = useState(true);
    //Screen resources
    const ResourceSet: string = "WorkflowTypeAdminComponent";
    const [titleLabel, setTitleLabel] = useState("");
    const [filterIsActiveLabel, setFilterLabel] = useState("");
    const [refreshLabel, setRefreshLabel] = useState("");
    const [newLabel, setNewLabel] = useState("");
    const [exportLabel, setExportLabel] = useState("");
    const [importLabel, setImportLabel] = useState("");
    const [deleteLabel, setDeleteLabel] = useState("");
    const [updateLabel, setUpdateLabel] = useState("");
    const [cloneLabel, setCloneLabel] = useState("");

    const [searchLabel, setSearchLabel] = useState("");
    const [thereAreNoRecordsToShow, setThereAreNoRecordsToShow] = useState("");
    //grid columns
    const [idColumnLabel, setIdColumnLabel] = useState("");
    const [descriptionColumnLabel, setDescriptionColumnLabel] = useState("");
    const [organizationColumnLabel, setOrganizationColumnLabel] = useState("");
    const [customerSLAColumnLabel, setCustomerSLAColumnLabel] = useState("");
    const [isDirectSolutionColumnLabel, setIsDirectSolutionColumnLabel] =
      useState("");
    const [isActiveColumnLabel, setIsActiveColumnLabel] = useState("");
    const [isCasesUsedColumnLabel, setIsCasesUsedColumnLabel] = useState("");

    //modal resources
    const [deleteQuestion, setDeleteQuestion] = useState("");
    const [deleteTitle, setDeleteTitle] = useState("");
    const [deleteOkLabel, setDeleteOkLabel] = useState("");
    const [deleteCanceLabel, setDeleteCancelLabel] = useState("");

    //State filter dropdown
    let initialStateFilter: Array<TPKeyValue> = [];
    const [filterKeyValue, setFilterKeyValue] = useState(initialStateFilter);

    //State modal
    let modalQuestionInitialState: TPModalQuestionState = {
      isShown: false,
      callBackData: {},
    };

    const [modalQuestionState, setModalQuestionState] = useState(
      modalQuestionInitialState,
    );

    const [fixedHeaderScrollHeight, setFixedHeaderScrollHeight] = useState(600);

    const [isHandsOnActive, setIsHandsOnActive] = useState<boolean>(false);
    const [renderedData, setRenderedData] = useState({
      id: "",
      description: "",
      promiseLimit: "",
      isDirectSolution: "",
      isActive: "",
      isCasesUsed: "",
    });
    //#endregion

    const loadResourcesAndOrganizationsRelationsFilter = async () => {
      setDeleteQuestion(
        await TPI18N.GetText(ResourceSet, "RecordDeleteConfirm"),
      );
      setDeleteTitle(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ConfirmTitle"),
      );
      setDeleteOkLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "OkButton"),
      );
      setDeleteCancelLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CancelButton"),
      );

      setDeleteLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "DeleteLabel"),
      );
      setUpdateLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "UpdateLabel"),
      );
      setCloneLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "CloneLabel"),
      );
      setRefreshLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ReloadButton"),
      );
      setNewLabel(await TPI18N.GetText(ResourceSet, "NewButton"));
      setExportLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "ExportButton"),
      );
      setImportLabel(await TPI18N.GetText(TPGlobal.globalResourceSet, "ImportLabel"));
      setSearchLabel(
        await TPI18N.GetText(TPGlobal.globalResourceSet, "Search"),
      );
      setThereAreNoRecordsToShow(
        await TPI18N.GetText(
          TPGlobal.globalResourceSet,
          "DataTableNoCurrentData",
        ),
      );
      setTitleLabel(await TPI18N.GetText(ResourceSet, "TitleLabel"));
      setFilterLabel(await TPI18N.GetText(ResourceSet, "FilterIsActiveLabel"));

      setIdColumnLabel(await TPI18N.GetText(ResourceSet, "Id"));
      setOrganizationColumnLabel(
        await TPI18N.GetText(ResourceSet, "Organization"),
      );
      setDescriptionColumnLabel(
        await TPI18N.GetText(ResourceSet, "Description"),
      );
      setCustomerSLAColumnLabel(
        await TPI18N.GetText(ResourceSet, "CustomerSLA"),
      );
      setIsDirectSolutionColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsDirectSolution"),
      );
      setIsActiveColumnLabel(await TPI18N.GetText(ResourceSet, "IsActive"));
      setIsCasesUsedColumnLabel(
        await TPI18N.GetText(ResourceSet, "IsCasesUsedColumnLabel"),
      );

      setFilterKeyValue([
        {
          key: TPActiveOptions.ALL.toString(),
          value: await TPI18N.GetText(ResourceSet, "All"),
        },
        {
          key: TPActiveOptions.ACTIVE.toString(),
          value: await TPI18N.GetText(ResourceSet, "Active"),
        },
        {
          key: TPActiveOptions.INACTIVE.toString(),
          value: await TPI18N.GetText(ResourceSet, "Inactive"),
        },
      ]);
    };

    const handleHandsOnTable = (row: any) => {
      setIsHandsOnActive(!isHandsOnActive);
      setRenderedData(row);
    };

    const setupGridColumns = (prevState: AdminStateType) => {
      try {
        let newState: AdminStateType;
        newState = { ...prevState };
        let newColumns: Array<any> = [];
        //delete
        newColumns.push({
          name: "",
          width: "50px",
          style: { padding: 0 },
          center: true,
          cell: (row: { [x: string]: any }) => {
            return (
              <div className="dropdown">
                <TPButton
                  id="IdButton"
                  dataBsToggle={true}
                  type={TPButtonTypes.empty}
                  onClick={() => {
                    TPGlobal.foo();
                  }}
                  className={"menu-button"}
                >
                  <TPIcon iconType={TPIconTypes.moreVert} />
                </TPButton>
                <ul className="dropdown-menu">
                  <li>
                    <a
                      onClick={(id: any) => handleDeleteClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {deleteLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(id: any) => handleUpdateClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {updateLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(id: any) => handleCloneClick(row["id"])}
                      className="dropdown-item"
                      href="#"
                    >
                      {cloneLabel}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={(id: any) => handleOnClickExportButton(row)}
                      className="dropdown-item"
                      href="#"
                    >
                      {exportLabel}
                    </a>
                  </li>
                </ul>
              </div>
            );
          },
        });
        //update
        newColumns.push({
          width: "50px",
          style: { padding: 0 },
          cell: (row: { [x: string]: any }) => {
            return (
              <TPButton
                id="IdButton"
                type={TPButtonTypes.primary}
                onClick={(id: string) => handleUpdateClick(row["id"])}
                className="update-button"
              >
                <TPIcon iconType={TPIconTypes.chevronRight} />
              </TPButton>
            );
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });
        //id
        newColumns.push({
          width: "150px",
          name: idColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["id"];
          },
          selector: (row: { [x: string]: any }) => row["id"],
          sortable: true,
        });

        //Description
        newColumns.push({
          name: descriptionColumnLabel,
          cell: (row: { [x: string]: any }) => {
            return row["localizedDescription"];
          },
          sortable: true,
          width: "350px",
        });
        //customer SLA
        newColumns.push({
          name: customerSLAColumnLabel,
          width: "200px",
          cell: (row: { [x: string]: any }) => {
            return (
              (row["promiseLimit"] == null ? "" : row["promiseLimit"]) +
              (row["promiseLimitUnit"] == null ? "" : row["promiseLimitUnit"])
            );
          },
          sortable: true,
        });
        //Is direct solution
        newColumns.push({
          name: isDirectSolutionColumnLabel,
          selector: (row: { [x: string]: any }) => row["isDirectSolution"],
          width: "250px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isDirectSolution"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });
        //isActive
        newColumns.push({
          name: isActiveColumnLabel,
          selector: (row: { [x: string]: any }) => row["isActive"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isActive"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        //IsCasesUsed
        newColumns.push({
          name: isCasesUsedColumnLabel,
          selector: (row: { [x: string]: any }) => row["isCasesUsed"],
          width: "150px",
          center: true,
          cell: (row: { [x: string]: any }) => {
            let currentValue: boolean = true;
            currentValue = row["isCasesUsed"];
            return (
              <IsActiveIcon
                className={currentValue ? "active" : "inactive"}
                iconType={TPIconTypes.activeInactive}
              />
            );
          },
        });

        newState.gridColumns = [...newColumns];
        return newState;
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} setupGridColumns ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} setupGridColumns ex`);
        return prevState;
      }
    };

    //Get organizations relations by Filter
    const reloadDataGrid = async (selectedFilter: string) => {
      let serviceClient = new WorkflowTypeService();
      let expectedCodes: Array<number> = [200, 404];

      try {
        setIsLoadingScreen(true);

        let responseRequest = await serviceClient.getByFilterIsActive(
          selectedFilter,
          false,
          true,
          expectedCodes,
        );
        setIsLoadingScreen(false);
        return [...responseRequest];
      } catch (error) {
        TPLog.Log(
          `Error ${componentFileName} reloadDataGrid ex`,
          TPLogType.ERROR,
          error,
        );
        console.error(`Error ${componentFileName} reloadDataGrid ex`);
        setIsLoadingScreen(false);
        return [];
      }
    };

    const reloadGridCommand = () => {
      reloadDataGrid(adminState.selectedFilter)
        .then(function (result) {
          let command1: commandType = {
            type: commandsEnum.reload_grid,
            payload: result,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} reloadGridCommand ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} reloadGridCommand ex`);
        });
    };

    //Filter Active Change
    const handleFilterChange = (e: any) => {
      let command1: commandType = {
        type: commandsEnum.change_selectedFilter,
        payload: e.target.value,
      };
      dispatchCommand(command1);
    };

    //New Organization Relation
    const handleNewClick = () => {
      let command: any = { command: "new" };
      callBackCommands(command);
    };

    //Refresh
    const handleRefreshClick = () => {
      setIsHandsOnActive(false);
      reloadGridCommand();
    };

    function handleOnClickImportButton() {
      callBackCommands({ command: "import" });
    }

    //Update organization relation
    const handleUpdateClick = (id: string) => {
      let command: any = { command: "update", recordId: id };
      callBackCommands(command);
    };

    //Clone organization relation
    const handleCloneClick = (id: string) => {
      let command: any = { command: "clone", recordId: id };
      callBackCommands(command);
    };

    function handleOnClickExportButton(element: any) {
      callBackCommands({ command: "export", payload: element });
    }

    //Modal Question to delete organization relation
    const handleDeleteClick = (id: string) => {
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = true;
      newModalQuestionState.callBackData = { recordId: id };
      setModalQuestionState(newModalQuestionState);
    };

    //Delete organization relation after question confirmation
    const handleCallBackModal = async (
      confirmDelete: boolean,
      callBackData: any,
    ) => {
      let expectedCodes: Array<number> = [200];
      let serviceClient = new WorkflowTypeService();
      let newModalQuestionState: TPModalQuestionState;
      newModalQuestionState = { ...modalQuestionState };
      newModalQuestionState.isShown = false;
      newModalQuestionState.callBackData = {};
      setModalQuestionState(newModalQuestionState);
      if (confirmDelete) {
        try {
          setIsLoadingScreen(true);
          let responseRequest = await serviceClient.delete(
            callBackData.recordId,
            true,
            true,
            expectedCodes,
          );
          setIsLoadingScreen(false);
          if (responseRequest.responseData.responseCode !== 500) {
            reloadGridCommand();
            callBackCommands({
              command: "delete",
              recordId: callBackData.recordId,
            });
          }
        } catch (error) {
          TPLog.Log(
            `Error ${componentFileName} handleCallBackModal ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(`Error ${componentFileName} handleCallBackModal ex`);
          setIsLoadingScreen(false);
        }
      }
    };

    //Handler to filter data inside data grid
    const handleSearchPatternChange = (newValue: string) => {
      let command1: commandType = {
        type: commandsEnum.change_search_pattern,
        payload: newValue,
      };
      dispatchCommand(command1);
    };

    //Filtered data based on selected pattern on search box
    const filteredData = () => {
      let searcheableColumns: Array<string> = [
        "id",
        "localizedDescription",
        "organizationId",
      ];
      let i: number;
      let search: string;
      search = adminState.searchPattern.trim();
      return adminState.gridData.filter(function (item: any, index: number) {
        if (search == "" || search.length <= 2) {
          return item;
        }
        for (i = 0; i <= searcheableColumns.length - 1; i++) {
          let itemany: any;
          itemany = item;
          if (
            itemany[searcheableColumns[i]] &&
            itemany[searcheableColumns[i]]
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase())
          ) {
            return item;
          }
        }
      });
    };

    //Modified code
    const exportToCSV = (
      apiData = filteredData(),
      fileName = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    ) => {
      
      const ws = XLSX.utils.json_to_sheet(apiData);
      /* custom headers */
      XLSX.utils.sheet_add_aoa(
        ws,
        [["id", "localizedDescription","promiseLimitUnit"]],
        { origin: "A1" },
      );
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      FileSaver.saveAs(data, fileName + ".xlsx");
    };

    //State grid and current filter
    const initialStateBLL: AdminStateType = {
      filterIsLoaded: false,
      columnsAreLoaded: false,
      selectedFilter: TPActiveOptions.ALL.toString(),
      gridColumns: [],
      gridData: [],
      searchPattern: "",
    };
    //reducer definition
    const [adminState, dispatchCommand] = useReducer(
      doCommand,
      initialStateBLL,
    );

    function doCommand(prevState: AdminStateType, command: commandType) {
      switch (command.type) {
        case commandsEnum.set_filterIsLoaded:
          let newStateFilter: AdminStateType;
          newStateFilter = { ...prevState };
          newStateFilter.filterIsLoaded = true;
          return newStateFilter;
        case commandsEnum.setup_grid_columns:
          let newStateColumns: AdminStateType = setupGridColumns(prevState);
          newStateColumns.columnsAreLoaded = true;
          return newStateColumns;
        case commandsEnum.reload_grid:
          let newStateGrid: AdminStateType;
          newStateGrid = { ...prevState };
          newStateGrid.gridData = command.payload;
          return newStateGrid;
        case commandsEnum.change_selectedFilter:
          let newStateChangeFilter: AdminStateType;
          newStateChangeFilter = { ...prevState };
          newStateChangeFilter.selectedFilter = command.payload;
          return newStateChangeFilter;
        case commandsEnum.change_search_pattern:
          let newStatePattern: AdminStateType;
          newStatePattern = { ...prevState };
          newStatePattern.searchPattern = command.payload;
          return newStatePattern;
        default:
          return prevState;
      }
    }

    //Run only once to load resources and active filters
    useEffect(() => {
      loadResourcesAndOrganizationsRelationsFilter()
        .then(function () {
          //set filter is loaded
          let command1: commandType = {
            type: commandsEnum.set_filterIsLoaded,
            payload: null,
          };
          dispatchCommand(command1);
        })
        .catch(function (error) {
          TPLog.Log(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
            TPLogType.ERROR,
            error,
          );
          console.error(
            `Error ${componentFileName} loadResourcesAndOrganizationsRelationsFilter ex`,
          );
        });
    }, []);

    //Run when filter is loaded to get columns
    useEffect(() => {
      if (adminState.filterIsLoaded) {
        let command1: commandType = {
          type: commandsEnum.setup_grid_columns,
          payload: null,
        };
        dispatchCommand(command1);
      }
    }, [adminState.filterIsLoaded, isHandsOnActive]);

    //Run to populate grid columns when columns are loaded or
    //user change filter
    useEffect(() => {
      if (adminState.columnsAreLoaded) {
        reloadGridCommand();
      }
    }, [adminState.columnsAreLoaded, adminState.selectedFilter]);

    const handleRowsPerPageChanged = (e: any) => {
      const recordSize = fixedHeaderScrollHeight / 10;
      const newRecordSize = recordSize * e;
      setFixedHeaderScrollHeight(newRecordSize);
    };

    return (
      <>
        <TPModalQuestion
          id="IdModalQuestion"
          title={deleteTitle}
          yesLabel={deleteOkLabel}
          noLabel={deleteCanceLabel}
          question={deleteQuestion.replace(
            "{recordId}",
            modalQuestionState.callBackData.recordId,
          )}
          callBackData={modalQuestionState.callBackData}
          isShown={modalQuestionState.isShown}
          callBackAnswer={handleCallBackModal}
        ></TPModalQuestion>
        <TPLoadingOverlay active={isLoadingScreen}>
          {!isHandsOnActive ? (
            <div>
              <div className="row">
                <div className="col">
                  <TPPageTitle>{titleLabel}</TPPageTitle>
                  <hr />

                  <TPPageFirstRow>
                    <TPPageActions>
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.icon}
                        onClick={() => handleNewClick()}
                        text={newLabel}
                        icon={TPIconTypes.newEntity}
                      />
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.icon}
                        onClick={() => handleRefreshClick()}
                        text={refreshLabel}
                        icon={TPIconTypes.refresh}
                      />
                      <TPButton
                        id="IdButton"
                        type={TPButtonTypes.icon}
                        onClick={() =>
                          exportToCSV(filteredData(), "workflow-admin-data")
                        }
                        text={exportLabel}
                        icon={TPIconTypes.fileDownload}
                      />
                      <TPButton
                        id="ImportButton"
                        key="ImportButton"
                        type={TPButtonTypes.icon}
                        icon={TPIconTypes.import}
                        iconStyle={{ transform: "rotate(270deg)" }}
                        text={importLabel}
                        onClick={handleOnClickImportButton}
                      />
                    </TPPageActions>

                    <TPFilterAndSearch>
                      <TPPageFilterContainer>
                        <TPSelect
                          id="IdSelect"
                          onChange={handleFilterChange}
                          dataSource={filterKeyValue}
                          value={adminState.selectedFilter}
                          labelText={filterIsActiveLabel}
                          isHorizontal={true}
                        ></TPSelect>
                      </TPPageFilterContainer>
                      <TPPageSearchContainer>
                        <TPTextBox
                          id="IdTextBox"
                          icon={TPIconTypes.search}
                          withIcon={true}
                          value={adminState.searchPattern}
                          placeholder={searchLabel}
                          onChange={(e: any) =>
                            handleSearchPatternChange(e.target.value)
                          }
                          isHorizontal={true}
                        />
                      </TPPageSearchContainer>
                    </TPFilterAndSearch>
                  </TPPageFirstRow>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {/* TableContainer,DataTableContainer,TableHeaderContainer,TableSearchContainer,TableToolbar */}

                  <TableContainer>
                    <TableHeaderContainer>
                      <TableToolbar></TableToolbar>
                      <TableSearchContainer></TableSearchContainer>
                    </TableHeaderContainer>
                    <DataTableContainer>
                      <DataTable
                        fixedHeader={true}
                        persistTableHead={true}
                        /*fixedHeaderScrollHeight={alturaGrid.toString() + "px"}*/
                        fixedHeaderScrollHeight={`${fixedHeaderScrollHeight}px`}
                        onChangeRowsPerPage={handleRowsPerPageChanged}
                        responsive={true}
                        dense={true}
                        striped={true}
                        highlightOnHover={true}
                        pagination
                        paginationPerPage={10}
                        paginationComponentOptions={
                          TPGlobal.paginationComponentOptions
                        }
                        columns={adminState.gridColumns}
                        data={filteredData()}
                        noDataComponent={thereAreNoRecordsToShow}
                        sortFunction={TPGlobal.datatableCustomSort}
                        customStyles={tableStyles}
                      />
                    </DataTableContainer>
                  </TableContainer>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h5 style={{ textAlign: "center", marginBottom: "10px" }}>
                Export and Bulk Load Workflow
              </h5>
              <h6>Workflow Information</h6>
              <HotTable
                data={[
                  [
                    renderedData.id,
                    renderedData.description,
                    renderedData.promiseLimit,
                    renderedData.isDirectSolution,
                    renderedData.isActive,
                    renderedData.isCasesUsed,
                  ],
                ]}
                rowHeaders={true}
                colHeaders={[
                  "Id",
                  "Description",
                  "Customer SLA",
                  "Is it direct solution?",
                  "Active?",
                  "Used in Cases",
                ]}
                // colHeaders={true}
                height="auto"
                width="auto"
                autoWrapRow={true}
                autoWrapCol={true}
                licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                contextMenu={["copy", "cut"]}
              />
              <TPButton
                type={TPButtonTypes.empty}
                onClick={() => handleRefreshClick()}
                className={"text-end"}
              >
                X
              </TPButton>
            </div>
          )}
        </TPLoadingOverlay>
      </>
    );
  },
);

export default WorkflowTypeAdmin;
